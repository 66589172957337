import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import AppHelper from '../../helpers/AppHelper';

const RouteListener = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = queryString.parse(location.search);

    // if lang search param exists in the url, change language if possible, and remove from url
    if (searchParams.lang) {
      const didLangChange = AppHelper.switchPortalLanguage(searchParams.lang);

      delete searchParams.lang;
      const purgedParamString = queryString.stringify(searchParams);

      history.replace({
        to: location.pathname,
        search: purgedParamString
      });

      if (didLangChange) {
        /**
         * Reload on language change because api will need to refetch to match current language.
         * 1 second delay to give adequate time for any redux actions to complete.
         */
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  }, [location]);

  const render = () => {
    return null;
  };
  
  return render();
};

export { RouteListener };