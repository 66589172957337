import ApiHelper from '../helpers/ApiHelper';
import AppHelper from '../helpers/AppHelper';
// import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

// const CONSTANT = require('../helpers/ConstantHelper');

export const getPsSummary = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`private-studio/summary?lang=${lang}`, true);
};

export const getPsOpenExams = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`private-studio/open-exams?lang=${lang}`, true);
};

