import React from 'react';
import _ from 'lodash';

import GLOBAL from './helpers/GLOBAL';
import AppHelper from './helpers/AppHelper';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue, grey, red } from '@material-ui/core/colors';
import { i18n as elementI18n } from 'element-react';
import * as elementLocale from 'element-react/src/locale/lang/en'

import moment from 'moment';
import 'moment/locale/en-au';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';

import history from './history';
import './i18n';
import { labels } from './lang/labels';
import { messages } from './lang/messages';
import { CustomRouter } from './routing';
import './App.css';


elementI18n.use(elementLocale);

// material-ui global theme
const globalTheme = createTheme({
  palette: {
    primary: {
      main: blue[500]
    },
    secondary: {
      main: red[500],
    },
    text: {
      primary: '#000',
      secondary: grey[500]
    }
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700
  }
});

// Global variable - DO NOT REMOVE
window.APP = {};

const App = () => {
  // DO NOT REMOVE - set the global locale for moment
  let locale = GLOBAL.get('locale');
  const i18nextLng = GLOBAL.get('i18nextLng', false);
 
  // if local is not determined
  if (_.isEmpty(locale)) {
    if (!_.isEmpty(i18nextLng)) {
      // make default moment format to 'en'
      locale = i18nextLng;
      let lang = AppHelper.convertLocaleToLang(locale);
      GLOBAL.set('locale', locale);
      GLOBAL.set('lang', lang);

    }
  }
  if (locale) {
    moment.locale(locale);
    labels.setLanguage(locale);
    messages.setLanguage(locale);
  }

  return (
    <ThemeProvider theme={globalTheme}>
      <CustomRouter
        history={history}
      />
    </ThemeProvider>
  );
};

export default App;
