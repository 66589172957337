import ApiHelper from '../helpers/ApiHelper';
// import AppHelper from '../helpers/AppHelper';
// import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

// const CONSTANT = require('../helpers/ConstantHelper');

export const studentCertificatePaySubmit = (params) => {
  return ApiHelper.post('studentcertificatepaysubmit', params, true);
};

