export const zhHans = {
  // START ---- actions::AuthActions
  fieldCannotEmpty: '此输入框不能留空。',
  enterValidEmail: '请输入有效邮箱地址。',
  unknownLoginError: '未知登录错误。请联系系统管理员。',
  onlyForCandidateAndTeacher: '此手机APP目前只开放给学生和老师使用。',
  incorrectLoginCredentials: '您的邮箱地址或密码不正确。',
  accountIsNotEmailVerified: '您的帐号未经验证。请登录您的邮箱以索取帐号验证邮件。',
  teacherAccountIsNotAdminVerified: '您的帐号未经系统管理员批准。请耐心等候，帐号经批准后您将会收到邮件通知。',
  sendPasswordResetEmailSuccess: '密码重设链接邮件已发送至 {0}。',
  sendPasswordResetEmailFail: '无法发送密码重设链接邮件至 {0}。请您稍后再试或联系系统管理员。',
  unableToLogIn: '暂时无法登录帐号。请稍后再试或联系系统管理员。',
  weChatNotInstalled: {
    title: '未安装微信',
    body: '没有安装微信软件，\n请您安装微信之后再试',
    buttonsText: '确认'
  },
  resetPasswordSuccess: '您已成功重置密码。',
  resetPasswordFail: '无法重置密码。请您稍后再试或联系系统管理员。',
  siwaOnlySupportIosThirteenAbove: 'Apple ID 登录仅限于 iOS 13 或以上的设备，请选择其他登录选项。',
  //   END ---- actions::AuthActions

  // START ---- components::CandidateRegisterScreen
  agreeForSubscription: '我同意接收优伯的电子邮件通讯及宣传资讯。',
  areYouWillingToProvideFreeTrial: '您是否愿意为优伯教学介绍的学生提供一堂免费体验课程？',
  registeredForGST: '我已经注册了GST',
  atLeastThreeYearsOld: '至少3岁才可以开始注册上课',
  atLeastThirteenYearsOld: '我已获得父母或监护人的同意，可以注册一个帐户以访问优伯教学平台和相关服务。',
  pleaseCheckPermission: '请确认你是否有父母或者监护人的同意',
  abnMustBeFilledForGST: '已注册GST的用户必须填写ABN。',
  //   END ---- components::CandidateRegisterScreen

  // START ---- components::TeacherRegisterScreen
  atLeastSixteenYearsOldToBeTeacher: '您必须是十六岁以上才能注册为老师。',
  //   END ---- components::TeacherRegisterScreen

  // START ---- actions::RegisterActions
  passwordMustBeAtLeast8Chars: '密码长度至少8位。',
  passwordMustConsistOfBothAlphabeticAndNumericChar: '密码必须由字母与数字符号构成。',
  passwordDontMatch: '密码与确认密码不匹配。',
  pleaseSelectACountry: '请选择国家。',
  pleaseSelectAnInstitutionType: '请选择学院类型。',
  emailHasExisted: '此邮箱地址已被注册。',
  checkEmailFail: '目前无法检验此邮箱地址是否已被注册。请您稍后再试。',
  teacherRegistrationSuccess: '帐号验证邮件已发送至 {0}. 请依照邮件内的指示验证您的帐号。',
  candidateRegistrationSuccess: '创建新帐号成功。请登录。',
  accountRegistrationFail: '无法注册新帐号。请稍后再试或联系系统管理员。',
  //   END ---- actions::RegisterActions

  // START ---- components::CandidateHomeScreen
  uploadPhotoBeforeEnrol: '请在报考考试或申请级数评估前上传个人头像。',
  homeScreenGreeting: '今天过得好吗？',
  logoutConfirmation: '请问您确认退出吗？',
  needHelpWithTraining: '需要培训方面的辅助？\n在优伯教学上预订您的课程吧！',
  //   END ---- components::CandidateHomeScreen

  // START ---- actions::UserActions
  teacherIdDoesNotExist: '老师证编码不存在。',
  abnWrongFormat: 'ABN必须为11位数字',
  unableToCheckTeacherId: '无法验证老师证编码。请您稍后再试。',
  updateProfileSuccess: '您已成功更新个人资料。',
  updateProfileFail: '更新个人资料失败。请您稍后再试。',
  studentUpdateProfileSuccess: '您已成功更新此学生的个人资料。',
  studentUpdateProfileFail: '无法更新此学生的个人资料。请您稍后再试。',
  pleaseSelectDateOfBirth: '请选择您的出生日期。',
  pleaseSelectYourGender: '请选择您的性别。',
  emailsDoNotMatch: '新邮箱地址与确认邮箱地址不匹配。',
  identicalToTheCurrentEmail: '与目前的邮箱地址相同。',
  emailChangeSuccess: '您已成功更换您的邮箱地址。请您在下一次登录时使用{0}。',
  emailChangeFail: '无法更换邮箱地址。请您稍后再试或联系系统管理员。',
  exitEdit: '如果您现在退出, 将会丢失修改内容',
  exitWizard: '如果您现在退出，您的选项并不会被保留',
  //   END ---- actions::UserActions

  // START ---- components::TnCScreen
  agreeToTermsAndCondition: '我同意并接受所有的条款与条件。',
  //   END ---- components::TnCScreen

  // START ---- components::VideoGalleryScreen
  confirmDeletevideo: '您确定删除此视频？',
  youCanChooseExamVideoRes: '您可以选择480p或720p作为视频分辨率。然而，720p的视频将需要更长的上传时间。',
  //   END ---- components::VideoGalleryScreen

  // START ---- actions::ExamActions
  enterAValidVideoLink: '请输入有效的视频链接。（来自受支持的视频寄存服务网站）',
  examDetailsSaveSuccess: '考试详情储存成功。',
  examDetailsSaveFail: '考试详情储存失败。请稍后再试或联系系统管理员。',
  pleaseSelectAMusicType: '请选择曲目类型。',
  pleaseSelectAMusicTitle: '请选择曲目名字。',
  photoIdIsNeededForDiploma: '文凭级别的考试需要证件照。',
  examSubmitSuccess: '提交考试成功。',
  examSubmitFail: '提交考试失败。请您稍后再试或联系系统管理员。',
  initiatePaymentGatewayFail: '无法启动支付网关。请您稍后再试。',
  getLatestPaymentInfoFail: '无法索取最新的付款资料。请您稍后再试。',
  getUpdatedExaminationPriceFail: '无法索取最新的考试报名费。请您稍后再试。',
  affiliationCodeNotValid: '附属代码无效。',
  checkAffiliationCodeFail: '无法验证附属代码。请您稍后再试。',
  pleaseSelectAnInstrument: '请选择乐器。',
  pleaseSelectAGrade: '请选择级别。',
  paymentAmountNotUpToDate: '无法更新付款银额。请重新报考考试。',
  enrolExamSuccess: '您已成功付款并报考考试。',
  enrolExamFail: '报考考试失败。请您稍后再试或联系系统管理员。',
  enrolExamNotYetProcessed: '您已成功付款，但是系统还在核实此交易中。请稍后再查看您的付款历史记录以及考试列表。若您的付款历史记录在一个小时后还未更新，请联系系统管理员。',
  enrolmentPaymentGatewayError: '支付网关出现错误。请重新再试或联系系统管理员。',
  examReportSentEmailSuccess: '您的考试报告已发送至{0}。',
  examReportSentEmailFail: '无法发送您的考试报告至{0}。请您稍后再试或联系系统管理员。',
  examCertSentEmailSuccess: '您的考试证书已发送至{0}。',
  examCertSentEmailFail: '无法发送您的考试证书至{0}。请您稍后再试或联系系统管理员。',
  appealSubmitSuccess: '您已成功提交上诉请求。',
  appealSubmitFail: '无法提交上诉请求。请您稍后再试或联系系统管理员。',
  appealPaymentGatewayError: '支付网关出现错误。请重新申请上诉请求或联系系统管理员。',
  purchaseCertSuccess: '您已成功购买证书。',
  purchaseCertFail: '无法购买证书。请您稍后再试或联系系统管理员。',
  pleaseSelectACandidate: '请选择学生。',
  videoUploadSuccess: '您已成功上传您的视频。视频链接是 {0}',
  vidUploaded: '您已成功上传您的视频',
  videoUploadFail: '无法上传您的视频。请稍后再试或联系系统管理员。',
  pleaseSelectVideo: '请选择您要上传的视频。',
  youHaveNotUploadedAnyVideo: '您未为此曲目上传任何视频。',
  youHaveNotUploadedThisVideo: '您未上传此视频。',
  uploadVideoBeforeSaving: '请您在储存考试之前上传此视频。若您只想单单储存其他考试详情，请删除此视频。',
  //   END ---- actions::ExamActions

  //  START ---- actions::SyllabusActions
  musicPieceExistInSystem: '这曲目已收录在此系统中。',
  checkMusicPieceFail: '现在无法验证曲目。请您稍后再试。',
  pleaseIncludeMusicSheet: '请附上曲目乐谱的照片。',
  musicPieceSubmitSuccess: '您的新曲目申请已提交成功。',
  musicPieceSubmitFail: '新曲目申请失败。请您稍后再试或联系系统管理员。',
  //   END ---- actions::SyllabusActions

  //  START ---- components::StudentProfileEditScreen
  confirmRemoveStudentFromList: '您确定将此学生从您的学生列表上删除吗？',
  removeStudentSuccess: '您已成功删除此学生。',
  removeStudentFail: '无法删除此学生。请您稍后再试或联系系统管理员。',
  unsavedChangesWillBeLost: '此屏幕拥有未储存的更改，退出此屏幕后所有更改将消失。请问您确认退出此屏幕吗？',
  //    END ---- components::StudentProfileEditScreen

  //  START ---- components::EditExamScreen/SummaryTab
  photoIdRequirement: '报考文凭级别的学生们必须上传一份证件照（所接受的证件包括身份证、学生证或驾驶执照）。',
  additionalRequirement1: '请确保您三首曲目演奏的时间是总共至少{0}分钟或以上。',
  additionalRequirement2: '我们将接受以两首曲目合并为一个视频的方式来达到最低演奏时间的条件。在这种情况下，一共有四首曲目将会被评估。然而，其中两首较短曲目的权重将被视为一首。',
  additionalRequirement3: '您若提交超过三首曲目，请在下方的备注另行注明。',
  //    END ---- components::EditExamScreen/SummaryTab

  //  START ---- actions::CommonActions
  pleaseUpdateApp: '您的APP版本太旧了，请更新您的APP以继续使用。谢谢您。',
  versionCheckFail: '暂时无法验证您的APP版本。请您重新启动APP或联系系统管理员。',
  somethingWrongPleaseRestartAppOrLoginAgain: '不好意思，系统出了状况。请重启APP或重新登录。',
  sessionHasExpiredPleaseRelogin: '您的有效使用时段过期了，请重新登录。',
  //   END ---- actions::CommonActions

  // START ---- components::BrowseMusicLibraryScreen
  enterAtLeastThreeChar: '请输入至少三个字符',
  enterAtLeastTwoChar: '请输入至少两个字符',
  //   END ---- components::BrowseMusicLibraryScreen

  //  START ---- components::MusicLibraryDetailsScreen
  videoForThisPieceIsUnavailable: '此曲目无视频可播。',
  updateEmailBeforePurchase: '请在购买曲谱之前添加有效的电子邮件地址。我们将把曲谱发至您的邮箱。',
  thisIsASoftcopySheetMusic: '这是电子曲谱。处理好您的订单后，我们将把曲谱发至您的邮箱。',
  //    END ---- components::MusicLibraryDetailsScreen

  //  START ----- components::AccountUpgradeChooseRoleScreen
  upgradeAccountMessage: '为了报考考试以及享用更多的功能，您必须完成您的个人资料。',
  //    END ----- components::AccountUpgradeChooseRoleScreen

  //  START ----- components::AccountUpgradeChooseRoleScreen
  pleaseProvideEmailQuickLogin: '看来您未允许让我们获取您Facebook帐号的邮箱地址。但是为了创建帐号，我们仍需要您的邮箱地址。',
  pleaseProvideEmailQuickLoginWX: '如果您用微信帐号登录，为了创建帐号，我们需要您的邮箱地址。',
  //    END ----- components::AccountUpgradeChooseRoleScreen

  //  START ----- components::SocialMediaPasswordScreen
  linkFbAccountMessage: '您刚输入的邮箱地址已经存在于系统中。若您欲连接此邮箱地址的帐号与您的Facebook帐号，我们需要确认此帐号是否是您的。为了安全起见，请输入密码并登录。',
  //  END ----- components::SocialMediaPasswordScreen

  // START ---- actions::PieceEvaluationActions
  pieceEvaluationReportSentEmailSuccess: '您的级数评估报告已发送至{0}。',
  pieceEvaluationReportSentEmailFail: '无法发送您的级数评估报告至{0}。请您稍后再试或联系系统管理员。',
  requestPerformanceEvaluationSuccess: '您已成功付款并申请级数评估。',
  requestPerformanceEvaluationFail: '申请级数评估失败。请您稍后再试或联系系统管理员。',
  requestPerformanceEvaluationNotYetProcessed: '您已成功付款，但是系统还在核实此交易中。请稍后再查看您的付款历史记录以及级数评估列表。若您的付款历史记录在一个小时后还未更新，请联系系统管理员。',
  performanceEvaluationDetailsSaveSuccess: '级数评估详情储存成功。',
  performanceEvaluationDetailsSaveFail: '级数评估详情储存失败。请稍后再试或联系系统管理员。',
  performanceEvaluationDetailsSubmitSuccess: '提交级数评估成功。',
  performanceEvaluationDetailsSubmitFail: '提交级数评估失败。请您稍后再试或联系系统管理员。',
  youHaveNotSelectedAnyVideo: '您未选择任何视频。',
  //   END ---- actions::PieceEvaluationActions

  // START ---- component::LandingScreen
  confirmLoginAsAdmin: '是否确认登录为系统管理员？',
  loginAsAdminFail: '无法登录为系统管理员。请您稍后再试或联系系统管理员。',
  confirmLoginAsSupport: '是否确认登录为客服？',
  loginAsSupportFail: '无法登录为客服。请您稍后再试或联系系统管理员。',
  backToTeacherFail: '无法返回帐号界面，请您稍后再试',
  confirmBackToTeacher: '确认要回到您的帐号吗?',
  pleaseEnterValidMobileNumber: '请输入您的手机号码以完成帐号注册。',
  updateMobileNumSuccess: '您已成功更新您的手机号码。',
  updateMobileNumFail: '无法更新您的手机号码。请稍后再试。',
  approvedTeachers: '所有经由优伯平台所推荐的老师都是透过层层筛选及在线师资培训过的优质老师。许许多多学员的傲人成绩就是我们最好的口碑！',
  customerService: '优伯提供24小时专属教务客服，为家长和老师们解读各种学习上和技术上的问题。',
  safePayment: '安全快捷的在线支付，领先的高科技反欺诈技术和用户保障服务，为每一笔优伯学费提供风险保障',
  withdrawalGuarentee: '学员及老师可以随时提出余额取款要求。平均在6个小时内即可打款到用户微信/支付宝帐号上',
  noMatchFound: '抱歉, 未找到相关结果',
  shareYourPerformance: '与你的朋友和家人分享',
  //   END ---- component::LandingScreen

  // START ---- component::LandingScreen/AssessTab
  tapToAccessMyExams: '点击进入',
  //   END ---- component::LandingScreen/AssessTab

  // START ---- actions::EventActions
  eventEnrolmentSuccess: '您已成功报名此项目，请按继续以上传您的演奏视频。',
  eventEnrolmentFail: '报名项目失败。请您稍后再试或联系系统管理员。',
  saveEventEnrolmentDetailSuccess: '项目报名详情储存成功。',
  saveEventEnrolmentDetailFail: '项目报名详情储存失败。请稍后再试或联系系统管理员。',
  submitEventEnrolmentDetailSuccess: '提交项目报名成功。',
  submitEventEnrolmentDetailFail: '提交项目报名失败。请您稍后再试或联系系统管理员。',
  pleaseAttachYourPhotoId: '请附上您的证件照。',
  pleaseSelectAGenre: '请选择曲风。',
  pleaseAttachYourPerformanceVideo: '请附上您的演奏视频。',
  //   END ---- actions::EventActions

  // START ---- component::CandidateNewEventEnrolmentScreen
  eventAffiliateCodeInfo: '为了能够参加此项目独家的造势活动以及有关的演奏会，请扫一扫二维码或输入我们合作伙伴的附属代码。',
  //   END ---- component::CandidateNewEventEnrolmentScreen

  // START ---- component::AddAffiliateCodeScreen
  checkAffiliateCodeFail: '无法验证附属代码。请您稍后再试或联系系统管理员。',
  affiliateCodeIsSuccessfullyApplied: '成功添加附属代码“{0}”！',
  affiliateCodeIsInvalid: '附属代码无效。',
  //   END ---- component::AddAffiliateCodeScreen

  // START ---- actions::CertificateActions
  certUploadSuccess: '证书上传成功。管理员审查证书后将提升您的用户等级。',
  photoUploadSuccess: '您已成功上传成就照片。',
  certUploadFail: '证书上传失败。请您稍后再试或联系系统管理员。',
  photoUploadFail: '成就照片上传失败。请您稍后再试或联系系统管理员。',
  //   END ---- actions::CertificateActions

  // START ---- components::UserLevelInfoListScreen/UserLevelInfoListScreen
  userLevelInfoMessage: '您可通过以下方式升级用户等级：\n  - 上传各类相关评定等级证书。\n  - 通过此APP报考并完成CBE音乐考级。\n\n在通过管理员审核确认后，将跟据以下图示升级用户等级。',
  //   END ---- components::UserLevelInfoListScreen/UserLevelInfoListScreen

  // START ---- components::EventDetailSwiperScreen/EventDetailSwiperScreen
  thereIsNoEventsCurrently: '目前还未有项目进行中。',
  //   END ---- components::EventDetailSwiperScreen/EventDetailSwiperScreen

  // START ---- actions::PaymentActions
  paymentReceiptSentEmailSuccess: '您的收据已发送至{0}。',
  paymentReceiptSentEmailFail: '无法发送您的收据至{0}。请您稍后再试或联系系统管理员。',
  //   END ---- actions::PaymentActions

  // START ---- components::LandingScreen/LandingTab/ProfileDetailVideoTab/MyVideoTab
  youHaveNotUploadedAnyVideoEnrolExamOrEvent: '您未上传任何视频。今天就报名参加项目或报考考试并上传您的演奏视频吧！',
  //   END ---- components::LandingScreen/LandingTab/ProfileDetailVideoTab/MyVideoTab

  // START ---- components::EventEnrolmentListScreen
  youHaveNotEnrolIntoAnyEvents: '您未报名参加任何项目。今天就报名吧，别错过哦！',
  //   END ---- components::EventEnrolmentListScreen

  // START ---- components::EventDetailScreen
  uploadPhotoBeforeEnrolEvent: '请在报名参加项目前上传个人头像。',
  //   END ---- components::EventDetailScreen

  // START ---- actions:VideoActions
  contentNotFound: '内容不存在。',
  unableToLoadContent: '无法载入内容。请稍后再试或联系系统管理员。',
  //   END ---- actions:VideoActions

  // START ---- components::WantToTeachScreen
  noSelection: '请至少选择一个选项',
  noABN: '请确认您的ANB和GST',
  noCert: '请上传至少一张证书',
  createdTeachProfile: '恭喜！您的老师主页已创建成功。',
  createdProfileFailed: '创建您的老师主页失败，请稍后尝试。',
  deleteCertificate: '您确定要删除此证书吗？',
  //   END ---- components::WantToTeachScreen

  // START ---- components::TeachProfileScreen
  noProfile: '未完成',
  verified: '已通过审核',
  awaiting: '正在审核中',
  rejected: '审核失败',
  deactivated: '审核无效',
  deleteAchievement: '您确定您要删除此成就吗？',
  somethingWrong: '发生未知错误，请稍后再尝试。',
  deletePrivateStudent: '您确定要删除此学生吗?',
  //   END ---- components::TeachProfileScreen

  // START ---- components::LearnProfileScreen
  failedToGetStudentQuestion: '获取问题失败',
  uploadProfilePhoto: '请上传头像照片',
  failedToCreateLearnProfile: '创建您的学生主页失败，请稍后尝试。',
  createdLearnProfile: '恭喜！您的学生主页已创建成功。',
  //   END ---- components::LearnProfileScreen

  // START ---- components::TopUpFundScreen
  topUpInfo: '充值金额必须介于{0}{1}至{0}{2}。',
  //   END ---- components::TopUpFundScreen

  // START ---- actions::FundActions
  topUpFundSuccess: '您已成功充值{0}{1}{2}。',
  topUpFundFail: '充值学习币失败。请您稍后再试或联系系统管理员。',
  unableToTopUpFund: '暂时无法充值',
  topUpPaymentProcessing: '您已成功付款，我们会为您尽快处理此交易。请稍后再次查看您的交易记录。如果1小时后付款仍未记录，请与系统管理员联系。',
  addPaymentMethodSuccess: '创建提款方式成功。',
  addPaymentMethodFail: '暂时无法创建提款方式。请您稍后再试或联系系统管理员。',
  //   END ---- actions::FundActions

  // START ---- components::CreateLessonScreen
  thisWillDetermineYourLessonDuration: '请您仔细确认每节课的时间。',
  thisWillDetermineYourMinimumLessonDuration: '请设定您每一节课的最短时段。',
  tellUsMoreAboutYourLesson: '告诉我们更多关于您将要设置的课程。课程名称和介绍将帮助学生们了解您在课程中提供的内容。',
  lessonFeeForEachSession: '课程结束后，该金额将记入您的帐户。',
  lessonRateInfo: '请根据您当地税收法律的指定加上GST或VAT等来设定您的课程学费。YOUBRIO将一律不负责老师的纳税申报。收费模式将会以计时收费。课程结束后，该金额将记入您的帐户。',
  reviewStudentInfo: '当学生预约您的课程时，您可以选择接受或拒绝此课程。',
  youCanSetMaximumSlot: '您可以为本课程设置最多{0}个时段。',
  byProceedYouAgreeToTnC: '* 如果继续操作，即表示您同意YOUBRIO的条款和条件。',
  youAreUnableToAlterLessonSettingDueToOrgPolicy: '由于 {0} 的学校政策，您无法更改一些课程设置。',
  lessonFeeDpositedWhenLessonComplete: '*课程结束后课时费会及时到您的优伯钱包里！',
  noStudentAdded: '你还未添加任何学生',
  theBookingWillBeMadeOnBehalfOfTheStudentAutomatically: '这是一堂免费的课程，系统将自动为请求课程的学生预约这堂课。是否继续？',
  thisStudentIsUnableToBookFreeLessonFromYou: '这位学生无法跟您预约您免费课程。',
  someOfYourStudentsAreUnableToBookFreeLessonFromYou: '部分已选的学生无法跟您预约免费课程，您的通知列表已重置。',
  //   END ---- components::CreateLessonScreen

  // START ---- actions:LessonActions
  createNewLessonSuccess: '您已成功创建一堂新课程。',
  createNewLessonFail: '无法保存新课程。请稍后再试或联系系统管理员。',
  bookLessonSuccess: '您已成功预订课程。',
  bookLessonFail: '无法预订课程。请稍后再试或联系系统管理员。',
  createNewChangeRequestSuccess: '您已成功发送更改课程请求。',
  createNewChangeRequestFail: '无法发送您的请求。请稍后再试或联系系统管理员。',
  deleteChangeRequestSuccess: '您已成功撤销了您的课程更改请求。',
  deleteChangeRequestFail: '无法撤销您的请求。请稍后再试或联系系统管理员。',
  approveChangeRequestSuccess: '您已批准了此课程更改请求。请留意已更新的上课时间。',
  approveChangeRequestFail: '无法批准此请求。请稍后再试或联系系统管理员。',
  rejectChangeRequestSuccess: '您已拒绝了此课程更改请求。',
  rejectChangeRequestFail: '无法拒绝此请求。请稍后再试或联系系统管理员。',
  pleaseDeleteChangeRequestForNew: '您目前有一个待定的课程更改请求。若您想发送新的请求，请先撤销目前的请求。',
  pleaseRespondChangeRequestForNew: '您目前有一个待定的课程更改请求。请先回应目前待定的请求。',
  //   END ---- actions:LessonActions

  // START ---- actions::YoubrioProfileActions
  somethingWentWrongPlsTryLtr: '抱歉！发生错误。请稍后再试。',
  //   END ---- actions::YoubrioProfileActions

  // START ---- components::BookLessonScreen
  requestRequiredLesson: '老师将会查看到您的个人主页。',
  requestRequiredLessonDetail: '预约课程操作完成，系统将进行预授权扣款。老师确认并接受该预约课程时，预授权自动转成实际付款。如遇老师拒绝，款项自动返回至您的钱包。',
  youWillPayWithYourStudyWallet: '在预订课程成功后，将从您的学习钱包中扣除总费用。',
  youHaveInsufficientWalletBalance: '您的学习钱包余额不足，请充值再继续。',
  letYourTeacherKnowYourLessonGoals: '请让您的老师知道您的学习目标以及您想学的内容。老师将会以您的要求备课并进行教学。',
  //   END ---- components::BookLessonScreen

  // START ---- components::TeacherLessonBookingDetailScreen
  lessonComplete: '请问您想做什么?',
  giftReceived: '收到礼物',
  noGift: '这节课您还未收到任何礼物',
  areYouSureRejectLesson: '您确定要拒绝此预订吗？此操作无法撤消。',
  areYouSureCancelLesson: '您确定要取消此预订吗？此操作无法撤消。',
  areYouSureLateCancelLesson: '您确定要取消此预订吗？您将无法索取退款因为这是临时取消。',
  cancelLessonInfo: '您可以在课程开始前的{0}个小时取消并索取退款。若超越时间后临时取消， 您将无法索取退款。',
  pleaseGrantPermissionsForLesson: '请授权YOUBRIO所需要的权限以确保您的课程能够正常的进行。请到APP设定开通权限。',
  pleaseGrantPrerequiredPermissionsForLessonAndroid: '请授权YOUBRIO使用相机、麦克风及储存空间以确保您的课程能够正常的进行。请到APP设定开通权限。',
  pleaseGrantPrerequiredPermissionsForLessonIos: '请授权YOUBRIO使用相机、麦克风及相册以确保您的课程能够正常的进行。请到APP设定开通权限。',
  dualDeviceInfo: '双设备模式只在您的第二台设备开启智能板，从而提高视频质量并改善您的上课体验！',
  //   END ---- components::TeacherLessonBookingDetailScreen

  // START ---- components::LandingScreen/CompleteProfileBlockerModal
  completeProfile: '若要使用此功能，您需要完成个人资料',
  completeProfileStartRemoteLesson: '完成您的个人资料\n开始在线教学和考级',
  //   END ---- components::LandingScreen/CompleteProfileBlockerModal

  // START ---- components::TeacherEditLessonScreen
  updateLessonSuccess: '您的课程已成功更新。',
  updateLessonFail: '无法更新您的课程。请稍后再试或联系系统管理员。',
  thisLessonIsUnlisted: '本课不公开。它对公众不可见，您将不会收到本课程的任何预订。',
  thisLessonIsListed: '此课程已列出，公众可以预订。',
  areYouSureUnlistLesson: '您确定要取消此课程吗？您将无法获得本课程的任何新预订。您可以稍后再重新使用它。 （这不会影响本课程的所有现有预订。）',
  areYouSureDeleteLesson: '您确定要删除此课程时段吗？（这不会影响此时段的所有现有预订。）',
  deleteLessonSuccess: '课程已成功删除。',
  deleteLessonFail: '无法删除课程。请稍后再试或联系系统管理员。',
  youHaveNotCreatedAnyLesson: '您还未创建任何课程。',
  //   EventDetailSwiperScreen ---- components::TeacherEditLessonScreen

  // START ---- components::MyLessonTeacherListScreen
  youHaveBookedAnyLessonWithAnyTeacher: '您没有与任何老师预约任何课程。',
  noTeacherFound: '您没有老师，是否前往搜索老师？',
  //   END ---- components::MyLessonTeacherListScreen

  // START ---- components::MyLessonStudentListScreen
  youDontHaveAnyStudentYet: '您还没有学生。\n点击并添加学生。',
  youHaveNoFavouriteUserYet: '您未收藏任何用户。',
  //   END ---- components::MyLessonStudentListScreen

  // START ---- components::ProfilePreviewScreen
  bookStudentIsOnlyForStudent: '请另外申请一个学生帐号以预定课程。',
  notOfferingAnyLesson: '这个老师目前还未提供任何课程。你是否要发送一个课程请求？',
  wouldYouWantToAddSomeoneAsYourRole: '请问您是否要添加{0}为您的{1}？',
  someoneIsYourRole: '{0}是您的{1}。',
  youAreUnableToAddThisUser: '您无法添加此用户。',
  youHaveAlreadyAddedThisUser: '您已添加此用户。',
  wouldYouLikeToAddThisTeacherAsFavourite: '请问您是否要加这位老师入收藏名单？',
  //   END ---- components::ProfilePreviewScreen

  // START ---- components::ManageMyLessonScreen
  youHaveNoUpcomingLessons: '您暂时没有即将开始的课程。',
  //   END ---- components::ManageMyLessonScreen

  // START ---- components::TeacherLessonBookingDetailScreen
  lessonRoomAllowedTimeInfo: '您可以在此课程开始前的{0}分钟进入教室。',
  completeLessonAllowedTimeInfo: '您可以在此课程开始时间后{0}分钟完成课程。',
  completeLessonTimeExactInfo: '您只能在此课程结束后才能完成课程。',
  areYouSureCompleteLesson: '完成此课程后，课程费用将转入您的钱包。完成课程之后，您将无法进入教室。',
  completeLessonNow: '你想要完成这个课程吗？完成之后，您将无法进入教室。',
  //   END ---- components::TeacherLessonBookingDetailScreen

  // START ---- components::LessonSessionActions
  itsTooEarlyToEnterLessonRoom: '现在进入教室还为时过早。',
  lessonTimeEndedUnableToEnterRoom: '由于课程时间已结束，因此您无法进入教室。',
  unableToEnterLessonRoom: '无法进入教室。请稍后再试或联系系统管理员。',
  pleaseCompleteLearnProfile: '请把您的个人资料填写完整并注册为学生后再预定课程。',
  //   END ---- components::LessonSessionActions

  // START ---- components::ProfileDetailTab
  uploadCertToTeach: '上传你的证书并完整你的个人主页可获得更多曝光率',
  noReview: '嗯。看来你暂时还没有任何评论',
  tapToRefresh: '点击屏幕刷新',
  tapHereToUploadCert: '点击此处以上传证书',
  //   END ---- components::ProfileDetailTab

  // START ---- components::TopUpPaymentMethodScreen
  serviceOnlyAvailableForChinaVerifiedUser: '此服务只限于中国身份证实名认证过的支付宝帐号使用。',
  unableToGetExchangeRate: '无法索取汇率。',
  //   END ---- components::TopUpPaymentMethodScreen

  // START ---- components::FundWithdrawScreen
  withdrawInfo: '每一次提现必须介于 {0}{1} - {0}{2} 之间，钱包也必须要有足够的余额。',
  serviceFee: '请注意每笔取款将会收取 {0}% 的服务费',
  //   END ---- components::FundWithdrawScreen

  // START ---- component::FundWithdrawAddPaymentMethodScreen
  confirmAllInfoAreCorrect: '我在此确认以上所填写的资料都是正确的。',
  addPaymentMethodInfo: '请确保所填写的资料都是正确的。',
  fullNameMustBeCorrect: '所输入的全名必须与登记在此付款平台帐号的全名匹配。为了保护我们的用户，我们将拒绝任何有错误资料的提款请求。',
  invalidPhoneOrEmail: '此手机号码/电子邮件地址无效。',
  invalidNumber: '输入此数字无效',
  enterValidBankAccNo: '银行户口号码无效。',
  pleaseEnterValidBSB: 'BSB必须要有6个字符。',
  pleaseEnterValidIBAN: '请输入有效的国际银行帐户号码（IBAN）。',
  pleaseEnterValidSWIFT: '请输入有效的SWIFT/BIC代码。',
  //   END ---- component::FundWithdrawAddPaymentMethodScreen

  // START ---- component::FundWithdrawPaymentMethodListScreen
  noPaymentMethodFound: '无提款方式。',
  allowedUpToNPaymentMethod: '您只能够储存{0}个提款方式。欲添加新的提款方式，请删除旧的提款方式。',
  //   END ---- component::FundWithdrawPaymentMethodListScreen

  // START ---- component::WithdrawalSummaryScreen
  pleaseVerifyYourself: '此交易需要核实身份，请输入本帐号的密码。',
  makeWithdrawalSuccess: '创建提款请求成功。提款手续处理完毕后我们会通知您。',
  makeWithdrawalFailed: '创建提款请求失败。请您稍后再试或联系系统管理员。',
  enteredIncorrectPassword: '您输入了错误的密码。',
  thisOnlyAppliesForAusUser: '以上只供澳洲用户填写。',
  //   END ---- component::WithdrawalSummaryScreen

  // START ---- component::FundWithdrawalListScreen
  balanceInsufficient: '钱包余额不足，提款最低数额为 {0}。',
  youHaveNotMadeWithdrawal: '您未提交任何提款请求。',
  //   END ---- component::FundWithdrawalListScreen

  // START ---- component::WizardResultScreen
  couldNotFindMatchingResult: '无匹配的结果。',
  //   END ---- component::WizardResultScreen

  //  START ---- components::BrowseLessonListScreen
  teacherNoOfferLesson: '这位老师目前没有交任何的课程。',
  //    END ---- components::BrowseLessonListScreen

  //  START ---- components::WriteReviewScreen
  exitReview: '你确定不想提交评价吗？将会丢失修改内容。',
  reviewCommentPH: '告诉我们多一些你的评价...',
  noRating: '你还没有给任何评分',
  reviewSuccess: '评分提交成功',
  reviewFail: '评分提交失败，请稍后再试',
  //    END ---- components::WriteReviewScreen

  //  START ---- components::ViewReviewScreen
  getReviewFailed: '获取评论失败，请稍后再尝试',
  //    END ---- components::ViewReviewScreen

  // START ---- component::BrioWalletSummaryScreen
  pleaseCompleteProfileBeforeTopUp: '请把您的个人资料填写完整并注册为学生后再充值钱包。',
  //   END ---- component::BrioWalletSummaryScreen

  // START ---- component::SchoolIntroScreen
  doYouOwnASchool: '您是否拥有学校或教育性的机构？把它登记在YOUBRIO并让您的学生及老师都加入！',
  benefitsOfSchool: '当您的学生向您的老师预定课程时，学生们可享有群组折扣。',
  searchForSchool: '您也可以寻找并加入学校。群组的力量是很大的，一起与其他会员携手达到更高的成就！',
  studentJoinSchoolInfo: '现在就加入学校以发掘更多老师。当您预定同学校的老师的课程时，您可享有学校所提供的折扣！',
  //   END ---- component::SchoolIntroScreen

  // START ---- component::SchoolProfileScreen
  getSchoolProfileFailed: '无法获取学校简介。请您稍后再试或联系系统管理员。',
  saveSchoolProfileFailed: '无法储存学校简介。请您稍后再试或联系系统管理员。',
  saveSchoolProfileSuccessful: '您已成功更新学校简介。',
  yearFormatError: '请输入正确的年份格式。',
  yearInvalid: '请输入有效年份。',
  //   END ---- component::SchoolProfileScreen

  // START ---- component::CreateSchoolScreen
  applySchoolSuccess: '您已成功提交您的学校申请。当系统管理员批准您的申请时，公众可参阅您学校的简介。',
  applySchoolFail: '无法提交您的申请。请您稍后再试或联系系统管理员。',
  pleaseEnterValidYear: '请输入有效年份。',
  commissionInfo: '当您的学生向您的老师预定课程时（双方都是您学校的成员），此百分比的学费将作为学校推荐的佣金。\n\n例子，如果佣金百分比是15%以及学费是¥100，¥15将会作为学校推荐的佣金。',
  newOrgCommissionInfo: '当您学校的老师完成课程时，这是学校将会收到的默认提成。若想调节提成率，您可以在学校设置界面内委任老师至不同的学费分类。',
  discountInfo: '当您的学生向您的老师预定课程时（双方都是您学校的成员），学生获得此百分比的折扣。\n\n例子，如果折扣百分比是5%以及学费是¥100，学生只需付¥95的学费。',
  profitInfo: '佣金百分比是包括学生的折扣百分比，所以最终的盈利百分比是（佣金百分比）-（折扣百分比）。\n\n例子，如果佣金百分比是15%，折扣百分比是5%以及学费是¥100，您的学校将获得¥10，因为学生获¥5的折扣。',
  makeSureAllSchoolInfoCorrect: '请确保所填写的资料都是正确的。系统管理员有权利拒绝任何含有不完整或不正确资料的请求。',
  //   END ---- component::CreateSchoolScreen

  // START ---- component::ManagingSchoolListScreen
  youAreNotManagingAnySchoolORg: '您未管理任何学校。',
  //   END ---- component::ManagingSchoolListScreen

  // START ---- component::SchoolTeacherListScreen
  noTeachersFound: '未找到任何老师。',
  noTeachersNewRequest: '未找到任何相关老师，请点击“老师请求” 优伯教学就会帮您安排最合适的老师',
  areYouSureYouWantToRemoveThisMember: '您是否确认要把此会员从学校删除？',
  //   END ---- component::SchoolTeacherListScreen

  // START ---- component::SchoolStudentListScreen
  noStudentsFound: '未找到任何学生。',
  //   END ---- component::SchoolStudentListScreen

  // START ---- component::SchoolPendingMembershipListScreen
  noPendingJoinRequestFound: '未找到任何待批准的加入请求。',
  areYouSureYouWantToDeclineRequest: '您是否确认要拒绝此加入请求？',
  areYouSureYouWantToBlacklistRequest: '您是否确认要列入黑名单？被列入黑名单的老师以及学生都无法搜索与加入此学校。',
  //   END ---- component::SchoolPendingMembershipListScreen

  // START ---- component::SchoolBlacklistedMembershipListScreen
  blacklistInfo: '被列入黑名单的老师以及学生都无法搜索与加入此学校。',
  youHaveNotBlacklistedAnyone: '您未列任何人入黑名单。',
  //   END ---- component::SchoolBlacklistedMembershipListScreen

  // START ---- component::SchoolProfilePreviewScreen
  areYouSureWantToLeave: '您是否确认要退出此学校吗？',
  joinOneSchoolAtATime: '老师们一次只许加入一间学校。请在加入新的学校前退出\'{0}\'。',
  completeProfileBeforeJoiningOrg: '请在加入任何学校之前完成您的个人简介。',
  comNotice: '当你教此学校的学生时，学校会收取 {0}% 作为手续费。',
  //   END ---- component::SchoolProfilePreviewScreen

  // START ---- component::SchoolAdminProfileScreen
  wouldYouWantCreateFeeCategory: '您尚未创建任何学费类别。您可以委任老师至不同的学费类别以更有效地管理老师的课程收费以及个性化学费提成率。是否现在前往创建学费类别？',
  //   END ---- component::SchoolAdminProfileScreen

  // START ---- component::SchoolAdminProfileScreen/SchoolProfileBlockerModal
  schoolIsDeactivated: '您的学校简介因触犯了YOUBRIO的用户条规已被注销了。请您联系系统管理员以采取下一步行动。',
  schoolPendingApproval: '您的学校简介还未经系统管理员审核及批准。',
  schoolIsRejected: '您的学校简介已被系统管理员拒绝。请您联系系统管理员以采取下一步行动。',
  youMayDeleteYourOrgAndReapply: '您可以删除此学校申请并再重新提交新的申请。',
  //   END ---- component::SchoolAdminProfileScreen/SchoolProfileBlockerModal

  // START ---- actions::OrganisationActions
  unableToDeleteOrganisation: '无法删除申请。请您稍后再试或联系系统管理员。',
  //   END ---- actions::OrganisationActions

  // START ---- component::AnnotationDocumentScreen
  changesWillBeGoneWithoutSaving: '请确认您已储存所需要的智能板笔记。您确认要退出？（若您只是想关闭此标签，请点击并按住下方的标签。）',
  confirmCloseTab: '您确定要关闭此页？您将无法重新加载此页。',
  iosAnnotationSaveSuccessfully: '成功储存此智能板笔记至您的相册。',
  androidAnnotationSaveSuccessfully: '成功储存此智能板笔记至 {0}。',
  annotationSaveFailed: '无法储存此智能板笔记。 请您稍后再试或联系系统管理员。',
  tapToAddSheetMusicForAnnotation: '点击此处以上传图片并添加新页面。',
  tapToAddBlankPage: '点击此处以开启一个新的空白页。',
  writeExternalPermission: '请授权YOUBRIO使用您的储存空间，否则您将无法储存智能板的笔记。',
  confirmClearSmartboard: '您确定要删除所有笔画？您将无法再加载所删除的笔画。',
  tabRemovedDoYouWantToSave: '此页将永久被移除。请问您是否要保存？',
  //   END ---- component::AnnotationDocumentScreen

  // START --- component::CalendarSlotPickerScreenV2
  pleaseRearrangeYourAdjacentTimeSlot: '老师设定的最短时段是{0}分钟。 请移除相邻的时段或选择其他时段。',
  editLessonEventCalendar: '您的日历中已经有一个相关课程，你是否要修改?',
  lessonEventLost: '无法从日历中找到您的课程，您有可能把它删除了，请重新添加',
  //   END --- component::CalendarSlotPickerScreenV2

  // START --- component::VideoCallScreen
  annotationSessionStarted: '对方正在使用智能板。点击此处并开始使用智能板。',
  youCanJoinTheAnnotationByBottomLeft: '点击右下方的按钮，您便可以随时使用智能板。',
  //   END --- component::VideoCallScreen

  // START --- component::QRCodeScannerScreen
  cameraQRPermission: '请授权YOUBRIO使用您的相机，否则扫码无法正常操作。',
  thisIsNotARoleProfile: '此用户不是一名{0}。',
  //   END --- component::QRCodeScannerScreen

  // START --- component::VideoRecorderScreen
  cameraRecorderPermission: '请授权YOUBRIO使用您的相机，否则APP无法正常录制视频。',
  //   END --- component::VideoRecorderScreen

  // START ---- components::UploadVideoScreen/UploadVideoScreen
  failedToGetVideoTypes: '获取视频类型失败，请稍后再尝试',
  //   END ---- components::UploadVideoScreen/UploadVideoScreen

  // START --- component::QRCodeScreen
  shareReferralProgramInfo: '您现在可以通过我们的推荐活动介绍老师给您的学生！若该老师允许，每当您所推荐的学生与该老师完成一堂课时，您将可获取学费的一部分作为推荐赏金。若您选择不要接受任何的推荐赏金，您可以在分享老师简介的链接或二维码时关闭获取赏金的选项。',
  //   END --- component::QRCodeScreen

  // START ---- component::ReferralQRCodeScreen
  referralCommissionInfo: '在开通此选项时，每当您所推荐的学生与此老师完成一堂课时，您将可获取学费的{0}%作为推荐赏金。',
  shareReferralInvitationLinkText: '我想邀请您使用YOUBRIO APP与这位非常棒的老师上一堂课。点此并使用YOUBRIO APP以开启此链接来查看这位老师的简介。 {0}',
  //   END ---- component::ReferralQRCodeScreen

  // START ---- components::LessonPaymentScreen
  topUpMoreNotice: '为了方便您下一次再预定课程，请问您是否要预先充值10堂课的学费呢？',
  topUpSuggestionNotice: '请问您要充值{0}来预定{1}的课程吗？提前预定课程可以确保您预先锁定上课时间。',
  //   END ---- components::LessonPaymentScreen

  // START ---- components.LiveLessonRoomScreen/VideoCallTabScreen
  weNeedCameraMicrophoneForVideoCall: '请授权YOUBRIO使用您的相机和麦克风，否则将无法正常进行课程。',
  oneOrMorePermissionIsRestricted: 'YOUBRIO无法使用您的相机和麦克风。原因可能是受到父母监控限制或本手机不支持。',
  weNeedCameraForVideoCall: '请授权YOUBRIO使用您的相机，否则将无法正常进行课程。',
  weNeedMicrophoneForVideoCall: '请授权YOUBRIO使用您的麦克风，否则将无法正常进行课程。',
  weNeedAccessStorageToStoreAppData: '请授权YOUBRIO使用您的设备储存以储存APP的数据。',
  weNeedAccessStorageToReadAppData: '请授权YOUBRIO使用您的设备储存以读取APP的数据。',
  areYouSureExit: '请问您确认要退出吗？',
  oopsDisconnectedFromCall: '啊，与服务器的连接断开了！是否重新连接？（错误：{0}）',
  oopsYouAreDisconnectedPleaseCheckInternetAndReconnect: '啊，与服务器的连接断开了！请确保您的网络稳定再重连。（错误：{0}）',
  //   END ---- components.LiveLessonRoomScreen/VideoCallTabScreen

  // START ---- components::LiveLessonRoomScreen/SmartboardTabScreen
  youNeedAtLeastTwoTabs: '您的智能板只有少过两页。',
  confirmToRefreshTab: '请问您是否确定要刷新此页？',
  //   END ---- components::LiveLessonRoomScreen/SmartboardTabScreen

  // START ---- helpers::ApiHelper
  networkErrorMakeSureGoodConnection: '您的网络出现了问题。',
  //   END ---- helperps::ApiHelper

  // START ---- components::ChangePasswordScreen
  passwordSetup: '您目前还未设置任何密码. 如果需要使用钱包你需要现在设置密码，是否现在设置？',
  passwordChanged: '密码已更改',
  passwordChangeFailed: '更改密码失败，请稍后尝试',
  oldPasswordWrong: '旧密码输入错误',
  //   END ---- components::ChangePasswordScreen

  // START ---- components::ProfilePreviewScreen
  noEndorsement: '此用户还未有任何评价，你可以现在为他写一个',
  //   END ---- components::ProfilePreviewScreen

  // START ---- components::SubjectRequestScreen
  learnSubRequestDesc: '比如: \n\n我要找一位数学老师，我家孩子10岁...\n我要找一位小提琴老师，孩子学了五年...\n我要找一位英文老师，教雅思托福...',
  teachSubRequestDesc: '比如: 我是一个竖琴老师, 教初级至进阶到学生等...',
  findTeacherForU: '没有找到合适的老师?\n\n点击这里把您的需求告诉我们,\n我们将帮您预约您理想的老师',
  becomeTeacher: '我想要成为一名老师! 点击此处开始与世界各地的学生上课',
  requestSuccess: '您的要求已提交成功，谢谢你的反馈',
  requestFail: '提交失败，请稍后再尝试',
  //   END ---- components::SubjectRequestScreen

  // START ---- helpers::AlipayHelper
  alipaySandboxNotAvailableForiOS: 'iOS无法使用支付宝沙箱。请更改环境变数。',
  //   END ---- helpers::AlipayHelper

  // START ---- components::UserNameIcon
  noIntroVideo: '添加介绍视频',
  noOneMinVideo: '您还没有任何视频',
  failedToSaveIntro: '保存自我介绍视频失败，请稍后尝试',
  //   END ---- components::UserNameIcon

  // START ---- components::ChatScreen
  adminChatFirst: '优伯教学会帮助你开始与 {0} 的第一节课',
  wantALesson: '您好优伯教学, 我想与 {0} 上课!',
  requestedLesson: '您好优伯教学, 我向 {0} 请求了一个课程 \n\n开始: {1} \n\n时常: {2} 分钟 \n\n详情: {3}',
  requestedLessonDirect: '您好 {0}, 我向您请求了一个课程 \n\n开始: {1} \n\n时常: {2} 分钟 \n\n详情: {3}',
  overlapBookingExistCreateLessonConfirmation: '已经有一个课程的时间与你选择的十分接近或者冲突。请问您是否确认继续？',
  createPrivateLessonFail: '无法创建课程。请您稍后再试或联系系统管理员。',
  areYouSureDeletePrivateLesson: '您确定要删除此课程时段吗？您的学生将无法预定此时段。',
  confirmStartVideoCall: '是否确认开始视频通话？',
  nStartedAVideoCall: '{0} 开始了视频通话。',
  thisLessonHasExpired: '已超出开课时间。',
  pleaseGrantPrerequiredPermissionsForVideoCallAndroid: '请授权YOUBRIO使用相机、麦克风及储存空间以确保视频通话能够正常的进行。请到APP设定开通权限。',
  pleaseGrantPrerequiredPermissionsForVideoCallIos: '请授权YOUBRIO使用相机、麦克风及相册以确保视频通话能够正常的进行。请到APP设定开通权限。',
  //   END ---- components::ChatScreen

  // START ---- actions::ChatActions
  unableToStartVideoCall: '无法连上视频通话。请您稍后再试或联系系统管理员。',
  //   END ---- actions::ChatActions

  // START ---- components::ViewAchievementScreen
  savePermission: '请确保您的设置里允许存储到相册',
  savedToAlbums: '已成功保存至相册',
  failedToDownload: '下载失败, 请稍后尝试',
  comeWatchVideo: '优伯教学邀请您观看一个来自Youbrio.com的视频',
  comeWatchPhoto: '优伯教学邀请您观看一个来自Youbrio.com的图片',
  //   END ---- components::ViewAchievementScreen

  // START ---- component::BookingSlotChangeRequestListScreen
  approveChangeRequestConfirmation: '批准此请求将更改上课的时间。是否确认批准？',
  overlapBookingExistApproveChangeRequestConfirmation: '在这时段有课程冲突，批准此请求将更改此课程上课的时间。是否确认批准？',
  rejectChangeRequestConfirmation: '请问您是否确认拒绝此请求？此课程上课时间将保持不变。',
  deleteChangeRequestConfirmation: '请问您是否确认撤销此请求？',
  overlapBookingExistCreateRequestConfirmation: '在这时段有课程冲突。请问您是否继续发送此请求？',
  //   END ---- component::BookingSlotChangeRequestListScreen

  // START ---- component::common/LessonRequestModal
  requestLessonSuccess: '您已成功发送课程请求。一旦老师对您的请求作出反应，您将第一时间收到通知。',
  requestLessonFail: '无法发送课程请求。请您稍后再试或联系系统管理员。',
  addedCalendar: '已成功添课程到您的日历',
  enableCalendar: '您需要允许优伯教学使用日历来添加课程',
  //   END ---- component::common/LessonRequestModal

  // START ---- component::CandidateProfileScreen
  teacherRelationshipInfo: '您的帐号已经与这位老师连接。若有任何错误，请联络YOUBRIO客服以进行更正。',
  //   END ---- component::CandidateProfileScreen

  // START ---- component::LessonBookingDetailScreen
  bookAnother: '请问您要不要再预约一堂课?',
  sameLesson: '是否为{0}创建相同时间的课程?',
  bookSameLessonSuccess: '您已经预约成功了',
  unableToGetBookingDetails: '是否重新载入/刷新课程详情？',
  //   END ---- component::LessonBookingDetailScreen

  // START ---- component::common/TeacherIcon
  needHelpFindingTeacher: '找老师？\n点击这里。',
  needAssistance: '需要帮忙吗？\n点击这里。',
  //   END ---- component::common/TeacherIcon

  // START ---- actions::MusicLibraryActions
  getSheetMusicPriceDetailFail: '无法载入曲谱详情。请您稍后再试或联系系统管理员。',
  paymentGatewayError: '支付网关出现错误。请重新再试或联系系统管理员。',
  purchaseSheetMusicSuccess: '您已成功付款并购买曲谱。处理好您的订单后，我们将把曲谱发至您的邮箱。',
  purchaseSheetMusicFail: '无法购买曲谱。请您稍后再试或联系系统管理员。',
  purchaseSheetMusicNotYetProcessed: '您已成功付款，但是系统还在核实此交易中。请稍后再查看您的付款历史记录。若您的付款历史记录在一个小时后还未更新，请联系系统管理员。',
  //   END ---- actions::MusicLibraryActions

  // START ---- components::HomeTabV2
  profileIsIncompleteCompleteNow: '您的个人资料尚未完整。请问您是否想现在填写完整？',
  //   END ---- components::HomeTabV2

  // START ---- components::SchoolFeeCategoryListScreen
  noFeeCategoryFoundCreateNew: '学费类别不存在。\n点击并创建新学费类别。',
  //   END ---- components::SchoolFeeCategoryListScreen

  // START ---- component/SchoolFeeCategoryCreateScreen
  defaultOrgFeeCategoryHelpInfo: '若设为默认，所有未委任至任何学费类别的老师将会属于此学费类别。',
  emptySelectedTeacherOrgFeeCategoryInfo: '未委任老师。\n点击并添加老师。',
  isDefaultFeeCategorySelectedTeacherInfo: '所有未委任至任何学费类别的老师将会属于此默认学费类别。',
  createFeeCategorySuccess: '您已成功创建学费类别。',
  createFeeCategoryFail: '无法创建学费类别。请您稍后再试或联系系统管理员。',
  noTeacherAddedFeeCategoryWarning: '未委任老师至此非默认学费类别。请问是否现在委任？',
  //   END ---- component/SchoolFeeCategoryCreateScreen

  // START ---- component/SchoolFeeCategoryEditScreen
  unableToGetFeeCategoryDetail: '无法载入学费类别详情。请您稍后再试或联系系统管理员。',
  feeCategorySaveSuccess: '您已成功储存学费类别设置。',
  feeCategorySaveFail: '无法储存学费类别设置。请您稍后再试或联系系统管理员。',
  feeCategoryDeleteSuccess: '您已成功删除学费类别。',
  feeCategoryDeleteFail: '无法删除学费类别。请您稍后再试或联系系统管理员。',
  areYouSureYouWantToDeleteThis: '您确定要删除吗？',
  setThisCategoryAsDefault: '设为默认学费类别？',
  setCategoryDefaultSuccess: '成功设为默认学费类别。',
  setCategoryDefaultFail: '无法设为默认学费类别。请您稍后再试或联系系统管理员。',
  youHaveSuccessfullyAddedSomeoneAsYourRoleList: '您已成功添加{0}至您的{1}列表。',
  unableToAddSomeoneToYourRoleList: '无法添加{0}至您的{1}列表。',
  youHaveRemovedSomeoneAsYourRole: '您已把{0}从您的{1}列表中删除。',
  unableToRemoveSomeoneFromYourRoleList: '无法把{0}从您的{1}列表中删除。',
  //   END ---- component/SchoolFeeCategoryEditScreen

  // START ---- component/ReferralIntroScreen
  referralIntroText: '推荐老师给您身边好友（或转发朋友圈），\n您好友所有完成课程的课时费的百分之十，\n将自动进入您的优伯钱包作为奖励。',
  selectTeacherYouWishToReferFromYourTeachers: '从您的老师收藏名单里选择您想介绍的老师',
  //   END ---- component/ReferralIntroScreen

  // START ---- component/ChatListScreen
  unableToViewThisProfile: '无法查看此简介。',
  deleteChatPermanently: '你确定你要永久删除你发给{0}的聊天记录吗? 注意! 删除后将无法恢复!',
  chatDeleteSuccess: '聊天记录删除成功',
  failedToDelete: '聊天记录删除失败',
  //   END ---- component/ChatListScreen

  // START ---- modal/AgreementModal
  agree: '同意',
  agreeDescription1: '请您务必审慎阅读、充分理解',
  agreeDescription2: '《使用条款》',
  and: '和',
  agreeDescription3: '《个人隐私条款》',
  agreeDescription4: '。如果您同意，请点击“同意”开始接受我们的服务。',
  //   END ---- modal/AgreementModal

  // START ---- component::common/AnnotationTabBar
  youHaveReachedMaxTab: '为了确保您拥有最佳的上课体验，您可以在每一堂课中上传不超过 {0} 页的笔记。若要上传新的一页的笔记，请关闭任何一页。',
  //   END ---- component::common/AnnotationTabBar

  // START ---- component::NotificationListScreen/NotificationListItem
  areYouSureToDeleteNotification: '请问您确定删除此通知吗？',
  //   END ---- component::NotificationListScreen/NotificationListItem

  // START ---- component::NetworkTestingModal
  networkLowSpeedComment: '您可能会体验:\n\n1. 视频 + 声音效果不佳\n2. 连线不稳定\n\n您可以试着:\n 1. 切换 4G 网络重试\n2. 靠近路由器重试\n3. 重启路由器重试\n4. 重启设备\n5. 在课堂上关闭视频功能',
  networkLowSpeedNote: '*注意事项\n若有其他用户在同个网络上使用或下载，连线效果将会受影响。',
  networkModerateSpeedComment: '若您体验:\n1. 视频 + 声音效果不佳\n2. 连线不稳定\n\n您可以试着 : \n1. 切换 4G 网络重试\n2. 靠近路由器重试\n3. 重启路由器重试\n4. 重启设备',
  networkFastSpeedComment: '您的网络检测非常好，优伯上课的效果应该没问题。\n\n若您有任何连线上课的问题，请与我们优伯客服联系。',
  //   END ---- component::NetworkTestingModal

  // START ---- component::components/modals/NetworkErrorModal
  oopsYourInternetHasGoneToOuterSpace: '网络飞到外太空去啦！\n请检查您的网络连线再试。',
  //   END ---- component::components/modals/NetworkErrorModal

  // START ---- component::EditReferenceRateScreen/EditReferenceRateScreen
  setUpReferenceRate: '您还未设置您的课程收费标准每小时，是否现在设置?',
  priceRange: '您输入的价钱应该在 {0} 到 {1} 之间',
  moreHelp: '您需要更多的帮助吗?',
  //   END ---- component::EditReferenceRateScreen/EditReferenceRateScreen

  // START ---- component::DatingScreen/DatingScreen
  endOfSearch: '这个已经是最后一个了. 你是否需要要重新搜索?',
  couldNotFindASuitableTeacher: '您刚刷完最后一个搜寻结果。\n找不到适合的老师吗？',
  //   END ---- component::DatingScreen/DatingScreen

  // START ---- component::WebAccessScreen
  webAccess: '电脑端',
  parentAccess: '家长端',
  stepByStep: '使用步骤',
  acOne: '1. 请确保您已在优伯教学APP的手机或平板端与您的学生或老师预订了课程。',
  acTwo: '2. 前往',
  acTwoRest: ' 并在上课时间通过登录页面点击“进入教室”。',
  acThree: '3. 若要使用智能白板，请使用您的手机或平板设备，在课程预订页中点击“ 智能白板”启用。',
  giftDescription: '您可以在视频课程中收到其他用户的礼物',
  //   END ---- component::WebAccessScreen

  // START ---- component::JitsiAudioSettingScreen
  settingsSavedSuccessfully: '设置储存成功。',
  //   END ---- component::JitsiAudioSettingScreen

  // START ---- component::common/LessonRequestModal
  pleasePickTimeBetweenAAndBOnly: '请您选择 {0} 至 {1} 的时段。',
  wantToLearnFrom: '我想和 {0} 学习',
  //   END ---- component::common/LessonRequestModal

  // START ---- component::modals/ShareLessonModal
  shareLesson: '您好！点击以下链接以加入我的课程：',
  lessonExpiredTitle: '抱歉, 此课程已过期',
  lessonExpiredContent: '无法进入此课程, 如果你有任何问题请联系YOUBRIO管理员',
  lessonShareGreetingTitle: '您好, ',
  lessonAwaitingApprovalC: '感谢您预定我的课程, 我会尽快确定',
  lessonAwaitingApprovalN: '请确保您在设置中开启YOUBRIO通知',
  lessonAcceptedC: '感谢您预定我的课程, 期待与你一起上课',
  lessonCompletedC: '课程已经结束了, 您表现的很好!',
  lessonNeedBookC: '我为您创建了一个课程, 课程如下',
  lessonUrlHint: '请复制粘贴你的课程链接在此处',
  invalidLessonCode: '输入错误，请检查你的链接和房间号码是否正确',
  lessonShareUrlEx: '例如: https://www.youbrio.com/invite/06a3YNpm',
  meetShareUrlPlaceholder: '例如: {0}invite/meet?u=xxxxxxxx',
  lessonOnlyAvailableToStudentAccount: '只有完善的学生帐号可以预定此课程。请透过下方按钮完成您的个人资料并设置您的账号为学生帐号。完成个人资料后再点击链接即可预定此课程。',
  // END ---- component::modals/ShareLessonModal

  // START ---- components::MyReferralCodeScreen
  referralCodeIsCopied: '您的代码已被复制到剪贴板上。',
  flyerTitleFrom: '现在输入',
  flyerTitleTo: '就能获取价值 ¥288 的在线一对一精品课！',
  shareReferralCodeRewardText: '当您的朋友使用您的推荐码注册账号并上课时，双方将获得奖励！',
  noOneUsedCode: '抱歉,还未有人使用你的推荐码',
  shareReferralText: '小声点🤫\n\n让我告诉你一个秘密，我在用着优伯教学APP来学习以及开课。若你现在用我的推荐码注册帐号，我们俩都会有奖励哦！\n\n好了不多说了，这是我的推荐码：\n{0}\n\n现在就下载优伯教学！\n{1}',
  //   END ---- components::MyReferralCodeScreen

  // START ---- components::CreateMeetScheduleScreen
  getMeetSettingsFailed: '无法索取课程设置。请您稍后再试或联系系统管理员。',
  //   END ---- components::CreateMeetScheduleScreen

  // START ---- components::EditMeetScheduleScreen
  confirmToCancelInvitation: '是否确认取消对 {0} 的邀请?',
  youCannotInviteMoreThan: '您无法邀请超过 {0} 参与者。',
  //   END ---- components::EditMeetScheduleScreen

  // START ---- components::ScheduledMeetDetailScreen
  areYouSureRejectMeetBooking: '请问您是否确认拒绝此预约？ 此操作无法撤消。',
  areYouSureHostCancelMeetBooking: '请问您是否确认取消此预约？ 此操作无法撤消。',
  areYouSureHostCancelMeet: '请问您是否确认取消这堂课？系统将把预约费退回给所有参与者，此操作无法撤消。',
  areYouSureCompleteMeet: '完成此课程后，所有有效参与者的预约费将转入您的钱包。完成课程之后，您将无法进入教室。',
  plsActionTheRequestedBookingsBeforeCompletingMeet: '在完成课程之前，请批准或拒绝参与者们的预约。',
  plsActionTheRequestedBookingsBeforeEnteringClassroom: '在进入教室之前，请批准或拒绝参与者们的预约。',
  areYouSureCompleteMeetNoActiveBookings: '此课程没有参与者预约，完成课程后您将不会收到任何学费。请问您是否确认完成此课程？',
  recreateLesson: '是否为所有参与者创建相同时间的课程呢？',
  wantedToBookTheSameLessonAgainNextWeek: '我想再下周预约同样时间的课程。',
  //   END ---- components::ScheduledMeetDetailScreen

  // START ---- components::HallOfFameScreen
  examDetails: '在康柏尔国际音乐测评系统中取得最优秀成绩的学员们会展示在优伯明日之星榜。测评中可以演奏任何您喜爱的曲目，给国际专业考官点评 + 推荐信，赶紧报名哦！',
  //   END ---- components::HallOfFameScreen

  // START ---- common/NetworkStatusBar
  youAreConnectedToTheInternet: '您已连接至互联网。',
  plsCheckYourNetworkConnection: '请检查您的网络连接。',
  //   END ---- common/NetworkStatusBar

  // START ---- common/SubscriptionXScreen
  areYouKids: '孩子练琴学习没有动力？在家效率不高？',
  hardToFind: '陪练家教老师不好预约？质量参差不齐？',
  lessonAreAlready: '正课老师的课程价格怎么请的起天天学？',
  tutorRoomToHelp: '优伯随心学为您解忧！',
  tutorRoomDesc: '优伯随心学是根据美国茱莉亚音乐学院助教陪练模式所研发的在线辅导培训系统，让学员们可以随时随地、无限量地在专业老师的辅导下练琴、学习。优伯随心学利用互联网平台的优势，采取名师团体辅导模式，由优伯专业老师团队进行精准辅导、及时纠错，引导孩子们高效率地学习。',
  practiceHours: '优伯随心学的老师在线时间为：',
  theUsualRate: '每个月老师在线时间共计100个小时，平时该级别老师课时费都是$100/小时以上，透过优伯随心学的模式我们提供给学员物超所值的指导！学员们可以根据自身需求，弹性地安排练琴，学习时间，让每一次学习都发挥最好的效果。学在课上，功在课下，提高自学效率，减少不必要的错误，让学员们少走弯路，才能事半功倍！',
  k12: '优伯随心学现在运营的科目为：钢琴房，小提琴房和学习房，专注在辅导K-12学生们的英文，数学和基本作业。',
  iHaveToppedUpAndWantToSubscribe: '我刚刚充值了 {0}。我想订阅优伯随心学 {1}，{2} {3}。',
  //   END ---- common/SubscriptionXScreen

  // START ---- actions::PracticeRoomActions
  youAreNotTeacherOfPracticeRoom: '您不是这间课室的老师。',
  youAreNotSubscribedToThisRoom: '您未订阅这间课室。',
  theRoomIsNotOpenedYet: '这间课室还未开课。',
  //   END ---- actions::PracticeRoomActions

  // START ---- components/PracticeModeScreen/modals/LearnModeInfoModal
  learnModeInfoHeader: '欢迎来到优伯随心学\n请仔细听从导师的指示。',
  learnModeInfo1: '1. 学习模式: 优伯导师将为您服务，\n帮助您高效率的练习。',
  learnModeInfo2: '2. 练琴模式: 按照导师的指示认真练习，\n练好了老师将过来给您指导。',
  learnModeInfo3: '3. 举手: 有任何不确定的问题，\n可以使用举手按钮通知老师。\n\n练琴愉快，下个朗朗就是你！',
  //   END ---- components/PracticeModeScreen/modals/LearnModeInfoModal

  // START ---- components/PracticeModeScreen/modals/LearnModeNotificationModal
  lessonModeActivatedInfo: '优伯导师将为您服务，\n帮助您高效率的练习。',
  practiceModeActivatedInfo: '按照导师的指示认真练习，\n练好了老师将过来给您指导。',
  //   END ---- components/PracticeModeScreen/modals/LearnModeNotificationModal

  // START ---- components/PracticeModeScreen/modals/MeetParticipantListModal
  confirmMarkUserExitPracticeRoom: '是否确认这位学员已退出教室？',
  participantIsMissingFromPracticeRoom: '这位学员已不在教室里。若学员还在，请重新打开此弹窗以刷新列表。',
  someoneHasEditedTheNote: '其他老师刚刚更新了这位学生的笔记。此列表已经刷新，但您所更改的笔记已安全地复制到粘贴板上。',
  someoneHasChangedTheLockStatus: '其他老师刚刚更新了这位学生的封锁状态。此列表正在刷新中...',
  alertedStudentNoAudio: '我们已向学生发出无声音警示。',
  //   END ---- components/PracticeModeScreen/modals/MeetParticipantListModal

  // START ---- common/AcknowledgementScreen
  usedTheFollowingAnimation: '此应用软件使用了以下动画:',
  //   END ---- common/AcknowledgementScreen

  // START ---- components/PracticeModeScreen/modals/CoffeeBreakModal
  coffeeBreakDeactivatedInfo: '谢谢您一直都这么努力！\n若需要休息片刻，您可以点击下方按钮，我们将为您关闭摄像头与麦克风。\n休息模式启动时，其他老师还是可以与您语音连接。',
  coffeeBreakActivatedInfo: '休息是为了走更长远的路！\n若您准备好退出休息模式，请点击下方按钮以继续教导。',
  finishTeachingThenOnlyGoBreak: '不好意思，看来您还在教着一位学生。麻烦您在进入休息模式之前把该学生设为“练习模式”。',
  pleaseTurnOffCoffeeBreak: '请您退出休息模式再继续。',
  //   END ---- components/PracticeModeScreen/modals/CoffeeBreakModal

  // START ---- component::SettingScreenV2
  confirmDeleteAccount: '我们对您的离开感到遗憾。删除账号是永久性的，并无法恢复账号资料。请问您是否确定删除账号？',
  weNeedToVerifyYourIdentity: '我们需要核实您的身份，请输入您的账号密码。',
  accountDeleted: '您的账号已被删除。',
  //   END ---- component::SettingScreenV2

  // START ---- helpers::AnnotationSocketHelper
  teacherCantHearYou: '老师无法听见您的声音。麻烦您重新进入教室或重启APP。谢谢！'
  //   END ---- helpers::AnnotationSocketHelper
};
