import ApiHelper from '../helpers/ApiHelper';
import AppHelper from '../helpers/AppHelper';
import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

const CONSTANT = require('../helpers/ConstantHelper');

export const getStudentExamList = (examType = null) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  switch (examType) {
    case CONSTANT.STRING.EXAM_TYPE.CURRENT:
      return ApiHelper.get(`student/exams?type=current&lang=${lang}`, true);
    case CONSTANT.STRING.EXAM_TYPE.REWORK:
      return ApiHelper.get(`student/exams?type=rework&lang=${lang}`, true);
    case CONSTANT.STRING.EXAM_TYPE.APPEAL:
      return ApiHelper.get(`student/exams?type=appeal&lang=${lang}`, true);
    case CONSTANT.STRING.EXAM_TYPE.COMPLETED:
      return ApiHelper.get(`student/exams?type=completed&lang=${lang}`, true);
    case null:
      return ApiHelper.get(`student/exams?lang=${lang}`, true);
    default:
      break;
  }
};

export const getTeacherExamList = (examType = null) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  switch (examType) {
    case CONSTANT.STRING.EXAM_TYPE.CURRENT:
      return ApiHelper.get(`teacher/exams?type=current&lang=${lang}`, true);
    case CONSTANT.STRING.EXAM_TYPE.REWORK:
      return ApiHelper.get(`teacher/exams?type=rework&lang=${lang}`, true);
    case CONSTANT.STRING.EXAM_TYPE.APPEAL:
      return ApiHelper.get(`teacher/exams?type=appeal&lang=${lang}`, true);
    case CONSTANT.STRING.EXAM_TYPE.COMPLETED:
      return ApiHelper.get(`teacher/exams?type=completed&lang=${lang}`, true);
    case null:
      return ApiHelper.get(`teacher/exams?lang=${lang}`, true);
    default:
      break;
  }
};

export const getStudentExamDetails = (examId) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`student/exam/${examId}?lang=${lang}`, true);
};

export const getTeacherExamDetails = (examId) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`teacher/exam/${examId}?lang=${lang}`, true);
};

export const saveStudentExamDetails = (examId, parameters) => {
  const params = new FormData();
  const { pieceDetails, remarks, photoIdUri } = parameters;
  for (let i = 0; i < pieceDetails.length; i++) {
    let videoLinkToBeSaved = '';
    if (pieceDetails[i].video.type === 'link') {
      videoLinkToBeSaved = pieceDetails[i].video.link === null ? '' : pieceDetails[i].video.link;
    } else if (pieceDetails[i].video.type === 'upload') {
      if (pieceDetails[i].video.uploadedLink) {
        videoLinkToBeSaved = pieceDetails[i].video.uploadedLink;
      } else {
        videoLinkToBeSaved = pieceDetails[i].video.link === null ? '' : pieceDetails[i].video.link;
      }
    }

    params.append(`musicTypeId${i + 1}`, pieceDetails[i].musicTypeId);
    params.append(`musicTitleId${i + 1}`, pieceDetails[i].musicTitleId);
    params.append(`videoLink${i + 1}`, videoLinkToBeSaved);
  }

  const remarksToBeSaved = remarks === null ? '' : remarks;
  params.append('remarks', remarksToBeSaved);

  if (photoIdUri) {
    const fileName = AppHelper.getFileNameWithExtension(photoIdUri);
    const fileExt = AppHelper.getFileTypeExtension(photoIdUri);

    params.append('photoId', { uri: photoIdUri, name: fileName, type: `image/${fileExt.toLowerCase()}` });
  }

  return ApiHelper.post(`student/exam/${examId}`, params, true, true);
};

export const saveTeacherExamDetails = (examId, parameters) => {
  const params = new FormData();
  const { pieceDetails, remarks, photoIdUri } = parameters;
  for (let i = 0; i < pieceDetails.length; i++) {
    let videoLinkToBeSaved = '';
    if (pieceDetails[i].video.type === 'link') {
      videoLinkToBeSaved = pieceDetails[i].video.link === null ? '' : pieceDetails[i].video.link;
    } else if (pieceDetails[i].video.type === 'upload') {
      if (pieceDetails[i].video.uploadedLink) {
        videoLinkToBeSaved = pieceDetails[i].video.uploadedLink;
      } else {
        videoLinkToBeSaved = pieceDetails[i].video.link === null ? '' : pieceDetails[i].video.link;
      }
    }

    params.append(`musicTypeId${i + 1}`, pieceDetails[i].musicTypeId);
    params.append(`musicTitleId${i + 1}`, pieceDetails[i].musicTitleId);
    params.append(`videoLink${i + 1}`, videoLinkToBeSaved);
  }

  const remarksToBeSaved = remarks === null ? '' : remarks;
  params.append('remarks', remarksToBeSaved);

  if (photoIdUri) {
    const fileName = AppHelper.getFileNameWithExtension(photoIdUri);
    const fileExt = AppHelper.getFileTypeExtension(photoIdUri);

    params.append('photoId', { uri: photoIdUri, name: fileName, type: `image/${fileExt.toLowerCase()}` });
  }

  return ApiHelper.post(`teacher/exam/${examId}`, params, true, true);
};

export const submitStudentExam = (examId, parameters) => {
  const params = new FormData();
  const { pieceDetails, remarks, photoIdUri } = parameters;
  for (let i = 0; i < pieceDetails.length; i++) {
    let videoLinkToBeSubmitted = '';
    if (pieceDetails[i].video.type === 'link') {
      videoLinkToBeSubmitted = pieceDetails[i].video.link;
    } else if (pieceDetails[i].video.type === 'upload') {
      videoLinkToBeSubmitted = pieceDetails[i].video.uploadedLink;
    }

    params.append(`musicTypeId${i + 1}`, pieceDetails[i].musicTypeId);
    params.append(`musicTitleId${i + 1}`, pieceDetails[i].musicTitleId);
    params.append(`videoLink${i + 1}`, videoLinkToBeSubmitted);
  }

  const remarksToBeSumitted = remarks === null ? '' : remarks;
  params.append('remarks', remarksToBeSumitted);
  params.append('isSubmit', true);

  if (photoIdUri) {
    const fileName = AppHelper.getFileNameWithExtension(photoIdUri);
    const fileExt = AppHelper.getFileTypeExtension(photoIdUri);

    params.append('photoId', { uri: photoIdUri, name: fileName, type: `image/${fileExt.toLowerCase()}` });
  }

  return ApiHelper.post(`student/exam/${examId}`, params, true, true);
};

export const submitTeacherExam = (examId, parameters) => {
  const params = new FormData();
  const { pieceDetails, remarks, photoIdUri } = parameters;
  for (let i = 0; i < pieceDetails.length; i++) {
    let videoLinkToBeSubmitted = '';
    if (pieceDetails[i].video.type === 'link') {
      videoLinkToBeSubmitted = pieceDetails[i].video.link;
    } else if (pieceDetails[i].video.type === 'upload') {
      videoLinkToBeSubmitted = pieceDetails[i].video.uploadedLink;
    }
    params.append(`musicTypeId${i + 1}`, pieceDetails[i].musicTypeId);
    params.append(`musicTitleId${i + 1}`, pieceDetails[i].musicTitleId);
    params.append(`videoLink${i + 1}`, videoLinkToBeSubmitted);
  }

  const remarksToBeSumitted = remarks === null ? '' : remarks;
  params.append('remarks', remarksToBeSumitted);
  params.append('isSubmit', true);

  if (photoIdUri) {
    const fileName = AppHelper.getFileNameWithExtension(photoIdUri);
    const fileExt = AppHelper.getFileTypeExtension(photoIdUri);

    params.append('photoId', { uri: photoIdUri, name: fileName, type: `image/${fileExt.toLowerCase()}` });
  }

  return ApiHelper.post(`teacher/exam/${examId}`, params, true, true);
};

// let's name the wrapper function as enrolNewExamAndPay
export const candidateEnrolNewExamAndPay = ({
    instrumentId,
    gradeId,
    amount,
    paymentCurrency,
    affiliateAmount,
    affiliateCode,
    paymentMethodNonce,
    isSelectCnTranslation,
    cnTranslationFee,
    selectedExtraOptions
}) => {
  // let's refer to the sample call in AuthApi.js

  // here we define the params, the field key must be exactly the same as defined
  // in the cbe server, it is CASE sensitive
  // and to pass these parameters, we'll pass it to this wrapper function
  // using an object, defined with these keys
  // but we'll destructure them straightaway using {}
  const params = {
      instrumentId,
      gradeId,
      amount,
      paymentCurrency,
      affiliateAmount,
      affiliateCode,
      paymentMethodNonce,
      isSelectCnTranslation,
      cnTranslationFee: isSelectCnTranslation ? cnTranslationFee : null,
      selectedExtraOptions
  };
  // the parameter are arranged in this sequence ApiHelper.<post/get>('apiendpointurl', parameters, needToPassToken, isFormData)
  // let's ignore about FormData for now
  // the endpoint url for this is 'https://cbe.kudositconsultancy.com.au/cbeapp/api/student/exam'
  // we only need to include the url after /api/
  return ApiHelper.post('student/exam', params, true);
};
// i think we are done with this wrapper function, let's try to call it in ExamActions.js

export const teacherEnrolNewExamAndPay = ({
    studentId,
    instrumentId,
    gradeId,
    amount,
    paymentCurrency,
    affiliateAmount,
    affiliateCode,
    paymentMethodNonce,
    isSelectCnTranslation,
    cnTranslationFee,
    selectedExtraOptions
}) => {
  const params = {
      studentId,
      instrumentId,
      gradeId,
      amount,
      paymentCurrency,
      affiliateAmount,
      affiliateCode,
      paymentMethodNonce,
      isSelectCnTranslation,
      cnTranslationFee: isSelectCnTranslation ? cnTranslationFee : null,
      selectedExtraOptions
  };
  return ApiHelper.post('teacher/exam', params, true);
};

export const sendReportToEmail = ({ cbeRole, examId }) => {
  const params = {
      cbeRole,
      examId
  };
  return ApiHelper.post('email/exam/report', params, true);
};

export const sendCertToEmail = ({ cbeRole, examId }) => {
  const params = {
      cbeRole,
      examId
  };
  return ApiHelper.post('email/exam/cert', params, true);
};

export const getExamDetailsForAppeal = ({ examId }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`exam/appeal/${examId}?lang=${lang}`, true);
};

export const submitAppealExam = ({ examId, appealReason }) => {
  const params = {
      appealReason
  };
  return ApiHelper.post(`exam/appeal/${examId}`, params, true);
};

export const payAndSubmitAppealExam = ({ examId, amount, paymentMethodNonce }) => {
  const params = {
      examId,
      amount,
      paymentMethodNonce
  };
  return ApiHelper.post(`exam/paidappeal/${examId}`, params, true);
};

export const getCertPurchaseDetails = ({ examId }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`exam/certpurchase/${examId}?lang=${lang}`, true);
};

export const purchaseCertificate = ({ examId }) => {
  return ApiHelper.post(`exam/certpurchase/${examId}`, null, true);
};

export const saveUploadedVideoLink = ({ examId, videoLink1, videoLink2, videoLink3 }) => {
  let params = {};

  if (videoLink1) {
    params = {
      videoLink1
    };
  } else if (videoLink2) {
    params = {
      videoLink2
    };
  } else {
    params = {
      videoLink3
    };
  }

  return ApiHelper.post(`exam/videolink/${examId}`, params, true);
};

export const getExamVideoPreview = () => {
  return YoubrioApiHelper.get('exam-videos/preview', true);
};

export const getExamVideo = (params) => {
  return YoubrioApiHelper.post('exam-videos', params, true);
};

export const updateExamVideoLike = (examVideoId) => {
  return YoubrioApiHelper.post('exam-videos/like', { examVideoId }, true);
};

export const updateExamVideoView = (examVideoId) => {
  return YoubrioApiHelper.post('exam-videos/watch', { examVideoId }, true);
};

export const checkURL = ({ checkURL, examID }) => {
  return ApiHelper.post('exam/check-url', { checkURL, examID }, true);
};
