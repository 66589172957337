/* eslint-disable no-unused-vars */

import moment from 'moment';
// import { Alert } from 'react-native';
import _ from 'lodash';
import {
  SET_USER_PROFILE,
  UPDATE_USER_STATE,
  UPDATE_USER_PROFILE_STATES,
  RESET_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_FIELD_ERROR,
  UPDATE_USER_MODAL_STATE,
  SET_STUDENT_LIST,
  RESET_STUDENT_LIST,
  SET_STUDENT_PROFILE,
  RESET_STUDENT_PROFILE,
  UPDATE_STUDENT_LIST_LOADING,
  UPDATE_STUDENT_PROFILE_FIELD_ERROR,
  UPDATE_STUDENT_PROFILE_STATES,
  SET_USER_STATE,
  UPDATE_YOUBRIO_PROFILE_STATE,
  UPDATE_USER_YOUBRIO_PROFILE_STATE,
  RESET_USER_YOUBRIO_PROFILE_STATE,
  UPDATE_EDIT_EMAIL_FIELD_STATE,
  RESET_EDIT_EMAIL_FIELD_STATE,
  UPDATE_EDIT_EMAIL_FIELD_ERROR
} from './types';
import AppHelper from '../helpers/AppHelper';
// import { messages } from '../lang/messages';
// import { labels } from '../lang/labels';
import { getStore } from '../Store';
import {
  getUserProfile,
  checkPrivateStudioId,
  saveUserProfile,
  getStudentList,
  getProfileForStudentList,
  saveStudentProfile,
  deleteStudent,
  getYoubrioProfile,
  getUserYoubrioProfile,
  checkEmailExistence,
  changeUserEmail,
  updateUserProfileItem
} from '../api';
import {
  appStartsLoading,
  appDoneLoading
} from './';
import {
  multipleFieldErrorUpdate
} from './RegistrationActions';
import i18n from '../i18n';
import GLOBAL from '../helpers/GLOBAL';
// import ChatHelper from '../helpers/ChatHelper';

const CONSTANT = require('../helpers/ConstantHelper');

export const setUserProfile = (user) => {
  return {
    type: SET_USER_PROFILE,
    payload: user
  };
};

export const updateUserState = ({ prop, value }) => {
  return {
    type: UPDATE_USER_STATE,
    payload: { prop, value }
  };
};

export const setUserProfileState = ({ prop, value }) => {
  return {
    type: UPDATE_USER_PROFILE_STATES,
    payload: { prop, value }
  };
};

export const setUserState = ({ prop, value }) => {
  return {
    type: SET_USER_STATE,
    payload: { prop, value }
  };
};

export const getUserProfileInfo = () => {
  return (dispatch) => {
    getUserProfile()
      .then(response => {
        console.log(response);
        dispatch(setUserProfile(response.data));
        if (parseInt(response.data.basic, 10) === 1) {
          dispatch(setUserProfileState({ prop: 'subscribed', value: true }));
          dispatch(setUserState({ prop: 'hasUserChanged', value: false }));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const updateUserProfileFieldError = ({ prop, error }) => {
  return {
    type: UPDATE_USER_PROFILE_FIELD_ERROR,
    payload: { prop, error }
  };
};

export const updateUserModalState = ({ prop, value }) => {
  return {
    type: UPDATE_USER_MODAL_STATE,
    payload: { prop, value }
  };
};

export const resetUserProfileFieldError = () => {
  return {
    type: RESET_USER_PROFILE_ERROR
  };
};

// export const validateUserProfileFields = ({ prop, value, role }) => {
//   return (dispatch) => {
//     let errorString = '';
//     let age = 0;
//     const { userToBeEdited } = getStore().getState().user;
// 
//     if (prop !== 'abn' && prop !== 'privateStudioId' && AppHelper.isEmptyOrWhiteSpace(value)) {
//       errorString = messages.fieldCannotEmpty;
//       if (prop === 'teachAddress' && !(userToBeEdited.cbeRole.includes('Teacher') && userToBeEdited.teachLocation === 'Others')) {
//         errorString = '';
//       }
//     } else {
//       errorString = '';
// 
//       switch (prop) {
//         case 'newEmail':
//           if (!AppHelper.isEmail(value)) {
//             errorString = messages.enterValidEmail;
//           } else {
//             checkEmailExistence({ email: value })
//               .then((response) => {
//                 if (response.checkResult.isExist) {
//                   errorString = messages.emailHasExisted;
//                 } else {
//                   errorString = '';
//                 }
//                 dispatch(updateUserProfileFieldError({ prop: 'newEmail', error: errorString }));
//               })
//               .catch((error) => {
//                 errorString = messages.checkEmailFail;
//                 console.log(error);
//                 dispatch(updateUserProfileFieldError({ prop, error: errorString }));
//               });
//           }
//           break;
//         case 'retypeNewEmail':
//           if (!AppHelper.isEmail(value)) {
//             errorString = messages.enterValidEmail;
//           } else if (value !== userToBeEdited.newEmail) {
//             errorString = messages.emailsDoNotMatch;
//           } else {
//             errorString = '';
//           }
//           dispatch(updateUserProfileFieldError({ prop: 'retypeNewEmail', error: errorString }));
//           break;
//         case 'dob':
//           if (value) {
//             age = moment().diff(value, 'years');
//             console.log(age);
//             if (role === CONSTANT.STRING.ROLE.TEACHER && age < 16) {
//               errorString = messages.atLeastSixteenYearsOldToBeTeacher;
//             } else if (role === CONSTANT.STRING.ROLE.STUDENT && age < 3) {
//               errorString = messages.atLeastThreeYearsOld;
//             } else if (role === CONSTANT.STRING.ROLE.STUDENT && age < 13) {
//               dispatch(setUserState({ prop: 'studentUnderThirteen', value: true }));
//             } else if (role === CONSTANT.STRING.ROLE.STUDENT) {
//               dispatch(setUserState({ prop: 'studentUnderThirteen', value: false }));
//             }
//           } else {
//             errorString = messages.pleaseSelectDateOfBirth;
//           }
//           break;
//         case 'privateStudioId':
//           if (!AppHelper.isEmptyOrWhiteSpace(value)) {
//             checkPrivateStudioId(value)
//               .then((response) => {
//                 if (response.isExist) {
//                   errorString = '';
//                 } else {
//                   errorString = messages.teacherIdDoesNotExist;
//                 }
//                 dispatch(updateUserProfileFieldError({ prop: 'privateStudioId', error: errorString }));
//               })
//               .catch((error) => {
//                 console.log(error);
//                 errorString = messages.unableToCheckTeacherId;
//                 dispatch(updateUserProfileFieldError({ prop: 'privateStudioId', error: errorString }));
//               });
//           } else {
//             errorString = '';
//             dispatch(updateUserProfileFieldError({ prop: 'privateStudioId', error: errorString }));
//           }
//           break;
//         case 'gender':
//           if (value) {
//             errorString = '';
//           }
//           break;
//         case 'abn':
//           if (value && !AppHelper.isABN(value)) {
//             console.log(value);
//             errorString = messages.abnWrongFormat;
//             dispatch(updateUserProfileFieldError({ prop: 'abn', error: errorString }));
//           } else {
//             errorString = '';
//           }
//           break;
//         default:
//           break;
//       }
//     }
//     dispatch(updateUserProfileFieldError({ prop, error: errorString }));
//   };
// };
// 
// export const validateAndSaveUserProfile = ({ locations }) => {
//   return async (dispatch) => {
//     const { userToBeEdited, upgradeAccountRole } = getStore().getState().user;
//     let errorString = '';
//     let hasError = false;
// 
//     if (AppHelper.isEmptyOrWhiteSpace(userToBeEdited.firstName)) {
//       errorString = messages.fieldCannotEmpty;
//       hasError = true;
//     } else {
//       errorString = '';
//     }
//     dispatch(updateUserProfileFieldError({ prop: 'firstName', error: errorString }));
// 
//     if (AppHelper.isEmptyOrWhiteSpace(userToBeEdited.lastName)) {
//       errorString = messages.fieldCannotEmpty;
//       hasError = true;
//     } else {
//       errorString = '';
//     }
//     dispatch(updateUserProfileFieldError({ prop: 'lastName', error: errorString }));
// 
//     if (AppHelper.isEmptyOrWhiteSpace(userToBeEdited.gender)) {
//       errorString = messages.pleaseSelectYourGender;
//       hasError = true;
//     } else {
//       errorString = '';
//     }
//     dispatch(updateUserProfileFieldError({ prop: 'gender', error: errorString }));
// 
//     if (_.isEmpty(locations) || _.isEmpty(locations.countryId)) {
//       errorString = messages.pleaseSelectACountry;
//       hasError = true;
//     } else {
//       errorString = '';
//     }
//     dispatch(updateUserProfileFieldError({ prop: 'countryId', error: errorString }));
// 
//     if (parseInt(userToBeEdited.basic, 10) === 1) {
//       if (userToBeEdited.dob) {
//         const age = moment().diff(userToBeEdited.dob, 'years');
//         console.log(age);
//         if (upgradeAccountRole === CONSTANT.STRING.ROLE.TEACHER && age < 16) {
//           errorString = messages.atLeastSixteenYearsOldToBeTeacher;
//           hasError = true;
//         } else if (upgradeAccountRole === CONSTANT.STRING.ROLE.STUDENT && age < 3) {
//           errorString = messages.atLeastThreeYearsOld;
//           hasError = true;
//         } else if (upgradeAccountRole === CONSTANT.STRING.ROLE.STUDENT && age < 13 && userToBeEdited.permitted !== true) {
//           // Alert.alert('', messages.pleaseCheckPermission, [
//           //   { text: labels.ok, onPress: () => console.log('ok pressed') }
//           // ]);
//           hasError = true;
//           errorString = '';
//         } else {
//           errorString = '';
//         }
//       } else {
//         errorString = messages.pleaseSelectDateOfBirth;
//         hasError = true;
//       }
//       dispatch(updateUserProfileFieldError({ prop: 'dob', error: errorString }));
// 
//       if (!AppHelper.isEmail(userToBeEdited.email)) {
//         if (AppHelper.isEmptyOrWhiteSpace(userToBeEdited.newEmail)) {
//           errorString = messages.fieldCannotEmpty;
//           hasError = true;
//         } else if (!AppHelper.isEmail(userToBeEdited.newEmail)) {
//           errorString = messages.enterValidEmail;
//           hasError = true;
//         } else {
//           try {
//             const checkResponse = await checkEmailExistence({ email: userToBeEdited.newEmail });
//             console.log(checkResponse);
//             if (checkResponse.checkResult.isExist) {
//               errorString = messages.emailHasExisted;
//               hasError = true;
//             } else {
//               errorString = '';
//             }
//           } catch (error) {
//             console.log(error);
//             errorString = messages.unableToCheckEmail;
//             hasError = true;
//           }
//         }
//         dispatch(updateUserProfileFieldError({ prop: 'newEmail', error: errorString }));
// 
//         if (!AppHelper.isEmail(userToBeEdited.retypeNewEmail)) {
//           errorString = messages.enterValidEmail;
//           hasError = true;
//         } else if (userToBeEdited.retypeNewEmail !== userToBeEdited.newEmail) {
//           errorString = messages.emailsDoNotMatch;
//           hasError = true;
//         } else {
//           errorString = '';
//         }
//         dispatch(updateUserProfileFieldError({ prop: 'retypeNewEmail', error: errorString }));
//       }
// 
//       if (upgradeAccountRole === CONSTANT.STRING.ROLE.TEACHER) {
//         console.log('qualification is empty', AppHelper.isEmptyOrWhiteSpace(userToBeEdited.qualification));
//         if (AppHelper.isEmptyOrWhiteSpace(userToBeEdited.qualification)) {
//           errorString = messages.fieldCannotEmpty;
//           hasError = true;
//         } else {
//           errorString = '';
//         }
//         dispatch(updateUserProfileFieldError({ prop: 'qualification', error: errorString }));
// 
//         if (userToBeEdited.teachLocation === 'Others' && AppHelper.isEmptyOrWhiteSpace(userToBeEdited.teachAddress)) {
//           errorString = messages.fieldCannotEmpty;
//           hasError = true;
//         } else {
//           errorString = '';
//         }
//         dispatch(updateUserProfileFieldError({ prop: 'teachAddress', error: errorString }));
// 
//         if (AppHelper.isEmptyOrWhiteSpace(userToBeEdited.teachLocation)) {
//           errorString = messages.pleaseSelectAnInstitutionType;
//           hasError = true;
//         } else {
//           errorString = '';
//         }
//         dispatch(updateUserProfileFieldError({ prop: 'teachLocation', error: errorString }));
//       }
//     } else {
//       //  moved out from basic and allow all user to edit
//       if (userToBeEdited.dob) {
//         const age = moment().diff(userToBeEdited.dob, 'years');
//         console.log(age);
//         if (AppHelper.isCBETeacher() && age < 16) {
//           errorString = messages.atLeastSixteenYearsOldToBeTeacher;
//           hasError = true;
//         } else if (age < 3) {
//           errorString = messages.atLeastThreeYearsOld;
//           hasError = true;
//         } else if (age < 13 && userToBeEdited.permitted !== true && parseInt(userToBeEdited.basic, 10) === 1) {
//           // this check only applies to basic account
//           // Alert.alert('', messages.pleaseCheckPermission, [
//           //   { text: labels.ok, onPress: () => console.log('ok pressed') }
//           // ]);
//           hasError = true;
//           errorString = '';
//         } else {
//           errorString = '';
//         }
//       } else {
//         errorString = messages.pleaseSelectDateOfBirth;
//         hasError = true;
//       }
//       dispatch(updateUserProfileFieldError({ prop: 'dob', error: errorString }));
//     }
// 
//     // if (AppHelper.isEmptyOrWhiteSpace(userToBeEdited.address1)) {
//     //   errorString = messages.fieldCannotEmpty;
//     //   hasError = true;
//     // } else {
//     //   errorString = '';
//     // }
//     // dispatch(updateUserProfileFieldError({ prop: 'address1', error: errorString }));
// 
//     // if (AppHelper.isEmptyOrWhiteSpace(userToBeEdited.suburb)) {
//     //   errorString = messages.fieldCannotEmpty;
//     //   hasError = true;
//     // } else {
//     //   errorString = '';
//     // }
//     // dispatch(updateUserProfileFieldError({ prop: 'suburb', error: errorString }));
// 
//     // if (AppHelper.isEmptyOrWhiteSpace(userToBeEdited.postCode)) {
//     //   errorString = messages.fieldCannotEmpty;
//     //   hasError = true;
//     // } else {
//     //   errorString = '';
//     // }
//     // dispatch(updateUserProfileFieldError({ prop: 'postCode', error: errorString }));
// 
//     // if (AppHelper.isEmptyOrWhiteSpace(userToBeEdited.state)) {
//     //   errorString = messages.fieldCannotEmpty;
//     //   hasError = true;
//     // } else {
//     //   errorString = '';
//     // }
//     // dispatch(updateUserProfileFieldError({ prop: 'state', error: errorString }));
// 
//     if (AppHelper.isEmptyOrWhiteSpace(userToBeEdited.mobile)) {
//       errorString = messages.fieldCannotEmpty;
//       hasError = true;
//     } else {
//       errorString = '';
//     }
//     dispatch(updateUserProfileFieldError({ prop: 'mobile', error: errorString }));
// 
//     if (userToBeEdited.cbeRole.includes('Teacher')) {
//       if (AppHelper.isEmptyOrWhiteSpace(userToBeEdited.qualification)) {
//         errorString = messages.fieldCannotEmpty;
//         hasError = true;
//       } else {
//         errorString = '';
//       }
//       dispatch(updateUserProfileFieldError({ prop: 'qualification', error: errorString }));
// 
//       if (userToBeEdited.teachLocation === 'Others' && AppHelper.isEmptyOrWhiteSpace(userToBeEdited.teachAddress)) {
//         errorString = messages.fieldCannotEmpty;
//         hasError = true;
//       } else {
//         errorString = '';
//       }
//       dispatch(updateUserProfileFieldError({ prop: 'teachAddress', error: errorString }));
// 
//       if (userToBeEdited.abn && !AppHelper.isABN(userToBeEdited.abn)) {
//         errorString = messages.abnWrongFormat;
//         hasError = true;
//       } else {
//         errorString = '';
//       }
//       dispatch(updateUserProfileFieldError({ prop: 'abn', error: errorString }));
//     }
// 
//     if (!AppHelper.isEmptyOrWhiteSpace(userToBeEdited.privateStudioId)) {
//       dispatch(appStartsLoading());
//       checkPrivateStudioId(userToBeEdited.privateStudioId)
//         .then((response) => {
//           console.log(response);
//           if (response.isExist) {
//             errorString = '';
//           } else {
//             errorString = messages.teacherIdDoesNotExist;
//             hasError = true;
//             dispatch(appDoneLoading());
//           }
//           dispatch(updateUserProfileFieldError({ prop: 'privateStudioId', error: errorString }));
// 
//           if (!hasError) {
//             dispatch(updateUserProfile({ ...userToBeEdited, ...locations }, upgradeAccountRole));
//           }
//         })
//         .catch((error) => {
//           dispatch(appDoneLoading());
//           console.log(error);
//           errorString = messages.unableToCheckTeacherId;
//           hasError = true;
//           dispatch(updateUserProfileFieldError({ prop: 'privateStudioId', error: errorString }));
//         });
//     } else {
//       errorString = '';
//       dispatch(updateUserProfileFieldError({ prop: 'privateStudioId', error: errorString }));
// 
//       if (!hasError) {
//         // locations are a function params doesnt store in redux, therefore append to userToBeEdited
//         dispatch(updateUserProfile({ ...userToBeEdited, ...locations }, upgradeAccountRole));
//       }
//     }
//   };
// };

const updateUserProfile = ({ user, upgradeAccountRole }) => {
  return (dispatch) => {
    saveUserProfile(user, upgradeAccountRole)
      .then((response) => {
        dispatch(setUserProfile(response.data));

        // if (upgradeAccountRole === CONSTANT.STRING.ROLE.TEACHER) {
        //   ChatHelper.sendNewTeacherAutoMessage();
        // } else if (upgradeAccountRole === CONSTANT.STRING.ROLE.STUDENT) {
        //   ChatHelper.sendNewStudentAutoMessage();
        // }

        dispatch(appDoneLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(appDoneLoading());
      });
  };
};

export const getStudentListForTeacher = () => {
  return (dispatch) => {
    dispatch(updateStudentListLoading(true));
    getStudentList()
      .then((response) => {
        console.log(response.studentList);
        setStudentList(dispatch, response.studentList)
          .then(() => {
            dispatch(updateStudentListLoading(false));
          });
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateStudentListLoading(false));
      });
  };
};

export const getStudentProfileForTeacher = ({ studentId }) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    getProfileForStudentList({ studentId })
      .then((response) => {
        console.log(response.data);
        dispatch(setStudentProfile(response.data));
        dispatch(appDoneLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(appDoneLoading());
      });
  };
};

const setStudentList = (dispatch, studentList) => new Promise((resolve) => {
  dispatch({
    type: SET_STUDENT_LIST,
    payload: studentList
  });
  resolve();
});

const setStudentProfile = (studentProfile) => {
  return {
    type: SET_STUDENT_PROFILE,
    payload: studentProfile
  };
};

export const resetStudentList = () => {
  return {
    type: RESET_STUDENT_LIST
  };
};

export const resetStudentProfile = () => {
  return {
    type: RESET_STUDENT_PROFILE
  };
};

export const updateStudentListLoading = (isLoading) => {
  return {
    type: UPDATE_STUDENT_LIST_LOADING,
    payload: isLoading
  };
};

export const updateStudentProfileFieldError = ({ prop, error }) => {
  return {
    type: UPDATE_STUDENT_PROFILE_FIELD_ERROR,
    payload: { prop, error }
  };
};

export const setStudentProfileState = ({ prop, value }) => {
  return {
    type: UPDATE_STUDENT_PROFILE_STATES,
    payload: { prop, value }
  };
};

// export const validateStudentProfileFields = ({ prop, value }) => {
//   return (dispatch) => {
//     let errorString = '';
// 
//     if (AppHelper.isEmptyOrWhiteSpace(value)) {
//       errorString = messages.fieldCannotEmpty;
//     } else {
//       errorString = '';
//     }
//     dispatch(updateStudentProfileFieldError({ prop, error: errorString }));
//   };
// };
// 
// export const validateAndSaveStudentProfile = ({ locations }) => {
//   return (dispatch) => {
//     const { studentProfile } = getStore().getState().user;
//     let errorString = '';
//     let hasError = false;
// 
//     if (AppHelper.isEmptyOrWhiteSpace(studentProfile.firstName)) {
//       errorString = messages.fieldCannotEmpty;
//       hasError = true;
//     } else {
//       errorString = '';
//     }
//     dispatch(updateStudentProfileFieldError({ prop: 'firstName', error: errorString }));
// 
//     if (AppHelper.isEmptyOrWhiteSpace(studentProfile.lastName)) {
//       errorString = messages.fieldCannotEmpty;
//       hasError = true;
//     } else {
//       errorString = '';
//     }
//     dispatch(updateStudentProfileFieldError({ prop: 'lastName', error: errorString }));
// 
//     if (_.isEmpty(locations) || _.isEmpty(locations.countryId)) {
//       errorString = messages.pleaseSelectACountry;
//       hasError = true;
//     } else {
//       errorString = '';
//     }
//     dispatch(updateUserProfileFieldError({ prop: 'countryId', error: errorString }));
// 
//     // if (AppHelper.isEmptyOrWhiteSpace(studentProfile.address1)) {
//     //   errorString = messages.fieldCannotEmpty;
//     //   hasError = true;
//     // } else {
//     //   errorString = '';
//     // }
//     // dispatch(updateStudentProfileFieldError({ prop: 'address1', error: errorString }));
//     //
//     // if (AppHelper.isEmptyOrWhiteSpace(studentProfile.suburb)) {
//     //   errorString = messages.fieldCannotEmpty;
//     //   hasError = true;
//     // } else {
//     //   errorString = '';
//     // }
//     // dispatch(updateStudentProfileFieldError({ prop: 'suburb', error: errorString }));
//     //
//     // if (AppHelper.isEmptyOrWhiteSpace(studentProfile.postCode)) {
//     //   errorString = messages.fieldCannotEmpty;
//     //   hasError = true;
//     // } else {
//     //   errorString = '';
//     // }
//     // dispatch(updateStudentProfileFieldError({ prop: 'postCode', error: errorString }));
//     //
//     // if (AppHelper.isEmptyOrWhiteSpace(studentProfile.state)) {
//     //   errorString = messages.fieldCannotEmpty;
//     //   hasError = true;
//     // } else {
//     //   errorString = '';
//     // }
//     // dispatch(updateStudentProfileFieldError({ prop: 'state', error: errorString }));
// 
//     if (AppHelper.isEmptyOrWhiteSpace(studentProfile.mobile)) {
//       errorString = messages.fieldCannotEmpty;
//       hasError = true;
//     } else {
//       errorString = '';
//     }
//     dispatch(updateStudentProfileFieldError({ prop: 'mobile', error: errorString }));
// 
//     if (!hasError) {
//       dispatch(updateStudentProfile({ ...studentProfile, ...locations }));
//     }
//   };
// };

// const updateStudentProfile = (user) => {
//   return (dispatch) => {
//     dispatch(appStartsLoading());
//     saveStudentProfile(user, user.id)
//       .then((response) => {
//         console.log(response);
//         dispatch(getStudentListForTeacher());
//         dispatch(appDoneLoading());
//         dispatch(updateUserModalState({ prop: 'mode', value: 'success' }));
//         dispatch(updateUserModalState({ prop: 'title', value: labels.studentProfileUpdate }));
//         dispatch(updateUserModalState({ prop: 'content', value: messages.studentUpdateProfileSuccess }));
//         dispatch(updateUserModalState({ prop: 'visible', value: true }));
//       })
//       .catch((error) => {
//         console.log(error);
//         dispatch(getStudentListForTeacher());
//         dispatch(appDoneLoading());
//         dispatch(updateUserModalState({ prop: 'mode', value: 'error' }));
//         dispatch(updateUserModalState({ prop: 'title', value: labels.studentProfileUpdate }));
//         dispatch(updateUserModalState({ prop: 'content', value: messages.studentUpdateProfileFail }));
//         dispatch(updateUserModalState({ prop: 'visible', value: true }));
//       });
//   };
// };
// 
// export const deleteStudentFromStudentList = (studentId) => {
//   return (dispatch) => {
//     dispatch(appStartsLoading());
//     deleteStudent(studentId)
//       .then((response) => {
//         console.log(response);
//         dispatch(getStudentListForTeacher());
//         dispatch(appDoneLoading());
//         dispatch(updateUserModalState({ prop: 'mode', value: 'success' }));
//         dispatch(updateUserModalState({ prop: 'title', value: labels.removeStudent }));
//         dispatch(updateUserModalState({ prop: 'content', value: messages.removeStudentSuccess }));
//         dispatch(updateUserModalState({ prop: 'visible', value: true }));
//       })
//       .catch((error) => {
//         console.log(error);
//         dispatch(getStudentListForTeacher());
//         dispatch(appDoneLoading());
//         dispatch(updateUserModalState({ prop: 'mode', value: 'error' }));
//         dispatch(updateUserModalState({ prop: 'title', value: labels.removeStudent }));
//         dispatch(updateUserModalState({ prop: 'content', value: messages.removeStudentFail }));
//         dispatch(updateUserModalState({ prop: 'visible', value: true }));
//       });
//   };
// };

export const updateYoubrioProfileState = ({ prop, value }) => {
  return {
    type: UPDATE_YOUBRIO_PROFILE_STATE,
    payload: { prop, value }
  };
};

export const getYoubrioUserProfile = () => {
  return (dispatch) => {
    getYoubrioProfile()
      .then((response) => {
        console.log(response);
        dispatch(updateYoubrioProfileState({ prop: 'totalVideos', value: response.totalVideos }));
        dispatch(updateYoubrioProfileState({ prop: 'totalLikes', value: response.totalLikes }));
        dispatch(updateYoubrioProfileState({ prop: 'level', value: response.userLevel.level }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const updateUserYoubrioProfileState = ({ prop, value }) => {
  return {
    type: UPDATE_USER_YOUBRIO_PROFILE_STATE,
    payload: { prop, value }
  };
};

export const resetUserYoubrioProfileState = () => {
  return {
    type: RESET_USER_YOUBRIO_PROFILE_STATE
  };
};

export const getOtherUserYoubrioProfile = ({ userId }) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    getUserYoubrioProfile({ userId })
      .then((response) => {
        console.log(response);
        dispatch(updateUserYoubrioProfileState({ prop: 'totalVideos', value: response.totalVideos }));
        dispatch(updateUserYoubrioProfileState({ prop: 'totalLikes', value: response.totalLikes }));
        dispatch(updateUserYoubrioProfileState({ prop: 'level', value: response.user.level }));
        dispatch(updateUserYoubrioProfileState({ prop: 'firstName', value: response.user.firstName }));
        dispatch(updateUserYoubrioProfileState({ prop: 'lastName', value: response.user.lastName }));
        dispatch(updateUserYoubrioProfileState({ prop: 'avatarUrl', value: response.user.avatarUrl }));
        dispatch(updateUserYoubrioProfileState({ prop: 'qualification', value: response.qualification }));
        dispatch(appDoneLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(appDoneLoading());
      });
  };
};

export const updateEditEmailField = ({ prop, value }) => {
  return {
    type: UPDATE_EDIT_EMAIL_FIELD_STATE,
    payload: { prop, value }
  };
};

export const updateEditEmailFieldError = ({ prop, error }) => {
  return {
    type: UPDATE_EDIT_EMAIL_FIELD_ERROR,
    payload: { prop, error }
  };
};

export const resetEditEmailField = () => {
  return {
    type: RESET_EDIT_EMAIL_FIELD_STATE
  };
};

// export const validateEditEmailFields = ({ prop, value }) => {
//   return async (dispatch) => {
//     const { editEmailFields } = getStore().getState().user;
//     let errorString = '';
// 
//     if (AppHelper.isEmptyOrWhiteSpace(value)) {
//       errorString = messages.fieldCannotEmpty;
//     } else {
//       switch (prop) {
//         case 'newEmail':
//           if (!AppHelper.isEmail(value)) {
//             errorString = messages.enterValidEmail;
//           } else if (value === editEmailFields.oldEmail) {
//             errorString = messages.identicalToTheCurrentEmail;
//           } else {
//             errorString = '';
//             dispatch(updateEditEmailFieldError({ prop, error: errorString }));
//             try {
//               const checkResponse = await checkEmailExistence({ email: value });
//               console.log(checkResponse);
//               if (checkResponse.checkResult.isExist) {
//                 errorString = messages.emailHasExisted;
//               } else {
//                 errorString = '';
//               }
//             } catch (error) {
//               console.log(error);
//               errorString = messages.unableToCheckEmail;
//             }
//           }
//           break;
//         case 'retypeNewEmail':
//           if (!AppHelper.isEmail(value)) {
//             errorString = messages.enterValidEmail;
//           } else if (value !== editEmailFields.newEmail) {
//             errorString = messages.emailsDoNotMatch;
//           } else {
//             errorString = '';
//           }
//           break;
//         default:
//           break;
//       }
//     }
//     dispatch(updateEditEmailFieldError({ prop, error: errorString }));
//   };
// };

// export const validateFieldsAndChangeEmail = () => {
//   return async (dispatch) => {
//     const {
//       oldEmail,
//       newEmail,
//       retypeNewEmail,
//       password
//     } = getStore().getState().user.editEmailFields;
// 
//     let errorString = '';
//     let hasError = false;
// 
//     if (AppHelper.isEmptyOrWhiteSpace(newEmail)) {
//       errorString = messages.fieldCannotEmpty;
//       hasError = true;
//     } else if (!AppHelper.isEmail(newEmail)) {
//       errorString = messages.enterValidEmail;
//       hasError = true;
//     } else if (newEmail === oldEmail) {
//       errorString = messages.identicalToTheCurrentEmail;
//       hasError = true;
//     } else {
//       errorString = '';
//       dispatch(updateEditEmailFieldError({ prop: 'newEmail', error: errorString }));
//       dispatch(appStartsLoading());
//       try {
//         const checkResponse = await checkEmailExistence({ email: newEmail });
//         console.log(checkResponse);
//         if (checkResponse.checkResult.isExist) {
//           errorString = messages.emailHasExisted;
//           hasError = true;
//         } else {
//           errorString = '';
//         }
//       } catch (error) {
//         console.log(error);
//         errorString = messages.unableToCheckEmail;
//         hasError = true;
//       }
//       dispatch(appDoneLoading());
//     }
//     dispatch(updateEditEmailFieldError({ prop: 'newEmail', error: errorString }));
// 
//     if (AppHelper.isEmptyOrWhiteSpace(retypeNewEmail)) {
//       errorString = messages.fieldCannotEmpty;
//       hasError = true;
//     } else if (!AppHelper.isEmail(retypeNewEmail)) {
//       errorString = messages.enterValidEmail;
//       hasError = true;
//     } else if (newEmail !== retypeNewEmail) {
//       errorString = messages.emailsDoNotMatch;
//       hasError = true;
//     } else {
//       errorString = '';
//     }
//     dispatch(updateEditEmailFieldError({ prop: 'retypeNewEmail', error: errorString }));
// 
//     if (AppHelper.isEmptyOrWhiteSpace(password)) {
//       errorString = messages.fieldCannotEmpty;
//       hasError = true;
//     } else {
//       errorString = '';
//     }
//     dispatch(updateEditEmailFieldError({ prop: 'password', error: errorString }));
// 
//     if (!hasError) {
//       dispatch(appStartsLoading());
//       changeUserEmail({ newEmail, oldEmail, password })
//         .then((response) => {
//           console.log(response);
//           dispatch(appDoneLoading());
//           if (response.success) {
//             dispatch(getUserProfileInfo());
//             dispatch(updateUserModalState({ prop: 'mode', value: 'success' }));
//             dispatch(updateUserModalState({ prop: 'title', value: labels.changeEmail }));
//             dispatch(updateUserModalState({ prop: 'content', value: messages.formatString(messages.emailChangeSuccess, newEmail) }));
//             dispatch(updateUserModalState({ prop: 'visible', value: true }));
//           }
//         })
//         .catch((error) => {
//           console.log(error);
//           dispatch(appDoneLoading());
//           if (error === CONSTANT.ERROR.UNAUTHORIZED) {
//             dispatch(updateUserModalState({ prop: 'mode', value: 'error' }));
//             dispatch(updateUserModalState({ prop: 'title', value: labels.changeEmail }));
//             dispatch(updateUserModalState({ prop: 'content', value: messages.incorrectLoginCredentials }));
//             dispatch(updateUserModalState({ prop: 'visible', value: true }));
//           } else {
//             dispatch(updateUserModalState({ prop: 'mode', value: 'error' }));
//             dispatch(updateUserModalState({ prop: 'title', value: labels.changeEmail }));
//             dispatch(updateUserModalState({ prop: 'content', value: messages.emailChangeFail }));
//             dispatch(updateUserModalState({ prop: 'visible', value: true }));
//           }
//         });
//     }
//   };
// };

export const updateUserProfileIndividualItems = ({ profile, callback }) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    updateUserProfileItem(profile)
      .then((response) => {
        if (callback) {
          dispatch(appDoneLoading());
          callback({ response });
        }
      })
      .catch((error) => {
        if (callback) {
          dispatch(appDoneLoading());
          callback({ error });
        }
      });
  };
};

export const validateAndCompleteStudentProfile = ({ user, callback }) => {
  return (dispatch) => {
    let hasError = false;
    let errors = {};

    if (AppHelper.isEmptyOrWhiteSpace(user.firstName)) {
      errors = { ...errors, firstName: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else {
      errors = { ...errors, firstName: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(user.lastName)) {
      errors = { ...errors, lastName: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else {
      errors = { ...errors, lastName: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(user.mobile)) {
      errors = { ...errors, mobile: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(user.countryId)) {
      errors = { ...errors, countryId: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else {
      errors = { ...errors, countryId: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(user.dob)) {
      errors = { ...errors, dob: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else {
      const age = moment().diff(user.dob, 'years');
      if (age < 13) {
        errors = { ...errors, dob: i18n.t('form.error.atLeastThirteenYearsOld') };
        hasError = true;
      } else {
        errors = { ...errors, dob: '' };
      }
    }

    multipleFieldErrorUpdate(dispatch, errors);
    if (!hasError) {
      // clear errors and register
      dispatch(appStartsLoading());
      saveUserProfile(user, 'Student')
        .then((response) => {
          // console.log(response.data);
          // dispatch(setUserProfile(response.data));
          // console.log('complete student profile', response.data);
          dispatch(appDoneLoading());
          if (callback) {
            callback({ response: response.data.data });
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(appDoneLoading());
          if (callback) {
            callback({ error });
          }
        });
    }
  };
};
