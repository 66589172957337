import React, { useEffect, useState } from 'react';
import { useSessionStorageState, useDebounce } from 'ahooks';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Select,
  Button,
  Layout,
  Checkbox,
  // Radio,
  Input,
  Alert,
  Loading,
  MessageBox,
  Radio,
} from 'element-react';
import 'element-theme-default';
import {
  Typography,
  Paper,
  FormControl,
  Grid,
} from '@material-ui/core';

import {
  PaymentMethodPicker,
  BraintreeDropIn,
} from '../common';
import {
  getInstruments as getInstrumentsApi,
  getGradesAbove as getGradesAboverApi,
  getEnrolExamDisclaimerText as getEnrolExamDisclaimerTextApi,
  getExamPrice as getExamPriceApi,
  validateAffiliationCodeV2 as validateAffiliationCodeV2Api,
  // validateAffiliationCode,
  getStudentListForExamEnrolment as getStudentListForExamEnrolmentApi,
  teacherEnrolNewExamAndPay,
  candidateEnrolNewExamAndPay,
  // getEnrolExamWechatPrepay,
  // getExamOrPEIdByOutTradeNo,
  // getStudentEnrolExamAlipayOrderString,
  // getTeacherEnrolExamAlipayOrderString,
  // getBraintreeToken,
} from '../../api';
import { labels } from '../../lang/labels';
import { messages } from '../../lang/messages';
import AppHelper from '../../helpers/AppHelper';
import GLOBAL from '../../helpers/GLOBAL';
// import { NetworkCellSharp } from '@material-ui/icons';
// import AlipayHelper from '../../helpers/AlipayHelper';

const CONSTANT = require('../../helpers/ConstantHelper');

const EnrolNewExamScreen = (props) => { 
  const { t } = useTranslation();
  const {
    history,
  } = props;
  const user = GLOBAL.get('user');
  const {
    cbeRole,
  } = user;
  const isTeacherRole = cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER);
  const isUserInChina = false; // AppHelper.isUserInChina(); // not work
  const isCNLang = GLOBAL.get('lang')?.includes('cn');

  const [instruments, setInstruments] = useState([]);
  const [gradesAbove, setGradesAbove] = useState([]);
  const [disclaimText, setDisclaimText] = useState('');
  const [studentList, setStudentList] = useState([]);
  const [newExamState, setNewExamState] = useSessionStorageState('EnrolNewExamScreen-newExamState', {
    defaultValue: {
      studentId: isTeacherRole ? null : user?.id,
      instrumentId: null,
      gradeId: null,
      affiliationCode: '',
      isSelectedCnTranslation: false,
      selectedExtraOptions: {},
      formDisabled: false,
      paymentPrice: null,
      isLoadingInstrumentList: false,
      isLoadingGradeList: false,
      isLoadingPrice: false,
      isPriceFinalized: false,
      isLoadingStudentList: false,
      isValidatingAffiliationCode: false,
      extraOptions: [],
      error: {
        common: ''
      },
      cnTranslationAvailable: false,
    }
  });
  const setNewExamStateError = (v) => {
    setNewExamState({
      ...newExamState,
      error: {
        ...newExamState.error,
        ...v
      }
    });
  };
  const debouncedAffiliationCode = useDebounce(
    newExamState.affiliationCode,
    { wait: 1000 }
  );
  const debouncedGradeId = useDebounce(
    newExamState.gradeId,
    { wait: 800 }
  );
  const [currencySign, setCurrencySign] = useState('');
  const [paymentState, setPaymentState] = useSessionStorageState('EnrolNewExamScreen-paymentState', {
    defaultValue: {
      visible: false,
      pickerVisible: true,
      paymentMethodVisible: false,
      selectedPaymentMethod: '',
      isLoading: false,
    }
  });
  const btDiv = React.useRef(null);

  const fetchGetInstruments = async() => {
    try {
      setNewExamState({ ...newExamState, isLoadingInstrumentList: true});
      const res = await getInstrumentsApi();
      if (res && res.data && res.data.instruments) {
        setInstruments(res.data.instruments.map(x => {
          x.key = x.id;
          return x;
        }));
      } else {
        console.warn(res);
      }
    } catch (e) {
      console.error(e)
      setInstruments([]);
    } finally {
      setNewExamState({ ...newExamState, isLoadingInstrumentList: false});
    }
  };

  const fetchGetGradesAbove = async ({ instrumentId, studentId }) => {
    if (!instrumentId || !studentId) {
      return;
    }
    try {
      const res = await getGradesAboverApi({ instrumentId, studentId });
      if (res && res.data && res.data.grades) {
        setGradesAbove(res.data.grades.map(x => {
          x.key = x.id;
          return x;
        }));
      } else {
        console.warn(res);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchGetStudentList = async () => {
    try {
      const res = await getStudentListForExamEnrolmentApi();
      if (res && res.data && res.data.studentList) {
        setStudentList(res.data.studentList.map(x => {
          x.key = x.ID;
          return x;
        }));
      } else {
        console.warn(res);
      }
    } catch (e) {
      console.error(e);
    }
  };


  const fetchGetEnrolExamDisclaimerText = async () => {
    try {
      const res = await getEnrolExamDisclaimerTextApi();
      if (res && res.data && res.data.disclaimer) {
        setDisclaimText(res.data.disclaimer);
      } else {
        console.warn(res);
      }
    } catch (e) {
      console.error(e);
      setDisclaimText('');
    }
  };

  const fetchGetExamPrice = async ({ gradeId }) => {
    console.log('---gradeId=', gradeId);
    setNewExamState({ ...newExamState, isLoadingPrice: true });
    try {
      const res = await getExamPriceApi({ gradeId });
      if (res && res.data) {
        const data = res.data;
        const cnTranslationAvailable = !isNaN(data.examPrice.cnTranslationFee) && (isUserInChina || isCNLang);
        setNewExamState({
          ...newExamState,
          paymentAmount: data.examPrice.amount,
          currencyCode: data.examPrice.currencyCode,
          extraOptions: data.extraOptions,
          isPriceFinalized: true,
          cnTranslationAvailable,
          cnTranslationFee: isNaN(data.examPrice.cnTranslationFee) ? 0 : data.examPrice.cnTranslationFee,
          isLoadingPrice: false,
        });
      } else {
        console.warn(res);
        setNewExamState({
          ...newExamState,
          isLoadingPrice: false,
        });
      }
    } catch (e) {
      console.error(e);
      setNewExamState({
        ...newExamState,
        isLoadingPrice: false,
      });
    } finally {
    }
  };

  const fetchValidateAffiliationCodeV2 = async ({ affiliationCode }) => {
    // if (!affiliationCode || affiliationCode.length < 3 || affiliationCode.length > 50) {
    //   return;
    // }
    if (AppHelper.isEmptyOrWhiteSpace(affiliationCode)) {
      setNewExamState({
        ...newExamState,
        discount: 0,
        error: {
          ...newExamState.error,
          affiliationCode: '',
        }
      });
      return;
    }
    try {
      setNewExamState({
        ...newExamState,
        isValidatingAffiliationCode: true,
        error: {
          ...newExamState.error,
          affiliationCode: ''
        }
      });
      const res = await validateAffiliationCodeV2Api({ affiliationCode });
      if (res && res.data.affiliateValidationResult) {
        const validationResult = res.data.affiliateValidationResult;
        if (validationResult.isValid) {
          setNewExamState({
            ...newExamState,
            isValidatingAffiliationCode: false,
            discount: validationResult.discount,
            couponType: validationResult.type,
            isPriceFinalized: true,
            error: {
              ...newExamState.error,
              affiliationCode: ''
            }
          });
        } else {
          setNewExamState({
            ...newExamState,
            isValidatingAffiliationCode: false,
            discount: 0,
            couponType: 0,
            isPriceFinalized: true,
            error: {
              ...newExamState.error,
              affiliationCode: messages.affiliateCodeIsInvalid,
            }
          });
        }
      } else {
        setNewExamState({
          ...newExamState,
          isValidatingAffiliationCode: false,
          discount: 0,
          isPriceFinalized: true,
           error: {
            ...newExamState.error,
            affiliationCode: ''
          }
        });
        console.warn(res);
      }
    } catch (e) {
      console.error(e);
      setNewExamState({
        ...newExamState,
        isValidatingAffiliationCode: false,
        discount: 0,
        isPriceFinalized: true,
        error: {
          ...newExamState.error,
          affiliationCode: messages.checkAffiliateCodeFail,
        }
      });
    }
  };

  const getTotalExtraOptionPrice = () => {
    const {
      extraOptions,
    } = newExamState;
    const {
      selectedExtraOptions,
    } = newExamState;

    let total = 0;
    extraOptions.forEach((item) => {
      if (selectedExtraOptions[item.key] != null) {
        if (item.choice === 'single') {
          const selectedChoice = item.options.find(i => i.key === selectedExtraOptions[item.key]);

          if (selectedChoice) {
            total += selectedChoice.price;
          }
        }
      }
    });

    return total;
  };


  const getPaymentPrice = () => {
    const { 
      paymentAmount,
      discount,
      couponType,
      cnTranslationFee,
    } = newExamState;
    const {
      isSelectedCnTranslation,
    } = newExamState;

    let price = 0;
    let discountAmount = 0;
    console.log('---couponType=', couponType, ',cnTr=', cnTranslationFee, ',discount=', discount);
    if (couponType === 0) {
      discountAmount = discount;
    } else if (couponType === 1) {
      discountAmount = paymentAmount * discount / 100;
    }
    console.log('discountAmount=', discountAmount);

    price = paymentAmount - discountAmount;
    if (isSelectedCnTranslation) {
      price += cnTranslationFee;
    }
    price = price < 0 ? 0 : price;
    price += getTotalExtraOptionPrice();
    
    return price;
  };
 
  const canProceed = () => {
    const result = AppHelper.getSelectedOption(newExamState.instrumentId, instruments)
    && AppHelper.getSelectedOption(newExamState.gradeId, gradesAbove)
    && newExamState.isPriceFinalized
    && !newExamState.error.affiliationCode
    && !newExamState.error.price
    && hasFulfilledAllRequiredExtraOptions();

    return result;
  };

  const hasFulfilledAllRequiredExtraOptions = () => {
    const {
      extraOptions,
      selectedExtraOptions
    } = newExamState;

    let hasFulfilled = true;

    extraOptions.forEach((item) => {
      if (item.required && selectedExtraOptions[item.key] == null) {
        hasFulfilled = false;
      }
    });

    return hasFulfilled;
  };
 
  const onChangeInstrumentSelect = async (instrumentId) => {
    setNewExamState({ ...newExamState, instrumentId });
  };

  const onChangeCandidateSelect = async (studentId) => {
    setNewExamState({ ...newExamState, studentId });
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  // const continueToEnrolExam = () => {
  //   const {
  //     paymentAmount,
  //     discount
  //   } = newExamState;
  //   if (paymentAmount - discount > 0) {
  //     startPaymentPage();
  //   } else {
  //     enrolFreeExam();
  //   }
  // };

  const enrolFreeExam = () => {
    const exam = newExamState;

    if (cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER)) {
      teacherEnrolNewExamAndPay({
        studentId: exam.studentId,
        instrumentId: exam.instrumentId,
        gradeId: exam.gradeId,
        amount: exam.paymentAmount,
        isSelectCnTranslation: exam.isSelectedCnTranslation,
        paymentCurrency: exam.currencyCode,
        affiliateAmount: exam.discount,
        affiliateCode: exam.affiliationCode
      })
        .then((response) => {
          // when api request call is successful and server returns a positive response
          console.log(response);
          setNewExamState({
            ...newExamState,
            examId: response.examId,
          });
          MessageBox.msgbox({
            title: labels.examEnrolment,
            message: messages.enrolExamSuccess,
            type: 'success',
            confirmButtonText: labels.confirm,
          }).then(action => {
            if (action === 'confirm') {
              history.replace('/open-exam-list');
            } else if (action === 'cancel') {
            }
          });
        })
        .catch((error) => {
          // when there is error in the api request call
          console.error(error);
          MessageBox.msgbox({
            title: labels.error,
            message: messages.enrolExamFail,
            type: 'error',
            confirmButtonText: labels.confirm,
          });
        });
    } else if (cbeRole.includes(CONSTANT.STRING.ROLE.STUDENT)) {
      console.log('student');
      candidateEnrolNewExamAndPay({
          instrumentId: exam.instrumentId,
          gradeId: exam.gradeId,
          isSelectCnTranslation: exam.isSelectCnTranslation,
          amount: exam.paymentAmount,
          paymentCurrency: exam.currencyCode,
          affiliateAmount: exam.discount,
          affiliateCode: exam.affiliationCode
        })
        .then((response) => {
          // when api request call is successful and server returns a positive response
          console.log(response);
          setNewExamState({
            ...newExamState,
            examId: response.examId,
          });
          MessageBox.msgbox({
            title: labels.examEnrolment,
            message: messages.enrolExamSuccess,
            type: 'success',
            confirmButtonText: labels.confirm,
          }).then(action => {
            if (action === 'confirm') {
              history.replace('/open-exam-list');
            } else if (action === 'cancel') {
            }
          });
        })
        .catch((error) => {
          // when there is error in the api request call
          console.log(error);
          MessageBox.msgbox({
            title: labels.error,
            message: messages.enrolExamFail,
            type: 'error',
            confirmButtonText: labels.confirm,
          });
        });
    }
  };

  /*const payExamViaWechatPay = () => {
    const exam = newExamState;
  
    const params = {
      instrumentId: exam.instrumentId,
      gradeId: exam.gradeId,
      affiliateCode: exam.affiliationCode,
      isSelectCnTranslation: exam.isSelectedCnTranslation,
      cnTranslationFee: exam.cnTranslationFee,
      selectedExtraOptions: exam.selectedExtraOptions
    };
    if (cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER)) {
      params.studentId = exam.studentId;
    }
  
    getEnrolExamWechatPrepay(params)
    .then((response) => {
    
      console.log(response);
      const { result, outTradeNo, isSandbox } = response;
      if (result || isSandbox) {
        const payload = {
          partnerId: result.partnerid,
          prepayId: result.prepayid,
          nonceStr: result.noncestr,
          timeStamp: String(result.timestamp),
          package: result.package,
          sign: result.sign
        };
        if (isSandbox) {
          console.warn('Sandbox environment, payment success!');
          getNewExamIdByOutTradeNo({ outTradeNo, payMethod: 'WXCN' });
        } else {
          // WeChat.pay(payload)
          //   .then(r => {
          //     console.log(r);
          //     console.log(outTradeNo);
          //     getNewExamIdByOutTradeNo({ outTradeNo, payMethod: 'WXCN' });
          //   })
          //   .catch(e => {
          //     console.warn(e);
          //     MessageBox.msgbox({
          //       title: labels.paymentGatewayError,
          //       content: messages.enrolmentPaymentGatewayError,
          //       type: 'warning',
          //     });
          //   });
        }
      } else {
        MessageBox.msgbox({
          title: labels.paymentGatewayError,
          content: messages.enrolmentPaymentGatewayError,
          type: 'warning',
        });
      }
    })
    .catch((error) => {
      MessageBox.msgbox({
        title: labels.error,
        content: messages.enrolExamFail,
        type: 'error',
      });
      console.log(error);
    });
  };*/
  
  /*const payExamViaAlipay = () => {
    const exam = newExamState;
  
    if (cbeRole.includes(CONSTANT.STRING.ROLE.STUDENT)) {
      getStudentEnrolExamAlipayOrderString({
        instrumentId: exam.instrumentId,
        gradeId: exam.gradeId,
        affiliateCode: exam.affiliationCode,
        isSelectCnTranslation: exam.isSelectCnTranslation,
        cnTranslationFee: exam.cnTranslationFee,
        selectedExtraOptions: exam.selectedExtraOptions
      })
        .then((response) => {
          console.log(response);
          const orderText = response.result;
          const isSandbox = response.isSandbox;
          AlipayHelper.payOrder({ orderString: orderText, isSandbox })
            .then((result) => {
              console.log(result);
              if (parseInt(result.resultStatus, 10) === 9000) {
                // payment success
                const outTradeNo = AppHelper.getOutTradeNoV2(result.result);
                console.log(outTradeNo);
                getNewExamIdByOutTradeNo({ outTradeNo });
              } else if (parseInt(result.resultStatus, 10) !== 6001) {
                // request timeout
                MessageBox.msgbox({
                  title: labels.paymentGatewayError,
                  message: messages.enrolmentPaymentGatewayError,
                  type: 'warning'
                });
              }
            })
            .catch((error) => {
              console.log(error);
              MessageBox.msgbox({
                title: labels.paymentGatewayError,
                message: messages.enrolmentPaymentGatewayError,
                type: 'error'
              });
            });
        })
        .catch((error) => {
          MessageBox.msgbox({
            title: labels.error,
            message: messages.enrolExamFail,
            type: 'error',
          });
          console.log(error);
        });
    } else if (cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER)) {
      getTeacherEnrolExamAlipayOrderString({
        instrumentId: exam.instrumentId,
        gradeId: exam.gradeId,
        affiliateCode: exam.affiliationCode,
        studentId: exam.studentId,
        isSelectCnTranslation: exam.isSelectCnTranslation,
        cnTranslationFee: exam.cnTranslationFee,
        selectedExtraOptions: exam.selectedExtraOptions
      })
        .then((response) => {
          console.log(response);
          const orderText = response.result;
          const isSandbox = response.isSandbox;
          AlipayHelper.payOrder({ orderString: orderText, isSandbox })
            .then((result) => {
              console.log(result);
              if (parseInt(result.resultStatus, 10) === 9000) {
                // payment success
                const outTradeNo = AppHelper.getOutTradeNoV2(result.result);
                console.log(outTradeNo);
                getNewExamIdByOutTradeNo({ outTradeNo });
              } else if (parseInt(result.resultStatus, 10) !== 6001) {
                // request time-out
                MessageBox.msgbox({
                  title: labels.paymentGatewayError,
                  message: messages.enrolmentPaymentGatewayError,
                  confirmButtonText: labels.confirm,
                  type: 'warning'
                });
              }
            })
            .catch((error) => {
              console.log(error);
              MessageBox.msgbox({
                title: labels.paymentGatewayError,
                message: messages.enrolmentPaymentGatewayError,
                confirmButtonText: labels.confirm,
                type: 'warning',
              });
            });
        })
        .catch((error) => {
          MessageBox.msgbox({
            title: labels.paymentGatewayError,
            message: messages.enrolmentPaymentGatewayError,
            type: 'error',
            confirmButtonText: labels.confirm,
          });
          console.log(error);
        });
    }
    
  }; */

  const payExamViaBraintree = async ({ nonce }) => {
    const exam = newExamState;

    // payment succeeded, pass nonce to server
    // so now, we will write a function in the ApiWrapper to pass the nonce, instrument id
    // and other required information to the server
    // we'll use ExamApi.js since it's about creating a new exam
    console.log('then nonce');

    setPaymentState({
      ...paymentState,
      isLoading: true,
    });
    try {
      let response;
      if (cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER)) {
        response = await teacherEnrolNewExamAndPay({
          studentId: exam.studentId,
          instrumentId: exam.instrumentId,
          gradeId: exam.gradeId,
          amount: exam.paymentAmount,
          paymentCurrency: exam.currencyCode,
          affiliateAmount: exam.discount,
          affiliateCode: exam.affiliationCode,
          paymentMethodNonce: nonce, // this "nonce" is returned from the braintree,
          isSelectCnTranslation: exam.isSelectedCnTranslation,
          cnTranslationFee: exam.cnTranslationFee,
          selectedExtraOptions: exam.selectedExtraOptions
        })
      } else if (cbeRole.includes(CONSTANT.STRING.ROLE.STUDENT)) {
        console.log('student');
        response = await candidateEnrolNewExamAndPay({
          instrumentId: exam.instrumentId,
          gradeId: exam.gradeId,
          amount: exam.paymentAmount,
          paymentCurrency: exam.currencyCode,
          affiliateAmount: exam.discount,
          affiliateCode: exam.affiliationCode,
          paymentMethodNonce: nonce, // this "nonce" is returned from the braintree
          isSelectCnTranslation: exam.isSelectedCnTranslation,
          cnTranslationFee: exam.cnTranslationFee,
          selectedExtraOptions: exam.selectedExtraOptions
        });
      }
      if (!response) { return; }
      // when api request call is successful and server returns a positive response
      setNewExamState({
        ...newExamState,
        examId: response.examId,
      });
      setPaymentState({
        ...paymentState,
        isLoading: false,
        visible: false,
      });
      MessageBox.msgbox({
        title: labels.examEnrolment,
        message: messages.enrolExamSuccess,
        type: 'success',
        confirmButtonText: labels.confirm,
      }).then(action => {
        if (action === 'confirm') {
          history.replace('/open-exam-list');
        } else if (action === 'cancel') {
        }
      });
    } catch (error) {
      // when there is error in the api request call
      console.log(error);
      setPaymentState({
        ...paymentState,
        isLoading: false,
      });
      MessageBox.msgbox({
        title: labels.error,
        message: messages.enrolExamFail,
        type: 'error',
        confirmButtonText: labels.confirm,
      });
    } finally {
    }
  };
 
  /*const startPaymentPage = () => {
    // const exam = newExamState;
    // const { braintreeToken } = getStore().getState().payment;
  
    if (AppHelper.isUserInChina()) {
      // user with countryId CN will use Alipay
      // OR WechatPay
      payExamViaAlipay();
    } else {
      // // user with countryId NOT CN will use Braintree Paypal
      // BTClient.show({
      //   clientToken: braintreeToken
      // }).then((result) => {
      //   const nonce = result.nonce;
      //   // payment succeeded, pass nonce to server
      //   // so now, we will write a function in the ApiWrapper to pass the nonce, instrument id
      //   // and other required information to the server
      //   // we'll use ExamApi.js since it's about creating a new exam
      //   console.log('then nonce');
      //   console.log(result);
      //   console.log('cbeRole', cbeRole);
      
      //   if (cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER)) {
      //     console.log('teacher');
      //     teacherEnrolNewExamAndPay({
      //       studentId: exam.studentId,
      //       instrumentId: exam.instrumentId,
      //       gradeId: exam.gradeId,
      //       amount: exam.paymentAmount,
      //       paymentCurrency: exam.currencyCode,
      //       affiliateAmount: exam.discount,
      //       affiliateCode: exam.affiliationCode,
      //       paymentMethodNonce: nonce // this "nonce" is returned from the braintree
      //     })
      //       .then((response) => {
      //         // when api request call is successful and server returns a positive response
      //         console.log(response);
      //         setNewExamState({
      //           ...newExamState,
      //           examId: response.examId,
      //         });
      //         MessageBox.msgbox({
      //           title: labels.examEnrolment,
      //           message: messages.enrolExamSuccess,
      //           type: 'success',
      //         });
      //       })
      //       .catch((error) => {
      //         // when there is error in the api request call
      //         console.log(error);
      //         MessageBox.msgbox({
      //           title: labels.error,
      //           message: messages.enrolExamFail,
      //           type: 'error',
      //         });
      //       });
      //   } else if (cbeRole.includes(CONSTANT.STRING.ROLE.STUDENT)) {
      //     console.log('student');
      //     candidateEnrolNewExamAndPay({
      //         instrumentId: exam.instrumentId,
      //         gradeId: exam.gradeId,
      //         amount: exam.paymentAmount,
      //         paymentCurrency: exam.currencyCode,
      //         affiliateAmount: exam.discount,
      //         affiliateCode: exam.affiliationCode,
      //         paymentMethodNonce: nonce // this "nonce" is returned from the braintree
      //     })
      //       .then((response) => {
      //         // when api request call is successful and server returns a positive response
      //         console.log(response);
      //         setNewExamState({
      //           ...newExamState,
      //           examId: response.examId,
      //         });
      //         MessageBox.msgbox({
      //           title: labels.examEnrolment,
      //           message: messages.enrolExamSuccess,
      //           type: 'success',
      //         });
      //        })
      //       .catch((error) => {
      //         // when there is error in the api request call
      //         console.log(error);
      //         MessageBox.msgbox({
      //           title: labels.error,
      //           message: messages.enrolExamFail,
      //           type: 'error',
      //         });
      //       });
      //   }
      // })
      // .catch((err) => {
      //   //error handling
      //   console.log(err);
      //   console.log(err.code);
      //   if (err.code !== 'USER_CANCELLATION') {
      //     MessageBox.msgbox({
      //       title: labels.paymentGatewayError,
      //       message: messages.enrolmentPaymentGatewayError,
      //       type: 'error',
      //     });
      //   }
      // });
    }
    
  }; */

  /*const checkNewExamFieldsAndProceedToPayment = () => {
    let errorString = '';
    let hasError = false;

    const {
      instrumentId,
      gradeId,
      studentId,
      studentList, // teacher role can get it
      affiliationCode,
      isPriceFinalized,
    } = newExamState;

    if (cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER) && !AppHelper.getSelectedOption(studentId, studentList)) {
      errorString = messages.pleaseSelectACandidate;
      setNewExamState({
        ...newExamState,
        error: {
          ...newExamState.error,
          student: errorString,
        }
      });
      hasError = true;
    } else {
      setNewExamState({
        ...newExamState,
        error: {
          ...newExamState.error,
          student: '',
        }
      });
    }

    if (!AppHelper.getSelectedOption(instrumentId, instruments)) {
      errorString = messages.pleaseSelectAnInstrument;
      setNewExamStateError({ instrument: errorString });
      hasError = true;
    } else {
      setNewExamStateError({ instrument: ''});
    }

    if (!AppHelper.getSelectedOption(gradeId, gradesAbove)) {
      errorString = messages.pleaseSelectAGrade;
      setNewExamStateError({ grade: errorString });
      hasError = true;
    } else {
      setNewExamStateError({ grade: '' });
    }

    if (!isPriceFinalized) {
      errorString = messages.paymentAmountNotUpToDate;
      setNewExamStateError({ price: errorString });
      hasError = true;
    } else {
      setNewExamStateError({ price: '' });
    }

    if (AppHelper.isEmptyOrWhiteSpace(affiliationCode)) {
      setNewExamState({
        ...newExamState,
        discount: 0,
        error: {
          ...newExamState.error,
          affiliationCode: ''
        }
      });
      if (!hasError) {
        continueToEnrolExam();
      }
    } else {
      setNewExamState({
        ...newExamState,
        isValidatingAffiliationCode: true,
      });
      validateAffiliationCode({ affiliationCode })
        .then((response) => {
          const validationResult = response.affiliateValidationResult;
          if (validationResult.isValid) {
            setNewExamState({
              ...newExamState,
              isValidatingAffiliationCode: false,
              discount: validationResult.discount
            });
          } else {
            setNewExamState({
              ...newExamState,
              isValidatingAffiliationCode: false,
              discount: 0,
              error: {
                ...newExamState.error,
                affiliationCode: messages.affiliationCodeNotValid,
              }
            });
            hasError = true;
          }

          // this check is to wait after the asycnchronous call (validateAffiliationCode) has ended
          if (!hasError) {
            continueToEnrolExam();
          }
        })
        .catch((error) => {
          setNewExamState({
            ...newExamState,
            isValidatingAffiliationCode: false,
            discount: 0,
            error: {
              ...newExamState.error,
              affiliationCode: messages.checkAffiliateCodeFail,
            }
          });
          console.log(error);
        });
    }
  }; */

  const checkNewExamFieldsAndProceedToPaymentV2 = ({ proceedCallback }) => {
    let errorString = '';
    let hasError = false;

    const {
      instrumentId,
      gradeId,
      studentId,
      // affiliationCode,
      isPriceFinalized,
    } = newExamState;
    let affiliationCode = debouncedAffiliationCode;

    if (cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER) && !AppHelper.getSelectedOption(studentId, studentList)) {
      errorString = messages.pleaseSelectACandidate;
      setNewExamStateError({ student: errorString });
      hasError = true;
    } else {
      setNewExamStateError({ student: '' });
    }

    if (!AppHelper.getSelectedOption(instrumentId, instruments)) {
      errorString = messages.pleaseSelectAnInstrument;
      setNewExamStateError({ instrument: errorString });
      hasError = true;
    } else {
     setNewExamStateError({ instrument: '' });
    }

    if (!AppHelper.getSelectedOption(gradeId, gradesAbove)) {
      errorString = messages.pleaseSelectAGrade;
      setNewExamStateError({ grade: errorString });
      hasError = true;
    } else {
      setNewExamStateError({ grade: '' });
    }

    if (!isPriceFinalized) {
      errorString = messages.paymentAmountNotUpToDate;
      setNewExamStateError({ price: errorString });
      hasError = true;
    } else {
      setNewExamStateError({ price: '' });
    }

    if (AppHelper.isEmptyOrWhiteSpace(affiliationCode)) {
      setNewExamState({
        ...newExamState,
        discount: 0,
        error: {
          ...newExamState.error,
          affiliationCode: ''
        }
      });
      if (!hasError) {
        proceedCallback();
      } else {
        setNewExamStateError({
          common: errorString
        });
      }
      return;
    } else {
      setNewExamState({
        ...newExamState,
        isValidatingAffiliationCode: true,
      });
      validateAffiliationCodeV2Api({ affiliationCode })
        .then((response) => {
          const validationResult = response?.data?.affiliateValidationResult;
          if (validationResult && validationResult.isValid) {
            setNewExamState({
              ...newExamState,
              isValidatingAffiliationCode: false,
              discount: validationResult.discount,
              couponType: validationResult.type,
              error: {
                ...newExamState.error,
                affiliationCode: '',
              }
            });
          } else {
            setNewExamState({
              ...newExamState,
              isValidatingAffiliationCode: false,
              error: {
                ...newExamState.error,
                affiliationCode: messages.affiliationCodeNotValid,
              },
              discount: 0,
              couponType: 0,
            });
            hasError = true;
          }

          // this check is to wait after the asycnchronous call (validateAffiliationCodeV2) has ended
          if (!hasError) {
            proceedCallback();
          }
        })
        .catch((error) => {
          setNewExamState({
            ...newExamState,
            isValidatingAffiliationCode: false,
            discount: 0,
            error: {
              ...newExamState.error,
              affiliationCode: messages.checkAffiliateCodeFail,
            }
          });
          console.error(error);
        });
    }

  };

  const onContinuePress = () => {
    // const {
    //   currencyCode
    // } = newExamState;
    console.log('--newExamState=', newExamState.discount);
    const paymentPrice = getPaymentPrice();

    // let paymentMethodChoices = [];
    // if (currencyCode === 'CNY') {
    //   paymentMethodChoices = ['AP', 'WX', 'BT'];
    // } else if (currencyCode === 'AUD') {
    //   paymentMethodChoices = ['BT'];
    // } else if (currencyCode === 'USD') {
    //   paymentMethodChoices = ['BT'];
    // }

    checkNewExamFieldsAndProceedToPaymentV2({
      proceedCallback: () => {
        if (paymentPrice > 0) {
          setPaymentState({
            ...paymentState,
            visible: true,
          });
          setNewExamState({
            ...newExamState,
            formDisabled: true
          });
        } else {
          enrolFreeExam();
        }
      }
    });
  }

  /*const getNewExamIdByOutTradeNo = ({ outTradeNo, payMethod }) => {
    getExamOrPEIdByOutTradeNo({ outTradeNo, payMethod })
    .then((response) => {
      console.log(response);
      if (response.paymentProcessed) {
        setNewExamState({
          ...newExamState,
          examId: response.examId,
        });
        MessageBox.msgbox({
          title: labels.examEnrolment,
          message: messages.enrolExamSuccess,
          type: 'success'
        });
      } else {
        MessageBox.msgbox({
          title: labels.examEnrolment,
          message: messages.enrolExamNotYetProcessed,
          type: 'warning'
        });
      }
    })
    .catch((error) => {
      MessageBox.msgbox({
        title: labels.examEnrolment,
        message: messages.enrolExamNotYetProcessed,
        type: 'error'
      });
      console.log(error);
    });
  };*/

  useEffect(() => {
    setNewExamState({
      ...newExamState,
      isLoadingGradeList: false,
      isLoadingPrice: false,
      isLoadingInstrumentList: false,
      isLoadingStudentList: false,
      isValidatingAffiliationCode: false,
    });
    setPaymentState({
      ...paymentState,
      isLoading: false,
    });
    if (isTeacherRole) {
      fetchGetStudentList();
    } 
    fetchGetInstruments();
    fetchGetEnrolExamDisclaimerText();
  }, []);

  useEffect(() => {
    fetchGetGradesAbove({
      instrumentId: newExamState.instrumentId,
      studentId: newExamState.studentId,
    });
    // setNewExamState({
    //   ...newExamState,
    //   gradeId: null,
    // });
  }, [newExamState.instrumentId, newExamState.studentId]);

  useEffect(() => {
    fetchGetExamPrice({ gradeId: debouncedGradeId });
    setNewExamState({
      ...newExamState,
      isSelectedCnTranslation: false,
    });
  }, [
    debouncedGradeId, // newExamState.gradeId,
    // fetchGetExamPrice
  ]);

  useEffect(() => {
    console.log('---discount=', newExamState.discount);
    const price = getPaymentPrice();
    setNewExamState({
      ...newExamState,
      paymentPrice: price,
    });
  }, [
    newExamState.extraOptions,
    newExamState.paymentAmount,
    newExamState.discount,
    newExamState.couponType,
    newExamState.cnTranslationFee,
    newExamState.isSelectedCnTranslation,
    newExamState.selectedExtraOptions,
  ]);

  useEffect(() => {
    fetchValidateAffiliationCodeV2({ affiliationCode: debouncedAffiliationCode });
  }, [
    debouncedAffiliationCode
  ]);

  useEffect(() => {
    setCurrencySign(
      AppHelper.getCurrencySignByCurrencyCode(newExamState.currencyCode)
    );
  }, [
    newExamState.currencyCode
  ]);

  useEffect(() => {
    setPaymentState({
      ...paymentState,
      visible: false,
    });
  },
    [
      newExamState.instrumentId,
      newExamState.gradeId,
      newExamState.isSelectedCnTranslation,
      debouncedAffiliationCode,
    ]
  );

  const renderExtraOptions = () => {
    const {
      extraOptions,
      selectedExtraOptions,
    } = newExamState;

    if (!newExamState.instrumentId || !newExamState.gradeId) {
      return null;
    }

    const renderOption = (item) => {
      const {
        choice,
        label,
        key,
        // required,
        options,
      } = item;

      if (choice === 'single') {
        // let selectedIndex= null;
        // if (selectedExtraOptions[key] != null) {
        //   selectedIndex = options.findIndex( _item => _item.key === selectedExtraOptions[key]);
        // }

        const onSelect = (k, v) => {
          setNewExamState({
            ...newExamState,
            selectedExtraOptions: {
              ...selectedExtraOptions,
              key: v,
            }
          });
        };

        return (
          <>
            <Form.Item label={label}>
              <Radio.Group value={selectedExtraOptions[key]} onChange={onSelect}>
                {
                  options.map(option =>
                    <Radio.Button value={option.key} key={option.key}>
                      { option.label}
                    </Radio.Button>
                  )
                }
              </Radio.Group>
            </Form.Item>
          </>
        )
      }

      return null;
    };

    return extraOptions.map(item => renderOption(item));
  };

  const renderPayment = () => {
    const onSelectPaymentMethod = (selectedPaymentMethod) => {
      setPaymentState({
        ...paymentState,
        selectedPaymentMethod,
        paymentMethodVisible: true,
      });
    };

    return (
      paymentState.visible && <div>
        <Loading loading={paymentState.isLoading}>
          {  
            paymentState.pickerVisible && 
            <div className="picker">
              <Paper square className="px-3 py-3">
              <FormControl fullWidth className='mt-3'>
                <PaymentMethodPicker
                  selectedPaymentMethod={paymentState.selectedPaymentMethod}
                  onSelect={onSelectPaymentMethod}
                  currencyCode={newExamState.currencyCode}
                  textFieldProps={{
                    variant: 'outlined',
                    disabled: false,
                    helperText: '',
                    label: t('order.paymentMethod')
                  }}
                />
              </FormControl>
              </Paper>
            </div>
          }
          { renderBtDropIn() }
        </Loading>
      </div>
    )
  };

  const renderBtDropIn = () => {
    const onBTError = (error) => {
      console.log(error);
    };

    const onBTInit = ({ instance }) => {
      if (btDiv) {
        btDiv.current.scrollIntoView({ behavior: 'smooth'});
      }
    };

    const onPaymentMethodNonceReceived = ({ nonce }) => {
      payExamViaBraintree({ nonce });
    };

    return (
      paymentState.paymentMethodVisible && <div className="pay">
        {
          (paymentState.selectedPaymentMethod && (paymentState.selectedPaymentMethod === 'BT' || paymentState.selectedPaymentMethod === 'BTCN')) &&
          <Grid item ref={btDiv}>
            <Paper square className='px-3 py-3'>
              <BraintreeDropIn
                onError={onBTError}
                onInitialized={onBTInit}
                onPaymentMethodNonceReceived={onPaymentMethodNonceReceived}
              />
            </Paper>
          </Grid>
        }
      </div>
    );
  };

  return (
    <div style={{ 'marginLeft': '15%', 'marginRight': '40%', 'marginTop': '5%'}}>
      <Form model={newExamState} onSubmit={onSubmit} labelWidth="160">
        {
          isTeacherRole && studentList && <Form.Item label={labels.candidateName}>
            <Loading loading={newExamState.isLoadingStudentList}>
              <Select
                value={newExamState.studentId}
                disabled={newExamState.formDisabled}
                placeholder={labels.selectCandidate}
                onChange={onChangeCandidateSelect}
                loading={newExamState?.isLoadingStudentList}
              >
                {
                  studentList.map(item =>
                    <Select.Option
                      key={item.ID}
                      label={item.label}
                      value={item.ID}
                    />
                  )
                }
              </Select>
              {
                newExamState.error?.student && <Alert title={newExamState.error?.student} type="error" />
              }
            </Loading>
          </Form.Item>
        }
        {
          instruments && <Form.Item label={labels.instrument}>
            <Loading loading={newExamState.isLoadingInstrumentList}>
              <Select
                value={newExamState.instrumentId}
                disabled={newExamState.formDisabled}
                placeholder={labels.selectInstrument}
                onChange={onChangeInstrumentSelect}
                loading={newExamState?.isLoadingInstrumentList}
              >
                {
                  instruments.map(item =>
                    <Select.Option
                      key={item.id}
                      label={item.instrumentName}
                      value={item.id}
                    />
                  )
                }
              </Select>
              {
                newExamState.error?.instrument && <Alert title={newExamState.error?.instrument} type="error" />
              }
            </Loading>
          </Form.Item>
        }
        {
          newExamState.instrumentId && gradesAbove &&
          <Form.Item label={labels.grade}>
            <Select
              value={newExamState.gradeId}
              disabled={newExamState.formDisabled || newExamState.isLoadingPrice}
              loading={newExamState?.isLoadingGradeList}
              placeholder={labels.selectGrade}
              onChange={(value) => setNewExamState({ ...newExamState, gradeId: value})}
            >
              {
                gradesAbove.map(item =>
                  <Select.Option
                    key={item.id}
                    label={item.gradeName}
                    value={item.id}
                  />
                )
              }
            </Select>
            {
              newExamState.error?.grade && <Alert title={newExamState.error?.grade} type="error" />
            }
          </Form.Item>
        }
        <Loading loading={newExamState.isLoadingPrice}>
          {
            newExamState.instrumentId && newExamState.gradeId !== null &&  newExamState.cnTranslationAvailable && <Form.Item>
              <Checkbox
                checked={newExamState.isSelectedCnTranslation}
                disabled={newExamState.formDisabled}
                onChange={(checked) => setNewExamState({...newExamState, isSelectedCnTranslation: checked})}
              >
                {`${labels.examReportChineseTranslationService} (+${newExamState.currencyCode} ${currencySign}${newExamState.cnTranslationFee})`}
              </Checkbox>
              {
                newExamState.error?.price && <Alert title={newExamState.error?.price} type="error" />
              }
            </Form.Item>
          }
          {
            newExamState.instrumentId && newExamState.gradeId !== null && !isNaN(newExamState.paymentPrice) && <Form.Item label={labels.paymentAmount}>
              <div>
                <div>
                  {newExamState.currencyCode}
                  {'  '}
                  {currencySign}
                  {newExamState.paymentPrice}
                </div>
              </div>
            </Form.Item>
          }
          {
            renderExtraOptions()
          }
          {
            newExamState.instrumentId && newExamState.gradeId !== null && <Form.Item label={labels.affiliationCode}>
              <>
                <Input
                  icon="edit"
                  disabled={newExamState.formDisabled}
                  value={newExamState.affiliationCode}
                  onChange={(value) => setNewExamState({...newExamState, affiliationCode: value})}
                  minLength={3}
                  maxLength={50}
                />
                <Loading loading={newExamState.isValidatingAffiliationCode}>
                {
                  newExamState?.error?.affiliationCode &&
                    <Alert title={newExamState?.error?.affiliationCode} type="error" closable={false} />
                }
                </Loading>
              </>
            </Form.Item>
          }
          {
            newExamState.instrumentId && newExamState.gradeId !== null && <Form.Item>
              <Typography variant="caption" display="block" gutterBottom>{ disclaimText }</Typography>
            </Form.Item>
          }
          {
            newExamState?.error?.common &&
            <Alert title={newExamState?.error?.common} type="error" closable={false} />
          }
          {
            newExamState.instrumentId && newExamState.gradeId !== null && <Form.Item>
              <Layout.Row type="flex" justify='space-between'>
                <Layout.Col span="6">
                  <Button
                    type="primary"
                    onClick={onContinuePress}
                    disabled={!canProceed() || newExamState.formDisabled }
                    loading={
                      newExamState.isLoadingInstrumentList
                      || newExamState.isLoadingStudentList
                      || newExamState.isLoadingGradeList
                      || newExamState.isLoadingPrice
                      || newExamState.isValidatingAffiliationCode
                    }
                  >
                    {labels.proceedToPayment}
                  </Button>
                </Layout.Col>
                <Layout.Col span="6">
                  {
                    newExamState.formDisabled && <Button
                      type="primary"
                      onClick={() => {
                        setNewExamState({
                          ...newExamState,
                          formDisabled: false,
                        });
                        setPaymentState({
                          ...paymentState,
                          visible: false,
                        });
                      }}
                    >
                      {labels.reset}
                    </Button>
                  }
                </Layout.Col>
              </Layout.Row>
            </Form.Item>
          }
          {
            renderPayment()
          }
        </Loading>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {
})(EnrolNewExamScreen);
