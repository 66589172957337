export const zhHant = {
  // START ---- components::common/LoadingScreen
  saving: '儲存中...',
  submitting: '提交中...',
  uploading: '上傳中...',
  downloading: '下載中...',
  //   END ---- components::common/LoadingScreen

  // START ---- components::common/PhotoPickerWithLabel
  camera: '相機',
  album: '相冊',
  //   END ---- components::common/PhotoPickerWithLabel

  // START ---- components::common/FilePicker
  pdf: 'PDF 文件',
  //   END ---- components::common/FilePicker

  // START ---- components::common/FilterModalPickerWithLabel
  noMatches: '無匹配的結果...',
  searchHere: '在此搜尋...',
  //   END ---- components::common/FilterModalPickerWithLabel

  // START ---- components::LoginScreen
  email: '郵箱地址',
  password: '密碼',
  login: '登錄',
  forgotYourPassword: '忘記密碼？',
  dontHaveAnyAccount: '還未註冊帳號？',
  register: '註冊帳號',
  ok: '好的',
  error: '錯誤',
  connectWithFb: 'Facebook帳號登錄',
  connectWithWX: '微信帳號登陸',
  signInWithGoogle: 'Google帳號登錄',
  copyToClipBoard: '鏈接已複制到剪貼板',
  //   END ---- components::LoginScreen

  // START ---- components::RegisterRoleChoiceScreen
  registerAccount: '註冊用戶帳號',
  asTeacher: '老師註冊',
  asStudent: '學生註冊',
  //   END ---- components::RegisterRoleChoiceScreen

  // START ---- components::CandidateRegisterScreen
  state: '省份/州',
  selectstate: '請選擇省份/州',
  registerAsCandidate: '學生註冊',
  uploadProfilePhoto: '上傳個人頭像',
  confirmPassword: '確認密碼',
  firstName: '名',
  lastName: '姓',
  gender: '請選擇性別',
  male: '男',
  female: '女',
  other: '其他',
  dateOfBirth: '出生日期',
  addressLine1: '地址1',
  addressLine2: '地址2',
  suburb: '郊區',
  postcode: '郵政編碼',
  country: '國家',
  region: '地區',
  location: '定位',
  allRegion: '所有地區',
  selectCountry: '請選擇國家',
  landLine: '固定電話號碼',
  mobile: '手機號碼',
  teacherIdOptional: '老師證編碼 (可略)',
  guardianName: '家長/監護人姓名',
  candidateRegistration: '學生註冊',
  //   END ---- components::CandidateRegisterScreen

  // START ---- components::TeacherRegisterScreen
  registerAsTeacher: '老師註冊',
  institutionType: '學院類型',
  selectInstitutionType: '請選擇學院類型',
  qualification: '學歷資格',
  teacherRegistration: '老師註冊',
  //   END ---- components::TeacherRegisterScreen

  // START ---- components::QuickRegisterScreen
  accountRegistration: '註冊帳號',
  referralCode: '推薦碼',
  referralCodeOptional: '推薦碼 (可選)',
  //   END ---- components::QuickRegisterScreen

  // START ---- components::LoadingModal
  loading: '載入中...',
  //   END ---- components::LoadingModal

  // START ---- components::HomeScreen/SideBar/CandidateSideBar
  exams: '考試列表',
  paymentHistory: '付款歷史記錄',
  musicPieceRequests: '曲目申請列表',
  musicLibrary: '音樂庫',
  requestHelp: '請求幫助',
  logout: '退出',
  emailSubjectMyExamsCandidate: 'MyExams-學生APP',
  emailSubjectIssueReport: '錯誤報告',
  feeStructure: '考級費用',
  examGuidelines: '考級指南',
  newPerformanceEvaluation: '新級數評估',
  performanceEvaluations: '級數評估列表',
  diagnostics: '網絡檢測',
  //   END ---- components::HomeScreen/SideBar/CandidateSideBar

  // START ---- components::HomeScreen/SideBar/TeacherSideBar
  studentList: '學生列表',
  emailSubjectMyExamsTeacher: 'MyExams-老師APP',
  //   END ---- components::HomeScreen/SideBar/TeacherSideBar

  // START ---- components::HomeScreen/ExamPanel
  openExams: '開放中考試',
  submittedExams: '已提交考試',
  closedExams: '已完成考試',
  reworkExams: '重考考試',
  appealedExams: '已上訴考試',
  openPE: '開放中級數評估',
  closedPE: '已完成級數評估',
  //   END ---- components::HomeScreen/ExamPanel

  // START ---- components::CandidateHomeScreen
  myExamsForCandidate: '康柏爾考級 - 學員端',
  hello: '您好',
  newExam: '新考試',
  myProfile: '個人資料',
  newMusicPiece: '新曲目申請',
  confirm: '確認',
  cancel: '取消',
  exit: '退出',
  //   END ---- components::CandidateHomeScreen

  // START ---- components::CandidateProfileScreen
  candidateId: '學生證編碼',
  teacherId: '老師證編碼',
  updateProfile: '更新個人資料',
  confirmEmail: '確認郵箱地址',
  unsavedChanges: '未儲存的更改',
  selectDateOfBirth: '請選擇出生日期',
  //   END ---- components::CandidateProfileScreen

  // START ---- actions::UserActions
  profileUpdate: '更新個人資料',
  studentProfileUpdate: '更新學生個人資料',
  //   END ---- actions::UserActions

  // START ---- components::CandidateNewExamScreen
  instrument: '樂器',
  piano: '鋼琴',
  violin: '小提琴',
  altoSaxophone: '中音薩克管',
  flute: '長笛',
  cello: '大提琴',
  viola: '中提琴',
  doubleBass: '低音提琴',
  vocal: '聲樂',
  clarinet: '單簧管',
  oboe: '雙簧管',
  selectInstrument: '請選擇樂器',
  grade: '級別',
  selectGrade: '請選擇級別',
  affiliationCode: '附屬代碼 (如果適用)',
  paymentAmount: '付款金額',
  proceedToPayment: '前往付款',
  examReportChineseTranslationService: '中文報告翻譯服務',
  //   END ---- components::CandidateNewExamScreen

  // START ---- components::TnCScreen
  termsAndConditions: '條款和條件',
  submitExam: '提交考試',
  //   END ---- components::TnCScreen

  // START ---- components::CandidateAppealExamDetailsScreen
  remarks: '備註',
  submitAppeal: '提交上訴',
  cost: '用費',
  appealExam: '上訴考試',
  appealOption: '上訴選項',
  selectAppealOption: '請選擇上訴選項',
  //   END ---- components::CandidateAppealExamDetailsScreen

  // START ---- components::CompletedExamDetailScreen
  appeal: '上訴',
  sendCertToMyEmail: '發證書至我的郵箱',
  buyCert: '購買證書',
  completedExamDetails: '已完成考試詳情',
  candidateName: '學生',
  markedDate: '批改日期',
  sendReportToMyEmail: '發考試報告至我的郵箱',
  //   END ---- components::CompletedExamDetailScreen

  // START ---- helpers::AppHelper
  thisMonth: '這個月',
  lastMonth: '上個月',
  passWithHighDistinction: '特優',
  passWithDistinction: '優',
  passWithCredit: '良',
  pass: '及格',
  unsuccessful: '不及格',
  highDistinction: '特優',
  distinction: '優',
  credit: '良',
  yearsOld: '歲',
  yearsOldAndAbove: '歲或以上',
  yearsOldAndBelow: '歲或以下',
  upToMinutes: '不超過{0}分鐘',
  noAgeLimit: '無年齡限制',
  countThousand: '{0}k',
  countMillion: '{0}m',
  countBillion: '{0}b',
  localTime: '當地時間',
  //   END ---- helpers::AppHelper

  // START ---- components::EditExamScreen/EditExamTab
  pasteVideoLink: '粘貼視頻鏈接',
  recordAndUploadVideoNow: '錄製並上傳視頻',
  tapToSelectVideo: '輕敲此處以選擇視頻',
  remove: '删除',
  piece: '曲目',
  details: '詳情',
  musicType: '曲目類型',
  selectMusicType: '請選擇曲目類型',
  musicTitle: '曲目名字',
  selectMusicTitle: '請選擇曲目名字',
  searchHereOrSelectMusicTitle: '在此搜尋曲目...',
  video: '視頻',
  next: '繼續',
  uploadVideo: '上傳視頻',
  uploadPhoto: '上传照片',
  uploadedLink: '已上傳的視頻鏈接',
  reuploadVideo: '重新上傳視頻',
  //   END ---- components::EditExamScreen/EditExamTab

  // START ---- components::EditExamScreen/CandidateEditExamScreen
  piece1: '曲目1',
  piece2: '曲目2',
  piece3: '曲目3',
  piece4: '曲目4',
  piece5: '曲目5',
  summary: '概要',
  //   END ---- components::EditExamScreen/CandidateEditExamScreen

  // START ---- components::EditExamScreen/SummaryTab
  photoIdRequirement: '證件照條件',
  playingTimeRequirement: '演奏時間條件',
  photoId: '照片',
  save: '保存',
  proceedToSubmitExam: '前往提交考試',
  //   END ---- components::EditExamScreen/SummaryTab

  // START ---- components::MusicPieceListScreen/MusicPieceListItem
  by: '作曲家:',
  gradeRequested: '申請級別',
  dateSubmitted: '提交日期',
  submitted: '已提交',
  accepted: '已被接受',
  rejected: '已被拒絕',
  noRecordFound: '記錄不存在',
  //   END ---- components::MusicPieceListScreen/MusicPieceListItem

  // START ---- components::MusicPieceListScreen
  reasonofRejection: '拒絕原因',
  //   END ---- components::MusicPieceListScreen

  // START ---- components::NewMusicPieceScreen
  composer: '作曲家',
  nameOfThePiece: '曲目名字',
  sampleVideoLink: '視頻實例鏈接',
  uploadSheetMusic: '上傳樂譜',
  request: '申請',
  //   END ---- components::NewMusicPieceScreen

  // START ---- components::PaymentHistoryListScreen/PaymentHistoryListItem
  paymentDate: '付款日期',
  paymentType: '付款類型',
  //   END ---- components::PaymentHistoryListScreen/PaymentHistoryListItem

  // START ---- components::PurchaseCertificateScreen
  purchaseCertificate: '購買證書',
  address: '地址',
  certificate: '證書',
  //   END ---- components::PurchaseCertificateScreen

  // START ---- components::ResetPasswordScreen
  resetPassword: '重設密碼',
  sendPasswordResetLink: '發送重設密碼鏈接',
  //   END ---- components::ResetPasswordScreen

  // START ---- components::VideoGalleryScreen/VideoGalleryHeader
  selectPieceVideo: '請選擇曲目{0}的視頻',
  //   END ---- components::VideoGalleryScreen/VideoGalleryHeader

  // START ---- components::VideoGalleryScreen
  deleteVideo: '刪除視頻',
  yes: '是',
  no: '否',
  videoResolution: '視頻的分辨率',
  //   END ---- components::VideoGalleryScreen

  // START ---- components::VideoGalleryScreen/VideoOptionModal
  selectVideo: '請選擇視頻',
  playVideo: '播放視頻',
  //   END ---- components::VideoGalleryScreen/VideoOptionModal

  // START ---- actions::ExamActions
  success: '成功',
  examEnrolment: '報考考試',
  paymentGatewayError: '支付網關錯誤',
  examReport: '考試報告',
  examCertificate: '考試證書',
  examAppeal: '上訴考試',
  certPurchase: '購買證書',
  videoUpload: '上傳視頻',
  //   END ---- actions::ExamActions

  // START ---- components::UploadVideoScreen/UploadVideoScreen
  videoTitle: '標題',
  description: '詳情',
  upload: '上傳',
  goUpload: '點擊上傳新視頻',
  privacyPolicy: '隱私政策',
  privacyAndTerms: '服務條款和隱私政策',
  //   END ---- components::UploadVideoScreen/UploadVideoScreen

  // START ---- actions::SyllabusActions
  newMusicPieceRequest: '新曲目申請',
  //   END ---- actions::SyllabusActions

  // START ---- components::ExamListScreen/CandidateExamListScreen
  current: '目前',
  completed: '已完成',
  rework: '重考',
  //   END ---- components::ExamListScreen/CandidateExamListScreen

  // START ---- components::ExamListScreen/CandidateAppealExamListItem
  appealDate: '上訴日期',
  //   END ---- components::ExamListScreen/CandidateAppealExamListItem

  // START ---- components::ExamListScreen/CandidateCurrentExamListItem
  expiryDate: '截止日期',
  //   END ---- components::ExamListScreen/CandidateCurrentExamListItem

  // START ---- components::CandidateHomeScreen
  myExamsForTeacher: '康柏爾考級 - 老師端',
  //   END ---- components::CandidateHomeScreen

  // START ---- components::CandidateProfileScreen
  pleaseStateYourInstitutionType: '請另行註明您的學院類型',
  //   END ---- components::CandidateProfileScreen

  // START ---- components::CandidateProfileScreen
  studentProfile: '學生個人資料',
  //   END ---- components::CandidateProfileScreen

  // START ---- components::TeacherNewExamScreen
  selectCandidate: '請選擇學生',
  //   END ---- components::TeacherNewExamScreen

  // START ---- components::StudentProfileEditScreen
  removeThisStudent: '删除此學生',
  removeStudent: '删除學生',
  //   END ---- components::StudentProfileEditScreen

  // START ---- actions::AuthActions
  passwordReset: '密碼重設',
  //   END ---- actions::AuthActions

  // START ---- actions::CommonActions
  updateApp: '更新APP',
  versionCheckError: '版本驗證錯誤',
  //   END ---- actions::CommonActions

  // START ---- components::BrowseMusicLibraryScreen
  searchBy: '搜尋方式',
  throughInstrumentAndGrade: '通過樂器與等級',
  throughMusicName: '通過曲目名字',
  throughComposer: '通過作曲家',
  musicName: '曲目名字',
  searchMusicPiece: '搜尋曲目',
  //   END ---- components::BrowseMusicLibraryScreen

  //  START ---- components::BrowseMusicLibraryScreen
  cbeRecommendedPieces: '搜尋康柏爾推薦的曲目',
  searchResult: '搜尋結果',
  noResult: '無結果',
  //    END ---- components::BrowseMusicLibraryScreen

  //  START ---- components::MusicLibraryDetailsScreen
  recommendedByCBE: '康柏爾強力推薦',
  viewSheetMusic: '購買樂譜',
  reportBrokenVideoLink: '舉報無效視頻鏈接/版权信息',
  item: '物件',
  sheetMusicOf: '曲譜 [{0}]',
  //    END ---- components::MusicLibraryDetailsScreen

  //  START ---- components::SocialMediaEmailScreen
  submit: '提交',
  //    END ---- components::SocialMediaEmailScreen

  // START ---- components::AccountUpgradeChooseRoleScreen
  upgradeAccount: '完成個人資料',
  //   END ---- components::AccountUpgradeChooseRoleScreen

  // START ---- components::SocialMediaPasswordScreen
  linkYourFbAccount: '連接您的Facebook帳號',
  //   END ---- components::SocialMediaPasswordScreen

  // START ---- components::CandidateCompletedPieceEvaluationDetailScreen
  completedPieceEvaluation: '已完成的級數評估',
  recommendedGrade: '建議級別',
  marks: '分數',
  sendPerformanceEvaluationReportToMyEmail: '發級數評估報告至我的郵箱',
  //   END ---- components::CandidateCompletedPieceEvaluationDetailScreen

  // START ---- actions::PieceEvaluationActions
  performanceEvaluationReport: '級數評估報告',
  requestPieceEvaluation: '申請級數評估',
  //   END ---- actions::PieceEvaluationActions

  // START ---- components::CandidateNewPieceEvaluationScreen
  pieceLength: '曲目長度',
  //   END ---- components::CandidateNewPieceEvaluationScreen

  // START ---- components::TeacherEditPieceEvaluationScreen
  pieceSource: '曲目來源',
  proceedToSubmit: '前往提交',
  syllabusFromCbe: '康柏爾音樂庫的曲目',
  ownDetails: '自備的曲目',
  videoLink: '視頻鏈接',
  //   END ---- components::TeacherEditPieceEvaluationScreen

  // START ---- components::LandingScreen
  home: '主頁',
  wallet: '錢包',
  events: '活動',
  trending: '熱門',
  library: '圖書館',
  assess: '考級',
  manage: '管理',
  youbrioApp: 'YOUBRIO APP',
  loginAsAdmin: '登錄爲系統管理員',
  loginAsSupport: '登錄爲客服',
  backToTeacher: '回到帳號',
  featured: '精選',
  tapToEnter: '點擊進入',
  enterMobileNo: '更新手機號碼',
  recentNews: '最新消息',
  moreInfo: '了解優伯',
  hallOfFame: '明日之星',
  beginner: '初級',
  intermediate: '中級',
  advanced: '高級',
  qualityControl: '優伯教學的品質保證',
  howToStart: '如何上課',
  aboutYoubrio: '關於優伯',
  testimonials: '用戶評价',
  approvedTeachers: '優伯老師保證',
  customerService: '五星客服',
  safePayment: '平台安全付款系統',
  withdrawalGuarentee: '取款承諾',
  //   END ---- components::LandingScreen

  // START ---- actions::EventActions
  eventEnrolment: '報名項目',
  //   END ---- actions::EventActions

  // START ---- components::LandingScreen/SideBar
  eventEnrolments: '項目報名列表',
  youbrioUserLevelInfo: '优伯教学等級詳情',
  myWallet: '我的錢包',
  scan: '掃描',
  //   END ---- components::LandingScreen/SideBar

  // START ---- components/CandidateEventEnrolmentListScreen
  eventEnrolmentDetails: '報名項目詳情',
  //   END ---- components/CandidateEventEnrolmentListScreen

  // START ---- components/CandidateEventEnrolmentListScreen/CandidateEventEnrolmentListItem
  enrolmentDate: '報名日期',
  ageGroup: '年齡層',
  //   END ---- components/CandidateEventEnrolmentListScreen/CandidateEventEnrolmentListItem

  // START ---- components::PaymentDetailScreen
  paymentDetail: '支付詳情',
  sendReceiptToMyEmail: '發收據致我的郵箱',
  paymentGateway: '支付網關',
  directDeposit: '銀行轉帳',
  //   END ---- components::PaymentDetailScreen

  // START ---- components::LandingScreen/LandingTab/EventsTab
  currentEvents: '正在進行中的項目',
  collections: '收集',
  upcomingEvents: '即將來臨的項目',
  //   END ---- components::LandingScreen/LandingTab/EventsTab

  // START ---- components::NewEventEnrolmentScreen/CandidateNewEventEnrolmentScreen
  musicPeriod: '曲目時期',
  duration: '視頻長度',
  fee: '報名費',
  newEventEnrolment: '新項目報名',
  event: '項目',
  eventDate: '項目日期',
  eventLocation: '項目地點',
  category: '組別',
  selectCategory: '請選擇組別',
  addAffiliateCode: '添加附屬代碼',
  appliedAffiliateCode: '附屬代碼',
  free: '免費',
  proceed: '繼續',
  //   END ---- components::NewEventEnrolmentScreen/CandidateNewEventEnrolmentScreen

  // START ---- components::AddAffiliateCodeScreen/AddAffiliateCodeScreen
  tapToScanQRCode: '掃一掃二維碼',
  enterAffiliateCode: '輸入附屬代碼',
  applyAffiliateCode: '應用附屬代碼',
  or: '或',
  //   END ---- components::AddAffiliateCodeScreen/AddAffiliateCodeScreen

  // START ---- components::EditEventEnrolmentScreen/CandidateEditEventEnrolmentScreen
  genre: '演奏人數',
  selectGenre: '請選擇演奏人數',
  performanceVideo: '演奏視頻',
  selectPerformanceVideo: '請選擇演奏視頻',
  //   END ---- components::EditEventEnrolmentScreen/CandidateEditEventEnrolmentScreen

  // START ---- components::SearchVideoScreen/SearchVideoScreen
  showingSearchResultFor: '正在顯示“{0}”的搜索結果。',
  enterNameOrTitle: '請輸入關鍵字...',
  //   END ---- components::SearchVideoScreen/SearchVideoScreen

  // START ---- components::LandingScreen/LandingTab/TrendingListItem
  shareYoubrioVideo: '分享Youbrio視頻',
  more: '更多',
  levelN: 'LV {0}',
  share: '分享',
  //   END ---- components::LandingScreen/LandingTab/TrendingListItem

  // START ---- components::LandingScreen/LandingTab/TrendingListItem
  shareYoubrioPhoto: '分享Youbrio圖片',
  //   END ---- components::LandingScreen/LandingTab/TrendingListItem

  // START ---- components/LandingScreen/ProfileDetailTab
  me: '我',
  yourLevelIsNow: '您目前的等級是',
  toTeach: '老師',
  teachProfile: '老師個人主頁',
  makeMePrivate: '是否改為私人賬號？ ',
  referenceRate: '我的課程小時費',
  askAdmin: '詢問客服',
  teacherRequest: '請求老師',
  //   END ---- components/LandingScreen/ProfileDetailTab

  // START ---- components/WantToTeachScreen/WantToTeachScreen
  //   END ---- components/WantToTeachScreen/WantToTeachScreen

  // START ---- components/WatchVideoScreen/WatchVideoScreen
  comeWatchPerform: 'YOUBRIO邀約您在Youbrio.com上觀看{0}所演奏的{1}',
  //   END ---- components/WatchVideoScreen/WatchVideoScreen

// START ---- components/LandingScreen/LandingTab/ProfileDetailVideoTab/CertificateTab/CertificateTab
  uploadCertificate: '上傳證書',
  certDescAndOrigin: '填寫證書描述、來源等等',
  photoDescAndOrigin: '照片介紹、來源等等',
  enrolExam: '報考考試',
  myCerts: '我的證書',
  photo: '照片',
  //   END ---- components/LandingScreen/LandingTab/ProfileDetailVideoTab/CertificateTab/CertificateTab

  // START ---- components/AuditionListScreen/AuditionListScreen
  auditions: '試鏡項目',
  //   END ---- components/AuditionListScreen/AuditionListScreen

  // START ---- components/AuditionListScreen/AuditionListScreen
  competitions: '比賽項目',
  //   END ---- components/AuditionListScreen/AuditionListScreen

  // START ---- components/AuditionListScreen/AuditionListScreen
  examinations: '考級',
  //   END ---- components/AuditionListScreen/AuditionListScreen

  // START ---- components::EventDetailScreen
  eventLinks: '項目鏈接',
  comingSoonEllipsized: '即將來臨...',
  enroll: '報名',
  audition: '試鏡項目',
  currentEvent: '正在進行中的項目',
  pastEvent: '過去的項目',
  upcomingEvent: '即將來臨的項目',
  //   END ---- components::EventDetailScreen

  // START ---- components::UserYoubrioProfileScreen
  userProfile: '用戶資料',
  videos: '個視頻',
  likes: '個贊',
  levelFullN: 'Level {0}',
  uploadedVideos: '視頻',
  //   END ---- components::UserYoubrioProfileScreen

  // START ---- components::MyCertificatesListScreen/MyCertificatesListItem
  uploadDate: '上傳日期',
  reviewed: '已審查',
  //   END ---- components::MyCertificatesListScreen/MyCertificatesListItem

  // START ---- components::EditEmailScreen
  changeEmail: '更換郵箱地址',
  currentEmail: '目前的郵箱地址',
  newEmail: '新郵箱地址',
  //   END ---- components::EditEmailScreen

  // START ---- components::UserLevelInfoListScreen/UserLevelInfoListScreen
  userLevelInfo: '用戶等級詳情',
  voteCount: '票數',
  //   END ---- components::UserLevelInfoListScreen/UserLevelInfoListScreen

  // START ---- actions::PaymentActions
  requestReceipt: '發送收據',
  //   END ---- actions::PaymentActions

  // START ---- components::common/VideoPickerWithLabel
  appVideo: 'APP視頻',
  //   END ---- components::common/VideoPickerWithLabel

  // START ---- components::TeachProfileScreen/TeachProfileScreen
  na: '無',
  organization: '教育机构',
  level: '等级',
  experience: '资历',
  yr: '年',
  age: '年龄',
  rating: '评分',
  language: '语言',
  spokenLanguage: '課程語言',
  math: '數學',
  subjects: '科目',
  purpose: '目標',
  recommendation: '相关老師推荐',
  about: '关于',
  abn: 'ABN',
  achievement: '收藏',
  collection: '我的收藏',
  othercollection: '收藏',
  delete: '删除',
  manageMyLessons: '管理我的课程',
  manageMyClasses: '管理我的学习',
  registerForGST: '是否注册GST',
  edit: '修改',
  add: '添加',
  play: '播放',
  intro: '老師簡介',
  chooseFromLibrary: '從相冊中選擇',
  chooseFromAchievements: '從我的成就選擇',
  changeVideo: '更換視頻',
  //   END ---- components::TeachProfileScreen/TeachProfileScreen

  // START ---- components::EditReferenceRateScreen
  privateInSearch: '将將帳戶設為私人賬號，您將不會被包括在“搜索”教師的搜索中，您的學生仍然可以通過搜索您的姓名來找到您。',
  referenceRateDesc: '為了幫您找到更合適到學生，請讓我們知道您每小時的課程收費。這將顯示在您的個人資料上作為費用指示。',
  //   END ---- components::EditReferenceRateScreen

  // START ---- components::EditDescriptionScreen
  aboutMe: '关于',
  aboutMeLong: '告诉我们一些关于您的事情，以便您未来的学生或老師能够更好的认识您。',
  //   END ---- components::EditDescriptionScreen

  // START ---- components::EditDescriptionScreen
  experienceTitle: '经验',
  experienceDesc: '您教学多少年了?',
  //   END ---- components::EditDescriptionScreen

  // START ---- components::ManageMyLessonScreen
  myStudents: '我的学生',
  myLessons: '我的课程時段',
  cLesson: '創建課程',
  new: '創建',
  lessonSettings: '課程設置',
  myFees: '我的费用',
  myTimetable: '我的时间表',
  resources: '學習資源',
  shop: '音樂商城',
  help: '幫助',
  helpCenter: '功能介紹',
  categories: '類型',
  myPayments: '我的付款方式',
  upcomingLessons: '即将开始的课程',
  favourite: '收藏',
  unfavourite: '取消收藏',
  addToCalendar: '添加到日曆',
  editCalendar: '修改日曆',
  cannotBeFound: '无法找到',
  //   END ---- components::ManageMyLessonScreen

  // START ---- components::AdminManageScreen
  newTeachers: '新老師',
  //   END ---- components::AdminManageScreen

  // START ---- components::ManageMyClassScreen
  myTeachers: '我的老師',
  myClasses: '我的课程',
  //   END ---- components::ManageMyClassScreen

  // START ---- components::FindTab
  connect: '連接',
  disconnect: '斷開',
  find: '寻找',
  explore: '搜索',
  findMyTeacher: '寻找老師',
  connectMyTeacher: '掃二維碼連接我的老師',
  connectMyStudent: '掃二維碼連接我的學生',
  findTeacherSchool: '尋找新老師',
  any: '任何',
  genderPreference: '推荐性别',
  search: '搜索',
  keyword: '關鍵詞',
  hourlyRate: '課時費/小時',
  searchCriteria: '搜索條件',
  noSubject: '老師還未選擇任何科目',
  feeIndication: '課費範圍',
  upTo: '{0}{1}以下',
  andAbove: '以上',
  lastOnlineTime: '最後在線 {0} 之前',
  months: '月',
  year: '年',
  searchForTeacher: '搜索老師',
  searchForTeacherAndSchool: '老師/學校',
  //   END ---- components::FindTab

  // START ---- components::BasicProfileTab
  wantToLearn: '我想学习',
  wantToTeach: '我想教课',
  //   END ---- components::BasicProfileTab

  // START ---- components::VideoCallScreen
  waiting: '等待',
  waitingToReconnect: '重新连接中...',
  //   END ---- components::VideoCallScreen

  // START ---- components::TopUpFundScreen
  deposit: '充值',
  studyCreditDeposit: '钱包充值',
  //   END ---- components::TopUpFundScreen

  // START ---- components::FundTransactionListScreen
  txnHistory: '交易历史',
  //   END ---- components::FundTransactionListScreen

  // START ---- components::FundTransactionListScreen/FundPriceListItem
  studyCredit: '錢包余額',
  //   END ---- components::FundTransactionListScreen/FundPriceListItem

  // START ---- components::TopUpPaymentMethodScreen
  paymentMethod: '支付方式',
  topUpAmt: '充值金額',
  retry: '重試',
  convertedAmount: '已兌換金額',
  processingFee: '手續費',
  alipayProcessingFee: '支付寶手續費',
  wechatProcessingFee: '微信支付手續費',
  thirdPartyProcessingFee: '銀行手續費',
  pay: '去支付',
  paymentWillBeMadeToAccount: '您將支付至 {0}',
  //   END ---- components::TopUpPaymentMethodScreen

  // START ---- components::BrioWalletSummaryScreen
  moreTxnHistory: '更多交易历史...',
  youHaveNotMadeAnyTransactions: '您未做任何交易。',
  mostRecentTransactions: '最新交易记录',
  withdraw: '提現',
  serviceFee: '服務費',
  receivableAmount: '應收款',
  //   END ---- components::BrioWalletSummaryScreen

  // START ---- components::NotificationListScreen
  notifications: '通知',
  youDoNotHaveAnyNotificationsYet: '您還未有任何消息',
  markAllAsRead: '標記所有通知為已讀',
  deleteNotification: '刪除通知',
  //   END ---- components::NotificationListScreen

  // START ---- components::LessonNotificationListScreen
  lessonRequestNotifications: '課程請求通知',
  //   END ---- components::LessonNotificationListScreen

  // START ---- components::CreateLessonScreen
  createLesson: '創建課程時段',
  startLesson: '立即開課',
  scheduleLesson: '課程創建',
  createAndInvite: '創建並分享',
  shareLesson: '分享课程',
  close: '關閉',
  invite: '邀請',
  anyone: '任何人',
  selectStudent: '選擇學生',
  timeNow: '現在時間',
  lessonDashboard: '課程控制面板',
  lessonType: '課程類型',
  lessonDuration: '課程時間',
  nMinutes: '{0} 分鐘',
  lessonSlots: '課程時段',
  lessonName: '課程名稱',
  lessonDescription: '課程介紹',
  lessonFee: '課程費用',
  iWantToReviewMyStudents: '我想要確認學生',
  tDuration: '時長',
  sunday: '星期天',
  monday: '星期一',
  tuesday: '星期二',
  wednesday: '星期三',
  thursday: '星期四',
  friday: '星期五',
  saturday: '星期六',
  daily: '每天',
  once: '一次',
  setLessonSlot: '設置課程時段',
  lesson: '課程',
  set: '確認設置',
  reminder: '溫馨提醒',
  minimumLessonDuration: '最短課程時段',
  minimumDuration: '最短時段',
  repeat: '重複',
  time: '時間',
  onceOnly: '一次',
  notify: '通知我認識的人',
  notifyStudents: '通知學生',
  noNotify: '我不想通知任何人',
  availableForEveryone: '開放給任何人',
  lessonFeeForTeacher: '老師的學費',
  makeThisLessonFree: '建立免費課程',
  //   END ---- components::CreateLessonScreen

  // START ---- components::MyCalendarScreen
  myTimeTable: '我的時間表',
  noLesson: '無課程',
  practiceLesson: '練習課',
  pleaseTapOnADate: '請點擊以上日期。',
  groupLesson: '多人課程',
  //   END ---- components::MyCalendarScreen

  // START ---- components::BrowseLessonListScreen
  someonesLessons: '{0}\'的課程',
  //   END ---- components::BrowseLessonListScreen

  // START ---- components::BookLessonScreen
  bookLesson: '預定課程',
  selectLessonSlots: '選擇課程時段',
  feeSummary: '費用總結',
  platformCharge: '平台收費',
  gstCharged: 'GST',
  currency: '貨幣',
  total: '總額',
  discountedTotal: '優惠價',
  lessonGoals: '課程目標',
  //   END ---- components::BookLessonScreen

  // START ---- components::LessonPaymentScreen
  studyWalletBalance: '我的錢包餘額',
  toBePaid: '許付款',
  payAndBookLesson: '支付並預定課程',
  topUp: '充值',
  yesSure: '好的，沒問題',
  noThanks: '不了，謝謝',
  //   END ---- components::LessonPaymentScreen

  // START ---- components::LessonPaymentScreen
  noLessonBookingFound: '無法找到任何預定課程',
  awaitingApproval: '等待老師同意',
  confirmed: '已確認',
  bookingRejected: '請求被拒絕',
  cancelledByTeacher: '已被老師取消',
  cancelledByStudent: '已被學生取消',
  cancelledByStudentNoRefund: '已被學生取消 (無退款)',
  expiredStudent: '已過期 (學生未出現)',
  expiredTeacher: '已過期 (老師未出現)',
  bookingCompleted: '已完成',
  inProgress: '课程進行中',
  bookedAt: '預定時間',
  //   END ---- components::LessonPaymentScreen

  // START ---- component::TeacherLessonBookingDetailScreen
  bookingId: '預定ID',
  status: '狀態',
  bookedBy: '預定者',
  referralDetails: '推薦詳情',
  bookedThrough: '預定管道',
  commission: '提成',
  timeToClass: '上課時間',
  timeToLesson: '上課時間',
  lessonStarted: '課程已經開始了',
  lessonIsCanceled: '課程已被取消了',
  bookingDetails: '課程詳情',
  yearsDuration: '年',
  monthsDuration: '個月',
  weeksDuration: '個星期',
  daysDuration: '天',
  hoursDuration: '小時',
  minsDuration: '分鐘',
  days: '天',
  hours: '時',
  hour: '時',
  minutes: '分鍾',
  minShort: '分鍾',
  mins: '分',
  seconds: '秒',
  approve: '同意',
  reject: '拒絕',
  rejectLessonBooking: '拒絕此預定課程',
  cancelLessonBooking: '取消此預定課程',
  completeLessonBooking: '標記此課程為已完成',
  referredBy: '推薦人',
  dualCamera: '雙鏡頭',
  //   END ---- component::TeacherLessonBookingDetailScreen

  // START ---- component::StudentLessonBookingDetailScreen
  taughtBy: '老師名稱',
  web: '網頁版本',
  spectatorMode: '觀眾模式',
  viewLesson: '查看課程',
  enter: '進入',
  enterLesson: '進入教室',
  joinLesson: '參與課程',
  enterLessonRoom: '進入教室',
  launchSmartboardOnly: '只開啟智能板',
  dualDeviceMode: '雙設備模式',
  whatIsDualDeviceMode: '什麼是雙設備模式？',
  lateCancellation: '臨時取消',
  prepareSmartboard: '預備智能板',
  viewSmartboardNotes: '查看智能板筆記',
  youHaveNoSmartboardNotes: '您的智能板筆記不存在。',
  //   END ---- component::StudentLessonBookingDetailScreen

  // START ---- component::BookingSlotChangeRequestListScreen
  requestTimeChange: '請求更改課程時間',
  changeRequests: '課程更改請求',
  deleteChangeRequest: '撤銷課程更改請求',
  approveChangeRequest: '批准課程更改請求',
  rejectChangeRequest: '拒絕課程更改請求',
  noChangeRequestFoundForThisBooking: '此課程無更改請求。',
  sendRequest: '發送請求',
  notes: '備註',
  pleaseStateYourChangeRequestReason: '請填寫更改時間的原因、其他合宜時段等等...',
  you: '您',
  yourTeacher: '您的老師',
  yourStudent: '您的學生',
  requestApproved: '請求已被批准',
  requestDeclined: '請求已被拒絕',
  pendingRequest: '待定的請求',
  deleteRequest: '撤銷請求',
  requestToChangeLessonTime: '{0}做出了更改課程時間的請求。',
  youHaveALessonAtThisTime: '在這時段裏您將有另一個課程',
  youHaveNoLessonAtThisTime: '此時段無衝突課程。',
  checkingForOverlappingLesson: '正在搜索衝突課程...',
  unableToCheckForOverlappingLesson: '無法查詢衝突課程。\n請點擊以重試。',
  requestedByAt: '{0}在 {1} 做出了此請求。',
  yourTimeChangeRequestApproved: '您的課程請求已被批准了。',
  yourTimeChangeRequestRejected: '您的課程請求已被拒絕了。',
  //   END ---- component::BookingSlotChangeRequestListScreen

  // START ---- component::TeacherEditLessonScreen
  settings: '设置',
  editLesson: '修改課程時段',
  updateLesson: '更新課程時段',
  deleteLesson: '刪除課程時段',
  unlistLesson: '撤銷此課程',
  relistLesson: '重新發布此課程',
  lessonCreatedFor: '課程為此學生而創建',
  //   END ---- component::TeacherEditLessonScreen

  // START ---- component::MyLessonTeacherListScreen/MyLessonTeacherListItem
  nLessonsBooked: '預定了 {0} 節課程',
  selected: '已選',
  selectAll: '全選',
  unselectAll: '取消全選',
  selectedAll: '已全選',
  //   END ---- component::MyLessonTeacherListScreen/MyLessonTeacherListItem

  // START ---- component::ProfilePreviewScreen
  onlyForStudents: '請注意',
  //   END ---- component::ProfilePreviewScreen

  // START ---- component::ExaminationListScreen/ExaminationListItem
  onlineExamination: '在線考試',
  offlineExamination: '線下考試',
  //   END ---- component::ExaminationListScreen/ExaminationListItem

  // START ---- component::ManageMyClassScreen/UpcomingLessonBookingListItem
  now: '現在',
  tapToBook: '課程通知',
  group: '多人班',
  pendingBooking: '待預約中...',
  //   END ---- component::ManageMyClassScreen/UpcomingLessonBookingListItem

  // START ---- component::TimeSlotModalPicker
  slotStart: '開始',
  slotEnd: '結束',
  date: '日期',
  slotRecurringDay: '重複',
  hint: '提示',
  completeNow: '立即完成',
  selectTimeSlot: '選擇時段',
  select: '選擇',
  thisIsRecurring: '這是重複的時段。',
  addTimeSlot: '添加課程',
  startDate: '開始日期',
  endDate: '結束日期',
  //   END ---- component::TimeSlotModalPicker

  // START ---- component::BookingTimeSlotModalPicker
  iWantToBook: '我要約課',
  fee2: '學費',
  // START ---- component::BookingTimeSlotModalPicker

  // START ---- component::FundWithdrawScreen
  myPaymentMethods: '更新我的提現方式',
  choosePaymentMethod: '選擇提現方式',
  //   END ---- component::FundWithdrawScreen

  // START ---- component::FundWithdrawPaymentMethodScreenList
  addPaymentMethod: '添加提現方式',
  //   END ---- component::FundWithdrawPaymentMethodScreenList

  // START ---- component::FundWithdrawAddPaymentMethodScreen
  bank: '銀行',
  bsb: 'BSB',
  accNo: '銀行戶口號碼',
  paypalEmail: 'Paypal 電子郵件地址',
  alipayEmailOrMobile: '支付寶 電子郵件地址/手機號碼',
  fullNameRegistered: '全名',
  alipay: '支付寶',
  wechatpay: '微信支付',
  paypal: 'Paypal',
  bankTransfer: '銀行轉帳',
  paymentChannel: '支付管道',
  selectBank: '請選擇銀行',
  bankCountry: '銀行分行所屬國家',
  bankSwiftCode: 'SWIFT/BIC代碼',
  iban: '國際銀行帳戶號碼（IBAN）',
  bankName: '銀行名稱',
  bankAddress: '銀行地址',
  //   END ---- component::FundWithdrawAddPaymentMethodScreen

  // START ---- component::WithdrawalSummaryScreen
  withdrawalSummary: '提現摘要',
  amount: '金額',
  enterPassword: '請輸入密碼',
  enterLoginPassword: '請輸入登陸密碼',
  //   END ---- component::WithdrawalSummaryScreen

  // START ---- component::FundWithdrawalListScreen
  myWithdrawals: '提現',
  makeWithdrawal: '創建提現請求',
  //   END ---- component::FundWithdrawalListScreen

  // START ---- component::WriteReviewScreen
  writeAReview: '寫評語',
  writeAFeedback: '給予反饋',
  editAReview: '修改評分',
  rateTeacher: '給予老師評價',
  rateStudent: '給予學員評價!',
  rateCallQuality: '上課質量反饋',
  leaveComment: '你對他的課程評價',
  leaveCommentQuestion: '你願意現在評價這節課嗎?或者你隨時可以回到次課程評價。',
  leaveAsAnonymous: '匿名留言',
  anonymous: '匿名',
  //   END ---- component::WriteReviewScreen

  // START ---- component::ViewReviewScreen
  allReviews: '全部評論',
  viewMore: '查看更多',
  viewLess: '查看更少',
  //   END ---- component::ViewReviewScreen

  // START ---- component::SimpleIntroSlider
  sTitleZero: '如果您是壹位學生/家長',
  tTitleZero: '如果您是壹位老師',
  tutorial: '指南',
  thisTutorial: '這個指南',
  skip: '跳過',
  done: '完成',
  inLearning: '對學習有興趣',
  inTeaching: '對教學有興趣',
  titleZero: '1. 設立個人簡介',
  sDescZero: '設立您的YOUBRIO帳號爲學生帳號',
  tDescZero: '設立您的YOUBRIO帳號爲老師帳號',
  sTitleOne: '2. 完成您的個人簡介',
  sDescOne: '填寫您的個人資料',
  sTitleTwo: '3. 選擇老師',
  sDescTwo: '使用“尋找老師”功能以尋找及選擇適合您的學習需求。',
  sTitleThree: '4. 選擇課程',
  sDescThree: '當您選好老師後，就可以選擇老師所提供的課程以及上課時間。',
  sTitleFour: '5. 準備上課',
  sDescFour: '進入教室並等候您的老師！',
  tTitleOne: '2. 完成您的簡介',
  tDescOne: '填寫您的個人資料',
  tTitleTwo: '3. 創建課程',
  tDescTwo: '您所創建的課程都會讓您的學生參閱。請在創建課程時填寫課程詳情以及課程內容。',
  tTitleThree: '4. 確認課程預定',
  tDescThree: '當學生向您預定課程時，您可以選擇自動確認並加入到您的課程表或自行手動確認。',
  tTitleFour: '5. 準備上課',
  tDescFour: '當上課時間到了，進入教室並開始上課！',
  //   END ---- component::SimpleIntroSlider

  // START ---- component::SchoolIntroScreen
  schoolOrg: '學校',
  registerMySchoolOrg: '登記我的學校',
  manageMySchoolOrg: '管理學校',
  searchForSchool: '搜尋學校',
  //   END ---- component::SchoolIntroScreen

  // START ---- component::CreateSchoolScreen
  uploadSchoolProfilePhoto: '上傳學校簡介圖像',
  schoolOrgName: '學校名稱',
  schoolOrgIntrouction: '學校介紹',
  yearFounded: '創立年份',
  commissionPercentage: '提成百分比',
  defaultSchoolCommissionRate: '默認學校提成',
  organisationApplication: '申請學校',
  organisation: '學校',
  //   END ---- component::CreateSchoolScreen

  // START ---- component::SchoolProfileScreen
  editSchool: '編輯學校',
  schoolName: '學校名稱',
  profitPercentage: '盈利百分比',
  numOfTeacher: '老師',
  schoolOrgIntroduction: '學校介紹',
  schoolOrgAddress: '學校地址',
  discountPercentage: '學生折扣百分比',
  //   END ---- component::SchoolProfileScreen

  // START ---- component::ManagingSchoolListScreen/ManagingSchoolListItem
  teachers: '老師',
  students: '學生',
  requests: '請求',
  //   END ---- component::ManagingSchoolListScreen/ManagingSchoolListItem

  // START ---- component::SchoolTeacherListScreen/SchoolTeacherListItem
  removeFromSchool: '從學校删除',
  //   END ---- component::SchoolTeacherListScreen/SchoolTeacherListItem

  // START ---- component::SchoolPendingMembershipListScreen
  pendingJoinRequest: '待批准的加入請求',
  decline: '拒絕',
  blacklistName: '把{0}列入黑名單',
  viewNameProfile: '查看{0}的簡介',
  teacher: '老師',
  student: '學生',
  //   END ---- component::SchoolPendingMembershipListScreen

  // START ---- component::SchoolBlacklistedMembershipListScreen
  blacklistedProfiles: '黑名單',
  removeNameFromBlacklist: '把{0}從黑名單上删除',
  //   END ---- component::SchoolBlacklistedMembershipListScreen

  // START ---- component::WizardResultScreen
  school: '學校',
  //   END ---- component::WizardResultScreen

  // START ---- component::SchoolProfilePreviewScreen
  join: '加入',
  requestSent: '已發加入請求',
  joined: '已加入',
  leaveSchool: '退出學校',
  selectTeacher: '選擇老師',
  joinSchool: '加入學校/機構',
  viewSchool: '查看學校',
  admin: '管理員',
  //   END ---- component::SchoolProfilePreviewScreen

  // START ---- component::EditSchoolProfileScreen
  updateSchoolProfile: '更新學校簡介',
  //   END ---- component::EditSchoolProfileScreen

  // START ---- component:SchoolAdminProfileScreen
  deleteApplication: '刪除申請',
  findStudent: '尋找學生',
  //   END ---- component:SchoolAdminProfileScreen

  // START ---- component::DocumentFolderScreen
  sheetMusicFolder: '曲目文件夾',
  //   END ---- component::DocumentFolderScreen

  // START ---- component::AnnotationDocumentScreen
  waitingForSheetMusic: '正在載入文件中',
  unableToLoadSheetMusic: '無法載入文件。',
  zoom: '放大',
  annotate: '註釋',
  mode: '模式',
  uploadAnImage: '上傳新圖片',
  blankWhiteboard: '添加空白頁',
  eraseAll: '刪除所有筆畫',
  closeTab: '關閉此頁',
  tabRemoved: '此頁將被移除',
  //   END ---- component::AnnotationDocumentScreen

  // START --- component::AnnotationUploadPhotoScreen
  annotateMusicSheet: '標記曲譜',
  chooseMusicSheetImage: '選擇曲譜照片',
  myTeacherStudentWillUploadInstead: '恢復之前記錄（若有）',
  //   END --- component::AnnotationUploadPhotoScreen

  // START --- component::CalendarSlotPickerScreenV2
  doesNotFulfillMinDuration: '不符合最短時段',
  totalDuration: '總時間 {0} 分鐘 = {1} 節課',
  nMinutesLesson: '{0}分鐘課程',
  //   END --- component::CalendarSlotPickerScreenV2

  // START --- component::QRCodeScreen
  qrCode: '二維碼',
  myQRCode: '我的二維碼',
  ypLabel: '優伯個人主頁 - ',
  notNow: '拒絕',
  openSettings: '打開設置',
  scanToSee: '掃二維碼連接',
  //   END --- component::QRCodeScreen

  // START --- component::QRCodeScannerScreen
  cameraPermission: '相機權限',
  //   END --- component::QRCodeScannerScreen

  // START --- component::SettingScreen
  package: '課程包',
  discount: '優惠',
  lessons: '課程管理',
  off: '優惠',
  optin: '開啟課程包',
  agreePackage: '選擇開啟，即表示您同意以下課程包:',
  enablePackageNote: '通過開啟課程包，您可以鼓勵學生定期和長期致力於與您一起學習。',
  optInReferral: '開通並允許推薦活動',
  optInReferralNote: '當您開通此選項，您同意將一部分的學費獎賞您的推薦人。',
  deleteAccount: '刪除帳號',
  //   END --- component::SettingScreen

  // START ---- component::ReferralQRCodeScreen
  referralProgram: '推薦活動',
  scanQRWithYoubrioScanner: '使用YOUBRIO APP掃一掃',
  wantToReceiveCommission: '分享並獲取YOUBRIO賞金',
  //   END ---- component::ReferralQRCodeScreen

  // START ---- component::FAQListScreen
  faq: '常見問題',
  //   END ---- component::FAQListScreen

  // START --- component::ResourceScreen
  smartBoard: '智能白板',
  mLibrary: '音樂圖書館',
  mExam: '音樂考級',
  areaOfFocus: '教學重點',
  //   END --- component::ResourceScreen

  // START ---- components::LiveLessonRoomScreen/VideoCallTabScreen
  permission: '權限',
  microphonePermission: '麥克風權限',
  storagePermission: '儲存權限',
  backToVideoCall: '返回視頻通話',
  //   END ---- components::LiveLessonRoomScreen/VideoCallTabScreen

  // START ---- components::LiveLessonRoomScreen/SmartboardTabScreen
  noPicture: '無圖',
  arrangeTabs: '編排頁面',
  dragNToMovePages: '拖動 {0} 以移動頁面',
  refresh: '刷新',
  //   END ---- components::LiveLessonRoomScreen/SmartboardTabScreen

  // START ---- helpers::ApiHelper
  networkError: '網絡信號弱',
  //   END ---- helperps::ApiHelper

  // START ---- components::common/LessonRequestModal
  lessonRequest: '請求課程',
  requestLesson: '請求課程',
  requestADifferentTime: '請求另外一個時段',
  send: '發送',
  //   END ---- components::common/LessonRequestModal

  // START ---- components::common/ChangePasswordScreen
  changePassword: '更改密碼',
  oldPassword: '舊密碼',
  newPassword: '新密碼',
  confirmNewPassword: '確認新密碼',
  //   END ---- components::common/ChangePasswordScreen

  // START ---- components::modals/ProfilePreviewScreen
  myFocus: '主攻方向',
  myExperience: '個人經歷',
  myEducation: '教育背景',
  myAbout: '個人簡介',
  comment: '點評',
  review: '用戶點評',
  myReview: '我的點評',
  endorse: '點評',
  peopleSay: '用戶評語',
  rateEndorse: '打個分數',
  endReached: '已全部顯示',
  tProfile: '老師簡介',
  lProfile: '學生簡介',
  schoolProfile: '學校簡介',
  //   END ---- components::modals/ProfilePreviewScreen

  // START ---- components::component/LandingScreen/LandingTab/HomeTab
  schoolShort: '學科',
  clearAll: '清除選項',
  filter: '過濾',
  genderShort: '性别',
  sort: '排序',
  relevance: '相關優先',
  lastOnline: '在線優先',
  highestRating: '好評優先',
  highestLessonFee: '最高課程費用',
  lowestLessonFee: '最低課程費用',
  ratingSort: '好評優先',
  nameSortAZ: '名字 (A-Z)',
  nameSortZA: '名字 (Z-A)',
  back: '返回',
  translate: '顯示翻譯',
  original: '顯示原文',
  all: '全部',
  newRequest: '新增',
  reset: '重置',
  lRequest: '請求',
  later: '稍後吧',
  addTeacher: '添加老師',
  addStudent: '添加學生',
  create: '創建',
  start: '立即開課',
  ongoingImmediateLesson: '您現在有一個即使課程進行中',
  referral: '老師推薦',
  book: '約課',
  bookLessonAgain: '再次約課',
  createLessonAgain: '創建相同課程',
  iWantToTeach: '我想教課',
  iWantToLearn: '我想學習',
  contactAdmin: '聯繫客服',
  iWantToTeachBannerTagline: '讓我們知道您的專長，我們會向您介紹學生！',
  adminBannerStudentText1: '現在就預約！',
  adminBannerStudentText2: '想學什麼告訴我們，我們幫您預約老師！',
  adminBannerTeacherText1: '優伯客服',
  adminBannerTeacherText2: '在優伯你可以教，轉介紹，經營在線機構，現在就聯繫我們如何操作！',
  shareProfile: '分享我的簡介',
  schedule: '課程預定',
  shareMyProfile: '分享我的簡介',
  homeSearchTeacher: '老師搜尋',
  homeDeposit: '學費儲蓄',
  homeJoinLesson: '參與課程',
  homeManageLesson: '課程管理',
  homeOnlineExam: '在線測評',
  homeMusicLibrary: '音樂圖書館',
  homeTeacherReferral: '老師推薦',
  homeNetworkTest: '網絡檢測',
  //   END ---- components::component/LandingScreen/LandingTab/HomeTab

  //   START ---- acknowledgements
  youbrio: '優伯教育',
  cbe: '康柏爾國際在線音樂考級',
  copyright: '©2022 康柏爾國際在線音樂考級 版權所有',
  licenses: '許可證',
  bugReport: '發現問題？請幫助我們一起解決. ',
  agreeTC: '若您使用此應用軟件及相關服務，則視為您已同意使用條款和條件',
  libraries: '此應用軟件使用以下開源庫（在Apache的2.0下許可）:',
  feedback: '反饋',
  contactUsViaEmail: '聯繫我們 - 電子郵件',
  contactUsViaChat: '聯繫客服',
  //   END ---- acknowledgements

  //  START ---- components::component/ChatListScreen
  chat: '聊天',
  typeMsg: '輸入一條信息...',
  newMsg: '新消息',
  requireAction: '未回复',
  incompleteProfile: '未完善個人簡介',
  //  END ---- components::component/ChatListScreen

  //  START ---- components::component/ChatScreen
  createPrivateLesson: '創建課程',
  lessonSlotNotFound: '找不到此課程。',
  loadingPrivateLesson: '載入課程中...',
  createdAPrivateLesson: '{0} 已創建了一個課程',
  unableToGetPrivateLesson: '無法索取此課程詳情。\n請點擊以重試。',
  deleted: '已被刪除',
  expired: '已過期',
  booked: '已被預訂',
  privateLesson: '課程',
  deletePrivateLesson: '刪除課程',
  yesterday: '昨天',
  startVideoCall: '開始視頻通話',
  nDeclinedCall: '{0} 拒絕通話。',
  callEnded: '通話已結束。',
  //  END ---- components::component/ChatScreen

  // START ---- components::VideoCallChatScreen
  goToChat: '聊天',
  goToSmartBoard: '智能板',
  //   END ---- components::VideoCallChatScreen

  // START ---- component/modal/CallScreenModal
  incomingVideoCall: '視頻通話',
  youbrioLesson: '優伯教學課程',
  //   END ---- component/modal/CallScreenModal

  // START ---- component/modal/ReferralCodeModal
  enterReferralCode: '輸入推薦碼',
  validCode: '推薦碼有效。',
  invalidCode: '推薦碼無效。',
  useCode: '使用',
  unableToVerifyCode: '無法核實推薦碼。',
  verifying: '核實中',
  //   END ---- component/modal/ReferralCodeModal

  // START ---- component/ArticleScreen
  joinCBENow: '現在就報名康柏爾國際在線音樂考級',
  //   END ---- component/ArticleScreen

  // START ---- component/MySalesReferralScreen
  myReferrals: '我的推薦',
  //   END ---- component/MySalesReferralScreen

  // START ---- component/SchoolFeeCategoryListScreen
  feeCategory: '學費類別',
  setDefault: '設爲默認',
  default: '默認',
  //   END ---- component/SchoolFeeCategoryListScreen

  // START ---- component/SchoolFeeCategoryEditScreen
  editFeeCategory: '更改學費類別',
  //   END ---- component/SchoolFeeCategoryEditScreen

  // START ---- component/SchoolFeeCategoryCreateScreen
  newFeeCategory: '新學費類別',
  teachersAllowedToEditPrice: '老師可以更改學費',
  teachersNotAllowedToEditPrice: '老師不可更改學費',
  labelOrRemarks: '標籤/備註',
  setThisAsDefault: '設爲默認學費類別',
  selectTeachers: '選擇老師',
  addOrRemoveTeachers: '選擇/移除老師',
  selectedTeachers: '已選的老師',
  createFeeCategory: '創建學費類別',
  otherSettings: '其他設置',
  removeAll: '全部清除',
  searchNameOrEmail: '搜索姓名/郵箱地址',
  andNMoreTeachers: '與另外{0}位老師',
  noTeachersAssigned: '未委任老師',
  allTeachersWithoutCategory: '所有未屬於任何學費類別的老師',
  searchTeacher: '搜尋老師',
  searchStudent: '搜尋學生',
  copy: '複製',
  used: '已使用',
  friend: '用戶',
  friends: '用戶',
  friendUsedCode: '使用過你的推薦碼',
  referAndEarn: '推薦 & 分享',
  scanQrCode: '掃二維碼',
  shareByLink: '分享推薦碼鏈接',
  shareFlyer: '分享推薦碼海報',
  teachersLessonFee: '老師的學費',
  schoolCommission: '學校提成率',
  schoolsLessonFee: '學校的學費',
  //   END ---- component/SchoolFeeCategoryCreateScreen

  // START ---- components::SplashScreenV2
  theComprehensiveOnlineTeachingTool: '每位在線上課的好伙伴',
  //   END ---- components::SplashScreenV2

  // START ---- component/ReferralIntroScreen
  searchTheTeacherYouWishToRefer: '搜尋您想介紹的老師',
  referralBannerText1: '現在就分享！',
  referralBannerText2: '沒看到要介紹的老師？\n介紹他們上優伯教學平臺！',
  downloadYoubrioNow: '現在就下載優伯教學！',
  shareYoubrio: '分享優伯教學',
  //   END ---- component/ReferralIntroScreen

  // START ---- component/SearchStudentScreen
  searchForStudent: '搜尋學生...',
  //   END ---- component/SearchStudentScreen

  // START ---- modal/AgreementModal
  agree: '同意',
  //   END ---- modal/AgreementModal

  // START ---- component/SelectedPhotoPreviewScreen
  onlyNPhotoPerUpload: '一次最多上傳 {0} 張照片',
  longTapAndDragThumbnailToArrangePicture: '長按並拖動縮略圖以編排圖片',
  //   END ---- component/SelectedPhotoPreviewScreen

  // START ---- component/SelectedPDFPreviewScreen
  onlyNPagesPerUpload: '一次最多上傳 {0} 頁',
  onlyNFilesPerUpload: '一次最多上傳 {0} 個文件',
  nPages: '{0} 頁',
  //   END ---- component/SelectedPDFPreviewScreen

  // START ---- component/NetworkTestingModal
  networkDiagnostics: '網絡檢測',
  networkStart: '開始',
  networkStartComment: '在課堂開始前做好網絡檢測\n可以提升優伯課程的穩定性',
  ipRetrieval: 'IP存取測試',
  serverResponse: 'Server Response',
  downloadSpeedtest: '下載速度測試',
  uploadSpeedtest: '上傳速度測試',
  lessonSimulation: '課程模擬測試',
  networkLowSpeed: '檢測結果: 慢速',
  networkModerateSpeed: '檢測結果: 中速',
  networkFastSpeed: '檢測結果: 快速',
  requestDemoCall: '與客服預約測試連線',
  congratulations: '恭喜!',
  notBad: '不錯哦!',
  stopTest: '停止檢測',
  retrievingConfig: '正在載入檢測設定...',
  unableRetrieveNetworkTestConfig: '無法載入檢測設定。',
  //   END ---- component/NetworkTestingModal

  // START ---- component/DatingScreen/SliderEntry
  searchAgain: '重新搜尋',
  //   END ---- component/DatingScreen/SliderEntry

  // START ---- component/Lottie
  newFeature: '新功能!',
  gift: '禮物',
  giftTotal: '總數',
  oops: '抱歉',
  myGift: '我的禮物',
  sendGift: '發送禮物',
  duringLesson: '上課時',
  comingSoon: '即將推出',
  totalReceived: '您一共收到',
  totalReceivedGifts: '您一共收到 {0} 份禮物',
  // END ---- component/Lottie

  // START ---- component/JitsiAudioSettingScreen/JitsiAudioSettingScreen
  advancedAudioSetting: '進階音頻設置',
  audioSetting: '音頻設置',
  audioSettingShort: '音頻設置',
  presets: '預設',
  //   END ---- component/JitsiAudioSettingScreen/JitsiAudioSettingScreen

  // START ---- components::common/TimePickerV2
  invalidTime: '時間無效',
  //   END ---- components::common/TimePickerV2

  // START ---- component/MyReferralCodeScreen/MyReferralCodeScreen
  myReferralCode: '我的推薦碼',
  wechatShare: '微信分享',
  //   END ---- component/MyReferralCodeScreen/MyReferralCodeScreen

  // START ---- component/CreateMeetScheduleScreen
  selectStudents: '選擇學生',
  maxStudent: '學生最多',
  openForAnyoneToBook: '任何人可預定。',
  onlyForTheFollowingStudents: '此課程只允許選定學生預定。',
  // END ---- component/CreateMeetScheduleScreen

  // START ---- component/EditMeetScheduleScreen
  invited: '已邀請',
  cancelInvitation: '取消邀請',
  // END ---- component/EditMeetScheduleScreen

  // START ---- component/ScheduledMeetDetailScreen
  lessonDetails: '課程詳情',
  lessonParticipants: '課程參與者',
  cancelLesson: '取消課程',
  attention: '注意',
  creatorNameLesson: '{0}的課程',
  youHaveRatedAllParticipants: '您已經為所有學生留下評語。',
  youHaveRatedNOfTheParticipants: '您已經為 {0}/{1} 學生留下評語。',
  rate: '評價',
  reviewsAbtYou: '對您的評價',
  // END ---- component/ScheduledMeetDetalScreen

  // START ---- component/ImmediateMeetScreen
  startLessonNow: '立即開課',
  selectInvitees: '選擇學生',
  hey: '嗨',
  checkYour: '請檢查您的',
  startAt: '開始時間',
  advanceSettings: '高級設置',
  private: '私人',
  public: '公開',
  maxParticipants: '最多人數',
  anyoneCanJoin: '任何人可加入',
  errorMax: '最多人數只要應該是 {0}, 因為您已經邀請了 {1}',
  invitee: '學生',
  invitees: '學生',
  maybeLater: '之後再說',
  wantToReturn: '您是否想要回到課程?',
  overview: '課程內容',
  viewMoreDetails: '更多詳情',
  end: '結束',
  // END ---- component/ImmediateMeetScreen

  // START ---- component/modals/GiftV2
  sendTo: '發送至...',
  everyone: '所有人',
  noUserFoundRefresh: '發現無人。\n點擊刷新。',
  // END ---- component/modals/GiftV2

  // START ---- MeetReviewListScreen
  reviewForN: '給予 {0} 的評語',
  feedbackForN: '給予 {0} 的反饋',
  youHaveNotWrittenAnyReview: '您還未留下任何評語。',
  youHaveNotWrittenAnyFeedback: '您還未留下任何反饋。',
  feedbackforTutors: '給老師的反饋',
  // END ---- MeetReviewListScreen

  // START ---- YoubrioXSubscriptionScreen
  tutorRoom: '優伯隨心學',
  tutorRoomSubscription: '優伯隨心學會員專區',
  perMonth: '每月',
  oneMonth: '1 個月',
  threeMonth: '3 個月',
  twelveMonth: '12 個月',
  getFreeTrial: '點擊領取20分鐘的優伯隨心學體驗課！ ',
  tutorRoomPlan: '優伯隨心學會員套餐：',
  open: '開課中，趕緊加入',
  opens: '下次開課',
  openUntil: '開課到',
  closes: '下課在',
  closed: '暫時休息中',
  participants: '學生',
  numberOfStudent: '{0}位學生',
  firstOneJoin: '火熱報名中！',
  subscribed: '已訂閱',
  subscribe: '現在就報名！',
  iWantToSubscribe: '我想訂閱優伯隨心學',
  // END ---- YoubrioXSubscriptionScreen

  // START ---- components/PracticeModeScreen/modals/LearnModeNotificationModal
  lessonMode: '學習模式',
  practiceMode: '練習模式',
  activated: '啓動',
  deactivated: '未啓動',
  practice: '練習',
  teach: '教學',
  //   END ---- components/PracticeModeScreen/modals/LearnModeNotificationModal

  // START --- components/PracticeModeScreen/modals/StudentProfileDetailTabV2
  objective: '目標',
  note: '筆記',
  progressNote: '筆記',
  records: '記錄',
  profile: '資料',
  files: '文件',
  stillWorking: '我們還在努力中',
  noData: '暫無數據',
  nothingAdded: '您還未添加任何東西',
  accountSecurity: '賬戶和安全',
  personalDetails: '個人資料',
  generalInfo: '通用',
  name: '名字',
  goal: '目標',
  device: '設備',
  pleaseEnterHere: '請在此輸入...',
  week: '週',
  month: '月',
  lastSevenDays: '前7天',
  lastThirtyDays: '前30天',
  todayYouPracticed: '今天您練習了',
  today: '今天',
  noParticipants: '今天暫時還沒有任何學生',
  stats: '數據',
  weeklyReport: ' 本週報告',
  tutorRoomWeeklyRecord: '優伯隨心學 ｜ 學員每週練琴檔案',
  averageMin: '分鐘平均',
  day: '天',
  practiced: '練習',
  missionAccomplished: '任務完成',
  noAccomplishedMission: '你還沒完成任何任務',
  compareWithOther: '優伯隨心學習榜',
  beats: '擊敗',
  totalTimeThisWeek: '學員總計練習時間',
  startPractice: '開始練習',
  //   END ---- components/PracticeModeScreen/modals/StudentProfileDetailTabV2

  // START ---- components/PracticeModeScreen/modals/MeetParticipantListModal
  tutoredBy: '老師: {0}',
  noNotes: '暫無筆記。',
  //   END ---- components/PracticeModeScreen/modals/MeetParticipantListModal

  // START ---- components::PracticeRoomListScreen
  practiceRoomComingSoon: '即將開課',
  //   END ---- components::PracticeRoomListScreen

  // START ---- components/PracticeModeScreen/modals/CoffeeBreakModal
  coffeeBreak: '休息時光',
  goOnBreak: '讓我休息片刻',
  imBack: '我回來了！'
  //   END ---- components/PracticeModeScreen/modals/CoffeeBreakModal
};
