export const en = {
  // START ---- actions::AuthActions
  fieldCannotEmpty: 'This field cannot be empty.',
  enterValidEmail: 'Please enter a valid e-mail address.',
  unknownLoginError: 'Unknown login error. Please contact the system administrator.',
  onlyForCandidateAndTeacher: 'This mobile application is currently only available to candidates and teachers.',
  incorrectLoginCredentials: 'Your log in credentials are incorrect.',
  accountIsNotEmailVerified: 'You have not verified your account. Please check your email for the verification email.',
  teacherAccountIsNotAdminVerified: 'Your account has not been approved by the system administrator. You will be notified through email upon the approval.',
  sendPasswordResetEmailSuccess: 'A password reset link has been sent to {0} if the email is valid.',
  sendPasswordResetEmailFail: 'Unable to send the password reset link to {0}. Please try later or contact the system administrator.',
  unableToLogIn: 'Unable to log in for the moment. Please try later or contact the system administrator.',
  weChatNotInstalled: {
    title: 'WeChat Not Installed',
    body: 'WeChat is not installed, please install WeChat first',
    buttonsText: 'Confirm'
  },
  resetPasswordSuccess: 'You have reset your password successfully.',
  resetPasswordFail: 'Unable to reset your password. Please try again later or contact the system administrator.',
  siwaOnlySupportIosThirteenAbove: 'Sign in with Apple is only supported on devices running on iOS 13 or later, please choose other login method.',
  //   END ---- actions::AuthActions

  // START ---- components::CandidateRegisterScreen
  agreeForSubscription: 'I agree to receive email newsletters and promotional materials from Youbrio.',
  areYouWillingToProvideFreeTrial: 'Are you willing to provide a free trial lesson for Youbrio referred students?',
  registeredForGST: 'I am registered for GST',
  atLeastThreeYearsOld: 'You have to be at least 3 year old to start learning',
  atLeastThirteenYearsOld: 'I have received consent from my parent or guardian to register an account to access Youbrio and the associated services.',
  pleaseCheckPermission: 'Please confirm if you have the consent of your parent or guardian',
  abnMustBeFilledForGST: 'ABN is required for GST registered user.',
  //   END ---- components::CandidateRegisterScreen

  // START ---- components::TeacherRegisterScreen
  atLeastSixteenYearsOldToBeTeacher: 'You must be at least 16 years of age to be a teacher.',
  //   END ---- components::TeacherRegisterScreen

  // START ---- actions::RegisterActions
  passwordMustBeAtLeast8Chars: 'Password length must be at least 8 characters long.',
  passwordMustConsistOfBothAlphabeticAndNumericChar: 'Password must contain both alphabetic and numeric characters.',
  passwordDontMatch: 'Passwords do not match.',
  pleaseSelectACountry: 'Please select a country.',
  pleaseSelectAnInstitutionType: 'Please select an institution type.',
  emailHasExisted: 'This email address has been registered.',
  checkEmailFail: 'Unable to check the availability of email at the moment. Please try again.',
  teacherRegistrationSuccess: 'An account verification email has been sent to {0}. Please follow the instruction in the email to verify your account.',
  candidateRegistrationSuccess: 'You have successfully created an account. Please login using the registered e-mail.',
  accountRegistrationFail: 'Unable to create an account. Please try later or contact the system administrator.',
  //   END ---- actions::RegisterActions

  // START ---- components::CandidateHomeScreen
  uploadPhotoBeforeEnrol: 'Please upload profile photo before enrolling for an exam or requesting for a performance evaluation.',
  homeScreenGreeting: 'How are you today?',
  logoutConfirmation: 'Are you sure you want to log out?',
  needHelpWithTraining: 'Need help with training?\nBook your lessons on YOUBRIO!',
  //   END ---- components::CandidateHomeScreen

  // START ---- actions::UserActions
  teacherIdDoesNotExist: 'Teacher ID does not exist.',
  abnWrongFormat: 'ABN must consist of 11 digit numbers.',
  unableToCheckTeacherId: 'Unable to check Teacher ID. Please try again later.',
  updateProfileSuccess: 'You have successfully updated your profile.',
  updateProfileFail: 'Failed to update profile. Please try again later.',
  studentUpdateProfileSuccess: 'You have successfully updated your student\'s profile.',
  studentUpdateProfileFail: 'Failed to update your student\'s profile. Please try again later.',
  pleaseSelectDateOfBirth: 'Please enter your date of birth.',
  pleaseSelectYourGender: 'Please select your gender.',
  emailsDoNotMatch: 'E-mails do not match.',
  identicalToTheCurrentEmail: 'Identical to the current e-mail.',
  emailChangeSuccess: 'You have successfully changed your e-mail to {0}. Please use this e-mail for your next login.',
  emailChangeFail: 'Failed to change your e-mail. Please try again later or contact the system administrator.',
  exitEdit: 'If you exit now, your edits won\'t be saved.',
  exitWizard: 'If you exit now, your selections won\'t be saved.',
  //   END ---- actions::UserActions

  // START ---- components::TnCScreen
  agreeToTermsAndCondition: 'I agree to all the terms and conditions.',
  //   END ---- components::TnCScreen

  // START ---- components::VideoGalleryScreen
  confirmDeletevideo: 'Are you sure you want to delete this video?',
  youCanChooseExamVideoRes: 'You can choose to record your videos in 480p or 720p. However, choosing 720p will result in longer upload time.',
  //   END ---- components::VideoGalleryScreen

  // START ---- actions::ExamActions
  enterAValidVideoLink: 'Please enter a valid video link from supported hosting sites',
  examDetailsSaveSuccess: 'Examination details are saved successfully.',
  examDetailsSaveFail: 'Examination details are not saved successfully. Please try again later or contact the system administrator.',
  pleaseSelectAMusicType: 'Please select a music type.',
  pleaseSelectAMusicTitle: 'Please select a music title.',
  photoIdIsNeededForDiploma: 'Photo ID is needed for Diploma examinations.',
  examSubmitSuccess: 'Examination is submitted successfully.',
  examSubmitFail: 'Examination is not submitted successfully. Please try again later or contact the system administrator.',
  initiatePaymentGatewayFail: 'Unable to initiate payment gateway. Please try again.',
  getLatestPaymentInfoFail: 'Unable to get the latest payment information. Please try again.',
  getUpdatedExaminationPriceFail: 'Unable to get updated examination price. Please try again.',
  affiliationCodeNotValid: 'Affiliation code is not valid.',
  checkAffiliationCodeFail: 'Unable to check affiliation code. Please try again.',
  pleaseSelectAnInstrument: 'Please select an instrument.',
  pleaseSelectAGrade: 'Please select a grade.',
  paymentAmountNotUpToDate: 'The payment amount is not up to date. Please restart the enrollment procedure.',
  enrolExamSuccess: 'You have successfully made a payment and enrolled for an examination.',
  enrolExamFail: 'Exam enrolment is not successful. Please try again later or contact the system administrator.',
  enrolExamNotYetProcessed: 'You have successfully made a payment but it is still in process. Please check your payment history and exam list again later. Please contact the system administrator if the payment is still not in record after 1 hour.',
  enrolmentPaymentGatewayError: 'Something is wrong with the payment gateway. Please restart the enrollment procedure or contact the system administrator.',
  examReportSentEmailSuccess: 'Your examination report has been sent to {0}.',
  examReportSentEmailFail: 'Unable to send your examination report to {0}. Please try again later or contact the system administrator.',
  examCertSentEmailSuccess: 'Your examination certificate has been sent to {0}.',
  examCertSentEmailFail: 'Unable to send your examination certificate to {0}. Please try again later or contact the system administrator.',
  appealSubmitSuccess: 'You have successfully submitted an appeal request.',
  appealSubmitFail: 'Unable to submit your appeal request. Please try again or contact the system administrator.',
  appealPaymentGatewayError: 'Something is wrong with the payment gateway. Please restart the appeal procedure or contact the system administrator.',
  purchaseCertSuccess: 'You have succcessfully purchased a certificate.',
  purchaseCertFail: 'Unable to purchase certificate. Please try again later or contact the system administrator.',
  pleaseSelectACandidate: 'Please select a candidate.',
  videoUploadSuccess: 'You have successfully uploaded your video. The link to the video is {0}',
  vidUploaded: 'You have successfully uploaded your video',
  videoUploadFail: 'Unable to upload your video. Please try again later or contact the system administrator.',
  pleaseSelectVideo: 'Please select a video before uploading.',
  youHaveNotUploadedAnyVideo: 'You have not uploaded any video for this piece.',
  youHaveNotUploadedThisVideo: 'You have not uploaded this video.',
  uploadVideoBeforeSaving: 'Please upload this video before saving, or remove the video to save the exam without uploading.',
  //   END ---- actions::ExamActions

  //  START ---- actions::SyllabusActions
  musicPieceExistInSystem: 'This music piece has been recorded in our system.',
  checkMusicPieceFail: 'Unable to check the music piece title with the database at the moment, please try again.',
  pleaseIncludeMusicSheet: 'Please include a picture of the music sheet.',
  musicPieceSubmitSuccess: 'Your new music piece request is submitted successfully.',
  musicPieceSubmitFail: 'Your new music piece request submission failed. Please try again or contact our system administrator.',
  //   END ---- actions::SyllabusActions

  //  START ---- components::StudentProfileEditScreen
  confirmRemoveStudentFromList: 'Are you sure you want to remove this student from your student list?',
  removeStudentSuccess: 'You have successfully removed this student from your student list.',
  removeStudentFail: 'Unable to remove this student from your student list. Please try again or contact our system administrator.',
  unsavedChangesWillBeLost: 'You have made some unsaved changes that will be lost upon exiting. Are you sure you want to exit this screen?',
  //    END ---- components::StudentProfileEditScreen

  //  START ---- components::EditExamScreen/SummaryTab
  photoIdRequirement: "For Diploma candidates, please upload a photo ID (Identification Card, School ID or Driver's License) in the Photo ID section above.",
  additionalRequirement1: 'Please ensure your 3 pieces of musics, must add up to a minimum of {0} minutes of playing time.',
  additionalRequirement2: 'We will accept two pieces submitted as one video to meet the minimum time requirement of the grade. In this case, a total of four pieces will be marked however the weigthing of the two shorter pieces will be equivalent to one.',
  additionalRequirement3: 'Please notify via the remark section above if you are submitting more than three pieces.',
  //    END ---- components::EditExamScreen/SummaryTab

  //  START ---- actions::CommonActions
  pleaseUpdateApp: 'Your app version is out of date. Please update your app to continue. Thank you.',
  versionCheckFail: 'Unable to check your app version for the moment. Please restart the app or contact our system administrator.',
  somethingWrongPleaseRestartAppOrLoginAgain: 'Oops, something is wrong. Please restart the app or log in again.',
  sessionHasExpiredPleaseRelogin: 'Your session has expired. Please log in again.',
  //   END ---- actions::CommonActions

  // START ---- components::BrowseMusicLibraryScreen
  enterAtLeastThreeChar: 'Please enter at least 3 characters',
  enterAtLeastTwoChar: 'Please enter at least 2 characters',
  //   END ---- components::BrowseMusicLibraryScreen

  //  START ---- components::MusicLibraryDetailsScreen
  videoForThisPieceIsUnavailable: 'The video for this music piece is unavailable.',
  updateEmailBeforePurchase: 'Please add a valid email to your profile before purchasing sheet music. We will send the purchased sheet music to your email.',
  thisIsASoftcopySheetMusic: 'This is a softcopy sheet music. We will send it to your email once we have processed your purchase.',
  //    END ---- components::MusicLibraryDetailsScreen

  //  START ----- components::AccountUpgradeChooseRoleScreen
  upgradeAccountMessage: 'To access exam enrollment and more features, you need to complete your personal profile.',
  //    END ----- components::AccountUpgradeChooseRoleScreen

  //  START ----- components::AccountUpgradeChooseRoleScreen
  pleaseProvideEmailQuickLogin: 'It seems that you did not allow us to access your email on Facebook. However, we need your email to create an account for you and serve you better.',
  pleaseProvideEmailQuickLoginWX: 'If you login with WeChat, we need your email to create an account for you and serve you better.',
  //    END ----- components::AccountUpgradeChooseRoleScreen

  //  START ----- components::SocialMediaPasswordScreen
  linkFbAccountMessage: 'The email you have entered belongs to an existing CBE MyExams account. If you would like to link this account to your Facebook account, we need to verify that it is you. Please enter your password and log in.',
  //  END ----- components::SocialMediaPasswordScreen

  // START ---- actions::PieceEvaluationActions
  pieceEvaluationReportSentEmailSuccess: 'Your performance evaluation report has been sent to {0}.',
  pieceEvaluationReportSentEmailFail: 'Unable to send your performance evaluation report to {0}. Please try again later or contact the system administrator.',
  requestPerformanceEvaluationSuccess: 'You have successfully made a payment and requested for an performance evaluation.',
  requestPerformanceEvaluationFail: 'Performance evaluation request is not successful. Please try again later or contact the system administrator.',
  requestPerformanceEvaluationNotYetProcessed: 'You have successfully made a payment but it is still in process. Please check your payment history and performance evaluation list again later. Please contact the system administrator if the payment is still not in record after 1 hour.',
  performanceEvaluationDetailsSaveSuccess: 'Performance evaluation details are saved successfully.',
  performanceEvaluationDetailsSaveFail: 'Performance evaluation details are not saved successfully. Please try again later or contact the system administrator.',
  performanceEvaluationDetailsSubmitSuccess: 'Performance evaluation is submitted successfully.',
  performanceEvaluationDetailsSubmitFail: 'Performance evaluation is not submitted successfully. Please try again later or contact the system administrator.',
  youHaveNotSelectedAnyVideo: 'You have not selected any video to be uploaded.',
  //   END ---- actions::PieceEvaluationActions

  // START ---- component::LandingScreen
  confirmLoginAsAdmin: 'Confirm to log in as admin?',
  loginAsAdminFail: 'Unable to login as admin. Please try again later or contact the system administrator.',
  confirmLoginAsSupport: 'Confirm to log in as support?',
  loginAsSupportFail: 'Unable to login as support. Please try again later or contact the system administrator.',
  backToTeacherFail: 'Unable to go back to your user account. Please try again later.',
  confirmBackToTeacher: 'Confirm to go back to your account?',
  pleaseEnterValidMobileNumber: 'Please enter your mobile number to complete your account registration.',
  updateMobileNumSuccess: 'You have successfully updated your mobile number.',
  updateMobileNumFail: 'Unable to update your mobile number. Please try again later.',
  approvedTeachers: 'All the teachers recommended by the YOUBRIO platform are high-quality teachers who have passed through layers of screening and online teacher training. The impressive results of many students are our best reputation! ',
  customerService: 'YOUBRIO provides a 24-hour dedicated educational customer service to interpret various learning and technical issues for parents and teachers. ',
  safePayment: 'Safe and fast online payment, leading high-tech anti-fraud technology and user protection services, provide risk protection for every YOUBRIO tuition fee',
  withdrawalGuarentee: 'Students and teachers can submit balance withdrawal requests at any time. The payment can be sent to the user\'s WeChat/Alipay account in an average of 6 hours',
  noMatchFound: 'Sorry there are no matches found',
  shareYourPerformance: 'Share this performance with family and friends',
  //   END ---- component::LandingScreen

  // START ---- component::LandingScreen/AssessTab
  tapToAccessMyExams: 'Tap to Enter',
  //   END ---- component::LandingScreen/AssessTab

  // START ---- actions::EventActions
  eventEnrolmentSuccess: 'You have successfully enrolled, click next to proceed to video upload.',
  eventEnrolmentFail: 'Event enrollment is not successful. Please try again later or contact the system administrator.',
  saveEventEnrolmentDetailSuccess: 'Event enrollment details are saved successfully.',
  saveEventEnrolmentDetailFail: 'Event enrollment details are not saved successfully. Please try again later or contact the system administrator.',
  submitEventEnrolmentDetailSuccess: 'Event enrollment details are submitted successfully.',
  submitEventEnrolmentDetailFail: 'Event enrollment details are not submitted successfully. Please try again later or contact the system administrator.',
  pleaseAttachYourPhotoId: 'Please attach your photo ID.',
  pleaseSelectAGenre: 'Please select a genre.',
  pleaseAttachYourPerformanceVideo: 'Please attach your performance video.',
  //   END ---- actions::EventActions

  // START ---- component::CandidateNewEventEnrolmentScreen
  eventAffiliateCodeInfo: 'To be eligible to participate in exclusive offline concerts and prizes, please scan a QR code or enter an affiliation code from one of our partners.',
  //   END ---- component::CandidateNewEventEnrolmentScreen

  // START ---- component::AddAffiliateCodeScreen
  checkAffiliateCodeFail: 'Unable to validate affiliation code. Please try again later or contact the system administrator.',
  affiliateCodeIsSuccessfullyApplied: 'Affiliation code "{0}" is successfully applied!',
  affiliateCodeIsInvalid: 'Affiliation code is invalid.',
  //   END ---- component::AddAffiliateCodeScreen

  // START ---- actions::CertificateActions
  certUploadSuccess: 'You have successfully uploaded your certificate for verification. Your user level will be adjusted accordingly after verification.',
  photoUploadSuccess: 'You have successfully uploaded your achievement photo.',
  certUploadFail: 'Certificate upload is not successful. Please try again later or contact the system administrator.',
  photoUploadFail: 'Achievement photo upload is not successful. Please try again later or contact the system administrator.',
  //   END ---- actions::CertificateActions

  // START ---- components::UserLevelInfoListScreen/UserLevelInfoListScreen
  userLevelInfoMessage: 'You may upgrade your user level by:\n  - uploading certificates which you have obtained from other music examinations.\n  - enrolling and completing CBE Examinations using this app.\n\nAfter admin has reviewed your uploaded certificates or upon completion of CBE Examinations, your user level will be upgraded according to the charts below.',
  //   END ---- components::UserLevelInfoListScreen/UserLevelInfoListScreen

  // START ---- components::EventDetailSwiperScreen/EventDetailSwiperScreen
  thereIsNoEventsCurrently: 'No events going on for the moment but there will be more coming soon. Stay tuned!',
  //   END ---- components::EventDetailSwiperScreen/EventDetailSwiperScreen

  // START ---- actions::PaymentActions
  paymentReceiptSentEmailSuccess: 'Your payment receipt has been sent to {0}.',
  paymentReceiptSentEmailFail: 'Unable to send your payment receipt to {0}. Please try again later or contact the system administrator.',
  //   END ---- actions::PaymentActions

  // START ---- components::LandingScreen/LandingTab/ProfileDetailVideoTab/MyVideoTab
  youHaveNotUploadedAnyVideoEnrolExamOrEvent: 'You have not uploaded any videos. Enrol an exam or an event today to get your videos uploaded!',
  //   END ---- components::LandingScreen/LandingTab/ProfileDetailVideoTab/MyVideoTab

  // START ---- components::EventEnrolmentListScreen
  youHaveNotEnrolIntoAnyEvents: 'You have not enrolled into any events. Join now and don\'t miss out the fun!',
  //   END ---- components::EventEnrolmentListScreen

  // START ---- components::EventDetailScreen
  uploadPhotoBeforeEnrolEvent: 'Please upload profile photo before enrolling for an event.',
  //   END ---- components::EventDetailScreen

  // START ---- actions:VideoActions
  contentNotFound: 'Content is not found.',
  unableToLoadContent: 'Unable to load the requested content. Please try again later or contact the system administrator.',
  //   END ---- actions:VideoActions

  // START ---- components::WantToTeachScreen
  noSelection: 'At least one option should be selected',
  noABN: 'Remember to check your ABN and GST details before proceeding.',
  noCert: 'Please upload at least one certificate',
  createdTeachProfile: 'Your teach profile has successfully created',
  createdProfileFailed: 'Failed to create teach profile, please try again later',
  deleteCertificate: 'Are you sure you want to delete this certificate?',
  //   END ---- components::WantToTeachScreen

  // START ---- components::TeachProfileScreen
  noProfile: 'Incomplete',
  verified: 'Verified',
  awaiting: 'Verifing...',
  rejected: 'Rejected',
  deactivated: 'Deactivated',
  deleteAchievement: 'Are you sure you want to delete the achievement?',
  somethingWrong: 'Oops! Something went wrong, please try again later.',
  deletePrivateStudent: 'Are you sure you want to delete this student?',
  //   END ---- components::TeachProfileScreen

  // START ---- components::LearnProfileScreen
  failedToGetStudentQuestion: 'Failed to get the learn profile questions',
  uploadProfilePhoto: 'Please upload a profile photo',
  failedToCreateLearnProfile: 'Failed to create learn profile, please try again later',
  createdLearnProfile: 'Your learn profile has successfully created',
  //   END ---- components::LearnProfileScreen

  // START ---- components::TopUpFundScreen
  topUpInfo: 'You are allowed to top up a minimum of {0}{1} up to the maximum of {0}{2} per transaction.',
  //   END ---- components::TopUpFundScreen

  // START ---- actions::FundActions
  topUpFundSuccess: 'You have successfully deposited {0}{1}{2} into your wallet.',
  topUpFundFail: 'Wallet deposit is not successful. Please try again later or contact the system administrator.',
  unableToTopUpFund: 'Unable to top up your wallet.',
  topUpPaymentProcessing: 'You have successfully made a payment but it is still in process. Please check your transaction history again later. Please contact the system administrator if the payment is still not in record after 1 hour.',
  addPaymentMethodSuccess: 'You have successfully saved a new payment method.',
  addPaymentMethodFail: 'Unable to save your payment method. Please try again later or contact the system administrator.',
  //   END ---- actions::FundActions

  // START ---- components::CreateLessonScreen
  thisWillDetermineYourLessonDuration: 'This will determine the duration for each of your lesson session.',
  thisWillDetermineYourMinimumLessonDuration: 'This will determine the minimum duration for each lesson booking from the students. It is also used to determine the lesson rate below.',
  tellUsMoreAboutYourLesson: 'Tell us more about your lesson. Lesson name and description will help potential learners know what you have to offer in the lesson.',
  lessonFeeForEachSession: 'The amount will only be credited to your account once the lesson has been completed.',
  lessonRateInfo: 'Please note that the lesson fees you set will be inclusive of all GST/VAT in accordance with your local tax policies. YOUBRIO is not responsible for the tax declaration of individual teachers. You will receive this amount for every minimum lesson duration that you have set above. The amount will only be credited to your account once the lesson has been completed.',
  reviewStudentInfo: 'You will be required to approve every student that have requested to book your lesson before the booking is confirmed. You may also reject the student after reviewing their profile.',
  youCanSetMaximumSlot: 'You may set up to {0} time slots for this lesson.',
  byProceedYouAgreeToTnC: '* By proceeding ahead, you agree to YOUBRIO’s terms and conditions.',
  youAreUnableToAlterLessonSettingDueToOrgPolicy: 'You are unable to alter some of the lesson settings due to the school policy of {0}.',
  lessonFeeDpositedWhenLessonComplete: '*The lesson fee will be deposited to your YOUBRIO wallet as soon as the lesson is marked completed.',
  noStudentAdded: 'You don\'t have a student yet',
  theBookingWillBeMadeOnBehalfOfTheStudentAutomatically: 'Since this is a free lesson, the booking will be made automatically on behalf of the student who requested for this lesson. Confirm to proceed?',
  thisStudentIsUnableToBookFreeLessonFromYou: 'This student is not able to book free lesson from you.',
  someOfYourStudentsAreUnableToBookFreeLessonFromYou: 'Some of the selected students are unable to book free lesson from you, your notification list is reset.',
  //   END ---- components::CreateLessonScreen

  // START ---- actions:LessonActions
  createNewLessonSuccess: 'You have successfully created a new lesson.',
  createNewLessonFail: 'Unable to save your new lesson. Please try again later or contact the system administrator.',
  bookLessonSuccess: 'You have successfully booked your lessons.',
  bookLessonFail: 'Unable to book lesson. Please try again later or contact the system administrator.',
  createNewChangeRequestSuccess: 'You have successfully sent a time change request.',
  createNewChangeRequestFail: 'Unable to send your time change request. Please try again later or contact the system administrator.',
  deleteChangeRequestSuccess: 'Your change request has been deleted successfully.',
  deleteChangeRequestFail: 'Unable to delete your change request. Please try again later or contact the system administrator.',
  approveChangeRequestSuccess: 'You have approved the change request. Please take note of the new lesson time.',
  approveChangeRequestFail: 'Unable to approve the change request. Please try again later or contact the system administrator.',
  rejectChangeRequestSuccess: 'You have rejected the change request.',
  rejectChangeRequestFail: 'Unable to reject the change request. Please try again later or contact the system administrator.',
  pleaseDeleteChangeRequestForNew: 'You have an unconfirmed change request pending for response. Please delete the current change request if you would like to create a new one.',
  pleaseRespondChangeRequestForNew: 'You have an unattended change request pending for your response. Please approve/reject the current change request before creating a new one.',
  //   END ---- actions:LessonActions

  // START ---- actions::YoubrioProfileActions
  somethingWentWrongPlsTryLtr: 'Oops! Something went wrong, please try again later.',
  //   END ---- actions::YoubrioProfileActions

  // START ---- components::BookLessonScreen
  requestRequiredLesson: 'The teacher will review your profile upon booking.',
  requestRequiredLessonDetail: 'Your booking will not be confirmed until the teacher has reviewed your profile and approved your booking. The teacher has all the rights to reject any bookings. Your wallet will be deducted with the total fee upon booking but it will be refunded if the teacher rejects your booking.',
  youWillPayWithYourStudyWallet: 'The total fee will be deducted from your Study Wallet upon booking the lesson successfully.',
  youHaveInsufficientWalletBalance: 'You have insufficient Study Wallet balance, please top up to proceed.',
  letYourTeacherKnowYourLessonGoals: 'Let your teacher know what are your goals and what you want to learn so that the teacher will be able to cater for your needs in the lesson.',
  //   END ---- components::BookLessonScreen

  // START ---- components::TeacherLessonBookingDetailScreen
  lessonComplete: 'What do you want to do?',
  giftReceived: 'Gifts received',
  noGift: 'You haven\'t received any gift during this lesson',
  areYouSureRejectLesson: 'Are you sure that you want to reject this booking? This action cannot be undone.',
  areYouSureCancelLesson: 'Are you sure that you want to cancel this booking? This action cannot be undone.',
  areYouSureLateCancelLesson: 'Are you sure you want to cancel this booking? You will not receive a refund as it is within 24 hours of your lesson.',
  cancelLessonInfo: 'You are allowed to cancel the booking up to {0} hours before the lesson. There will be no refund for late cancellation.',
  pleaseGrantPermissionsForLesson: 'YOUBRIO needs these permissions to make sure that your live lesson call works properly. Please turn on the permissions in your settings screen.',
  pleaseGrantPrerequiredPermissionsForLessonAndroid: 'YOUBRIO needs camera, microphone and storage permissions to make sure that your live lesson call works properly. Please turn on the permissions in your settings screen.',
  pleaseGrantPrerequiredPermissionsForLessonIos: 'YOUBRIO needs camera, microphone and photo library permissions to make sure that your live lesson call works properly. Please turn on the permissions in your settings screen.',
  dualDeviceInfo: 'Dual Device Mode launches only the Smartboard on your second device, improving the video quality and your YOUBRIO lesson experience!',
  //   END ---- components::TeacherLessonBookingDetailScreen

  // START ---- components::LandingScreen/CompleteProfileBlockerModal
  completeProfile: 'To use this feature, you have to complete your profile first',
  completeProfileStartRemoteLesson: 'Complete your profile to start remote lessons.',
  //   END ---- components::LandingScreen/CompleteProfileBlockerModal

  // START ---- components::TeacherEditLessonScreen
  updateLessonSuccess: 'Your lesson has been updated successfully.',
  updateLessonFail: 'Unable to update your lesson. Please try again later or contact the system administrator.',
  thisLessonIsUnlisted: 'This lesson is unlisted. It is not visible to the public and you will not receive any booking for this lesson.',
  thisLessonIsListed: 'This lesson is listed and is visible to the public for booking.',
  areYouSureUnlistLesson: 'Are you sure you want to unlist this lesson? You will not be able to get any new bookings for this lesson. You may relist it at a later time. (It will not affect all existing bookings for this lesson.)',
  areYouSureDeleteLesson: 'Are you sure you want to delete this time slot? (It will not affect all existing bookings.)',
  deleteLessonSuccess: 'Lesson is deleted successfully.',
  deleteLessonFail: 'Unable to delete lesson. Please try again later or contact the system administrator.',
  youHaveNotCreatedAnyLesson: 'You have not created any lesson yet.',
  //   EventDetailSwiperScreen ---- components::TeacherEditLessonScreen

  // START ---- components::MyLessonTeacherListScreen
  youHaveBookedAnyLessonWithAnyTeacher: 'You have not booked any lesson with any teacher.',
  noTeacherFound: 'You don\'t have any teacher. Would you like to connect with a teacher?',
  //   END ---- components::MyLessonTeacherListScreen

  // START ---- components::MyLessonStudentListScreen
  youDontHaveAnyStudentYet: 'You don\'t have any student yet.\nTap to add student.',
  youHaveNoFavouriteUserYet: 'You have not added any favourite user yet.',
  //   END ---- components::MyLessonStudentListScreen

  // START ---- components::ProfilePreviewScreen
  bookStudentIsOnlyForStudent: 'To book a lesson, please register a separate account as a student.',
  notOfferingAnyLesson: 'This teacher is not offering any lesson at the moment. Would you like to request for a lesson?',
  wouldYouWantToAddSomeoneAsYourRole: 'Do you want to add {0} as your {1}?',
  someoneIsYourRole: '{0} is your {1}.',
  youAreUnableToAddThisUser: 'You are unable to add this user.',
  youHaveAlreadyAddedThisUser: 'You have already added this user.',
  wouldYouLikeToAddThisTeacherAsFavourite: 'Would you like to add this teacher as favourite?',
  //   END ---- components::ProfilePreviewScreen

  // START ---- components::ManageMyLessonScreen
  youHaveNoUpcomingLessons: 'You have no upcoming lessons.',
  //   END ---- components::ManageMyLessonScreen

  // START ---- components::TeacherLessonBookingDetailScreen
  lessonRoomAllowedTimeInfo: 'You can enter the lesson {0} minutes prior to the lesson.',
  completeLessonAllowedTimeInfo: 'You are allowed to complete the lesson {0} minutes after the actual lesson start time.',
  completeLessonTimeExactInfo: 'You are only allowed to complete the lesson after the actual lesson end time.',
  areYouSureCompleteLesson: 'Upon completion of lesson booking, the lesson fee will be credited into your wallet. By proceeding, you are not able to enter the lesson room and the lesson is completed.',
  completeLessonNow: 'Do you want to complete this lesson now? By proceeding, you are not able to enter the lesson room and the lesson is completed.',
  //   END ---- components::TeacherLessonBookingDetailScreen

  // START ---- components::LessonSessionActions
  itsTooEarlyToEnterLessonRoom: 'It is too early to enter the lesson room.',
  lessonTimeEndedUnableToEnterRoom: 'You not allowed to enter the lesson room because the lesson time has ended.',
  unableToEnterLessonRoom: 'Unable to enter the lesson room. Please try again later or contact the system administrator.',
  pleaseCompleteLearnProfile: 'Please complete your profile as a student before booking a lesson.',
  //   END ---- components::LessonSessionActions

  // START ---- components::ProfileDetailTab
  uploadCertToTeach: 'Upload a certificate and Complete Profile to get more publicity',
  noReview: 'Sorry, you currently do not have any review',
  tapToRefresh: 'Tap to refresh',
  tapHereToUploadCert: 'Tap here to upload your certificate',
  //   END ---- components::ProfileDetailTab

  // START ---- components::TopUpPaymentMethodScreen
  serviceOnlyAvailableForChinaVerifiedUser: 'This service is only available for Chinese Mainland ID verified ALIPAY account only.',
  unableToGetExchangeRate: 'Unable to get exchange rate.',
  //   END ---- components::TopUpPaymentMethodScreen

  // START ---- components::FundWithdrawScreen
  withdrawInfo: 'You are allowed to withdraw a minimum of {0}{1} up to the maximum of {0}{2} per transaction, subject to the balance of your wallet.',
  serviceFee: 'Please note that there will be a {0}% service fee on the withdrawal',
  //   END ---- components::FundWithdrawScreen

  // START ---- component::FundWithdrawAddPaymentMethodScreen
  confirmAllInfoAreCorrect: 'I hereby confirm that all information stated is correct.',
  addPaymentMethodInfo: 'Please let us know how we should pay you for your hardwork. It is important to ensure that all the details are correct.',
  fullNameMustBeCorrect: 'The full name must match the one registered with the selected payment channel. We will reject any withdrawal requests that has incorrect details to protect our users.',
  invalidPhoneOrEmail: 'This is not a valid phone number or e-mail.',
  invalidNumber: 'The number you have entered is not valid',
  enterValidBankAccNo: 'Please enter a valid bank account number.',
  pleaseEnterValidBSB: 'A valid BSB should have 6 digits.',
  pleaseEnterValidIBAN: 'Please enter a valid IBAN code.',
  pleaseEnterValidSWIFT: 'Please enter a valid SWIFT/BIC code.',
  //   END ---- component::FundWithdrawAddPaymentMethodScreen

  // START ---- component::FundWithdrawPaymentMethodListScreen
  noPaymentMethodFound: 'No payment method found.',
  allowedUpToNPaymentMethod: 'You are allowed to save up to {0} payment methods. Please delete the unused ones to add new payment methods.',
  //   END ---- component::FundWithdrawPaymentMethodListScreen

  // START ---- component::WithdrawalSummaryScreen
  pleaseVerifyYourself: 'This transaction requires identity verification, please enter your password.',
  makeWithdrawalSuccess: 'Withdrawal application is successful. We will let you know once we have processed the withdrawal.',
  makeWithdrawalFailed: 'Withdrawal application is unsuccessful. Please try again later or contact the system administrator.',
  enteredIncorrectPassword: 'You have entered an incorrect password.',
  thisOnlyAppliesForAusUser: 'The above fields are only for Australian users. Please leave them blank and unchecked if it does not apply to you.',
  //   END ---- component::WithdrawalSummaryScreen

  // START ---- component::FundWithdrawalListScreen
  balanceInsufficient: 'Insufficient balance, you need to have a minimum balance of {0} to perform withdrawal.',
  youHaveNotMadeWithdrawal: 'You have not made any withdrawal.',
  //   END ---- component::FundWithdrawalListScreen

  // START ---- component::WizardResultScreen
  couldNotFindMatchingResult: 'Could not find any matching result.',
  //   END ---- component::WizardResultScreen

  //  START ---- components::BrowseLessonListScreen
  teacherNoOfferLesson: 'The teacher is not offering any lesson at the moment.',
  //    END ---- components::BrowseLessonListScreen

  //  START ---- components::WriteReviewScreen
  exitReview: 'Are you sure you want to exit now? No change will be saved.',
  reviewCommentPH: 'Tell us a little bit more...',
  noRating: 'You haven\'t give any rating yet',
  reviewSuccess: 'Review submitted successfully',
  reviewFail: 'Failed to submit this review.  Please try again later or contact the system administrator.',
  //    END ---- components::WriteReviewScreen

  //  START ---- components::ViewReviewScreen
  getReviewFailed: 'Fail to get all the reviews.  Please try again later or contact the system administrator.',
  //    END ---- components::ViewReviewScreen

  // START ---- component::BrioWalletSummaryScreen
  pleaseCompleteProfileBeforeTopUp: 'Please complete your profile as a student before making a deposit.',
  //   END ---- component::BrioWalletSummaryScreen

  // START ---- component::SchoolIntroScreen
  doYouOwnASchool: 'Do you own a school or an educational organisation? Register it on YOUBRIO and get your teachers and students to join!',
  benefitsOfSchool: 'Let your students enjoy group benefits like fee discount when they book lesson from your school teachers.',
  searchForSchool: 'Not owning a school? Search and join one. Together you achieve more!',
  studentJoinSchoolInfo: 'Join a school now to discover more teachers and enjoy lesson fee discount while booking lessons with the teachers of the same school!',
  //   END ---- component::SchoolIntroScreen

  // START ---- component::SchoolProfileScreen
  getSchoolProfileFailed: 'Failed to get school profile.  Please try again later or contact the system administrator.',
  saveSchoolProfileFailed: 'Failed to save school profile.  Please try again later or contact the system administrator.',
  saveSchoolProfileSuccessful: 'Updated school profile sucessfully',
  yearFormatError: 'Please only enter correct year format',
  yearInvalid: 'Please enter a valid year',
  //   END ---- component::SchoolProfileScreen

  // START ---- component::CreateSchoolScreen
  applySchoolSuccess: 'You have successfully applied to register your school. Your school will be visible to the public once YOUBRIO admin has approved your application.',
  applySchoolFail: 'Unable to submit your application. Please try again later or contact the system administrator.',
  pleaseEnterValidYear: 'Please enter a valid year.',
  commissionInfo: 'When a student books a lesson with a teacher (both are members of your school), this percentage of the actual amount of the lesson fee will be deducted and reserved as  school\'s commission.\n\nFor example, if commission rate is 15% and the teacher charges $100 per lesson, $15 will be deducted from teacher\'s pay and reserved for  school\'s commission.',
  newOrgCommissionInfo: 'When a teacher of your school completes a lesson, the school will get this percentage of the actual lesson fee by default. However, this can be overwritten by assigning your teacher to a fee category at the school setting.',
  discountInfo: 'When a student books a lesson with a teacher (both are members of your school), the student will get a discount according to this percentage.\n\nFor example, if student discount rate is 5% and the teacher charges $100 per lesson, the student will only have to pay $95 for the lesson.',
  profitInfo: 'The commission rate is inclusive of the student discount rate, hence the final rate that the school/organisation will get as profit is (commission rate) - (student discount rate).\n\nFor example, if commission rate is 15%, student discount rate is 5% and the teacher charges $100 per lesson, the school will get $10 as commission profit as $5 has been deducted for student\'s discount.',
  makeSureAllSchoolInfoCorrect: 'Please make sure that all the information entered are correct. YOUBRIO admin reserves all the right to reject any application with incomplete or incorrect information.',
  //   END ---- component::CreateSchoolScreen

  // START ---- component::ManagingSchoolListScreen
  youAreNotManagingAnySchoolORg: 'You are not managing any school.',
  //   END ---- component::ManagingSchoolListScreen

  // START ---- component::SchoolTeacherListScreen
  noTeachersFound: 'No teachers found.',
  noTeachersNewRequest: 'No teachers found, would you like to put a teacher request?',
  areYouSureYouWantToRemoveThisMember: 'Are you sure you want to remove this profile from school?',
  //   END ---- component::SchoolTeacherListScreen

  // START ---- component::SchoolStudentListScreen
  noStudentsFound: 'No students found.',
  //   END ---- component::SchoolStudentListScreen

  // START ---- component::SchoolPendingMembershipListScreen
  noPendingJoinRequestFound: 'No pending join requests found.',
  areYouSureYouWantToDeclineRequest: 'Are you sure you want to decline this join request?',
  areYouSureYouWantToBlacklistRequest: 'Are you sure you want to blacklist this profile? Anyone that is blacklisted is unable to find and join this school.',
  //   END ---- component::SchoolPendingMembershipListScreen

  // START ---- component::SchoolBlacklistedMembershipListScreen
  blacklistInfo: 'Anyone that is blacklisted is unable to find and join this school.',
  youHaveNotBlacklistedAnyone: 'You have not blacklisted anyone.',
  //   END ---- component::SchoolBlacklistedMembershipListScreen

  // START ---- component::SchoolProfilePreviewScreen
  areYouSureWantToLeave: 'Are you sure you want to leave the school?',
  joinOneSchoolAtATime: 'Teachers are only allowed to join one school at a time. Please leave \'{0}\' before joining.',
  completeProfileBeforeJoiningOrg: 'Please complete your profile before joining a school.',
  comNotice: 'School will take {0}% as commission if you are teaching school\'s students',
  //   END ---- component::SchoolProfilePreviewScreen

  // START ---- component::SchoolAdminProfileScreen
  wouldYouWantCreateFeeCategory: 'You have not created any fee category. You can assign teachers to different fee category to manage their lesson fee and commission rate. Would you like to do it now?',
  //   END ---- component::SchoolAdminProfileScreen

  // START ---- component::SchoolAdminProfileScreen/SchoolProfileBlockerModal
  schoolIsDeactivated: 'Your school profile is deactivated by YOUBRIO admin because it has violated our policy. Please contact our system administrator for further action.',
  schoolPendingApproval: 'Your school profile is still awaiting YOUBRIO admin\'s approval.',
  schoolIsRejected: 'Your school profile is rejected by YOUBRIO admin. Please contact our system administrator for further action.',
  youMayDeleteYourOrgAndReapply: 'You may delete this school application and make a new application.',
  //   END ---- component::SchoolAdminProfileScreen/SchoolProfileBlockerModal

  // START ---- actions::OrganisationActions
  unableToDeleteOrganisation: 'Unable to delete the application. Please try again later or contact the system administrator.',
  //   END ---- actions::OrganisationActions

  // START ---- component::AnnotationDocumentScreen
  changesWillBeGoneWithoutSaving: 'Please make sure that you have saved the required smartboard notes. Are you sure you want to exit? (If you are trying to close this tab instead of exiting, please tap and hold the tab below.)',
  confirmCloseTab: 'Are you sure you want to close and remove this tab? You will not be able to access this tab anymore.',
  iosAnnotationSaveSuccessfully: 'The smartboard note is successfully saved to your camera roll.',
  androidAnnotationSaveSuccessfully: 'The smartboard note is successfully saved to {0}.',
  annotationSaveFailed: 'Unable to save the smartboard note. Please try again later or contact our system administrator.',
  tapToAddSheetMusicForAnnotation: 'Tap here to add image(s).',
  tapToAddBlankPage: 'Tap here to start a new blank page',
  writeExternalPermission: 'YOUBRIO needs access to your storage to save your smartboard notes.',
  confirmClearSmartboard: 'Are you sure you want to erase all? You will not be able to recover the erased notes of this tab.',
  tabRemovedDoYouWantToSave: 'This tab is going to be removed permanently. Do you want to save before it is removed?',
  //   END ---- component::AnnotationDocumentScreen

  // START --- component::CalendarSlotPickerScreenV2
  pleaseRearrangeYourAdjacentTimeSlot: 'The minimum duration permitted is {0} minutes. Please remove your adjacent time slot(s) or select other time slots.',
  editLessonEventCalendar: 'This lesson event has been added in your calendar already, would you like to edit now?',
  lessonEventLost: 'Cannot find relavent event in your calendar, you might have accidentally removed it. Please try to create a new one',
  //   END --- component::CalendarSlotPickerScreenV2

  // START --- component::VideoCallScreen
  annotationSessionStarted: 'A smartboard session has been started. Tap here to start using it.',
  youCanJoinTheAnnotationByBottomLeft: 'You can open the smartboard by tapping the button on the bottom right of this screen.',
  //   END --- component::VideoCallScreen

  // START --- component::QRCodeScannerScreen
  cameraQRPermission: 'YOUBRIO needs access to your camera to scan QR Code.',
  thisIsNotARoleProfile: 'This is not a {0} profile.',
  //   END --- component::QRCodeScannerScreen

  // START --- component::VideoRecorderScreen
  cameraRecorderPermission: 'YOUBRIO needs access to your camera to record video.',
  //   END --- component::VideoRecorderScreen

  // START ---- components::UploadVideoScreen/UploadVideoScreen
  failedToGetVideoTypes: 'Failed to get video types, please try again later',
  //   END ---- components::UploadVideoScreen/UploadVideoScreen

  // START --- component::QRCodeScreen
  shareReferralProgramInfo: 'You are now able to refer teachers to students! Where available, you\'ll get a portion of the lesson fee as commission everytime the student books and completes lesson with the referral link or QR code of the teacher that you have shared. Of course, you may also recommend teachers without taking any commission by turning off the option when sharing the teacher\'s profile.',
  //   END --- component::QRCodeScreen

  // START ---- component::ReferralQRCodeScreen
  referralCommissionInfo: 'By enabling this option, you will be receiving {0}% of the lesson fee whenever the student books and completes a lesson with this teacher.',
  shareReferralInvitationLinkText: 'I would like to invite you to take a lesson with this fantastic teacher on YOUBRIO APP! Click the following link and open it in YOUBRIO APP to check out the teacher. {0}',
  //   END ---- component::ReferralQRCodeScreen

  // START ---- components::LessonPaymentScreen
  topUpMoreNotice: 'For your convenience of rebooking in the future, would you like to top up with the total lesson fee of 10 lessons?',
  topUpSuggestionNotice: 'Would you like to deposit {0} to book {1}’s lessons? By booking lessons in advance, you can secure your lessons slots with the teacher and commit to regular lessons.',
  //   END ---- components::LessonPaymentScreen

  // START ---- components.LiveLessonRoomScreen/VideoCallTabScreen
  weNeedCameraMicrophoneForVideoCall: 'YOUBRIO needs access to your camera and microphone for live lesson call.',
  oneOrMorePermissionIsRestricted: 'YOUBRIO is not able to request for the camera and microphone permission. It might be blocked by parental control or it\'s not supported by your device.',
  weNeedCameraForVideoCall: 'YOUBRIO needs access to your camera for live lesson call.',
  weNeedMicrophoneForVideoCall: 'YOUBRIO needs access to your microphone for live lesson call.',
  weNeedAccessStorageToStoreAppData: 'YOUBRIO needs access to your storage to store app data.',
  weNeedAccessStorageToReadAppData: 'YOUBRIO needs access to your storage to read app data.',
  areYouSureExit: 'Are you sure you want to exit?',
  oopsDisconnectedFromCall: 'Oops, you are disconnected from the room. Would you like to reconnect? (Error: {0})',
  oopsYouAreDisconnectedPleaseCheckInternetAndReconnect: 'Oops, you are disconnected from the room. Please ensure that you have a stable connection and reconnect. (Error: {0})',
  //   END ---- components.LiveLessonRoomScreen/VideoCallTabScreen

  // START ---- components::LiveLessonRoomScreen/SmartboardTabScreen
  youNeedAtLeastTwoTabs: 'Your smartboard has less than 2 tabs.',
  confirmToRefreshTab: 'Are you sure you want to refresh this tab?',
  //   END ---- components::LiveLessonRoomScreen/SmartboardTabScreen

  // START ---- helpers::ApiHelper
  networkErrorMakeSureGoodConnection: 'Oops, something is wrong with your internet！',
  //   END ---- helperps::ApiHelper

  // START ---- components::ChangePasswordScreen
  passwordSetup: 'You do not have a password yet. If you want to use youbrio wallet please set a password now',
  passwordChanged: 'Your password has changed',
  passwordChangeFailed: 'Update password failed, please try again later',
  oldPasswordWrong: 'The old password you have entered is incorrect',
  //   END ---- components::ChangePasswordScreen

  // START ---- components::ProfilePreviewScreen
  noEndorsement: 'This user doesn\'t have any review yet. You can write a review for him now!',
  //   END ---- components::ProfilePreviewScreen

  // START ---- components::SubjectRequestScreen
  learnSubRequestDesc: 'For Example:\n\nI want to find a math teacher for my 10-year-old boy...\nI want to find a violin teacher, my girl has been learning for 5 years...\nI want to find an English teacher, who specialises in IELTS...',
  teachSubRequestDesc: 'E.g. I am a harp teacher that teaches beginner to intermediate levels…',
  findTeacherForU: 'Didn\'t find right teacher?\n\nTap here and tell us your requirement.\nWe will find a suitable one for you!',
  becomeTeacher: 'I want to become a YOUBRIO teacher! Tap here to start teach students from around the world!',
  requestSuccess: 'Your request has been submitted sucessfully',
  requestFail: 'Oops! Something went wrong, please try again later',
  //   END ---- components::SubjectRequestScreen

  // START ---- helpers::AlipayHelper
  alipaySandboxNotAvailableForiOS: 'Alipay Sandbox is not available to iOS. Please switch to production mode.',
  //   END ---- helpers::AlipayHelper

  // START ---- components::UserNameIcon
  noIntroVideo: 'Add Profile Intro Video',
  noOneMinVideo: 'You don\'t have any videos',
  failedToSaveIntro: 'Failed to save this video as your intro video, please try again later',
  //   END ---- components::UserNameIcon

  // START ---- components::ChatScreen
  adminChatFirst: 'YOUBRIO admin will help you to start your first lesson with {0}',
  wantALesson: 'Hi YOUBRIO, I would like to have a lesson with {0}!',
  requestedLesson: 'Hi YOUBRIO, I have requested a lesson with {0} \n\nStart at: {1} \n\nDuration: {2} mins \n\nDescription: {3}',
  requestedLessonDirect: 'Hi {0}, I have requested a lesson with you \n\nStart at: {1} \n\nDuration: {2} mins \n\nDescription: {3}',
  overlapBookingExistCreateLessonConfirmation: 'You have a lesson clash or a lesson very close at this time. Are you sure you want to proceed?',
  createPrivateLessonFail: 'Unable to create lesson. Please try again later or contact our system administrator.',
  areYouSureDeletePrivateLesson: 'Are you sure you want to delete this lesson? Your student will not be able to book this lesson.',
  confirmStartVideoCall: 'Confirm to start a video call?',
  nStartedAVideoCall: '{0} started a video call.',
  thisLessonHasExpired: 'The start time of the lesson has passed.',
  pleaseGrantPrerequiredPermissionsForVideoCallAndroid: 'YOUBRIO needs camera, microphone and storage permissions to make sure that your video call works properly. Please turn on the permissions in your settings screen.',
  pleaseGrantPrerequiredPermissionsForVideoCallIos: 'YOUBRIO needs camera, microphone and photo library permissions to make sure that your video call works properly. Please turn on the permissions in your settings screen.',
  //   END ---- components::ChatScreen

  // START ---- actions::ChatActions
  unableToStartVideoCall: 'Unable to start video call. Please try again later or contact our system administrator.',
  //   END ---- actions::ChatActions

  // START ---- components::ViewAchievementScreen
  savePermission: 'Please allow permission to save to your albums in your settings',
  savedToAlbums: 'Succesfully saved to your Albums',
  failedToDownload: 'Download failed, please try again later',
  comeWatchVideo: 'YOUBRIO invites you to watch a video on Youbrio.com',
  comeWatchPhoto: 'YOUBRIO invites you to view a photo on Youbrio.com',
  //   END ---- components::ViewAchievementScreen

  // START ---- component::BookingSlotChangeRequestListScreen
  approveChangeRequestConfirmation: 'Approving this request will change the date and time of this lesson. Confirm to proceed?',
  overlapBookingExistApproveChangeRequestConfirmation: 'You have a lesson clash at this time. Approving this request will change the date and time of this lesson. Confirm to proceed?',
  rejectChangeRequestConfirmation: 'Are you sure you want to reject this change request? Date and time of this lesson will not be changed.',
  deleteChangeRequestConfirmation: 'Are you sure you want to delete this change request?',
  overlapBookingExistCreateRequestConfirmation: 'You have a lesson clash at this time. Are you sure you want to send this time change request?',
  //   END ---- component::BookingSlotChangeRequestListScreen

  // START ---- component::common/LessonRequestModal
  requestLessonSuccess: 'You have successfully submitted your lesson request. You will receive a notification once the teacher has responded to your request.',
  requestLessonFail: 'Unable to submit your lesson request. Please try again later or contact the system administrator.',
  addedCalendar: 'Successfully added to your calendar',
  enableCalendar: 'Allow access to your calender to start adding lesson with YOUBRIO',
  //   END ---- component::common/LessonRequestModal

  // START ---- component::CandidateProfileScreen
  teacherRelationshipInfo: 'Your profile has been linked with this teacher. If this is incorrect, please contact YOUBRIO Admin via chat for assistance to remove the relationship with this teacher.',
  //   END ---- component::CandidateProfileScreen

  // START ---- component::LessonBookingDetailScreen
  bookAnother: 'Would you like to book another lesson?',
  sameLesson: 'Would you like to create the lesson for {0} again?',
  bookSameLessonSuccess: 'You have successfully requested the same lesson with your teacher, for next week.',
  unableToGetBookingDetails: 'Would you like to load/refresh the lesson booking details?',
  //   END ---- component::LessonBookingDetailScreen

  // START ---- component::common/TeacherIcon
  needHelpFindingTeacher: 'Need help finding a teacher?\nTap here.',
  needAssistance: 'Need assistance?\nTap here.',
  //   END ---- component::common/TeacherIcon

  // START ---- actions::MusicLibraryActions
  getSheetMusicPriceDetailFail: 'Unable to get sheet music details. Please try again later or contact our system administrator.',
  paymentGatewayError: 'Something is wrong with the payment gateway. Please restart the payment procedure or contact the system administrator.',
  purchaseSheetMusicSuccess: 'You have successfully purchased the sheet music. The sheet music will be sent to your email once the purchase is processed.',
  purchaseSheetMusicFail: 'Unable to purchase sheet music. Please try again later or contact our system administrator.',
  purchaseSheetMusicNotYetProcessed: 'You have successfully made a payment but it is still in process. Please check your payment history later. If the payment is still not in record after 1 hour, please contact the system administrator.',
  //   END ---- actions::MusicLibraryActions

  // START ---- components::HomeTabV2
  profileIsIncompleteCompleteNow: 'Your profile is incomplete. Would you like to complete it now?',
  //   END ---- components::HomeTabV2

  // START ---- components::SchoolFeeCategoryListScreen
  noFeeCategoryFoundCreateNew: 'No fee category found.\nTap to create new fee category.',
  //   END ---- components::SchoolFeeCategoryListScreen

  // START ---- component/SchoolFeeCategoryCreateScreen
  defaultOrgFeeCategoryHelpInfo: 'By setting this, all teachers who are not assigned to any fee category will belong to this fee category by default.',
  emptySelectedTeacherOrgFeeCategoryInfo: 'No teachers selected.\nTap to add teachers.',
  isDefaultFeeCategorySelectedTeacherInfo: 'All teachers that are not assigned to any fee category will belong to this default fee category.',
  createFeeCategorySuccess: 'Fee category created successfully.',
  createFeeCategoryFail: 'Unable to create fee category. Please try again later or contact our system administrator.',
  noTeacherAddedFeeCategoryWarning: 'No teachers are assigned to this non-default fee category. Would you like to add them now?',
  //   END ---- component/SchoolFeeCategoryCreateScreen

  // START ---- component/SchoolFeeCategoryEditScreen
  unableToGetFeeCategoryDetail: 'Unable to load fee category details. Please try again later or contact our system administrator.',
  feeCategorySaveSuccess: 'Fee category saved successfully.',
  feeCategorySaveFail: 'Unable to save fee category. Please try again later or contact our system administrator.',
  feeCategoryDeleteSuccess: 'Fee category deleted successfully.',
  feeCategoryDeleteFail: 'Unable to delete fee category. Please try again later or contact our system administrator.',
  areYouSureYouWantToDeleteThis: 'Are you sure you want to delete this?',
  setThisCategoryAsDefault: 'Set this category is default?',
  setCategoryDefaultSuccess: 'Fee category is set as default.',
  setCategoryDefaultFail: 'Unable to set fee category as default. Please try again later or contact our system administrator.',
  youHaveSuccessfullyAddedSomeoneAsYourRoleList: 'You have successfully added {0} to your {1} list.',
  unableToAddSomeoneToYourRoleList: 'Unable to add {0} to your {1} list.',
  youHaveRemovedSomeoneAsYourRole: 'You have removed {0} from your {1} list.',
  unableToRemoveSomeoneFromYourRoleList: 'Unable to remove {0} from your {1} list.',
  //   END ---- component/SchoolFeeCategoryEditScreen

  // START ---- component/ReferralIntroScreen
  referralIntroText: 'Select and Share\nGet rewarded 10% of the lesson fees\nfrom each successfully conducted lesson!',
  selectTeacherYouWishToReferFromYourTeachers: 'Select the teacher you wish to refer from your teachers',
  //   END ---- component/ReferralIntroScreen

  // START ---- component/ChatListScreen
  unableToViewThisProfile: 'Unable to view this profile.',
  deleteChatPermanently: 'Are you sure you want to delete your own messages with {0} permanently? Be careful! This action cannot be undone!',
  chatDeleteSuccess: 'Chat history has deleted successfully',
  failedToDelete: 'Failed to delete chat history',
  //   END ---- component/ChatListScreen

  // START ---- modal/AgreementModal
  agree: 'Agree',
  agreeDescription1: 'Please carefully read and understand the',
  agreeDescription2: 'Terms',
  and: 'and',
  agreeDescription3: 'Privacy Policy',
  agreeDescription4: '. If you agree, please tap "Agree" and start using our services.',
  //   END ---- modal/AgreementModal

  // START ---- component::common/AnnotationTabBar
  youHaveReachedMaxTab: 'To ensure the very best learning and teaching experience for you, you can upload up to {0} Smartboard tabs per session. Please close any existing tab to add a new one.',
  //   END ---- component::common/AnnotationTabBar

  // START ---- component::NotificationListScreen/NotificationListItem
  areYouSureToDeleteNotification: 'Are you sure to delete this notification?',
  //   END ---- component::NotificationListScreen/NotificationListItem

  // START ---- component::NetworkTestingModal
  networkLowSpeedComment: 'You may experience:\n\n1. Lower video + audio quality\n2. Interrupt in connection\n\n Suggestion: \n\n1. Switch to 4G mobile network\n2. Move closer to internet router\n3. Restart internet router\n4. Restart device\n5. Switch off video during lesson',
  networkLowSpeedNote: '*Please note:\nIf there are other users on the same network, the connection speed may be impacted.',
  networkModerateSpeedComment: 'If you experience:\n\n1. Lower video or audio quality\n2. Interruptions in connection\n\nPlease try the following :\n1. Switch to 4G mobile network\n2. Move closer to internet router\n3. Restart internet router \n4. Restart device',
  networkFastSpeedComment: 'Your internet connection is optimal to conduct YOUBRIO lessons.\n\nIf you experience any difficulties, please contact our admin team.',
  //   END ---- component::NetworkTestingModal

  // START ---- component::components/modals/NetworkErrorModal
  oopsYourInternetHasGoneToOuterSpace: 'Oops! Your internet has gone to outer space!\nPlease check your internet connection and try again.',
  //   END ---- component::components/modals/NetworkErrorModal

  // START ---- component::EditReferenceRateScreen/EditReferenceRateScreen
  setUpReferenceRate: 'It looks like you haven\'t setup your reference rate, would like to do it now?',
  priceRange: 'You should enter a price between {0} to {1} to allow your student better find you',
  moreHelp: 'Would you like more help?',
  //   END ---- component::EditReferenceRateScreen/EditReferenceRateScreen

  // START ---- component::DatingScreen/DatingScreen
  endOfSearch: 'You have reached last one. Would you like to go back to search criteria to find some other teachers?',
  couldNotFindASuitableTeacher: 'That\'s the last one in your search.\nCould not find a suitable teacher?',
  //   END ---- component::DatingScreen/DatingScreen

  // START ---- component::WebAccessScreen
  webAccess: 'Web Access',
  parentAccess: 'Parent Access',
  stepByStep: 'Step by Step',
  acOne: '1. Please make sure you have booked the lesson with your student/teacher in the Youbrio APP on your phone or tablet.',
  acTwo: '2. Go to',
  acTwoRest: 'and access the video call through the login page when it is time for your lesson.',
  acThree: '3. To access the Smartboard please use your phone or tablet device and press “Smarboard” in your lesson booking.',
  giftDescription: 'You can receive or send gifts during your online lesson video call',
  //   END ---- component::WebAccessScreen

  // START ---- component::JitsiAudioSettingScreen
  settingsSavedSuccessfully: 'Settings are saved successfully.',
  //   END ---- component::JitsiAudioSettingScreen

  // START ---- component::common/LessonRequestModal
  pleasePickTimeBetweenAAndBOnly: 'Please pick time between {0} and {1} only.',
  wantToLearnFrom: 'I want to learn from {0}',
  //   END ---- component::common/LessonRequestModal

  // START ---- component::modals/ShareLessonModal
  shareLesson: 'Hi! Join my lesson via the following link:',
  lessonExpiredTitle: 'Oops, Lesson Unavailable',
  lessonExpiredContent: 'The lesson seems to have expired or is unavailable now. Please contact your teacher to create another lesson on YOUBRIO, or contact Youbrio Admin via in app chat for assistance.',
  lessonShareGreetingTitle: 'Hi, ',
  lessonAwaitingApprovalC: 'Thanks for booking my lesson. I will confirm the lesson time as soon as possible.',
  lessonAwaitingApprovalN: 'You will receive a notification once the teacher has confirmed the lesson time.',
  lessonAcceptedC: 'Thanks for booking my lesson. Please enter below, looking forward to our lesson!',
  lessonCompletedC: 'You have done great! The lesson has already completed',
  lessonNeedBookC: 'I have created a lesson for you. Please check the lesson details below.',
  lessonUrlHint: 'Please copy and paste your lesson link/code here.',
  invalidLessonCode: 'Invalid input. Please check your link or code that entered are correct.',
  lessonShareUrlEx: 'e.g. https://www.youbrio.com/invite/06a3YNpm',
  meetShareUrlPlaceholder: 'e.g. {0}invite/meet?u=xxxxxxxx',
  lessonOnlyAvailableToStudentAccount: 'This lesson is only available for student account with complete profile. To book this lesson, please complete your profile as a student and tap the link again.',
  // END ---- component::modals/ShareLessonModal

  // START ---- components::MyReferralCodeScreen
  referralCodeIsCopied: 'Your referral code is copied to clipboard.',
  flyerTitleFrom: 'Enter code',
  flyerTitleTo: 'to get amazing one-to-one lesson package that worth $288!',
  shareReferralCodeRewardText: 'When your friend signs up using your referral code and attends lesson, both of you will get something from us!',
  noOneUsedCode: 'Sorry, currently no one has used your code.',
  shareReferralText: 'Psst...🤫\n\nLet me tell you a secret, I\'m actually using YOUBRIO to learn and to teach online. If you sign up now with my referral code, both of us will get rewarded.\n\nHere\'s my referral code:\n{0}\n\nDownload YOUBRIO now!\n{1}',
  //   END ---- components::MyReferralCodeScreen

  // START ---- components::CreateMeetScheduleScreen
  getMeetSettingsFailed: 'Unable to get lesson settings at the moment. Please try again or contact our system administrator.',
  //   END ---- components::CreateMeetScheduleScreen

  // START ---- components::EditMeetScheduleScreen
  confirmToCancelInvitation: 'Confirm to cancel the invitation for {0}?',
  youCannotInviteMoreThan: 'You cannot invite more than {0} participants.',
  //   END ---- components::EditMeetScheduleScreen

  // START ---- components::ScheduledMeetDetailScreen
  areYouSureRejectMeetBooking: 'Are you sure that you want to reject this booking? This action cannot be undone.',
  areYouSureHostCancelMeetBooking: 'Are you sure that you want to cancel this booking? This action cannot be undone.',
  areYouSureHostCancelMeet: 'Are you sure you want to cancel this lesson? All bookings will be refunded upon cancellation and this action cannot be undone.',
  areYouSureCompleteMeet: 'Upon completion of lesson, the lesson fee from all active bookings will be credited into your wallet. By proceeding, you are not able to enter the lesson room and the lesson is completed.',
  plsActionTheRequestedBookingsBeforeCompletingMeet: 'Please approve or reject the requested booking(s) before completing your lesson.',
  plsActionTheRequestedBookingsBeforeEnteringClassroom: 'Please approve or reject the requested booking(s) before entering classroom.',
  areYouSureCompleteMeetNoActiveBookings: 'You have no active bookings, you will not receive any payment upon lesson completion. Confirm to mark lesson as completed?',
  recreateLesson: 'Would you like to create the lesson for your student(s) again?',
  wantedToBookTheSameLessonAgainNextWeek: 'I want to book the same lesson again next week.',
  //   END ---- components::ScheduledMeetDetailScreen

  // START ---- components::HallOfFameScreen
  examDetails: 'Candidates who achieve the best results through Con Brio Examinations will be showcased in our Hall of Fame. You are able to play any piece of your choice for your examination and receive feedback from internationally renowned examiners. Enrol now!',
  //   END ---- components::HallOfFameScreen

  // START ---- common/NetworkStatusBar
  youAreConnectedToTheInternet: 'You are connected to the internet.',
  plsCheckYourNetworkConnection: 'Please check your network connection.',
  //   END ---- common/NetworkStatusBar

  // START ---- common/SubscriptionXScreen
  areYouKids: 'Are your kids lacking motivation and productivity in practicing and studying?',
  hardToFind: 'Hard to find a suitable teacher to monitor the kids progress?',
  lessonAreAlready: 'Lessons are already costly, how do we afford extra lessons?',
  tutorRoomToHelp: 'Youbrio Tutor Room is here to help!',
  tutorRoomDesc: 'Youbrio Tutor Room provides students with access to top-quality teachers, helping them get the most out of their practice time. These Tutor Rooms are offered in a group practice environment. Students will have a teacher to monitor their practice, correct wrong notes, teach effective practice methods, help them with homework and more!',
  practiceHours: 'Youbrio Tutor Room has teachers on standby during :',
  theUsualRate: 'The usual rates of these teachers are $100/hr, Youbrio Tutor Room offers exceptional value with a total of 100 hours of Youbrio tutor time in a month which you can choose to participate. With each hour of practice and study supervised by our elite team of teachers, students will gain visible progress, confidence and motivation in their learning!',
  k12: 'Youbrio Tutor Rooms are now offered in : Piano , Violin and Study Room which helps Homework across K-12.',
  iHaveToppedUpAndWantToSubscribe: 'I have just topped up {0} and I want to subscribe a tutor room plan {1}, {2} {3}.',
  //   END ---- common/SubscriptionXScreen

  // START ---- actions::PracticeRoomActions
  youAreNotTeacherOfPracticeRoom: 'You are not the teacher of this room.',
  youAreNotSubscribedToThisRoom: 'You are not subscribed to this room.',
  theRoomIsNotOpenedYet: 'The room is closed.',
  //   END ---- actions::PracticeRoomActions

  // START ---- components/PracticeModeScreen/modals/LearnModeInfoModal
  learnModeInfoHeader: 'Welcome to the Youbrio Tutor Room!\nPlease listen to the tutor\'s instructions carefully.',
  learnModeInfo1: '1. Lesson mode: The tutor will attend to you and help you practice with effective methods.',
  learnModeInfo2: '2. Practice mode: Practice carefully according to the tutor’s instructions, the tutor will attend to you once you are ready.',
  learnModeInfo3: '3. Raise your hand: If you have any questions please tap this button and the teacher will be notified.\n\nHappy Practicing! :)',
  //   END ---- components/PracticeModeScreen/modals/LearnModeInfoModal

  // START ---- components/PracticeModeScreen/modals/LearnModeNotificationModal
  lessonModeActivatedInfo: 'The tutor will attend to you and help you practice with effective methods.',
  practiceModeActivatedInfo: 'Practice carefully according to the tutor’s instructions, the tutor will attend to you once you are ready.',
  //   END ---- components/PracticeModeScreen/modals/LearnModeNotificationModal

  // START ---- components/PracticeModeScreen/modals/MeetParticipantListModal
  confirmMarkUserExitPracticeRoom: 'Are you sure that this user has exited the practice room?',
  participantIsMissingFromPracticeRoom: 'This participant is missing from the practice room. Please reopen this pop-up to refresh if the participant is actually still in the room.',
  someoneHasEditedTheNote: 'Another teacher has updated the notes for this student. Updated notes were downloaded, however all your changes are already safely copied onto the clipboard.',
  someoneHasChangedTheLockStatus: 'Another teacher has updated the lock status of this student. The list is being refreshed...',
  alertedStudentNoAudio: 'An alert is already sent to the student.',
  //   END ---- components/PracticeModeScreen/modals/MeetParticipantListModal

  // START ---- common/AcknowledgementScreen
  usedTheFollowingAnimation: 'This app uses the following animations:',
  //   END ---- common/AcknowledgementScreen

  // START ---- components/PracticeModeScreen/modals/CoffeeBreakModal
  coffeeBreakDeactivatedInfo: 'Thank you for your hard work!\nTap the button below if you need a short break, we will turn off the microphone and camera for you.\nHowever, other teachers may still connect to you via audio.',
  coffeeBreakActivatedInfo: 'A short break makes you go further!\nHit the button below whenever you are ready to get back to teach.',
  finishTeachingThenOnlyGoBreak: 'Oops, seems like you are still teaching one of the students. Please put them on "Practice Mode" before taking a break.',
  pleaseTurnOffCoffeeBreak: 'Please turn off coffee break mode to proceed.',
  //   END ---- components/PracticeModeScreen/modals/CoffeeBreakModal

  // START ---- component::SettingScreenV2
  confirmDeleteAccount: 'We\'re sad to see you go. Deleting your account is permanent and irreversible. Are you sure you want to delete your account?',
  weNeedToVerifyYourIdentity: 'We need to verify your identity. Please enter your password to proceed.',
  accountDeleted: 'Your account has been deleted.',
  //   END ---- component::SettingScreenV2

  // START ---- helpers::AnnotationSocketHelper
  teacherCantHearYou: 'The teacher can\'t hear you. Please re-enter the room or restart the app.'
  //   END ---- helpers::AnnotationSocketHelper
};
