import React, { useEffect, useState } from 'react';
import { useDebounce } from 'ahooks';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  Table,
  Pagination,
  Button,
  Loading,
  Input,
  // Popover,
  // Form,
  Dialog,
  // Alert,
  // Form,
  // Select,
  // Checkbox,
  // MessageBox,
  // Radio,
} from 'element-react';
import 'element-theme-default';
import {
  // Typography,
  // Paper,
  Container,
  Grid,
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import qs from 'qs';

// import {
// } from '../common';
import {
  getPsOpenExams as getPsOpenExamsApi,
} from '../../api';
import { useHistory } from 'react-router-dom';
// import { labels } from '../../lang/labels';
// import { messages } from '../../lang/messages';
// import AppHelper from '../../helpers/AppHelper';
// import GLOBAL from '../../helpers/GLOBAL';

// const CONSTANT = require('../../helpers/ConstantHelper');

const OpenExamListScreen = (props) => { 
  const { t } = useTranslation();
  // const {
  //   history,
  // } = props;
  const history = useHistory();
  // const user = GLOBAL.get('user');
  // const {
  //   cbeRole,
  // } = user;
  // const isTeacherRole = cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER);
  // const isUserInChina = false; // AppHelper.isUserInChina(); // not work
  // const isCNLang = GLOBAL.get('lang')?.includes('cn');

  const [examsLoading, setExamsLoading] = useState(false);

  const [myOpenExams, setMyOpenExams] = React.useState([]);
  const [myOpenExamsFiltered, setMyOpenExamsFiltered] = React.useState([]);
  const [myOpenExamsTotal, setMyOpenExamsTotal] = React.useState(0);
  const [myOpenExamsDisplay, setMyOpenExamsDisplay] = React.useState([]);
  const [myOpenExamsPageSize, setMyOpenExamsPageSize] = React.useState(10);
  const [myOpenExamsPage, setMyOpenExamsPage] = React.useState(1);
  const [myOpenExamsSearchKey, setMyOpenExamsSearchKey] = React.useState('');
  const debouncedMyOpenExamsSearchKey = useDebounce(
    myOpenExamsSearchKey,
    { wait: 800 }
  );

  const [myAppealExams, setMyAppealExams] = React.useState([]);
  const [myAppealExamsFiltered, setMyAppealExamsFiltered] = React.useState([]);
  const [myAppealExamsTotal, setMyAppealExamsTotal] = React.useState(0);
  const [myAppealExamsDisplay, setMyAppealExamsDisplay] = React.useState([]);
  const [myAppealExamsPageSize, setMyAppealExamsPageSize] = React.useState(10);
  const [myAppealExamsPage, setMyAppealExamsPage] = React.useState(1);
  const [myAppealExamsSearchKey, setMyAppealExamsSearchKey] = React.useState('');
  const debouncedMyAppealExamsSearchKey = useDebounce(
    myAppealExamsSearchKey,
    {wait: 800 }
  );


  const [videoDialogVisible, setVideoDialogVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const fetchOpenExams = async() => {
    try {
      setExamsLoading(true);
      const res = await getPsOpenExamsApi();
      if (res && res.data) {
        let openExams = res.data?.openexams || [];
        openExams = openExams.sort((a, b) => dateSortMethod(a, b, 'PaymentDate')).reverse();
        setMyOpenExams(openExams);
        let appealExams = res.data?.appealexams || [];
        appealExams = appealExams.sort((a, b) => dateSortMethod(a, b, 'AppealDate')).reverse();
        setMyAppealExams(appealExams);
      } else {
        console.warn(res);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setExamsLoading(false);
    }
  };

  const dateSortMethod = (a, b, k) => {
    const createDate = x => {
      const p = x.split('/');
      return new Date(p[2], p[1], p[0]);
    }
    return createDate(a[k]) - createDate(b[k]);
  };
 
  useEffect(() => {
    fetchOpenExams();
  }, []);

  useEffect(() => {
    let data = myOpenExams || [];
    data = data.map(x => {
      x.FullName = x.FirstName + ' ' + x.LastName;
      x.CertificateOption = x.cert_shipping_type ? t('openExamList.physicalAndDigital') : t('openExamList.digitalOnly');
      x.Status = x.examStatus === 'SI'
        ?
          t('open')
        :
          (
            (x.examStatus === 'SC' || x.examStatus === 'QC' || x.examStatus === 'EA' || x.examStatus === 'MI')
            ?
              t('openExamList.markingInProgress')
            :
              ''
          )
      return x;
    });

    let searchKey = debouncedMyOpenExamsSearchKey.toLowerCase();
    if (searchKey?.length > 0) {
      data = data.filter(x =>
        x.FullName.toLowerCase().includes(searchKey)
        || x.InstrumentName.toLowerCase().includes(searchKey)
        || x.Grade.toLowerCase().includes(searchKey)
        || x.PaymentDate.toLowerCase().includes(searchKey)
        || x.CertificateOption.toLowerCase().includes(searchKey)
        || x.Status.toLowerCase().includes(searchKey)
      );
    }
   
    setMyOpenExamsFiltered(data || []);
    setMyOpenExamsTotal(data.length || 0);
    setMyOpenExamsPage(1);
  }, [
    myOpenExams,
    debouncedMyOpenExamsSearchKey,
  ]);

  useEffect(() => {
    let data = myOpenExamsFiltered.slice(myOpenExamsPageSize * (myOpenExamsPage - 1), myOpenExamsPageSize * myOpenExamsPage) || [];
    setMyOpenExamsDisplay(data);
  }, [
    myOpenExamsFiltered,
    myOpenExamsPage,
    myOpenExamsPageSize,
  ]);

  useEffect(() => {
    let data = myAppealExams || [];
    data = data.map(x => {
      x.FullName = x.FirstName + ' ' + x.LastName;
      return x;
    });
    
    let searchKey = debouncedMyAppealExamsSearchKey.toLowerCase();
    if (searchKey?.length > 0) {
      data = data.filter(x =>
        x.FullName.toLowerCase().includes(searchKey)
        || x.InstrumentName.toLowerCase().includes(searchKey)
        || x.Grade.toLowerCase().includes(searchKey)
        || x.AppealDate.toLowerCase().includes(searchKey)
      );
    }
    
    setMyAppealExamsFiltered(data);
    setMyAppealExamsTotal(data?.length || 0);
    setMyAppealExamsPage(1);
  }, [
    myAppealExams,
    debouncedMyAppealExamsSearchKey,
  ]);

  useEffect(() => {
    let data = myAppealExamsFiltered?.slice(myAppealExamsPageSize * (myAppealExamsPage - 1), myAppealExamsPageSize * myAppealExamsPage) || [];
    setMyAppealExamsDisplay(data);
  }, [
    myAppealExamsFiltered,
    myAppealExamsPageSize,
    myAppealExamsPage,
  ]);

  const renderVideoDialog = () => {
    return (
      <>
        <Dialog
          size="large"
          visible={videoDialogVisible}
          onCancel={() => setVideoDialogVisible(false)}
        >
          <Dialog.Body>
            <ReactPlayer
              url={videoUrl}
              controls={true}
              playing={true}
              width="100%"
              height="100%"
            />
          </Dialog.Body>
        </Dialog>
      </>
    )
  };

  const renderCurrentExams = () => {
    const onSortChange = (data) => { // ({column, prop, order}) => {
      const sortMethod = data?.column?.sortMethod;
      const order = data?.order;
      if (!order) {
        return;
      } else if (order === 'ascending') {
        setMyOpenExamsFiltered(state =>
          state.sort(sortMethod).slice() // clone is needed for useEffect to detect the change!
        );
      } else {
        setMyOpenExamsFiltered(state =>
          state.sort(sortMethod).reverse().slice()
        );
      }
    };

    const columns = [
      {
        label: t('student'),
        prop: 'FullName',
//        width: 150,
//        fixed: 'left',
        sortable: 'custom', // true,
      },
      {
        label: t('instrument'),
        prop: 'InstrumentName',
        // width: 180,
        sortable: 'custom', // true,
      },
      {
        label: t('openExamList.examGrade'),
        prop: 'Grade',
//        width: 180,
        sortable: 'custom', // true,
      },
      {
        label: t('openExamList.paymentDate'),
        prop: 'PaymentDate',
//        width: 180,
        sortable: 'custom', // true,
        sortMethod: (a, b) => {
          const createDate = x => {
            const p = x.split('/');
            return new Date(p[2], p[1], p[0]);
          }
          return createDate(a.PaymentDate) - createDate(b.PaymentDate);
        },
      },
      {
        label: t('openExamList.certificateOption'),
        prop: 'CertificateOption',
//        width: 180,
        sortable: 'custom', // true,
      },
      {
        label: t('openExamList.examVideos'),
        prop: '',
//        width: 180,
        sortable: 'custom', // true,
        render: (row) => {
          return (
            <span>
              <Layout.Row type="flex" justify="space-around">
              {
                row.VideoLink1 &&
                  <Button
                    size="mini"
                    type="primary"
                    icon="caret-right"
                    onClick={() => {
                      // setVideoUrl(row.VideoLink1);
                      // setVideoDialogVisible(true);
                      window.location.href = row.VideoLink1;
                    }}
                  >
                    1
                  </Button>
              }
              {
                row.VideoLink2 &&
                  <Button
                    size="mini"
                    type="primary"
                    icon="caret-right"
                    onClick={() => {
                      // setVideoUrl(row.VideoLink2);
                      // setVideoDialogVisible(true);
                      window.location.href = row.VideoLink2;
                    }}
                  >
                    2
                  </Button>
              }
              {
                row.VideoLink3 &&
                  <Button
                    size="mini"
                    type="primary"
                    icon="caret-right"
                    onClick={() => {
                      // setVideoUrl(row.VideoLink3);
                      // setVideoDialogVisible(true);
                      window.location.href = row.VideoLink3;
                    }}
                  >
                    3
                  </Button>
              }
              </Layout.Row>
            </span>
          )
        },
      },
      {
        label: t('status'),
        prop: 'Status',
//        width: 180,
        sortable: 'custom', // true,
      },
      {
        label: t('openExamList.expiryDate'),
        prop: 'expiryDateTime',
        width: 180,
        sortable: true,
      },
      {
        label: t('action'),
        prop: '',
        width: "160",
        sortable: false,
        render: (row) => {
          return (
            <span>
              {
                row.examStatus === "SI" &&
                <Button
                  type="primary"
                  icon="edit"
                  size="small"
                  onClick={() => {
                    console.log('--row=', row);
                    history.push({
                      pathname: '/edit-exam-details/' + row.ID,
                      // query: { examId: row.encoded_exam_id },
                      // state: {}
                    });
                  }}
                >
                  {t('edit')}
                </Button>
              }
              {
                row.examStatus === "SI" && row.cert_shipping_type === 0 && row.paymentCurrency === "USD" &&
                <Button
                  type="danger"
                  icon="arrow-up"
                  size="small"
                  onClick={() => {
                    history.push(
                      '/general-purchase?' + qs.stringify({
                        type: 1,
                        new_grade_id: row.new_grade,
                        exam_enecoded_id: row.encoded_exam_id,
                        exam_id: row.ID,
                        refer_url: window.location.href
                      }),
                      // state: {}
                    )
                  }}
                >
                  {t('openExamList.upgradeCertificateOptions')}
                </Button>
              }
              {
                row.examStatus === "SI" && row.has_upgrade == 1 && row.old_grade === row.GradeID &&
                <Button
                  type="danger"
                  icon="arrow-up"
                  size="small"
                  onClick={() => {
                    history.push(
                      '/general-purchase?' + qs.stringify(
                      {
                        type: 2,
                        new_grade_id: row.new_grade,
                        exam_encoded_id: row.encoded_exam_id,
                        exam_id: row.ID,
                        refer_url: window.location.href
                      }),
                      // state: {}
                    )
                  }}
                >
                  {t('openExamList.upgradeExamLevel')}
                </Button>
              }
            </span>
          )
        },
      },
    ];

    return (
      <>
        <Layout.Row type="flex" justify="start" style={{'marginBottom': '10px'}}>
          <Layout.Col span={3}>
            <Button type="text" size="large">
              {t('openExamList.myOpenExams')}
            </Button>
          </Layout.Col>
          <Layout.Col span={3}>
            <Button type="primary" size="small" icon="refresh" onClick={() => fetchOpenExams()}>
              {t('refresh')}
            </Button>
          </Layout.Col>
          <Layout.Col span={6} push={12}>
            <Input
              placeholder=""
              size="large"
              value={myOpenExamsSearchKey}
              trim={true}
              onChange={(v) => setMyOpenExamsSearchKey(v)}
            />
          </Layout.Col>
        </Layout.Row>
        <Loading loading={examsLoading}>
          <Layout.Row>
            <Table
              style={{ width: '100%' }}
              columns={columns}
              data={myOpenExamsDisplay}
              border={true}
              onSortChange={onSortChange}
            />
          </Layout.Row>
          <Layout.Row type="flex" justify="start">
            <Layout.Col span={16}>
              <Pagination
                layout="sizes, prev, pager, next, jumper"
                total={myOpenExamsTotal}
                pageSizes={[10, 20, 50, 100]}
                pageSize={myOpenExamsPageSize}
                currentPage={myOpenExamsPage}
                onSizeChange={(size) => setMyOpenExamsPageSize(size)}
                onCurrentChange={(currentPage) => {console.log('--currentpage=', currentPage); setMyOpenExamsPage(currentPage);}}
              />
            </Layout.Col>
          </Layout.Row>
        </Loading>
      </>
    );
  };

  const renderAppealExams = () => {
    const onSortChange = (data) => { // ({column, prop, order}) => {
      const sortMethod = data?.column?.sortMethod;
      const order = data?.order;
      if (!order) {
        return;
      } else if (order === 'ascending') {
        setMyAppealExamsFiltered(state =>
          state.sort(sortMethod).slice() // clone is needed for useEffect to detect the change!
        );
      } else {
        setMyAppealExamsFiltered(state =>
          state.sort(sortMethod).reverse().slice()
        );
      }
    };

    const columns = [
      {
        label: t('student'),
        prop: 'FullName',
//        width: 180,
//        fixed: 'left',
        sortable: true,
      },
      {
        label: t('instrument'),
        prop: 'InstrumentName',
//        width: 150,
        sortable: true,
      },
      {
        label: t('openExamList.examGrade'),
        prop: 'Grade',
//        width: 280,
        sortable: true,
      },
      {
        label: t('openExamList.appealDate'),
        prop: 'AppealDate',
//        width: 180,
        sortable: true,
        sortMethod: (a, b) => {
          const createDate = x => {
            const p = x.split('/');
            return new Date(p[2], p[1], p[0]);
          }
          return createDate(a.AppealDate) - createDate(b.AppealDate);
        },
      },
      {
        label: t('openExamList.examVideos'),
        prop: '',
//        width: 280,
        sortable: true,
        render: (row) => {
          return (
            <span>
              <Layout.Row type="flex" justify="space-around">
              {
                row.VideoLink1 &&
                  <Button
                    size="mini"
                    type="primary"
                    icon="caret-right"
                    onClick={() => {
                      // setVideoUrl(row.VideoLink1);
                      // setVideoDialogVisible(true);
                      window.location.href = row.VideoLink1;
                    }}
                  >
                    1
                  </Button>
              }
              {
                row.VideoLink2 &&
                  <Button
                    size="mini"
                    type="primary"
                    icon="caret-right"
                    onClick={() => {
                      // setVideoUrl(row.VideoLink2);
                      // setVideoDialogVisible(true);
                      window.location.href = row.VideoLink2;
                    }}
                  >
                    2
                  </Button>
              }
              {
                row.VideoLink3 &&
                  <Button
                    size="mini"
                    type="primary"
                    icon="caret-right"
                    onClick={() => {
                      // setVideoUrl(row.VideoLink3);
                      // setVideoDialogVisible(true);
                      window.location.href = row.VideoLink3;
                    }}
                  >
                    3
                  </Button>
              }
              </Layout.Row>
            </span>
          )
        },
      },
    ];

    return (
      <>
        <Layout.Row type="flex" justify="start" style={{'marginBottom': '10px'}}>
          <Layout.Col span={3}>
            <Button type="text" size="large">
              {t('openExamList.myAppealExams')}
            </Button>
          </Layout.Col>
          <Layout.Col span={3}>
            <Button type="primary" size="small" icon="refresh" onClick={() => fetchOpenExams()}>
              {t('refresh')}
            </Button>
          </Layout.Col>
          <Layout.Col span={6} push={12}>
            <Input
              placeholder=""
              size="large"
              value={myAppealExamsSearchKey}
              trim={true}
              onChange={v => setMyAppealExamsSearchKey(v)}
            />
          </Layout.Col>
        </Layout.Row>
        <Loading loading={examsLoading}>
          <Layout.Row>
            <Table
              style={{ width: '100%' }}
              columns={columns}
              data={myAppealExamsDisplay}
              border={true}
              onSortChange={onSortChange}
            />
          </Layout.Row>
          <Layout.Row type="flex" justify="start">
            <Layout.Col span={16}>
              <Pagination
                layout="sizes, prev, pager, next, jumper"
                total={myAppealExamsTotal}
                pageSizes={[10, 20, 50, 100]}
                pageSize={myAppealExamsPageSize}
                currentPage={myAppealExamsPage}
                onSizeChange={(size) => setMyAppealExamsPageSize(size)}
                onCurrentChange={(currentPage) => {setMyAppealExamsPage(currentPage)}}
              />
            </Layout.Col>
          </Layout.Row>
        </Loading>
      </>
    );
  };

  return (
    <Container>
      <Grid>
        <div style={{ 'marginLeft': '0%', 'marginRight': '0%', 'marginTop': '2%', 'marginBottom': '2%', "paddingTop": '10px', "paddingBottom": '10px'}}>
          <Layout.Row>
            { renderCurrentExams() }
          </Layout.Row>
          <Layout.Row style={{"marginTop": "50px"}}>
            { renderAppealExams() }
          </Layout.Row>
          { renderVideoDialog() }
        </div>
      </Grid>
    </Container>
 );
};

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {
})(OpenExamListScreen);
