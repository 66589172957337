import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TranslateIcon from '@material-ui/icons/Translate';

import GLOBAL from '../../helpers/GLOBAL';
import { colors, Grid, Paper } from '@material-ui/core';
import { labels } from '../../lang/labels';
import { messages } from '../../lang/messages';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  icon: { color: '#000', fontSize: 24 },
  listRoot: {
    width: 260
  },
  listItem: {
    width: 260
  },
  paperHint: {
    padding: 20,
    marginRight: 20,
    backgroundColor: colors.blue[50],
  }
}));

export const LanguageSelector = () => {
  const { i18n, t } = useTranslation();

  const lang = GLOBAL.get('lang') || 'en';

  const changeLanguage = (value) => {
    const newLang = value;

    // Update moment locale
    let locale = 'en';
    if (newLang === 'cn') {
      locale = 'zh-CN';
    } else if (newLang === 'cnTW') {
      locale = 'zh-TW';
    }

    if (locale) {
      GLOBAL.set('locale', locale);
      i18n.changeLanguage(locale);
      labels.setLanguage(locale);
      messages.setLanguage(locale);
    }
    GLOBAL.set('lang', newLang);
    // reload on language change because api will need to refetch to match current language
    window.location.reload();
  };

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getBtnLabel = () => {
    if (lang === 'en') {
      return t('settings.english');
    } else if (lang === 'cn') {
      return t('settings.chinese');
    } else if (lang === 'cnTW') {
      return t('settings.chineseTraditional');
    }

    return '';
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid container>
      <Grid item md={8}>
        <Paper elevation={0}>
          <Typography variant="body2" color="textSecondary">
            {t('settings.languageHint')}
          </Typography>
        </Paper>
      </Grid>

      <Grid item md={4}>
        <Button
          aria-describedby={id}
          variant="outlined"
          color="primary"
          onClick={handleClick}
          className={classes.listRoot}
          startIcon={<TranslateIcon />}
        >
          { getBtnLabel() }
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.listRoot}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem
                className={classes.listItem}
                button
                selected={lang === 'en'}
                onClick={() => changeLanguage('en')}
              >
                <ListItemIcon className={classes.icon}>
                  <span role="img" aria-label="au-flag">🇦🇺</span>
                </ListItemIcon>
                <ListItemText primary={t('settings.english')} />
              </ListItem>
              <ListItem
                button
                selected={lang === 'cn'}
                onClick={() => changeLanguage('cn')}
              >
                <ListItemIcon className={classes.icon}>
                  <span role="img" aria-label="cn-flag">🇨🇳</span>
                </ListItemIcon>
                <ListItemText primary={t('settings.chinese')} />
              </ListItem>
              <ListItem
                button
                selected={lang === 'cnTW'}
                onClick={() => changeLanguage('cnTW')}
              >
                <ListItemIcon className={classes.icon}>
                  <span role="img" aria-label="cn-flag">🇨🇳</span>
                </ListItemIcon>
                <ListItemText primary={t('settings.chineseTraditional')} />
              </ListItem>
            </List>
          </div>
        </Popover>
      </Grid>
    </Grid>
  );
};
