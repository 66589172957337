import ApiHelper from '../helpers/ApiHelper';
import AppHelper from '../helpers/AppHelper';

export const getMusicPieceRequests = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`syllabirequests?lang=${lang}`, true);
};

export const getMusicTypes = ({ instrumentId }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`musictypes?instrumentid=${instrumentId}&lang=${lang}`, true);
};

export const getSyllabus = (instrumentId, gradeId, musicTypeId) => {
  return ApiHelper.get(`syllabi?instrumentid=${instrumentId}&gradeid=${gradeId}&musictypeid=${musicTypeId}`, true);
};

export const getSyllabusAbove = (instrumentId, gradeId, musicTypeId) => {
  return ApiHelper.get(`syllabi?instrumentid=${instrumentId}&gradeid=${gradeId}&musictypeid=${musicTypeId}&above=true`, true);
};

export const submitNewSyllabusRequest = ({
  gradeRequestedId,
  composerName,
  pieceTitle,
  videoLink,
  musicSheetUri
}) => {
  const params = new FormData();
  params.append('gradeRequestedId', gradeRequestedId);
  params.append('composerName', composerName);
  params.append('pieceTitle', pieceTitle);
  params.append('videoLink', videoLink);

  const fileName = AppHelper.getFileNameWithExtension(musicSheetUri);
  const fileExt = AppHelper.getFileTypeExtension(musicSheetUri);

  params.append('musicSheet', { uri: musicSheetUri, name: fileName, type: `image/${fileExt.toLowerCase()}` });

  return ApiHelper.post('syllabirequest', params, true, true);
};

export const checkIsSyllabusExist = ({ pieceTitle }) => {
  const params = {
    pieceTitle
  };
  return ApiHelper.post('syllabirequest/exist', params, true);
}

export const getMusicURL = ({ musicId }) => {
  const params = {
      musicID: musicId,
  };
  return ApiHelper.post('music-url', params, true);
};
