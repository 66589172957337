import React from 'react';
import { Router, Switch, Route, Link } from 'react-router-dom';

import { CustomizedSnackbar, RouteListener } from '../components/common';
import { MainDialogContainer } from '../components/dialogs';
import { LoadingModal } from '../components/modals';
import { PrivateRoute } from './PrivateRoute';
import { UnauthenticatedRoute } from './UnauthenticatedRoute';
// import { StudentOnlyRoute } from './StudentOnlyRoute';

import HomeScreen from '../components/HomeScreen/HomeScreen';
import DashScreen from '../components/DashScreen/DashScreen';
import SignInScreen from '../components/SigninScreen/SigninScreen';
import SignUpScreen from '../components/SignupScreen/SignupScreen';
import SignUpOrCompleteAsStudentScreen from '../components/SignUpOrCompleteAsStudentScreen/SignUpOrCompleteAsStudentScreen';
import ResetPasswordScreen from '../components/ResetPasswordScreen/ResetPasswordScreen';
import SettingScreen from '../components/SettingScreen/SettingScreen';
import EnrolNewExamScreen from '../components/EnrolNewExamScreen/EnrolNewExamScreen';
import OpenExamListScreen from '../components/OpenExamListScreen/OpenExamListScreen';
import EditExamDetailsScreen from '../components/EditExamDetailsScreen/EditExamDetailsScreen';
import GeneralPurchaseScreen from '../components/GeneralPurchaseScreen/GeneralPurchaseScreen';
import SyllabusScreen from '../components/SyllabusScreen/SyllabusScreen';
import EvaluationGuidelinesScreen from '../components/EvaluationGuidelinesScreen/EvaluationGuidelinesScreen';
import ExamGuidelinesScreen from '../components/ExamGuidelinesScreen/ExamGuidelinesScreen';
import GradingGuidelinesScreen from '../components/GradingGuidelinesScreen/GradingGuidelinesScreen';
import ExamFeeStructureScreen from '../components/FeeStructureScreen/FeeStructureScreen';
import EvaluationFeeStructureScreen from '../components/EvaluationFeeStructureScreen/EvaluationFeeStructureScreen';
import HallOfFameScreen from '../components/HallOfFameScreen/HallOfFameScreen';

export const CustomRouter = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <UnauthenticatedRoute exact path="/sign-in" component={SignInScreen} />
        <UnauthenticatedRoute exact path="/sign-up" component={SignUpScreen} />
        <UnauthenticatedRoute exact path="/sign-up-student" compProps={{ mode: 'signUp'}} component={SignUpOrCompleteAsStudentScreen} />
        <Route exact path="/reset-password" component={ResetPasswordScreen} />
        <PrivateRoute exact path="/settings" component={SettingScreen} />
        <PrivateRoute exact path="/dash" component={DashScreen} />
        <PrivateRoute exact path="/enrol-new-exam" component={EnrolNewExamScreen} />
        <PrivateRoute exact path="/open-exam-list" component={OpenExamListScreen} />
        <PrivateRoute exact path="/edit-exam-details/:examID" component={EditExamDetailsScreen} />
        <PrivateRoute exact path="/general-purchase" component={GeneralPurchaseScreen} />
        <PrivateRoute exact path="/syllabus" component={SyllabusScreen} />
        <PrivateRoute exact path="/evaluation-guidelines" component={EvaluationGuidelinesScreen} />
        <PrivateRoute exact path="/exam-guidelines" component={ExamGuidelinesScreen} />
        <PrivateRoute exact path="/grading-guidelines" component={GradingGuidelinesScreen} />
        <PrivateRoute exact path="/exam-fee-structure" component={ExamFeeStructureScreen} />
        <PrivateRoute exact path="/evaluation-fee-structure" component={EvaluationFeeStructureScreen} />
        <PrivateRoute exact path="/hall-of-fame" component={HallOfFameScreen} />
      </Switch>

      <RouteListener />

      {/* GLOBAL MODALS */}
      <LoadingModal />
      {/* Global snackbars */}
      <CustomizedSnackbar />
      <MainDialogContainer />
    </Router>
  );
};
