import ApiHelper from '../helpers/ApiHelper';
import YoubrioApiHelper from '../helpers/YoubrioApiHelper';
import AppHelper from '../helpers/AppHelper';


export const getCountries = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`countries?lang=${lang}`, true);
};

export const getInstruments = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`instruments?lang=${lang}`, true);
};

export const getGradesAbove = ({ instrumentId, studentId }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  if (studentId !== null && studentId !== undefined) {
    return ApiHelper.get(`grades?instrumentid=${instrumentId}&studentid=${studentId}&lang=${lang}`, true);
  }
  return ApiHelper.get(`grades?instrumentid=${instrumentId}&lang=${lang}`, true);
};

export const getGrades = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`grades?lang=${lang}`, true);
};

export const validateAffiliationCode = ({ affiliationCode }) => {
  return ApiHelper.get(`affiliate/code/${affiliationCode}`, true);
};


export const validateAffiliationCodeV2 = ({ affiliationCode }) => {
  return ApiHelper.get(`v2/affiliate/code/${affiliationCode}`, true);
};

export const getPhysicalLocation = () => {
  return ApiHelper.get('location', false);
};

export const getFaq = () => {
  return YoubrioApiHelper.get('faqs', true);
};

export const getBanners = ({ deviceType }) => {
  return YoubrioApiHelper.get(`banners?deviceType=${deviceType}`, true);
};
