import React, { Component } from 'react';
import { Redirect, Link as RLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Trans } from 'react-i18next';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  fieldUpdate,
  validateField,
  validateFieldsAndRegisterAsCompleteStudentProfile,
  validateAndCompleteStudentProfile,
  resetField,
  updateRegistrationModalState,
  checkForUnsavedData,
  resetCommonModalState,
  youbrioLogin,
  openDialog
} from '../../actions';
import YoubrioApiHelper from '../../helpers/YoubrioApiHelper';
import AppHelper from '../../helpers/AppHelper';
import { LogoAppBar, CountryPicker } from '../common';
import AuthHelper from '../../helpers/AuthHelper';
import { AlertDialog } from '../dialogs';
import NavigationHelper from '../../helpers/NavigationHelper';
// import ApiHelper from '../../helpers/ApiHelper';
import GLOBAL from '../../helpers/GLOBAL';
// import { labels } from '../../lang/labels';

const { STRING } = require('../../helpers/ConstantHelper');

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        YOUBRIO
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const styles = (theme) => ({
  root: {
    minHeight: '100vh',
    paddingTop: 90,
    paddingBottom: 30,
    backgroundColor: '#fafafa'
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
  },
  avatar: {
    padding: '20px 0 0 20px',
    width: '60px',
    height: '60px',
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  welcomeSloganLine1: {
    'font-weight': 'bold',
  },
  welcomeSloganLine2: {
    'font-weight': 'bold',
    'color': '#abababcc',
  },
  paper: {
    margin: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  or: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '85%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  agreeTerms: {
    'font-size': '0.8rem',
  },
  rightSide: {
    margin: '10% 0 10% 0',
  },
  rightSideImage: {
    width: 'auto',
    height: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  rightSlogan: {
    padding: '20px 40px 20px 40px',
  },
  rightDesc: {
    padding: '10% 2% 5% 5%',
  },
  rightDescLine: {
    padding: '10px 60px 0 10px',
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SignUpOrCompleteAsStudentScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.registerSubmitCallback = this.registerSubmitCallback.bind(this);
    this.completeSubmitCallback = this.completeSubmitCallback.bind(this);
  }

  componentDidMount() {
    if (this.props.mode === 'complete') {
      const user = AppHelper.getUser();
      this.props.fieldUpdate({ prop: 'firstName', value: user.firstName });
      this.props.fieldUpdate({ prop: 'lastName', value: user.lastName });
      this.props.fieldUpdate({ prop: 'email', value: user.email });
      this.props.fieldUpdate({ prop: 'mobile', value: user.mobile });
      this.props.fieldUpdate({ prop: 'dob', value: user.dob });
    }
  }

  onFirstNameChange(e) {
    const text = e.target.value;
    this.props.fieldUpdate({ prop: 'firstName', value: text });
    this.props.validateField({ prop: 'firstName', value: text });
  }

  onLastNameChange(e) {
    const text = e.target.value;
    this.props.fieldUpdate({ prop: 'lastName', value: text });
    this.props.validateField({ prop: 'lastName', value: text });
  }

  onEmailChange(e) {
    const text = e.target.value;
    this.props.fieldUpdate({ prop: 'email', value: text });
  }

  // only validate email after blur because will call API to check whether email exists
  onEmailBlur(e) {
    const text = e.target.value;
    this.props.validateField({ prop: 'email', value: text });
  }

  onPasswordChange(e) {
    const text = e.target.value;
    this.props.fieldUpdate({ prop: 'password', value: text });
    this.props.validateField({ prop: 'password', value: text });
    this.props.validateField({ prop: 'confirmPassword', value: this.props.confirmPassword });
  }

  onConfirmPasswordChange(e) {
    const text = e.target.value;
    this.props.fieldUpdate({ prop: 'confirmPassword', value: text });
    this.props.validateField({ prop: 'confirmPassword', value: text });
  }

  onMobileChange(e) {
    const text = e.target.value;
    this.props.fieldUpdate({ prop: 'mobile', value: text });
    this.props.validateField({ prop: 'mobile', value: text });
  }

  onDOBChange(e) {
    const text = e.target.value;
    this.props.fieldUpdate({ prop: 'dob', value: text });
    this.props.validateField({ prop: 'dob', value: text, role: STRING.ROLE.STUDENT });
  }

  onCountrySelect(countryId) {
    this.props.fieldUpdate({ prop: 'countryId', value: countryId });
    this.props.validateField({ prop: 'countryId', value: countryId });
  }

  onClickRegisterButton() {
    const fields = {
      email: this.props.email,
      password: this.props.password,
      confirmPassword: this.props.confirmPassword,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      countryId: this.props.countryId,
      mobile: this.props.mobile,
      dob: this.props.dob
    };

    this.props.validateFieldsAndRegisterAsCompleteStudentProfile({
      fields,
      callback: this.registerSubmitCallback
    });
  }

  onClickCompleteProfile() {
    const user = {
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      countryId: this.props.countryId,
      mobile: this.props.mobile,
      dob: this.props.dob
    };

    this.props.validateAndCompleteStudentProfile({
      user,
      callback: this.completeSubmitCallback
    });
  }

  registerSubmitCallback({ response, error }) {
    if (response) {
      this.props.youbrioLogin(this.props.email, this.props.password);
    } else if (error) {
      const {
        t
      } = this.props;

      this.props.openDialog({
        dialog: AlertDialog,
        props: {
          title: t('error'),
          contentText: t('signup.unableToRegisterYourAccount'),
          disableEscapeKeyDown: true,
          disableBackdropClick: true
        }
      });
    }
  }

  completeSubmitCallback({ response, error }) {
    const {
      t,
      history
    } = this.props;

    if (response) {
      this.props.openDialog({
        dialog: AlertDialog,
        props: {
          title: t('alert.allSet'),
          contentText: t('profile.studentProfileUpdateSuccess'),
          disableEscapeKeyDown: true,
          disableBackdropClick: true,
          buttons: [
            {
              text: t('alert.ok'),
              onClick: () => {
                GLOBAL.set('user', response);
                NavigationHelper.redirectCachedRoute({ history, replace: true });
              },
              primary: true
            }
          ]
        }
      });
    } else if (error) {
      this.props.openDialog({
        dialog: AlertDialog,
        props: {
          title: t('error'),
          contentText: t('signup.studentProfileUpdateFail'),
          disableEscapeKeyDown: true,
          disableBackdropClick: true
        }
      });
    }
  }

  renderTitle() {
    const {
      mode,
      classes,
      t
    } = this.props;

    if (mode === 'complete') {
      return (
        <Box py={2} width={'85%'}>
          <Typography component="h1" variant="h4" align="left" className={classes.welcomeSloganLine1}>
            { t('profile.completeMyStudentProfile') }
          </Typography>
        </Box>
      );
    } else if (mode === 'signUp') {
      return (
        <Box py={2} width={'85%'}>
          <Grid container>
            <Grid item xs={8}>
              <Typography component="h1" variant="h4" align="left" className={classes.welcomeSloganLine1}>
                { t('profile.signUpAsStudent') }
              </Typography>
            </Grid>
            <Grid item container xs={4} justifyContent="flex-end">
              <Link
                component={RLink}
                to={{
                  pathname: '/sign-in',
                  state: {
                    signUpAs: STRING.ROLE.STUDENT
                  }
                }}
                style={{ textAlign: 'right', whiteSpace: 'pre-line' }}
                variant="body2"
              >
                {t('signup.goToSignin')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      );
    }

    return null;
  }

  renderSubmitButton() {
    const {
      mode,
      classes,
      t
    } = this.props;

    if (mode === 'signUp') {
      return (
        <Box mt={3} mb={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={this.onClickRegisterButton.bind(this)}
          >
            { t('signup.createAccount') }
          </Button>
        </Box>
      );
    } else if (mode === 'complete') {
      return (
        <Box mt={3} mb={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={this.onClickCompleteProfile.bind(this)}
          >
            { t('profile.completeMyStudentProfile') }
          </Button>
        </Box>
      );
    }
    
  }

  renderTnCText() {
    const {
      mode
    } = this.props;

    if (mode === 'complete') {
      return (
        <Box mt={2} ml={1} mb={1}>
          <Typography variant={'body2'}>
            <Trans
              i18nKey="signup.byProceedingProfileCompleteYouAgreeToTerms"
              values={{
                tncLink: YoubrioApiHelper.getLegacyPortalPath(`terms?lang=${AppHelper.getAppLocaleCodeForApi()}`),
                privacyLink: YoubrioApiHelper.getLegacyPortalPath(`privacy?lang=${AppHelper.getAppLocaleCodeForApi()}`)
              }}
              components={{ linkcom: <Link /> }}
            />
          </Typography>
        </Box>
      );
    } else if (mode === 'signUp') {
      return (
        <Box mt={2} ml={1} mb={1}>
          <Typography variant={'body2'}>
            <Trans
              i18nKey="signup.byProceedingAccCreateYouAgreeToTerms"
              values={{
                tncLink: YoubrioApiHelper.getLegacyPortalPath(`terms?lang=${AppHelper.getAppLocaleCodeForApi()}`),
                privacyLink: YoubrioApiHelper.getLegacyPortalPath(`privacy?lang=${AppHelper.getAppLocaleCodeForApi()}`)
              }}
              components={{ linkcom: <Link /> }}
            />
          </Typography>
        </Box>
      );
    }
    
    return null;
  }

  handleCloseSnackbar(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.props.updateRegistrationModalState({ prop: 'visible', value: false });
  }

  SignUpSide(classes) {
    const { t, mode } = this.props;
    
    return (
      <Grid
        container
        component="main"
        className={classes.root}
        direction="column"
        alignItems="center"
      >
        <CssBaseline />
        <LogoAppBar />
        <Grid
          // maxWidth="md"
          item sm={11} md={6} component={Paper} elevation={1} square
        >
          <div className={classes.paper}>
            {this.renderTitle()}
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label={t('signup.firstName')}
                    value={this.props.firstName}
                    onChange={this.onFirstNameChange.bind(this)}
                    autoFocus
                    error={this.props.error.firstName}
                    helperText={this.props.error.firstName || t('signup.firstNameDefaultHelperText')}
                    inputProps={{ maxlength: 64 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label={t('signup.lastName')}
                    name="lastName"
                    value={this.props.lastName}
                    onChange={this.onLastNameChange.bind(this)}
                    autoComplete="lname"
                    error={this.props.error.lastName}
                    helperText={this.props.error.lastName || t('signup.lastNameDefaultHelperText')}
                    inputProps={{ maxlength: 64 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label={t('signup.email')}
                    name="email"
                    value={this.props.email}
                    onChange={this.onEmailChange.bind(this)}
                    onBlur={this.onEmailBlur.bind(this)}
                    autoComplete="email"
                    error={this.props.error.email}
                    disabled={mode === 'complete'}
                    helperText={this.props.error.email || t('signup.emailDefaultHelperText')}
                    inputProps={{ maxlength: 100 }}
                  />
                </Grid>
                {
                  mode === 'signUp'
                    ? <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label={t('signup.password')}
                        type="password"
                        id="password"
                        value={this.props.password}
                        onChange={this.onPasswordChange.bind(this)}
                        autoComplete="current-password"
                        error={this.props.error.password}
                        helperText={this.props.error.password || t('signup.passwordDefaultHelperText')}
                        inputProps={{ maxlength: 25 }}
                      />
                    </Grid>
                    : null
                }
                {
                  mode === 'signUp'
                    ? <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="confirmPassword"
                        label={t('signup.confirmPassword')}
                        type="password"
                        id="confirmPassword"
                        value={this.props.confirmPassword}
                        onChange={this.onConfirmPasswordChange.bind(this)}
                        autoComplete="current-password"
                        error={this.props.error.confirmPassword}
                        helperText={this.props.error.confirmPassword || t('signup.confirmPasswordDefaultHelperText')}
                        inputProps={{ maxlength: 25 }}
                      />
                    </Grid>
                    : null
                }
                <Grid item xs={12}>
                  <CountryPicker
                    selectedCountry={this.props.countryId}
                    onSelect={this.onCountrySelect.bind(this)}
                    label={t('signup.country')}
                    error={this.props.error.countryId}
                    helperText={this.props.error.countryId || t('signup.countryDefaultHelperText')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    required
                    fullWidth
                    name="dob"
                    type='date'
                    label={t('signup.dob')}
                    id="dob"
                    value={this.props.dob}
                    onChange={this.onDOBChange.bind(this)}
                    autoComplete='bday'
                    error={this.props.error.dob}
                    helperText={this.props.error.dob || t('signup.dobDefaultHelperText')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="phone"
                    label={t('signup.phoneNumber')}
                    id="phoneNumber"
                    value={this.props.mobile}
                    onChange={this.onMobileChange.bind(this)}
                    autoComplete="phoneNumber"
                    error={this.props.error.mobile}
                    helperText={this.props.error.mobile || t('signup.phoneNumberDefaultHelperText')}
                    inputProps={{ maxlength: 20 }}
                  />
                </Grid>
                {this.renderTnCText()}
              </Grid>
              {this.renderSubmitButton()}
              {
                mode === 'signUp'
                  ? <Grid container justify="flex-end">
                    <Grid item>
                      <Link
                        component={RLink}
                        to={{
                          pathname: '/sign-in',
                          state: {
                            signUpAs: STRING.ROLE.STUDENT
                          }
                        }}
                        variant="body2"
                      >
                        {t('signup.goToSignin')}
                      </Link>
                    </Grid>
                  </Grid>
                  : null
              }
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
        {/* <Grid item xs={false} sm={4} md={7} className={classes.image}>
          <div className={classes.rightSide}>
            <img alt="youbrio-login" src={ApiHelper.getCdnUrl('/public/images/login-main2.jpg')} className={classes.rightSideImage} />
          </div>
        </Grid> */}
        <Snackbar open={this.props.registrationModal.visible} autoHideDuration={6000} onClose={this.handleCloseSnackbar.bind(this)}>
          <Alert onClose={this.handleCloseSnackbar.bind(this)} severity={this.props.registrationModal.mode}>
            { this.props.registrationModal.message }
          </Alert>
        </Snackbar>
      </Grid>
    );
  }

  render() {
    if (AuthHelper.isLoggedIn() && !AppHelper.isIncompleteProfileAccount()) {
      return (
        <Redirect to={'/dash'} />
      );
    }

    return this.SignUpSide(this.props.classes);
  }
}

const mapStateToProps = state => {
  const {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    error,
    registrationModal,
    hasRegistrationChanged,
    mobile,
    dob,
    countryId
  } = state.registration;

  const {
    commonModal,
    loading
  } = state.dialog;

  return {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    error,
    registrationModal,
    hasRegistrationChanged,
    mobile,
    dob,
    countryId,
    commonModal,
    loading
  };
};

export default withTranslation()(
  withStyles(styles)(
    withRouter(
      connect(mapStateToProps, {
        fieldUpdate,
        validateField,
        validateFieldsAndRegisterAsCompleteStudentProfile,
        validateAndCompleteStudentProfile,
        resetField,
        updateRegistrationModalState,
        checkForUnsavedData,
        resetCommonModalState,
        youbrioLogin,
        openDialog
      })(SignUpOrCompleteAsStudentScreen)
    )
  )
);


