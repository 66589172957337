import React, { useEffect, useState } from 'react';
import { useDebounce, useSessionStorageState } from 'ahooks';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Layout,
  Button,
  Loading,
  Input,
  Form,
  Alert,
  Message,
  Select,
  Checkbox,
  Card,
  Upload,
  Dialog,
  Notification,
  // Dialog,
  MessageBox,
  // Radio,
} from 'element-react';
import 'element-theme-default';
import {
  // Typography,
  // Paper,
  Container,
  Grid,
} from '@material-ui/core';

import {
  // getPsOpenExams as getPsOpenExamsApi,
  getMusicTypes as getMusicTypesApi,
  // getLibraryInstruments as getLibraryInstrumentsApi,
  checkURL as checkURLApi,
  getTeacherExamDetails as getTeacherExamDetailsApi,
  getStudentExamDetails as getStudentExamDetailsApi,
  // getSyllabus as getSyllabusApi,
  getSyllabusAbove as getSyllabusAboveApi,
  getLibraryByMusicName as getLibraryByMusicNameApi,
  getMusicURL as getMusicURLApi,
  saveTeacherExamDetails as saveTeacherExamDetailsApi,
  submitTeacherExam as submitTeacherExamApi,
  saveStudentExamDetails as saveStudentExamDetailsApi,
  submitStudentExam as submitStudentExamApi,
} from '../../api';
// import { labels } from '../../lang/labels';
// import { messages } from '../../lang/messages';
// import AppHelper from '../../helpers/AppHelper';
import GLOBAL from '../../helpers/GLOBAL';
// import { MessageOutlined, MessageTwoTone } from '@material-ui/icons';

const CONSTANT = require('../../helpers/ConstantHelper');

const EditExamDetailsScreen = (props) => { 
  const { t } = useTranslation();
  const {
    history,
  } = props;
  const { examID } = useParams();
  const user = GLOBAL.get('user');
  const {
    cbeRole,
  } = user;
  const isTeacherRole = cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER);
  // const isUserInChina = false; // AppHelper.isUserInChina(); // not work
  // const isCNLang = GLOBAL.get('lang')?.includes('cn');

  const [form, setForm] = useSessionStorageState('EditExamDetails-form', {
    defaultValue: {
      pieces: {
        0: {},
        1: {},
        2: {},
      },
      comments: '',
      remarks: '',
      photoIdUri: null,
      agree: false,
    },
  });
  const musicTypeId1 = useDebounce(form?.pieces[0]?.musicTypeId);
  const musicTypeId2 = useDebounce(form?.pieces[1]?.musicTypeId);
  const musicTypeId3 = useDebounce(form?.pieces[2]?.musicTypeId);
  
  const setPiece = (index, k) => {
    setForm({
      ...form,
      pieces: {
        ...form.pieces,
        [index]: {
          ...form.pieces[index],
          ...k,
        }
      }
    });
  }; 

  const [loading, setLoading] = useState({
    examDetails: false,
    musicTypes: false,
    pieces: {
      0: {
        musicPieceOptions: false,
        musicPieceURL: false,
        videoLink: false,
      },
      1: {
        musicPieceOptions: false,
        musicPieceURL: false,
        videoLink: false,
      },
      2: {
        musicPieceOptions: false,
        musicPieceURL: false,
        videoLink: false,
      },
    },
    save: false,
    submit: false,
  });
  const [musicTypes, setMusicTypes] = useState([]);
  const [examDetails, setExamDetails] = useState(null);
  const [message, setMessage] = useState('');
  const [agreementDialogVisible, setAgreementDialogVisible] = useState(false);

  const fetchMusicTypes = async() => {
    if (!examDetails) {
      return;
    }
    try {
      setLoading({
        ...loading,
        musicTypes: true,
      });
      let res = await getMusicTypesApi({
        instrumentId: examDetails?.instrumentId, 
      });
      if (res && res.data) {
        setMusicTypes(res.data?.musicTypes||[]);
      } else {
        console.warn(res);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading({
        ...loading,
        musicTypes: false,
      });
    }
  };

  const fetchExamDetails = async() => {
    if (!examID) {
      return;
    }
    try {
      setLoading({
        ...loading,
        examDetails: true,
      });
      let res;
      if (isTeacherRole) {
        res = await getTeacherExamDetailsApi(examID);
      } else {
        res = await getStudentExamDetailsApi(examID);
      }
      setLoading({
        ...loading,
        examDetails: false,
      });
      if (res && res.data) {
        let examDetails = res.data?.examDetails || {};
        setExamDetails(examDetails);
        setForm({
          ...form,
          remarks: examDetails?.remarks,
          comments: examDetails?.comments,
          photoIdUri: examDetails?.photoIdUri,
          pieces: {
            0: {
              ...form.pieces[0],
              ...examDetails?.pieceDetails[0],  
            },
            1: {
              ...form.pieces[1],
              ...examDetails?.pieceDetails[1],      
            },
            2: {
              ...form.pieces[2],
              ...examDetails?.pieceDetails[2],      
            },
          }
        });
      } else {
        console.warn(res);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading({
        ...loading,
        examDetails: false,
      });
    }
  };

  const isValidURL = (url) => {
    var pattern = new RegExp('^((https?:)?\\/\\/)?'+ // protocol
						        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
						        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
						        '(\\:\\d+)?(\\/[-a-z\\d%_.~+=]*)*'+ // port and path
						        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
						        '(\\#[-a-z\\d_]*)?$','i'); // fragment locater
		if (!pattern.test(url)) {
		    return false;
		} else {
		    return true;
		}
  };

  const matchYoutubeUrl = (url) =>{
    var p = /^(?:https?:\/\/)?(?:www\.|m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|shorts\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if(url.match(p)){
        return url.match(p)[1];
    }
    return false;
  };

  const matchOtherUrl = (url) => {
    if (
      url.includes('video.com')
      || url.includes('www.acfun.cn')
      || url.includes('www.bilibili.com')
      || url.includes('www.func.tv')
      || url.includes('www.le.com')
      || url.includes('v.qq.com')
      || url.includes('www.tudou.com')
      || url.includes('video.conbrioexams.com') 
      || url.includes('drive.google.com')
      || url.includes('www.dropbox.com')
      || url.includes('www.youbrio.com')
      || url.includes('www.youbrio.cn')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const fetchCheckURL = async({ checkURL, examID, pieceIndex }) => {
    const setLoadingCheckURL = (v) => {
      setLoading({
        ...loading,
        pieces: {
          ...loading?.pieces,
          [pieceIndex]: {
            ...loading?.pieces[pieceIndex],
            checkURL: v,
          }
        }
      });
    };
    const setMessage = (m) => {
      setForm({
        ...form,
        pieces: {
          ...form?.pieces,
          [pieceIndex]: {
            ...form?.pieces[pieceIndex],
            videoLink: checkURL,
            message: m,
          }
        }
      });
    };

    try {
      setLoadingCheckURL(true);
      let message = null;
      // if (checkURL.indexOf('https://' === -1) && checkURL.indexOf("http://") === -1) {
      //   checkURL = 'https://' + checkURL;
      // }

      const isValid = isValidURL(checkURL);
      if (!isValid) {
        message = t('upload.invalidUrl');
      } else {
        const isYoutubeUrl = matchYoutubeUrl(checkURL);
        const isOtherUrl = matchOtherUrl(checkURL);

        if (isYoutubeUrl || isOtherUrl) {
          let res = await checkURLApi({ checkURL, examID });
          const exist = res?.data?.exist;
          if (exist) {
            message = t('upload.existInDB');
          } else {
            message = '';
          }
        } else {
          message = t('upload.invalidHostname');
        }
      }
      setMessage(message);
      setLoadingCheckURL(false);
      return message;
    } catch (e) {
      console.error(e);
      let message = e?.message;
      setMessage(message);
      setLoadingCheckURL(false);
      return message;
    }
  };

  const fetchSyllabusOptions = async({pieceIndex, instrumentId, gradeId, musicTypeId, callback}) => {
    const setMusicPieceOptionsLoading = (v) => {
      setLoading({
        ...loading,
        pieces: {
          ...loading.pieces,
          [pieceIndex]: {
            ...loading.pieces[pieceIndex],
            musicPieceOptions: v 
          }
        }
      });
    };
    try {
      setMusicPieceOptionsLoading(true);
      let res = await getSyllabusAboveApi(
        instrumentId,
        gradeId,
        musicTypeId,
      );
      setMusicPieceOptionsLoading(false);
      if (res && res.data) {
        if (callback) {
          callback(res.data);
        }
      } else {
        console.warn(res);
      }
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const fetchLibraryByMusicName = async ({musicName, callback}) => {
    if (!musicName) {
      return;
    }
    try {
      let res = await getLibraryByMusicNameApi({
        musicName,
      });
      if (res && res.data) {
        if (callback) {
          callback(res.data);
        }
      } else {
        console.warn(res);
      }
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const fetchMusicURL = async({ musicId, pieceIndex }) => {
    const setMusicPieceURLLoading = (v) => {
      setLoading({
        ...loading,
        pieces: {
          ...loading.pieces,
          [pieceIndex]: {
            ...loading.pieces[pieceIndex],
            musicPieceURL: v,
          }
        }
      });
    };
    try {
      let res = await getMusicURLApi({musicId});
      if (res && res.data) {
        return res.data?.urlLink;
      } else {
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    } finally {
    }
  };

  const fetchSaveExamDetails = async (examId, params) => {
    try {
      setLoading({
        ...loading,
        save: true,
      });
      let res;
      if (isTeacherRole) {
        res = await saveTeacherExamDetailsApi(examId, params);
      } else {
        res = await saveStudentExamDetailsApi(examId, params);
      }
      setLoading({
        ...loading,
        save: false,
      });
      if (res && res.data) {
        Notification({
          message: t('success'),
          type: 'success',
        });
      } else {
        Notification({
          message: t('fail'),
          type: 'warning',
        });
      }
    } catch (e) {
      console.error(e);
      setLoading({
        ...loading,
        save: false,
      });
      Notification.error(e?.message);
    }
  };

  const fetchSubmitExam = async(examId, params) => {
    try {
      setLoading({
        ...loading,
        submit: true,
      });
      let res;
      if (isTeacherRole) {
        res = await submitTeacherExamApi(examId, params);
      } else {
        res = await submitStudentExamApi(examId, params);
      }
      setLoading({
        ...loading,
        submit: false,
      });
      if (res && res.data) {
        MessageBox.msgbox({
          message: t('success'),
          type: 'success',
          confirmButtonText: t('confirm'),
          title: "",
        }).then(action => {
          if (action === 'confirm') {
            history.go(-1);
          } else {
          }
        });
      } else {
        MessageBox.msgbox({
          title: "",
          message: t('fail'),
          type: 'warning',
        });
      }
    } catch (e) {
      console.error(e);
      setLoading({
        ...loading,
        submit: false,
      });
      Notification.error(e?.message);
    }
  };

  useEffect(() => {
    fetchExamDetails();
  }, []);

  useEffect(() => {
    fetchMusicTypes();
  }, [
    examDetails?.instrumentId,
  ]);

  useEffect(() => { 
    fetchSyllabusOptions({
      pieceIndex: 0,
      instrumentId: examDetails?.instrumentId,
      gradeId: examDetails?.gradeId,
      musicTypeId: musicTypeId1,
      callback: (data) => {
        setPiece(0, {
          musicPieceOptions: data?.syllabi,
        });
      }
    });
  }, [
    musicTypeId1,
    examDetails?.instrumentId,
    examDetails?.gradeId,
  ]);

  useEffect(() => { 
    fetchSyllabusOptions({
      pieceIndex: 1,
      instrumentId: examDetails?.instrumentId,
      gradeId: examDetails?.gradeId,
      musicTypeId: musicTypeId2,
      callback: (data) => {
        setPiece(1, {
          musicPieceOptions: data?.syllabi,
        });
      }
    });
  }, [
    musicTypeId2,
    examDetails?.instrumentId,
    examDetails?.gradeId,
  ]);

  useEffect(() => { 
    fetchSyllabusOptions({
      pieceIndex: 2,
      instrumentId: examDetails?.instrumentId,
      gradeId: examDetails?.gradeId,
      musicTypeId: musicTypeId3,
      callback: (data) => {
        setPiece(2, {
          musicPieceOptions: data?.syllabi,
        });
      }
    });
  }, [
    musicTypeId3,
    examDetails?.instrumentId,
    examDetails?.gradeId,
  ]);

  const renderPiece = (index) => {
    const piece = form.pieces[index];

    return (
      <Card className="box-card" style={{"marginTop": "20px", "marginBottom": "20px"}}>
        <Form.Item label={t('editExamDetail.piece') + (index + 1) + t('editExamDetail.musicType')} required>
          <Select
            value={piece.musicTypeId}
            placeholder=""
            loading={loading?.musicTypes}
            style={{'width': '80%'}}
            onChange={async(v) => {
              setPiece(index, {
                musicTypeId: v,
                musicTitleId: null,
              });
            }}
          >
            {
              musicTypes.map(x => (
                <Select.Option
                  key={x.id}
                  label={x.musicType}
                  value={x.id}
                />
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item label={t('editExamDetail.piece') + (index + 1) + t('editExamDetail.musicSelection')} required>
          <Layout.Row type="flex" justify='start' gutter="5">
            <Layout.Col span={18}>
              <Select
                value={piece.musicTitleId}
                placeholder=""
                style={{'width': '80%'}}
                loading={loading?.pieces[index]?.musicPieceOptions}
                onChange={async (v) => {
                  setPiece(index, {
                    musicTitleId: v
                  });
                  let musicURL = await fetchMusicURL({
                    musicId: v,
                  });
                  setPiece(index, {
                    musicTitleId: v,
                    musicPieceURL: musicURL
                  }); 
                }}
              >
                {
                  piece.musicPieceOptions?.map(x => (
                    <Select.Option
                      label={x.musicName}
                      value={x.id}
                      key={x.id}
                    />
                  ))
                }
              </Select>
            </Layout.Col>
            <Layout.Col span={2}>
              <Button
                icon="caret-right"
                type="primary"
                size="mini"
                loading={loading?.pieces[index]?.musicPieceURL}
                onClick={async () => {
                  let href = piece.musicPieceURL;
                  if (!href) {
                    href = await fetchMusicURL({
                      musicId: piece.musicPiece,
                    });
                  }
                  window.location.href = piece.musicPieceURL;
                }}
                />
            </Layout.Col>
          </Layout.Row>
       </Form.Item>
        <Form.Item label={t('editExamDetail.piece') + (index + 1) + t('editExamDetail.linkForYourVideoRecording')} required>
          <Input
            value={form?.pieces[index]?.videoLink}
            onChange={(v) => {
              setPiece(index, {videoLink: v});
              fetchCheckURL({
                examID: examDetails?.examId,
                checkURL: v,
                pieceIndex: index,
              });
            }}
          />
        </Form.Item>
        {
          piece?.message &&
            <Form.Item>
              <Alert title={piece?.message} type="error" closable={false} />
            </Form.Item>
        }
      </Card>
    );
  };

  const renderAvatarUploader = () => {
    const handleAvatarSuccess = (res, file) => {
      console.log('---handleavatarsuce=',res, file);
      // let uri = URL.createObjectURL(file.raw);
      // setForm({
      //   ...form,
      //   photoIDUri: uri,
      // });
    };

    const beforeAvatarUpload = (file) => {
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      const isGIF = file.type === 'image/gif';
      const isLt2M = file.size / 1024 / 1024 < 5;

      const isRightExtension = isJPEG || isJPG || isPNG || isGIF;
      if (!isRightExtension) {
        Message(t('upload.invalidFormat', {extensions: 'jpg/png/gif'}));
        return false;
      }
      if (!isLt2M) {
        Message(t('upload.sizeLimitText', { size: '5M' }));
        return false;
      }
      setForm({
        ...form,
        photoIdUri: URL.createObjectURL(file),
      });
      return isRightExtension && isLt2M;
    };

    return (
      <>
        <Upload
          className="avatar-uploader"
          action="//uselessplaceholder.typicode.com/posts/"
          showFileList={false}
          limit={1}
          listType="picture"
          autoUpload={true}
          onSuccess={(res, file) => handleAvatarSuccess(res, file)}
          beforeUpload={file => beforeAvatarUpload(file)}
        >
          {
            form?.photoIdUri
            ?
            <img src={form?.photoIdUri} className="avatar" style={{'maxWidth': '200px', 'maxHeight': '200px'}} />
            :
            <i className="el-icon-plus avatar-uploader-icon"></i>
          }
        </Upload>
      </>
    );
  };

  const renderAgreementDialog = () => {
    return (
      <Dialog
        title=""
        size="large"
        visible={agreementDialogVisible}
        onCancel={() => {setAgreementDialogVisible(false)}}
      >
        <Dialog.Body style={{'fontSize': '24px'}}>
          <>
            <p style={{"textAlign":"left"}}>ASSIGNMENT OF COPYRIGHT</p>
            <p style={{"textAlign":"left"}}>IT IS AGREED:</p>
                             <ol style={{fontFamily: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif", fontWeight: 300, fontSize: "18px"}}>
	                             <li style={{textAlign:"left"}}>Interpretation</li>
	                             <ol>
	                             <li style={{textAlign:"left"}}>In this Assignment the following definitions apply:
	                              <br/>
																'Videos' means all cinematograph film produced by you pursuant to being assessed for award by Con Brio Examinations;
																<br/>
																'Con Brio Examinations' means Con Brio Examinations including its controlled entities, employees and any person authorized by Con Brio Examination in connection with this Assignment.
																<br/>
															  </li>
																<li style={{textAlign:"left"}}>
																	A reference to persons includes corporations; words including singular number include plural number and vice versa.
	                              </li>
                               </ol>

	                             <li style={{textAlign:"left"}}>Warranty</li>
	                             <ol>
	                             <li style={{textAlign:"left"}}>
	                             	  You warrant that you are the sole owner of all copyrights in the Videos and that you have full power to enter into this Assignment and that this Assignment does not infringe any existing copyright.
				                       </li>
															 <li style={{textAlign:"left"}}>
																	You agree to sign all necessary papers that may be necessary to effect the assignment under this Assignment, as and where necessary.
	                             </li>
	                             <li style={{textAlign:"left"}}>
																	You agree to indemnify Con Brio Examinations against loss, injury or damage (including any related legal costs or expenses properly incurred) occasioned to Con Brio Examinations in consequence of any breach by You (unknown to Con Brio Examinations) of the warranty in clause 2 (1).
	                             </li>
                               </ol>

                               <li style={{textAlign:"left"}}>Assignment of Copyright and Moral Rights</li>
	                             <ol>
	                             <li style={{textAlign:"left"}}>
	                             	   In submitting for your Videos to be assessed by Con Brio Examinations, you:
				                           <br/>
																	 a)assign the whole and absolute copyright of the Videos to Con Brio Examinations:
																	 <br/>
																	 b)agree that Con Brio Examinations may modify the Videos as it sees fit:
																	 <br/>
																	 c)unconditionally waive all moral rights (as defined in the Copyright Act 1968) you may have in respect of the Videos.
	                             </li>
                               </ol>

                               <li style={{textAlign:"left"}}>Severability</li>
																	<div style={{textAlign:"left"}}>
																	The invalidity, illegality or unenforceability of any provisions of this Assignment shall not affect the continuation in force of the remainder of this Assignment.
	                                </div>
	                              <li style={{textAlign:"left"}}>Applicable Law</li>
																	<div style={{textAlign:"left"}}>
																	This Agreement must be read and construed according to the laws of New South Wales, Australia, without regard to its conflict of law provisions.
                                  </div>
                             </ol>
          </>
        </Dialog.Body>
      </Dialog>
    )
  };

  const renderForm = () => {
    const onSubmit = (e) => {
      e.preventDefault();
    };

    const checkCommonParams = () => {
      const pieces = form.pieces;

      let pieceList = [pieces[0], pieces[1], pieces[2]];
      for(let i = 0; i < pieceList.length; i++) {
        const piece = pieceList[i];
        if (!piece.musicTypeId) {
          setPiece(i, {message: 'invalid musicTypeId'});
          return false;
        }
        if (!piece.musicTitleId) {
          setPiece(i, {message: 'invalid musicTitleId'});
          return false;
        }
        if (!piece.videoLink) {
          setPiece(i, {message: t('upload.invalidUrl')});
          return false;
        }
        if (piece.message) {
          return false;
        }
      }
      return true;
    }

    const onClickSaveBtn = async () => {
      let isOK = checkCommonParams();
      if (!isOK) {
        return;
      }

      const transformPiece = (piece) => {
        return {
          ...piece,
          video: {
            type: 'link',
            link: piece.videoLink,
          }
        };
      };

      let examId = examDetails?.gradeId;
      let params = {
        remarks: form.remarks,
        comments: form?.comments,
        photoIdUri: form.photoIdUri,
        pieceDetails: [
          transformPiece(form.pieces[0]),
          transformPiece(form.pieces[1]),
          transformPiece(form.pieces[2]),
        ]
      };
      fetchSaveExamDetails(
        examId,
        params, 
      );
    };

    const onClickSubmitBtn = async () => {
      let isOK = checkCommonParams();
      if (!isOK) {
        return;
      }

      const transformPiece = (piece) => {
        return {
          ...piece,
          video: {
            type: 'link',
            link: piece.videoLink,
          }
        };
      };

      let examId = examDetails?.gradeId;
      let params = {
        remarks: form.remarks,
        comments: form?.comments,
        photoIdUri: form.photoIdUri,
        pieceDetails: [
          transformPiece(form.pieces[0]),
          transformPiece(form.pieces[1]),
          transformPiece(form.pieces[2]),
        ]
      };
      fetchSubmitExam(
        examId,
        params, 
      );
    };

    return (
      <Loading loading={loading?.examDetails}>
      <Form model={form} labelWidth="120" onSubmit={onSubmit}>
        <Form.Item label={t('student')} required>
          <span>{examDetails?.studentFirstName + ' ' + examDetails?.studentLastName}</span>
        </Form.Item>
        <Form.Item label={t('instrument')} required>
          <span>{examDetails?.instrumentName}</span>
        </Form.Item>
        <Form.Item label={t('grade')} required>
          <span>{examDetails?.grade}</span>
        </Form.Item>
        {
          examDetails?.gradeId > 8 && examDetails?.gradeId < 11 && (
            <>
              <Form.Item label={t('editExamDetail.photoIDReq')}>
                <span>{t('editExamDetail.photoIDReqText')}</span>
              </Form.Item>
              <Form.Item label={t('editExamDetail.addReq')}>
                <Layout.Row>
                  <span>{t('editExamDetail.addReqText1', { mins: 20 })}</span>
                </Layout.Row>
                <Layout.Row style={{"lineHeight": "24px"}}>
                  <span>{t('editExamDetail.addReqText2')}</span>
                </Layout.Row>
                <Layout.Row style={{"lineHeight": "24px"}}>
                  <span>{t('editExamDetail.addReqText3')}</span>
                </Layout.Row>
              </Form.Item>
            </>
          )
        }
        {
          examDetails?.gradeId > 10 && examDetails?.gradeId < 13 && (
            <>
              <Form.Item label={t('editExamDetail.photoIDReq')}>
                <span>{t('editExamDetail.photoIDReqText')}</span>
              </Form.Item>
              <Form.Item label={t('editExamDetail.addReq')}>
                <Layout.Row>
                  <span>{t('editExamDetail.addReqText1', { mins: 30 })}</span>
                </Layout.Row>
                <Layout.Row style={{"lineHeight": "24px"}}>
                  <span>{t('editExamDetail.addReqText2')}</span>
                </Layout.Row>
                <Layout.Row style={{"lineHeight": "24px"}}>
                  <span>{t('editExamDetail.addReqText3')}</span>
                </Layout.Row>
              </Form.Item>
            </>
          )
        }
        {
          examDetails?.gradeId > 8 && <>
            <Form.Item label={t('editExamDetail.photoID')} required>
              {renderAvatarUploader()}
            </Form.Item>
          </>
        }
        { renderPiece(0) }
        { renderPiece(1) }
        { renderPiece(2) }
        {
          <Form.Item label={t('editExamDetail.specialRequestsComments')}>
            <Input
              type="textarea"
              value={form.comments}
              maxLength={4000}
              onChange={(v) => setForm({...form, comments: v})}
            />
          </Form.Item>
        }
        {
          examDetails?.gradeId > 8 && <Form.Item label={t('editExamDetail.remarks')}>
            <Input
              type="textarea"
              value={form.remarks}
              maxLength={4000}
              onChange={(v) => setForm({...form, remarks: v})}
            />
          </Form.Item>
        }
        <Form.Item required>
          <Layout.Row type="flex" justify="start" gutter={0}>
            <Layout.Col span="1">
              <Checkbox
                checked={form?.agree}
                onChange={(v) => setForm({ ...form, agree: v})}
              >
              </Checkbox>
            </Layout.Col>
            <Layout.Col span="20">
              <Button
                type="text"
                onClick={() => setAgreementDialogVisible(true)}
              >
                {t('signup.agreeToTerms')}
              </Button>
            </Layout.Col>
          </Layout.Row>
        </Form.Item>
        {
          message &&
            <Form.Item>
              <Alert title={message} type="error" closable={false} />
            </Form.Item>
        }
        <Form.Item style={{'marginBottom': '10px'}}>
          <Button type="primary" nativeType="submit" disabled={false} loading={loading?.save} onClick={onClickSaveBtn}>{t('save')}</Button>
          <Button type="primary" nativeType="submit" disabled={!form?.agree} loading={loading?.submit} onClick={onClickSubmitBtn}>{t('submit')}</Button>
         </Form.Item>
      </Form>
      </Loading>
    );
  };

  return (
    <Container>
      <Grid>
        <div style={{ 'marginLeft': '0%', 'marginRight': '0%', 'marginTop': '2%', 'marginBottom': '2%', "paddingTop": '10px', "paddingBottom": '10px'}}>
          {renderForm()}
          {renderAgreementDialog()}
       </div>
      </Grid>
    </Container>
 );
};

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {
})(EditExamDetailsScreen);
