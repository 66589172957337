// import ApiHelper from '../helpers/ApiHelper';
import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

export const saveAudioSettingsForStudent = (params) => {
  return YoubrioApiHelper.postRoot(`student-save-audio-settings?${params}`, true); // _token
};

export const saveAudioSettingsForTeacher = (params) => {
  return YoubrioApiHelper.postRoot(`teacher-save-audio-settings?${params}`, true);
};

