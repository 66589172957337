import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import RegistrationReducer from './RegistrationReducer';
import CommonReducer from './CommonReducer';
import UserReducer from './UserReducer';
import YoubrioProfileReducer from './YoubrioProfileReducer';
import FundReducer from './FundReducer';
import OrganisationReducer from './OrganisationReducer';
import DialogReducer from './DialogReducer';

export default combineReducers({
  auth: AuthReducer,
  registration: RegistrationReducer,
  common: CommonReducer,
  user: UserReducer,
  youbrioProfile: YoubrioProfileReducer,
  fund: FundReducer,
  organisation: OrganisationReducer,
  dialog: DialogReducer
});
