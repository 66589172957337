module.exports = {
  STRING: {
    TOKEN: 'token',
    PAYPAL_URL_SCHEME: 'com.kudosit.myexamsmobile.payments',
    ALIPAY_URL_SCHEME: 'com.kudosit.myexamsmobile.alipay',
    DEEPLINK_URL_SCHEME: 'youbrioapp',
    GOOGLE_API_KEY: 'AIzaSyDIlvH4Hu71rhYAzeetK6aux7iafJ2bwC4',
    IOS_GOOGLE_OAUTH_CLIENT_ID: '996694290454-ugd5kb4d4ft7gtv04ikeh4o75450jns8.apps.googleusercontent.com',
    SERVER_GOOGLE_OAUTH_CLIENT_ID: '996694290454-092ikcnrveqtol12rk3766ttkv510qm5.apps.googleusercontent.com',
    WECHAT_APPID: 'wx69271ab2b921c6de',
    FACEBOOK_URL_SCHEME: 'fb495781547443806',
    GOOGLE_URL_SCHEME: 'com.googleusercontent.apps.996694290454-ugd5kb4d4ft7gtv04ikeh4o75450jns8',
    ONESIGNAL_APP_ID: '2f692006-1d3d-42e0-a511-2222d0282075',
    BT_MERCHANT_NAME: 'Con Brio Examinations PTY LTD',
    APP_STATE: {
      ACTIVE: 'active',
      BACKGROUND: 'background',
      INACTIVE: 'inactive'
    },
    EXAM_STATUS: {
      SI: 'SI',
      SC: 'SC',
      RW: 'RW',
      MI: 'MI',
      MC: 'MC',
      EA: 'EA'
    },
    EXAM_TYPE: {
      CURRENT: 'currentExams',
      REWORK: 'reworkExams',
      APPEAL: 'appealExams',
      COMPLETED: 'completedExams',
      OPEN: 'openExams',
      SUBMITTED: 'submittedExams'
    },
    ROLE: {
      TEACHER: 'Teacher',
      STUDENT: 'Student',
      ADMIN: 'Admin',
      SUPPORT: 'Support',
      YOUBRIO_ADMIN: 'YoubrioAdmin'
    },
    GENDER: {
      MALE: 'male',
      FEMALE: 'female'
    },
    MUSIC_LIBRARY_SEARCH_OPTIONS: {
      INSTRUMENT_GRADE: 'instrumentAndGrade',
      MUSIC_NAME: 'musicName',
      COMPOSER: 'composer'
    },
    EVENT_RES_TYPE: {
      IMG_BACKGROUND: 'IBG',
      LINK_EVENT: 'LEV'
    },
    AS_KEY: {
      PHYSICAL_COUNTRY: 'PHYSICAL_COUNTRY',
      MSG: 'msg',
      CHATROOM: 'chat_rooms',
      READ_STATUS: 'chat_rooms@read_status',
      LAST_LOGIN_USER: 'last_login_user',
      UNAUTH_INIT_DATA: 'UNAUTH_INIT_DATA',
      LESSON_EVENT: 'lesson_event',
      GIFT: 'gift'
    },
    ALPHABET: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    RN_KEYCHAIN_DOWNGRADE_FIXED: 'RN_KEYCHAIN_DOWNGRADE_FIXED',
    AGREEMENT_V1_AGREED: 'AGREEMENT_V1_AGREED',
    TEACHER_STUDENT_RELATIONSHIP_TYPE: {
      PRIVATE: 'private',
      YOUBRIO_REFERRED: 'youbrio_referred',
      EXPLORE: 'explore'
    }
  },
  INTEGER: {
    GRADE_ID: {
      PRELIMINARY: 0,
      GRADE1: 1,
      GRADE2: 2,
      GRADE3: 3,
      GRADE4: 4,
      GRADE5: 5,
      GRADE6: 6,
      GRADE7: 7,
      GRADE8: 8,
      PCBE: 9,
      ACBE: 10,
      LCBE: 11,
      FCBE: 12
    },
    SYLLABUS_REQUEST_STATUS: {
      SUBMITTED: 0,
      ACCEPTED: 1,
      REJECTED: 2
    },
    LESSON_NOTIF_HOUR_OFFSET: 24,
    NETWORK_STAT_CHECK_INTERVAL_MS: 30 * 1000
  },
  ERROR: {
    UNAUTHORIZED: 401,
    NOT_FOUND: 404
  },
  COLOR: {
    YOUBRIO_RED: '#C3262F',
    YOUBRIO_SECOND: '#EF6461',
    YOUBRIO_THIRD: '#E4B363',
    LOGGED_IN_BACKGROUND_COLOR: '#eaeaea',
    SUCCESS: '#00a651',
    FAILED: '#ff0000',
    WARNING: '#ff9c00',
    REQUIREMENT_TEXT_COLOR: '#00bfff',
    YOUBRIO_THEME_PRIMARY_COLOR: '#C0272D',
    LESSON_STATUS_COLOR: {
      CF: '#447AD0',
      CP: '#00A651',
      CSL: '#999999',
      CSM: '#999999',
      CT: '#999999',
      CTT: '#999999',
      IP: '#862D63',
      RJ: '#FF0000',
      RQ: '#FD6A02',
      EX: '#555555',
      CSE: '#999999',
      CL: '#999999'
    },
    YOUBRIO_RED_TRANSPARENT: 'rgba(195, 38, 47, 0.25)',
    YOUBRIO_RED_LIGHT: '#ffabaf',
    LIGHT_RED: 'rgba(255, 59, 48, 0.1)',
    RED: 'rgb(255, 59, 48)',
    ORANGE: 'rgb(255, 149, 0)',
    YELLOW: 'rgb(255, 204, 0)',
    GREEN: '#33CC66',
    TEAL_BLUE: 'rgb(90, 200, 250)',
    BLUE: 'rgb(0, 122, 255)',
    LIGHT_BLUE: 'rgb(237, 248, 255)',
    PURPLE: 'rgb(88, 86, 214)',
    PINK: 'rgb(255, 45, 85)',
    BLACK: 'rgb(34, 2, 0)',
    LIGHT_BLACK: 'rgb(50, 60, 70)',
    WHITE: 'rgb(255, 255, 255)',
    TRANSPARENT_GREY: 'rgba(55.7, 55.7, 57.6, 0.75)',
    SYSTEM_EXTRA_LIGHT_GRAY: '#EFEFF4',
    SYSTEM_LIGHT_GRAY: '#E5E5EA',
    SYSTEM_LIGHT_MID_GRAY: '#D1D1D6',
    SYSTEM_MID_GRAY: '#C7C7CC',
    SYSTEM_GRAY: '#8E8E93',
    FAINT_WARNING: '#FFFACD',
    FAINT_SUCCESS: '#D4FFDE',
    FAINT_FAILED: '#FFE2E2',
    COBALT_BLUE: '#3373bd',
    EMERALD_GREEN: '#1a9360',
    GHOST: '#f3f2f8',
  },
  INPUT: {
    HEIGHT: 52,
    EDIT_BLUE: '#185a9d',
    CONTENT_TEXT: 'rgba(0, 0, 0, 0.87)',
    ACTIVE_LABEL: 'rgba(228, 179, 99, 1.0)', //YOUBRIO_THIRD in RGB
    ACTIVE_BORDER: 'rgba(228, 179, 99, 1.0)',
    INACTIVE_LABEL: 'rgba(0, 0, 0, 0.6)',
    INACTIVE_BORDER: 'rgba(0, 0, 0, 0.1)'
  },
  URL: {
    DEV_SERVER: {
      CN: 'https://dev.conbrioexams.com/public/api/',
      GLOBAL: 'https://dev.conbrioexams.com/public/api/',
    },
    PROD_SERVER: {
      CN: 'https://myexams.conbrioexams.cn/public/api/',
      GLOBAL: 'https://myexams.conbrioexams.com/public/api/'
    }
  },
  WIZARD_TYPE: {
    BY_NAME: 'by_name',
    BY_QUESTION: 'by_question'
  },
  MSG_TYPE_ID: {
    NEW_PRIVATE_LESSON: 'NEW_PRIVATE_LESSON',
    VIDEO_CALLING: 'VIDEO_CALLING',
    CALLEE_CANCELLED: 'CALLEE_CANCELLED', // callee cancels the call before call connected
    CALLEE_ANSWERED: 'CALLEE_ANSWERED',
    CALL_ENDED: 'CALL_ENDED', // any party ended call after call connected, or caller ends call before callee answers,
    VIDEO_CALL_SYS_MSG: 'VIDEO_CALL_SYS_MSG',
    LESSON_REQUEST: 'LESSON_REQUEST',
    LESSON_REQUEST_TO_ADMIN: 'LESSON_REQUEST_TO_ADMIN', // if student cannot chat to teacher, lesson request message is send to admin
  },
  ANY: 'any',
  OTHER: 'other',
  SORT: {
    RELEVANCE: 'relevance',
    LAST_ONLINE: 'last_online',
    HIGHEST_RATING: 'highest_rating',
    HIGHEST_LESSON_FEE: 'highest_lesson_fee',
    LOWEST_LESSON_FEE: 'lowest_lesson_fee'
  },
  GIFT_EVENT: {
    SEND_GIFT: 'send_gift',
    SEND_MEET_GIFT: 'send_meet_gift'
  },
  PRACTICE_ROOM_EVENT: {
    START_PRACTICE_MODE: 'start_practice_mode',
    START_TUTOR_MODE: 'start_tutor_mode',
    CONNECT_AUDIO: 'connect_audio',
    DISCONNECT_AUDIO: 'disconnect_audio',
    REFRESH_PARTICIPANT_LIST: 'refresh_participant_list',
    NO_AUDIO_ALERT: 'no_audio_alert',
    RESCUE_TR_STUDENT: 'rescue_tr_student'
  },
  SOCKETIO: {
    DISCONNECT_REASON: {
      IO_CLI_DC: 'io client disconnect',
      IO_SVR_DC: 'io server disconnect'
    },
    STATUS: {
      RECONNECTING: 1,
      DISCONNECTED: 2
    }
  },
  APP_LOG: {
    TAG: {
      TUTOR_ROOM_SMARTBOARD: 'tr_sboard'
    }
  }
};
