import React, {
    // useEffect,
    // useState
  } from 'react';
  import { connect } from 'react-redux';
  // import { useTranslation } from 'react-i18next';
  import 'element-theme-default';
  import {
    Container,
    Grid,
  } from '@material-ui/core';
  import Iframe from 'react-iframe';
  
  // import AppHelper from '../../helpers/AppHelper';
  import GLOBAL from '../../helpers/GLOBAL';
  import config from '../../config';
  const env = process.env.REACT_APP_ENV || 'dev';
  const region = process.env.REACT_APP_REGION || 'global';
  
  const EvaluationFeeStuctureScreen = (props) => { 
    // const { t } = useTranslation();
    // const {
    //   history,
    // } = props;
    // const isUserInChina = false; // AppHelper.isUserInChina(); // not work
    const isCNLang = GLOBAL.get('lang')?.includes('cn');
    const hostWww = config.cbeWwwUrl[env][region];
  
    const url = isCNLang ?
      hostWww + "performance-evaluations-fee?&lang=CN&light=1"
      :
      hostWww + "performance-evaluations-fee?light=1";
  
    const renderIframe = () => {
      return (
        <>
          <Iframe
            frameBorder={0}
            height="100%"
            width="100%"
            position="absolute"
            scrolling='yes'
            url={url}
          />
        </>
      )
    };
  
    return (
      <Container maxWidth="xl" style={{"paddingLeft": '0px', "paddingRight": '0px'}}>
        <Grid container spacing={0} direction="column" justify="center">
          <Grid>
            <div style={{ 'marginLeft': '0%', 'marginRight': '0%', 'marginTop': '0%', 'marginBottom': '0%', "paddingTop": '0px', "paddingBottom": '1px'}}>
              { renderIframe() }
            </div>
          </Grid>
        </Grid>
      </Container>
   );
  };
  
  const mapStateToProps = (state) => {
    return {
    };
  };
  
  export default connect(mapStateToProps, {
  })(EvaluationFeeStuctureScreen);
  