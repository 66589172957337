import ApiHelper from '../helpers/ApiHelper';
import AppHelper from '../helpers/AppHelper';

export const getLibraryMusicType = ({ instrumentId, gradeId }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`musiclibrary/musictypes?lang=${lang}&instrumentId=${instrumentId}&gradeId=${gradeId}`, true);
};

export const getLibraryInstruments = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`musiclibrary/instruments?lang=${lang}`, true);
};

export const getLibraryByMusicName = ({ musicName }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`musiclibrary/search?lang=${lang}&musicName=${musicName}`, true);
};

export const getLibraryByComposer = ({ composer }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`musiclibrary/search?lang=${lang}&composer=${composer}`, true);
};

export const getLibraryByInstrumentAndGrade = ({ instrumentId, gradeId, musicTypeId }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`musiclibrary/search?lang=${lang}&instrumentId=${instrumentId}&gradeId=${gradeId}&musicTypeId=${musicTypeId}`, true);
};

export const getRecommendedLibraryByInstrumentAndGrade = ({ instrumentId, gradeId }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`musiclibrary/search?lang=${lang}&instrumentId=${instrumentId}&gradeId=${gradeId}&recommended=true`, true);
};

export const getSheetMusicPrice = ({ syllabusId }) => {
  return ApiHelper.get(`sheet-music/price?sid=${syllabusId}`, true);
};

export const purchaseSheetMusicViaBraintree = ({
  price,
  currencyCode,
  sheetMusicId,
  nonce
}) => {
  const params = {
    price,
    currencyCode,
    smid: sheetMusicId,
    nonce
  };
  return ApiHelper.post('sheet-music-purchase', params, true);
};
