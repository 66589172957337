// Auth
export const UPDATE_AUTH_STATES = 'update_auth_error';
export const LOGOUT_USER_SUCCESS = 'logout_user_success';

// Registration
export const UPDATE_FIELD = 'update_field';
export const UPDATE_FIELD_ERROR = 'update_field_error';
export const VALIDATE_FIELD = 'validate_field';
export const UPDATE_MULTIPLE_FIELD_ERROR = 'update_multiple_fields_error';
export const RESET_REGISTRATION_FIELD = 'reset_registration_field';
export const UPDATE_REGISTRATION_MODAL_STATE = 'update_registration_modal_state';

// Common
export const UPDATE_COMMON_STATES = 'update_common_states';
export const UPDATE_SNACKBAR_STATES = 'update_snackbar_states';

// User
export const SET_USER_PROFILE = 'set_user_profile';
export const UPDATE_USER_STATE = 'update_user_state';
export const UPDATE_USER_PROFILE_STATES = 'update_user_profile_states';
export const RESET_USER_PROFILE_ERROR = 'reset_user_profile_error';
export const UPDATE_USER_PROFILE_FIELD_ERROR = 'update_user_profile_field_error';
export const UPDATE_USER_MODAL_STATE = 'update_user_modal_state';
export const SET_STUDENT_LIST = 'set_student_list';
export const RESET_STUDENT_LIST = 'reset_student_list';
export const SET_STUDENT_PROFILE = 'set_student_profile';
export const RESET_STUDENT_PROFILE = 'reset_student_profile';
export const UPDATE_STUDENT_LIST_LOADING = 'update_student_list_loading';
export const UPDATE_STUDENT_PROFILE_FIELD_ERROR = 'update_student_profile_field_error';
export const UPDATE_STUDENT_PROFILE_STATES = 'update_student_profile_states';
export const SET_USER_STATE = 'set_user_state';
export const UPDATE_YOUBRIO_PROFILE_STATE = 'update_youbrio_profile_state';
export const UPDATE_USER_YOUBRIO_PROFILE_STATE = 'update_user_youbrio_profile_state';
export const RESET_USER_YOUBRIO_PROFILE_STATE = 'reset_user_youbrio_profile_state';
export const UPDATE_EDIT_EMAIL_FIELD_STATE = 'update_edit_email_field_state';
export const RESET_EDIT_EMAIL_FIELD_STATE = 'reset_edit_email_field_state';
export const UPDATE_EDIT_EMAIL_FIELD_ERROR = 'update_edit_email_field_error';

// Exam
export const UPDATE_VIDEO_LIST = 'update_video_list';
export const UPDATE_EXAM_FIELD = 'update_exam_field';
export const UPDATE_EXAM_VIDEO_FIELD = 'update_exam_video_field';
export const UPDATE_EXAM_LIST = 'update_exam_list';
export const UPDATE_EXAM_LOADING = 'update_exam_loading';
export const UPDATE_EXAM_STATES = 'update_exam_states';
export const RESET_EXAM_DETAILS = 'reset_exam_details';
export const UPDATE_EXAM_DETAILS_ERROR = 'update_exam_details_error';
export const UPDATE_NEW_EXAM_STATE = 'update_new_exam_state';
export const RESET_NEW_EXAM_STATE = 'reset_new_exam_state';
export const UPDATE_NEW_EXAM_STATE_ERROR = 'update_new_exam_state_error';
export const UPDATE_EXAM_DETAILS_FOR_APPEAL = 'update_exam_details_for_appeal';
export const RESET_EXAM_DETAILS_FOR_APPEAL = 'reset_exam_details_for_appeal';
export const RESET_PURCHASE_CERT_DETAILS = 'reset_purchase_cert_details';
export const RESET_EXAM_MODAL = 'reset_exam_modal';

// Payment
export const UPDATE_PAYMENT_HISTORY_LIST = 'update_payment_history_list';
export const UPDATE_PAYMENT_HISTORY_LOADING = 'update_payment_history_loading';
export const UPDATE_PAYMENT_STATES = 'update_payment_states';
export const SHOW_PAYMENT_MODAL = 'show_payment_modal';
export const HIDE_PAYMENT_MODAL = 'hide_payment_modal';

// Syllabus
export const UPDATE_MUSIC_PIECE_REQUEST_LIST = 'update_music_piece_request_list';
export const UPDATE_MUSIC_PIECE_REQUEST_LOADING = 'update_music_piece_request_loading';
export const UPDATE_MUSIC_TYPE_LIST = 'update_music_type_list';
export const UPDATE_NEW_MUSIC_PIECE_FIELD = 'update_new_music_piece_field';
export const UPDATE_NEW_MUSIC_PIECE_FIELD_ERROR = 'update_new_music_piece_field_error';
export const RESET_NEW_MUSIC_PIECE_STATE = 'reset_new_music_piece_state';

// LegalStatement
export const UPDATE_LEGAL_STATEMENT_STATE = 'update_legal_statement_state';

// MusicLibrary
export const UPDATE_MUSIC_LIBRARY_STATE = 'update_music_library_state';
export const UPDATE_MUSIC_LIBRARY_SEARCH_INPUT = 'update_music_library_search_input';
export const RESET_MUSIC_LIBRARY_STATE = 'reset_music_library_state';
export const UPDATE_MUSIC_LIBRARY_SEARCH_FIELD_ERROR = 'update_music_library_search_field_error';

// PieceEvaluation
export const UPDATE_NEW_PIECE_EVALUATION_FIELD = 'update_new_piece_evaluation_field';
export const UPDATE_NEW_PIECEEVALUATION_STATE_ERROR = 'update_new_pieceevaluation_state_error';
export const UPDATE_PIECE_EVALUATION_STATE = 'update_piece_evaluation_state';
export const UPDATE_EDIT_PIECE_EVALUATION_FIELD = 'update_edit_piece_evaluation_field';
export const UPDATE_PIECE_EVALUATION_MODAL_STATE = 'update_piece_evaluation_modal_state';
export const RESET_PIECE_EVALUATION_MODAL = 'reset_piece_evaluation_modal';
export const RESET_NEW_PIECE_EVALUATION_FIELD = 'reset_new_piece_evaluation_field';
export const UPDATE_EDIT_PIECE_EVALUATION_FIELD_ERROR = 'update_edit_piece_evaluation_field_error';
export const RESET_EDIT_PIECE_EVALUATION_DETAILS = 'reset_edit_piece_evaluation_details';
export const RESET_EDIT_PIECE_EVALUATION_HAS_CHANGED = 'reset_edit_piece_evaluation_has_changed';
export const UPDATE_EDIT_PIECE_EVALUATION_VIDEO_FIELD = 'update_edit_piece_evaluation_video_field';

// Event
export const UPDATE_EVENT_STATE = 'update_event_state';
export const UPDATE_EVENT_DETAILS_STATE = 'update_event_details_state';
export const SHOW_EVENT_MODAL = 'show_event_modal';
export const HIDE_EVENT_MODAL = 'hide_event_modal';
export const UPDATE_NEW_EVENT_ENROLMENT_STATE = 'update_new_event_enrolment_state';
export const RESET_NEW_EVENT_ENROLMENT_STATE = 'reset_new_event_enrolment_state';
export const RESET_EVENT_DETAILS_STATE = 'reset_event_details_state';
export const UPDATE_EDIT_EVENT_ENROLMENT_STATE = 'update_edit_event_enrolment_state';
export const RESET_EDIT_EVENT_ENROLMENT_STATE = 'reset_edit_event_enrolment_state';
export const UPDATE_EDIT_EVENT_ENROLMENT_ERROR = 'update_edit_event_enrolment_error';

// Video
export const UPDATE_VIDEO_STATE = 'update_video_state';
export const UPDATE_TRENDING_VIDEOS_STATE = 'update_trending_videos_state';
export const UPDATE_SEARCH_VIDEOS_STATE = 'update_search_videos_state';
export const RESET_SEARCH_VIDEO_STATE = 'reset_search_video_state';
export const UPDATE_TRENDING_VIDEO_ITEM_STATE = 'update_trending_video_item_state';
export const UPDATE_HOME_VIDEO_ITEM_STATE = 'update_home_video_item_state';
export const UPDATE_SEARCH_VIDEO_ITEM_STATE = 'update_search_video_item_state';
export const UPDATE_MY_UPLOADED_VIDEOS_STATE = 'update_my_uploaded_videos_state';
export const UPDATE_MY_UPLOADED_VIDEO_ITEM_STATE = 'update_my_uploaded_video_item_state';
export const UPDATE_MY_LIKED_VIDEOS_STATE = 'update_my_liked_videos_state';
export const UPDATE_MY_LIKED_VIDEO_ITEM_STATE = 'update_my_liked_video_item_state';
export const UPDATE_USER_UPLOADED_VIDEOS_STATE = 'update_user_uploaded_videos_state';
export const UPDATE_USER_UPLOADED_VIDEO_ITEM_STATE = 'update_user_uploaded_video_item_state';
export const UPDATE_DEEP_LINK_VIDEO_ITEM_STATE = 'update_deep_link_video_item_state';
export const RESET_DEEP_LINK_VIDEO_ITEM_STATE = 'reset_deep_link_video_item_state';
export const UPDATE_FREE_UPLOAD_VIDEO_STATE = 'update_free_upload_video_state';
export const RESET_FREE_UPLOAD_VIDEO_STATE = 'reset_free_upload_video_state';
export const UPDATE_ONE_MIN_VIDEO_STATE = 'update_one_min_video_state';
export const SHOW_VIDEO_MODAL = 'show_video_modal';
export const HIDE_VIDEO_MODAL = 'hide_video_modal';

// Certificate
export const UPDATE_UPLOAD_CERTIFICATE_STATE = 'update_upload_certificate_state';
export const UPDATE_CERTIFICATE_STATE = 'update_certificate_state';
export const RESET_UPLOAD_CERTIFICATE_STATE = 'reset_upload_certificate_state';
export const UPDATE_UPLOADED_CERTIFICATES_STATE = 'update_uploaded_certificates_state';
export const RESET_UPLOADED_CERTIFICATES_STATE = 'reset_uploaded_certificates_state';

// YoubrioProfile
export const UPDATE_PROFILE_STATE = 'update_profile_state';
export const UPDATE_TEACH_PROFILE_STATE = 'update_teach_profile_state';
export const UPDATE_LEARN_PROFILE_STATE = 'update_learn_profile_state';
export const UPDATE_QUESTIONS_AND_ANSWERS_STATE = 'update_questions_and_answers_state';
export const UPDATE_EDIT_TEACHING__SUBJECTS_ITEM_STATE = 'update_edit_teaching_subjects_item_state';
export const UPDATE_NEW_TEACH_PROFILE_STATE = 'update_new_teach_profile_state';
export const UPDATE_NEW_LEARN_PROFILE_STATE = 'update_new_learn_profile_state';
export const UPDATE_TEACHER_DETAILS_STATE = 'update_teacher_details_state';
export const UPDATE_MY_ACHIEVEMENTS_STATE = 'update_my_achievements_state';
export const UPDATE_MY_ACHIEVEMENTS_ITEM_STATE = 'update_my_achievements_item_state';
export const UPDATE_OTHER_ACHIEVEMENTS_ITEM_STATE = 'update_other_achievements_item_state';
export const RESET_NEW_TEACH_PROFILE_STATE = 'reset_new_teach_profile_state';
export const RESET_TEACH_PROFILE_STATE = 'reset_teach_profile_state';
export const UPDATE_NEW_TEACH_PROFILE_ERROR = 'update_new_teach_profile_error';
export const UPDATE_OTHER_USER_ACHIEVEMENTS_STATE = 'update_other_user_achievemnets_state';
export const UPDATE_OTHER_USER_PROFILE_STATE = 'update_other_user_profile_state';
export const RESET_OTHER_USER_PROFILE_STATE = 'reset_other_user_profile_state';
export const UPDATE_REVIEW_STATE = 'update_review_state';
export const UPDATE_REVIEW_ITEM_STATE = 'update_review_item_state';
export const UPDATE_MY_FAVOURITE_TEACHER_STATE = 'update_my_favourite_teacher_state';
export const UPDATE_SUBJECT_STATE = 'update_subject_state';
export const UPDATE_ENDORSEMENT_BY_ME_STATE = 'update_endorsement_by_me_state';
export const UPDATE_MY_RELATED_TEACHER_STATE = 'update_my_related_teacher_state';
export const UPDATE_MY_RELATED_STUDENT_STATE = 'update_my_related_student_state';
export const UPDATE_SEARCH_STUDENT_LIST_STATE = 'update_search_student_list_state';
export const RESET_SEARCH_STUDENT_LIST_STATE = 'reset_search_student_list_state';

// FindTeacher
export const UPDATE_FIND_TEACHER_STATE = 'update_find_teacher_state';
export const UPDATE_SEARCH_RESULT_STATE = 'update_search_result_state';
export const RESET_SEARCH_RESULT_STATE = 'reset_search_result_state';
export const UPDATE_REFINE_STATE = 'update_refine_state';
export const UPDATE_REFINE_RESULT_STATE = 'update_refine_result_state';
export const CHANGE_SUBJECT_TYPE_STATE = 'change_subject_type_state';
export const UPDATE_SAVED_SEARCH_CRITERIA_STATE = 'update_saved_search_criteria_state';
export const RESET_SAVED_SEARCH_CRITERIA_STATE = 'reset_saved_search_criteria_state';

// Fund
export const UPDATE_FUND_STATE = 'update_fund_state';
export const UPDATE_FUND_SUMMARY_STATE = 'update_fund_summary_state';

// Notification
export const UPDATE_NOTIFICATION_STATE = 'update_notification_state';
export const UPDATE_NOTIFICATION_LIST_STATE = 'update_notification_list_state';
export const UPDATE_NOTIFICATION_ITEM_STATE = 'update_notification_item_state';

// Lesson
export const UPDATE_LESSON_STATE = 'update_lesson_state';
export const UPDATE_NEW_LESSON_STATE = 'update_new_lesson_state';
export const SHOW_LESSON_MODAL = 'show_lesson_modal';
export const HIDE_LESSON_MODAL = 'hide_lesson_modal';
export const UPDATE_SHARE_LESSON_MODAL = 'update_share_lesson_modal';
export const RESET_NEW_LESSON_STATE = 'reset_new_lesson_state';
export const UPDATE_MY_TIMETABLE_STATE = 'update_my_timetable_state';
export const RESET_MY_TIMETABLE_STATE = 'reset_my_timetable_state';
export const UPDATE_BOOK_NEW_LESSON_STATE = 'update_book_new_lesson_state';
export const UPDATE_BOOK_NEW_LESSON_DATE_SLOT_OBJ_STATE = 'update_book_new_lesson_date_slot_obj_state';
export const RESET_BOOK_NEW_LESSON_STATE = 'reset_book_new_lesson_state';
export const UPDATE_TEACHER_LESSON_BOOKING_LIST_STATE = 'update_teacher_lesson_list_state';
export const RESET_TEACHER_LESSON_BOOKING_LIST_STATE = 'reset_teacher_lesson_list_state';
export const UPDATE_STUDENT_LESSON_BOOKING_LIST_STATE = 'update_student_lesson_booking_list_state';
export const RESET_STUDENT_LESSON_BOOKING_LIST_STATE = 'reset_student_lesson_booking_list_state';
export const UPDATE_LESSON_OPTIONS_STATE = 'update_lesson_options_state';
export const UPDATE_EDIT_LESSON_DETAIL_STATE = 'update_edit_lesson_detail_state';
export const RESET_EDIT_LESSON_DETAIL_STATE = 'reset_edit_lesson_detail_state';
export const RESET_BOOKING_SLOT_DETAIL_STATE = 'reset_booking_slot_detail_state';
export const UPDATE_BOOKING_SLOT_CHANGE_REQUEST_STATE = 'update_booking_slot_change_request_state';
export const RESET_BOOKING_SLOT_CHANGE_REQUEST_STATE = 'reset_booking_slot_change_request_state';
export const UPDATE_IMMEDIATE_LESSSON_STATE = 'update_immediate_lesson_state';
export const RESET_IMMEDIATE_LESSON_STATE = 'reset_immediate_lesson_state';

// LessonSession
export const UPDATE_LESSON_SESSION_STATE = 'update_lesson_session_state';
export const RESET_LESSON_ROOM_INFO_STATE = 'reset_lesson_room_info_state';
export const UPDATE_LIVE_ANNOTATION_STATE = 'update_live_annotation_state';
export const CREATE_NEW_ANNOTATION_SESSION = 'create_new_annotation_session';
export const UPDATE_ANNOTATION_SESSION_STATE = 'update_annotation_session_state';
export const RESET_ANNOTATION_SESSIONS = 'reset_annotation_sessions';
export const UPDATE_VIDEO_CALL_STREAM_STATE = 'update_video_call_stream_state';
export const RESET_VIDEO_CALL_STREAM_STATE = 'reset_video_call_stream_state';
export const UPDATE_SMARTBOARD_TOOL_STATE = 'update_smartboard_tool_state';
export const UPDATE_JITSI_CONFIG_STATE = 'update_jitsi_config_state';
export const RESET_JITSI_CONFIG_STATE = 'reset_jitsi_config_state';
export const UPDATE_JITSI_CONFIG_INDIVIDUAL_SETTING = 'update_jitsi_config_individual_setting';

// Organisation
export const UPDATE_ORG_STATE = 'update_org_state';
export const UPDATE_NEW_ORG_STATE = 'update_new_org_state';
export const RESET_NEW_ORG_STATE = 'reset_new_org_state';
export const SHOW_ORG_MODAL = 'show_org_modal';
export const HIDE_ORG_MODAL = 'hide_org_modal';
export const UPDATE_SCHOOL_PROFILE_STATE = 'update_school_profile_state';
export const UPDATE_SCHOOL_ACHIEVEMENTS_STATE = 'update_school_achievements_state';
export const UPDATE_SCHOOL_ACHIEVEMENTS_ITEM_STATE = 'update_school_achievements_item_state';
export const RESET_SCHOOL_PROFILE_STATE = 'reset_school_profile_state';
export const RESET_SCHOOL_ACHIEVEMENTS_STATE = 'reset_school_achievements_state';
export const UPDATE_SCHOOL_PROFILE_TEACHERS_STATE = 'update_school_profile_teachers_state';
export const RESET_SCHOOL_PROFILE_TEACHERS_STATE = 'reset_school_profile_teachers_state';
export const UPDATE_SCHOOL_PROFILE_STUDENTS_STATE = 'update_school_profile_students_state';
export const RESET_SCHOOL_PROFILE_STUDENTS_STATE = 'reset_school_profile_students_state';
export const UPDATE_NEW_ORG_FEE_CATEGORY_STATE = 'update_new_org_fee_category_state';
export const RESET_NEW_ORG_FEE_CATEGORY_STATE = 'reset_new_org_fee_category_state';
export const UPDATE_ORG_FEE_TEACHER_LIST_ADMIN_STATE = 'update_org_fee_teacher_list_admin_state';
export const RESET_ORG_FEE_TEACHER_LIST_ADMIN = 'reset_org_fee_teacher_list_admin';
export const UPDATE_ORG_FEE_CATEGORY_LIST_STATE = 'update_org_fee_category_list_state';
export const UPDATE_ORG_FEE_CATEGORY_EDIT_STATE = 'update_org_fee_category_edit_state';
export const RESET_ORG_FEE_CATEGORY_EDIT_STATE = 'reset_org_fee_category_edit_state';

// Artical
export const UPDATE_ARTICLE_STATE = 'update_article_state';
export const UPDATE_ARTICLE_LIST_STATE = 'update_article_list_state';
export const UPDATE_OPENED_ARTICLE_STATE = 'update_opened_article_state';
export const RESET_OPENED_ARTICLE_STATE = 'reset_opened_article_state';
export const RESET_ARTICLE_STATE = 'reset_article_state';

// Chat
export const UPDATE_CHAT_STATE = 'update_chat_state';
export const UPDATE_CHAT_LIST_STATE = 'update_chat_list_state';
export const RESET_CHAT_STATE = 'reset_chat_state';

// Gift
export const UPDATE_GIFT_STATE = 'update_gift_state';
export const RESET_GIFT_STATE = 'reset_gift_state';

// PracticeRoom
export const UPDATE_PRACTICE_ROOM_STATE = 'update_practice_room_state';
export const RESET_PRACTICE_ROOM_STATE = 'reset_practice_room_state';
export const UPDATE_SUBSCRIPTION_PLANS_STATE = 'update_subscription_plans_state';
export const UPDATE_CONTROL_ROOM_STATE = 'update_control_room_state';

// PracticeRoomTeacher
export const UPDATE_PRACTICE_ROOM_TEACHER_LIST_STATE = 'update_practice_room_teacher_list_state';
export const RESET_PRACTICE_ROOM_TEACHER_LIST_STATE = 'reset_practice_room_teacher_list_state';

// PracticeTutorReview
export const UPDATE_PRACTICE_TUTOR_REVIEW_LIST_STATE = 'update_practice_tutor_review_list_state';
export const RESET_PRACTICE_TUTOR_REVIEW_LIST_STATE = 'reset_practice_tutor_review_list_state';

// Order
export const UPDATE_ORDER_STATE = 'update_order_state';
export const RESET_ORDER_DETAIL_STATE = 'reset_order_detail_state';

// Dialog
export const OPEN_DIALOG = 'open_dialog';
export const CLOSE_DIALOG = 'close_dialog';
export const APP_LOADING_STARTED = 'app_loading_started';
export const APP_LOADING_FINISHED = 'app_loading_finished';

// PracticeRoomPass
export const UPDATE_PRACTICE_ROOM_PASS_STATE = 'update_practice_room_pass_state';
export const RESET_PRACTICE_ROOM_PASS_STATE = 'reset_practice_room_pass_state';

