import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import {
  Grid,
  // GridList,
  // Paper,
  Typography,
  // Avatar,
  // CircularProgress,
  // Collapse,
  // IconButton,
  // Button,
  // Badge,
  Container,
} from '@material-ui/core';
// import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Layout,
  Card,
  Button,
  Loading,
} from 'element-react';
import 'element-theme-default';

import {
  // UserList,
  // YoubrioCalendar,
  // AlertDialog,
} from '../common';
import {
} from '../../actions';
import {
  getPsSummary as getPsSummaryApi,
} from '../../api';

import './DashScreen.css';

// import AppHelper from '../../helpers/AppHelper';
import GLOBAL from '../../helpers/GLOBAL';
import CONSTANT from '../../helpers/ConstantHelper';

const useStyles = makeStyles(() => ({
  childContainer: {
    marginTop: 40,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  announcementTitle: {
    color: 'orange',
    fontSize: 15,
    alignItems: 'center',
    justifyContent: 'center'
  },
  announcementContainer: {
    whiteSpace: 'pre-line',
    borderWidth: 1,
    border: '1px solid lightgrey',
    borderRadius: '5px',
    marginLeft: 10,
    marginRight: 10,
    cursor: 'pointer',
    backgroundColor: 'white'
  }
}));

const DashScreen = (props) => { 
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    history,
  } = props;

  useEffect(() => {
    // document.title = t('youbrio');
    fetchSummary();
  }, []);

  const user = GLOBAL.get('user');
  const {
    cbeRole,
  } = user;
  // const isNewLaunch = GLOBAL.get('isNewLaunch');
  // const isTeacherRole = cbeRole && cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER);
  // const isStudentRole = cbeRole && cbeRole.includes(CONSTANT.STRING.ROLE.STUDENT);

  // const [isNoticeExpanded, setIsNoticeExpanded] = React.useState(false);
  // const [banners, setBanners] = React.useState(null);
  const [summaryLoading, setSummaryLoading] = React.useState(false);
  const [summary, setSummary] = React.useState({});

  const fetchSummary = async () => {
    try {
      setSummaryLoading(true);
      const res = await getPsSummaryApi();
      if (res && res.data && res.data.data) {
        setSummary(res.data.data);
      } else {
        console.warn(res);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSummaryLoading(false);
    }
  };

  /*const renderAnnouncement = () => {
    return (
      <div
        className={`px-2 py-2 ${classes.announcementContainer}`}
        onClick={() => setIsNoticeExpanded(!isNoticeExpanded)}
      >
        <Typography component={'div'} className={isNoticeExpanded ? 'expanded-note' : 'collapsed-note'} style={{ color: 'grey', position: 'relative' }}>
          <Typography component={'div'} className={classes.announcementTitle}>
            <NotificationsActiveIcon  style={{ fill: 'orange', fontSize: 14, marginRight: '5px' }} />  
            {t('tutorRoom.announcement')}
          </Typography>
          {t('dash.announcement')}
          <div style={{ position: 'absolute', bottom: -5, right: 0 }} >
            {
              !isNoticeExpanded
                ? <KeyboardArrowDownIcon style={{ fill: '#000', fontSize: 18 }} />
                : <KeyboardArrowUpIcon style={{ fill: '#000', fontSize: 18 }} />
            }
          </div>
        </Typography>
      </div>
    );
  };*/

  /*const renderBanners = () => {
    if (!banners || !banners.tutorRoomBanner || !banners.tutorRoomBanner.url) {
      return null;
    }

    return (
      <Box mx={2} mt={2} component={Link} to='/tutor-room'>
        <img src={banners.tutorRoomBanner.url} width="100%" alt={'banner-image: ' + banners.tutorRoomBanner.url} />
      </Box>
    );
  };*/

  const renderNotifications = () => {
    return (
      <Loading loading={summaryLoading}>
        <Card
          className="box-card"
          header={
            <Typography gutterBottom variant="h5" component="div">
              <span style={{ "lineHeight": "36px"}}>{t('dash.notifications.notifications')}</span>
            </Typography>
          }
        >
          <div className="text">
            <Button type="text" onClick={() => {history.push('/open-exam-list')}}>
            - {t('dash.notifications.examsAwaitSubmission', { num: summary?.countOpen || 0 })}
            </Button>
          </div>
          <div className="text">
            <Button type="text" onClick={() => {history.push('/open-exam-list')}}>
             - {t('dash.notifications.examsAwaitMarking', { num: summary?.countSubmit || 0 })}
            </Button>
          </div>
          <div className="text">
            <Button type="text" onClick={() => {history.push('/open-exam-list')}}>
             - {t('dash.notifications.examsCompletedMarking', { num: summary?.countClose || 0})}
            </Button>
          </div>
          <div className="text">
            <Button type="text" onClick={() => {history.push('/open-exam-list')}}>
             - {t('dash.notifications.examsAppealed', { num: summary?.countAppeal || 0 })}
            </Button>
          </div>
          <div className="text">
            <Button type="text" onClick={() => {history.push('/open-exam-list')}}>
             - {t('dash.notifications.evaluationsAwaitSubmission', { num: summary?.countPerformanceEvaluation || 0 })}
            </Button>
          </div>
          <div className="text item">
            <Button type="text" onClick={() => {history.push('/open-exam-list')}}>
             - {t('dash.notifications.evaluationsCompleted', { num: summary?.countPerformanceEvaluationMC || 0} )}
            </Button>
          </div>
        </Card>
      </Loading>
    );
  };

  const renderSummary = () => {
    if (!cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER)) {
      return null;
    }
    return (
      <Loading loading={summaryLoading}>
        <Card
          className="box-card"
          header={
            <Typography gutterBottom variant="h5" component="div">
              <span style={{ "lineHeight": "36px"}}>{t('dash.summary.teacherSummary')}</span>
            </Typography>
          }
        >
          <div className="text">
            {t('dash.summary.teacherSummaryText', { id: summary?.teacherID })}
          </div>
          <br/><br/>
          <div className="text">
            <Button type="text">
             - {t('dash.summary.numbersOfExamsRegistered')}: {summary?.totalRegistered}
            </Button>
          </div>
          <div className="text">
            <Button type="text">
             - {t('dash.summary.currentMonth')}: {summary?.currentMonth}
            </Button>
           </div>
          <div className="text">
            <Button type="text">
             - {t('dash.summary.currentQuarter')}: {summary?.currentQuater}
            </Button>
           </div>
          <div className="text">
            <Button type="text">
             - {t('dash.summary.currentFinancialYear')}: {summary?.resultsFYear}
            </Button>
           </div>
        </Card>
      </Loading>
    );
  };

  const renderTop = () => {
    return (
      <div style={{ 'width': '100%', 'marginLeft': '5%', 'marginRight': '5%', 'marginTop': '1%'}}>
        <Layout.Row type="flex" justify="space-around" gutter="20">
          <Layout.Col span="12">
            {renderNotifications()}
          </Layout.Col>
          <Layout.Col span="12">
            {renderSummary()}
          </Layout.Col>
        </Layout.Row>
      </div>
    )
  };

  return (
    <Container maxWidth="md">
      <Grid container className={classes.childContainer} direction="row" alignItems="center" spacing={3}>
{/*     <Grid container className={classes.childContainer} direction="column" alignItems="center" spacing={3}> */} 
        {/* { renderAnnouncement() } */}
        {/* renderBanners() */}
        { renderTop() }
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {
})(DashScreen);
