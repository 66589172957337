export const zhHans = {
  // START ---- components::common/LoadingScreen
  saving: '储存中...',
  submitting: '提交中...',
  uploading: '上传中...',
  downloading: '下载中...',
  //   END ---- components::common/LoadingScreen

  // START ---- components::common/PhotoPickerWithLabel
  camera: '相机',
  album: '相册',
  //   END ---- components::common/PhotoPickerWithLabel

  // START ---- components::common/FilePicker
  pdf: 'PDF 文件',
  //   END ---- components::common/FilePicker

  // START ---- components::common/FilterModalPickerWithLabel
  noMatches: '无匹配的结果...',
  searchHere: '在此搜寻...',
  //   END ---- components::common/FilterModalPickerWithLabel

  // START ---- components::LoginScreen
  email: '邮箱地址',
  password: '密码',
  login: '登录',
  forgotYourPassword: '忘记密码？',
  dontHaveAnyAccount: '还未注册帐号？',
  register: '注册帐号',
  ok: '好的',
  error: '错误',
  connectWithFb: 'Facebook帐号登录',
  connectWithWX: '微信帐号登录',
  signInWithGoogle: 'Google帐号登录',
  copyToClipBoard: '链接已复制到剪贴板',
  //   END ---- components::LoginScreen

  // START ---- components::RegisterRoleChoiceScreen
  registerAccount: '注册用户帐号',
  asTeacher: '老师注册',
  asStudent: '学生注册',
  //   END ---- components::RegisterRoleChoiceScreen

  // START ---- components::CandidateRegisterScreen
  state: '省份/州',
  selectstate: '请选择省份/州',
  registerAsCandidate: '学生注册',
  uploadProfilePhoto: '上传个人头像',
  confirmPassword: '确认密码',
  firstName: '名',
  lastName: '姓',
  gender: '请选择性别',
  male: '男',
  female: '女',
  other: '其他',
  dateOfBirth: '出生日期',
  addressLine1: '地址1',
  addressLine2: '地址2',
  suburb: '郊区',
  postcode: '邮政编码',
  country: '国家',
  region: '地区',
  location: '定位',
  allRegion: '所有地区',
  selectCountry: '请选择国家',
  landLine: '固定电话号码',
  mobile: '手机号码',
  teacherIdOptional: '老师证编码 (可略)',
  guardianName: '家长/监护人姓名',
  candidateRegistration: '学生注册',
  //   END ---- components::CandidateRegisterScreen

  // START ---- components::TeacherRegisterScreen
  registerAsTeacher: '老师注册',
  institutionType: '学院类型',
  selectInstitutionType: '请选择学院类型',
  qualification: '学历资格',
  teacherRegistration: '老师注册',
  //   END ---- components::TeacherRegisterScreen

  // START ---- components::QuickRegisterScreen
  accountRegistration: '注册帐号',
  referralCode: '推荐码',
  referralCodeOptional: '推荐码 (可选)',
  //   END ---- components::QuickRegisterScreen

  // START ---- components::LoadingModal
  loading: '载入中...',
  //   END ---- components::LoadingModal

  // START ---- components::HomeScreen/SideBar/CandidateSideBar
  exams: '考试列表',
  paymentHistory: '付款历史记录',
  musicPieceRequests: '曲目申请列表',
  musicLibrary: '音乐库',
  requestHelp: '请求帮助',
  logout: '退出',
  emailSubjectMyExamsCandidate: 'MyExams-学生APP',
  emailSubjectIssueReport: '错误报告',
  feeStructure: '考级费用',
  examGuidelines: '考级指南',
  newPerformanceEvaluation: '新级数评估',
  performanceEvaluations: '级数评估列表',
  diagnostics: '网络检测',
  //   END ---- components::HomeScreen/SideBar/CandidateSideBar

  // START ---- components::HomeScreen/SideBar/TeacherSideBar
  studentList: '学生列表',
  emailSubjectMyExamsTeacher: 'MyExams-老师APP',
  //   END ---- components::HomeScreen/SideBar/TeacherSideBar

  // START ---- components::HomeScreen/ExamPanel
  openExams: '开放中考试',
  submittedExams: '已提交考试',
  closedExams: '已完成考试',
  reworkExams: '重考考试',
  appealedExams: '已上诉考试',
  openPE: '开放中级数评估',
  closedPE: '已完成级数评估',
  //   END ---- components::HomeScreen/ExamPanel

  // START ---- components::CandidateHomeScreen
  myExamsForCandidate: '康柏尔考级 - 学员端',
  hello: '您好',
  newExam: '新考试',
  myProfile: '个人资料',
  newMusicPiece: '新曲目申请',
  confirm: '确认',
  cancel: '取消',
  exit: '退出',
  //   END ---- components::CandidateHomeScreen

  // START ---- components::CandidateProfileScreen
  candidateId: '学生证编码',
  teacherId: '老师证编码',
  updateProfile: '更新个人资料',
  confirmEmail: '确认邮箱地址',
  unsavedChanges: '未储存的更改',
  selectDateOfBirth: '请选择出生日期',
  //   END ---- components::CandidateProfileScreen

  // START ---- actions::UserActions
  profileUpdate: '更新个人资料',
  studentProfileUpdate: '更新学生个人资料',
  //   END ---- actions::UserActions

  // START ---- components::CandidateNewExamScreen
  instrument: '乐器',
  piano: '钢琴',
  violin: '小提琴',
  altoSaxophone: '中音萨克管',
  flute: '长笛',
  cello: '大提琴',
  viola: '中提琴',
  doubleBass: '低音提琴',
  vocal: '声乐',
  clarinet: '单簧管',
  oboe: '双簧管',
  selectInstrument: '请选择乐器',
  grade: '级别',
  selectGrade: '请选择级别',
  affiliationCode: '附属代码 (如果适用)',
  paymentAmount: '付款金额',
  proceedToPayment: '前往付款',
  examReportChineseTranslationService: '中文报告翻译服务',
  //   END ---- components::CandidateNewExamScreen

  // START ---- components::TnCScreen
  termsAndConditions: '条款和条件',
  submitExam: '提交考试',
  //   END ---- components::TnCScreen

  // START ---- components::CandidateAppealExamDetailsScreen
  remarks: '备注',
  submitAppeal: '提交上诉',
  cost: '用费',
  appealExam: '上诉考试',
  appealOption: '上诉选项',
  selectAppealOption: '请选择上诉选项',
  //   END ---- components::CandidateAppealExamDetailsScreen

  // START ---- components::CompletedExamDetailScreen
  appeal: '上诉',
  sendCertToMyEmail: '发证书至我的邮箱',
  buyCert: '购买证书',
  completedExamDetails: '已完成考试详情',
  candidateName: '学生',
  markedDate: '批改日期',
  sendReportToMyEmail: '发考试报告至我的邮箱',
  //   END ---- components::CompletedExamDetailScreen

  // START ---- helpers::AppHelper
  thisMonth: '这个月',
  lastMonth: '上个月',
  passWithHighDistinction: '特优',
  passWithDistinction: '优',
  passWithCredit: '良',
  pass: '及格',
  unsuccessful: '不及格',
  highDistinction: '特优',
  distinction: '优',
  credit: '良',
  yearsOld: '岁',
  yearsOldAndAbove: '岁或以上',
  yearsOldAndBelow: '岁或以下',
  upToMinutes: '不超过{0}分钟',
  noAgeLimit: '无年龄限制',
  countThousand: '{0}k',
  countMillion: '{0}m',
  countBillion: '{0}b',
  localTime: '当地时间',
  //   END ---- helpers::AppHelper

  // START ---- components::EditExamScreen/EditExamTab
  pasteVideoLink: '粘贴视频链接',
  recordAndUploadVideoNow: '录制并上传视频',
  tapToSelectVideo: '轻敲此处以选择视频',
  remove: '删除',
  piece: '曲目',
  details: '详情',
  musicType: '曲目类型',
  selectMusicType: '请选择曲目类型',
  musicTitle: '曲目名字',
  selectMusicTitle: '请选择曲目名字',
  searchHereOrSelectMusicTitle: '在此搜寻曲目...',
  video: '视频',
  next: '继续',
  uploadVideo: '上传视频',
  uploadPhoto: '上传照片',
  uploadedLink: '已上传的视频链接',
  reuploadVideo: '重新上传视频',
  //   END ---- components::EditExamScreen/EditExamTab

  // START ---- components::EditExamScreen/CandidateEditExamScreen
  piece1: '曲目1',
  piece2: '曲目2',
  piece3: '曲目3',
  piece4: '曲目4',
  piece5: '曲目5',
  summary: '概要',
  //   END ---- components::EditExamScreen/CandidateEditExamScreen

  // START ---- components::EditExamScreen/SummaryTab
  photoIdRequirement: '证件照条件',
  playingTimeRequirement: '演奏时间条件',
  photoId: '照片',
  save: '保存',
  proceedToSubmitExam: '前往提交考试',
  //   END ---- components::EditExamScreen/SummaryTab

  // START ---- components::MusicPieceListScreen/MusicPieceListItem
  by: '作曲家:',
  gradeRequested: '申请级别',
  dateSubmitted: '提交日期',
  submitted: '已提交',
  accepted: '已被接受',
  rejected: '已被拒绝',
  noRecordFound: '记录不存在',
  //   END ---- components::MusicPieceListScreen/MusicPieceListItem

  // START ---- components::MusicPieceListScreen
  reasonofRejection: '拒绝原因',
  //   END ---- components::MusicPieceListScreen

  // START ---- components::NewMusicPieceScreen
  composer: '作曲家',
  nameOfThePiece: '曲目名字',
  sampleVideoLink: '视频实例链接',
  uploadSheetMusic: '上传乐谱',
  request: '申请',
  //   END ---- components::NewMusicPieceScreen

  // START ---- components::PaymentHistoryListScreen/PaymentHistoryListItem
  paymentDate: '付款日期',
  paymentType: '付款类型',
  //   END ---- components::PaymentHistoryListScreen/PaymentHistoryListItem

  // START ---- components::PurchaseCertificateScreen
  purchaseCertificate: '购买证书',
  address: '地址',
  certificate: '证书',
  //   END ---- components::PurchaseCertificateScreen

  // START ---- components::ResetPasswordScreen
  resetPassword: '重设密码',
  sendPasswordResetLink: '发送重设密码链接',
  //   END ---- components::ResetPasswordScreen

  // START ---- components::VideoGalleryScreen/VideoGalleryHeader
  selectPieceVideo: '请选择曲目{0}的视频',
  //   END ---- components::VideoGalleryScreen/VideoGalleryHeader

  // START ---- components::VideoGalleryScreen
  deleteVideo: '删除视频',
  yes: '是',
  no: '否',
  videoResolution: '视频的分辨率',
  //   END ---- components::VideoGalleryScreen

  // START ---- components::VideoGalleryScreen/VideoOptionModal
  selectVideo: '请选择视频',
  playVideo: '播放视频',
  //   END ---- components::VideoGalleryScreen/VideoOptionModal

  // START ---- actions::ExamActions
  success: '成功',
  examEnrolment: '报考考试',
  paymentGatewayError: '支付网关错误',
  examReport: '考试报告',
  examCertificate: '考试证书',
  examAppeal: '上诉考试',
  certPurchase: '购买证书',
  videoUpload: '上传视频',
  //   END ---- actions::ExamActions

  // START ---- components::UploadVideoScreen/UploadVideoScreen
  videoTitle: '标题',
  description: '详情',
  upload: '上传',
  goUpload: '点击上传新视频',
  privacyPolicy: '隐私政策',
  privacyAndTerms: '服务条款和隐私政策',
  //   END ---- components::UploadVideoScreen/UploadVideoScreen

  // START ---- actions::SyllabusActions
  newMusicPieceRequest: '新曲目申请',
  //   END ---- actions::SyllabusActions

  // START ---- components::ExamListScreen/CandidateExamListScreen
  current: '目前',
  completed: '已完成',
  rework: '重考',
  //   END ---- components::ExamListScreen/CandidateExamListScreen

  // START ---- components::ExamListScreen/CandidateAppealExamListItem
  appealDate: '上诉日期',
  //   END ---- components::ExamListScreen/CandidateAppealExamListItem

  // START ---- components::ExamListScreen/CandidateCurrentExamListItem
  expiryDate: '截止日期',
  //   END ---- components::ExamListScreen/CandidateCurrentExamListItem

  // START ---- components::CandidateHomeScreen
  myExamsForTeacher: '康柏尔考级 - 老师端',
  //   END ---- components::CandidateHomeScreen

  // START ---- components::CandidateProfileScreen
  pleaseStateYourInstitutionType: '请另行注明您的学院类型',
  //   END ---- components::CandidateProfileScreen

  // START ---- components::CandidateProfileScreen
  studentProfile: '学生个人资料',
  //   END ---- components::CandidateProfileScreen

  // START ---- components::TeacherNewExamScreen
  selectCandidate: '请选择学生',
  //   END ---- components::TeacherNewExamScreen

  // START ---- components::StudentProfileEditScreen
  removeThisStudent: '删除此学生',
  removeStudent: '删除学生',
  //   END ---- components::StudentProfileEditScreen

  // START ---- actions::AuthActions
  passwordReset: '密码重设',
  //   END ---- actions::AuthActions

  // START ---- actions::CommonActions
  updateApp: '更新APP',
  versionCheckError: '版本验证错误',
  //   END ---- actions::CommonActions

  // START ---- components::BrowseMusicLibraryScreen
  searchBy: '搜寻方式',
  throughInstrumentAndGrade: '通过乐器与等级',
  throughMusicName: '通过曲目名字',
  throughComposer: '通过作曲家',
  musicName: '曲目名字',
  searchMusicPiece: '搜寻曲目',
  //   END ---- components::BrowseMusicLibraryScreen

  //  START ---- components::BrowseMusicLibraryScreen
  cbeRecommendedPieces: '搜寻康柏尔推荐的曲目',
  searchResult: '搜寻结果',
  noResult: '无结果',
  //    END ---- components::BrowseMusicLibraryScreen

  //  START ---- components::MusicLibraryDetailsScreen
  recommendedByCBE: '康柏尔强力推荐',
  viewSheetMusic: '购买乐谱',
  reportBrokenVideoLink: '举报无效视频链接/版权信息',
  item: '物件',
  sheetMusicOf: '曲谱 [{0}]',
  //    END ---- components::MusicLibraryDetailsScreen

  //  START ---- components::SocialMediaEmailScreen
  submit: '提交',
  //    END ---- components::SocialMediaEmailScreen

  // START ---- components::AccountUpgradeChooseRoleScreen
  upgradeAccount: '完成个人资料',
  //   END ---- components::AccountUpgradeChooseRoleScreen

  // START ---- components::SocialMediaPasswordScreen
  linkYourFbAccount: '连接您的Facebook帐号',
  //   END ---- components::SocialMediaPasswordScreen

  // START ---- components::CandidateCompletedPieceEvaluationDetailScreen
  completedPieceEvaluation: '已完成的级数评估',
  recommendedGrade: '建议级别',
  marks: '分数',
  sendPerformanceEvaluationReportToMyEmail: '发级数评估报告至我的邮箱',
  //   END ---- components::CandidateCompletedPieceEvaluationDetailScreen

  // START ---- actions::PieceEvaluationActions
  performanceEvaluationReport: '级数评估报告',
  requestPieceEvaluation: '申请级数评估',
  //   END ---- actions::PieceEvaluationActions

  // START ---- components::CandidateNewPieceEvaluationScreen
  pieceLength: '曲目长度',
  //   END ---- components::CandidateNewPieceEvaluationScreen

  // START ---- components::TeacherEditPieceEvaluationScreen
  pieceSource: '曲目来源',
  proceedToSubmit: '前往提交',
  syllabusFromCbe: '康柏尔音乐库的曲目',
  ownDetails: '自备的曲目',
  videoLink: '视频链接',
  //   END ---- components::TeacherEditPieceEvaluationScreen

  // START ---- components::LandingScreen
  home: '主页',
  wallet: '钱包',
  events: '活动',
  trending: '热门',
  library: '图书馆',
  assess: '考级',
  manage: '管理',
  youbrioApp: 'YOUBRIO APP',
  loginAsAdmin: '登录为系统管理员',
  loginAsSupport: '登录为客服',
  backToTeacher: '回到帐号',
  featured: '精选',
  tapToEnter: '点击进入',
  enterMobileNo: '更新手机号码',
  recentNews: '最新消息',
  moreInfo: '了解优伯',
  hallOfFame: '明日之星',
  beginner: '初级',
  intermediate: '中级',
  advanced: '高级',
  qualityControl: '优伯教学的品质保证',
  howToStart: '如何上课',
  aboutYoubrio: '关于优伯',
  testimonials: '用户评价',
  approvedTeachers: '优伯老师保证',
  customerService: '五星客服',
  safePayment: '平台安全付款系统',
  withdrawalGuarentee: '取款承诺',
  //   END ---- components::LandingScreen

  // START ---- actions::EventActions
  eventEnrolment: '报名项目',
  //   END ---- actions::EventActions

  // START ---- components::LandingScreen/SideBar
  eventEnrolments: '项目报名列表',
  youbrioUserLevelInfo: '优伯教学等级详情',
  myWallet: '我的钱包',
  scan: '扫描',
  //   END ---- components::LandingScreen/SideBar

  // START ---- components/CandidateEventEnrolmentListScreen
  eventEnrolmentDetails: '报名项目详情',
  //   END ---- components/CandidateEventEnrolmentListScreen

  // START ---- components/CandidateEventEnrolmentListScreen/CandidateEventEnrolmentListItem
  enrolmentDate: '报名日期',
  ageGroup: '年龄层',
  //   END ---- components/CandidateEventEnrolmentListScreen/CandidateEventEnrolmentListItem

  // START ---- components::PaymentDetailScreen
  paymentDetail: '支付详情',
  sendReceiptToMyEmail: '发收据致我的邮箱',
  paymentGateway: '支付网关',
  directDeposit: '银行转账',
  //   END ---- components::PaymentDetailScreen

  // START ---- components::LandingScreen/LandingTab/EventsTab
  currentEvents: '正在进行中的项目',
  collections: '收集',
  upcomingEvents: '即将来临的项目',
  //   END ---- components::LandingScreen/LandingTab/EventsTab

  // START ---- components::NewEventEnrolmentScreen/CandidateNewEventEnrolmentScreen
  musicPeriod: '曲目时期',
  duration: '视频长度',
  fee: '报名费',
  newEventEnrolment: '新项目报名',
  event: '项目',
  eventDate: '项目日期',
  eventLocation: '项目地点',
  category: '组别',
  selectCategory: '请选择组别',
  addAffiliateCode: '添加附属代码',
  appliedAffiliateCode: '附属代码',
  free: '免费',
  proceed: '继续',
  //   END ---- components::NewEventEnrolmentScreen/CandidateNewEventEnrolmentScreen

  // START ---- components::AddAffiliateCodeScreen/AddAffiliateCodeScreen
  tapToScanQRCode: '扫一扫二维码',
  enterAffiliateCode: '输入附属代码',
  applyAffiliateCode: '应用附属代码',
  or: '或',
  //   END ---- components::AddAffiliateCodeScreen/AddAffiliateCodeScreen

  // START ---- components::EditEventEnrolmentScreen/CandidateEditEventEnrolmentScreen
  genre: '演奏人数',
  selectGenre: '请选择演奏人数',
  performanceVideo: '演奏视频',
  selectPerformanceVideo: '请选择演奏视频',
  //   END ---- components::EditEventEnrolmentScreen/CandidateEditEventEnrolmentScreen

  // START ---- components::SearchVideoScreen/SearchVideoScreen
  showingSearchResultFor: '正在显示“{0}”的搜索结果。',
  enterNameOrTitle: '请输入关键字...',
  //   END ---- components::SearchVideoScreen/SearchVideoScreen

  // START ---- components::LandingScreen/LandingTab/TrendingListItem
  shareYoubrioVideo: '分享Youbrio视频',
  more: '更多',
  levelN: 'LV {0}',
  share: '分享',
  //   END ---- components::LandingScreen/LandingTab/TrendingListItem

  // START ---- components::LandingScreen/LandingTab/TrendingListItem
  shareYoubrioPhoto: '分享Youbrio图片',
  //   END ---- components::LandingScreen/LandingTab/TrendingListItem

  // START ---- components/LandingScreen/ProfileDetailTab
  me: '我',
  yourLevelIsNow: '您目前的等级是',
  toTeach: '教课',
  teachProfile: '老师个人主页',
  makeMePrivate: '是否改为私人账号？',
  referenceRate: '我的课程小时费',
  askAdmin: '询问客服',
  teacherRequest: '请求老师',
  //   END ---- components/LandingScreen/ProfileDetailTab

  // START ---- components/WantToTeachScreen/WantToTeachScreen
  //   END ---- components/WantToTeachScreen/WantToTeachScreen

  // START ---- components/WatchVideoScreen/WatchVideoScreen
  comeWatchPerform: 'YOUBRIO邀约您在Youbrio.com上观看{0}所演奏的{1}',
  //   END ---- components/WatchVideoScreen/WatchVideoScreen

  // START ---- components/LandingScreen/LandingTab/ProfileDetailVideoTab/CertificateTab/CertificateTab
  uploadCertificate: '上传证书',
  certDescAndOrigin: '填写证书描述、来源等等',
  photoDescAndOrigin: '照片介绍、来源等等',
  enrolExam: '报考考试',
  myCerts: '我的证书',
  photo: '照片',
  //   END ---- components/LandingScreen/LandingTab/ProfileDetailVideoTab/CertificateTab/CertificateTab

  // START ---- components/AuditionListScreen/AuditionListScreen
  auditions: '试镜项目',
  //   END ---- components/AuditionListScreen/AuditionListScreen

  // START ---- components/AuditionListScreen/AuditionListScreen
  competitions: '比赛项目',
  //   END ---- components/AuditionListScreen/AuditionListScreen

  // START ---- components/AuditionListScreen/AuditionListScreen
  examinations: '考级',
  //   END ---- components/AuditionListScreen/AuditionListScreen

  // START ---- components::EventDetailScreen
  eventLinks: '项目链接',
  comingSoonEllipsized: '即将来临...',
  enroll: '报名',
  audition: '试镜项目',
  currentEvent: '正在进行中的项目',
  pastEvent: '过去的项目',
  upcomingEvent: '即将来临的项目',
  //   END ---- components::EventDetailScreen

  // START ---- components::UserYoubrioProfileScreen
  userProfile: '用户资料',
  videos: '个视频',
  likes: '个赞',
  levelFullN: 'Level {0}',
  uploadedVideos: '视频',
  //   END ---- components::UserYoubrioProfileScreen

  // START ---- components::MyCertificatesListScreen/MyCertificatesListItem
  uploadDate: '上传日期',
  reviewed: '已审查',
  //   END ---- components::MyCertificatesListScreen/MyCertificatesListItem

  // START ---- components::EditEmailScreen
  changeEmail: '更换邮箱地址',
  currentEmail: '目前的邮箱地址',
  newEmail: '新邮箱地址',
  //   END ---- components::EditEmailScreen

  // START ---- components::UserLevelInfoListScreen/UserLevelInfoListScreen
  userLevelInfo: '用户等级详情',
  voteCount: '票数',
  //   END ---- components::UserLevelInfoListScreen/UserLevelInfoListScreen

  // START ---- actions::PaymentActions
  requestReceipt: '发送收据',
  //   END ---- actions::PaymentActions

  // START ---- components::common/VideoPickerWithLabel
  appVideo: 'APP视频',
  //   END ---- components::common/VideoPickerWithLabel

  // START ---- components::TeachProfileScreen/TeachProfileScreen
  na: '无',
  organization: '教育机构',
  level: '等级',
  experience: '资历',
  yr: '年',
  age: '年龄',
  rating: '评分',
  language: '语言',
  spokenLanguage: '课程语言',
  math: '数学',
  subjects: '科目',
  purpose: '目标',
  recommendation: '相关老师推荐',
  about: '关于',
  abn: 'ABN',
  achievement: '收藏',
  collection: '我的收藏',
  othercollection: '收藏',
  delete: '删除',
  manageMyLessons: '管理我的课程',
  manageMyClasses: '管理我的学习',
  registerForGST: '是否注册GST',
  edit: '修改',
  add: '添加',
  play: '播放',
  intro: '老师简介',
  chooseFromLibrary: '从相册中选择',
  chooseFromAchievements: '从我的成就选择',
  changeVideo: '更换视频',
  //   END ---- components::TeachProfileScreen/TeachProfileScreen

  // START ---- components::EditReferenceRateScreen
  privateInSearch: '将帐户设为私人账号，您将不会被包括在“搜索”教师的搜索中，您的学生仍然可以通过搜索您的姓名来找到您。',
  referenceRateDesc: '为了帮您找到更合适到学生，请让我们知道您每小时的课程收费。这将显示在您的个人资料上作为费用指示。',
  //   END ---- components::EditReferenceRateScreen

  // START ---- components::EditDescriptionScreen
  aboutMe: '关于',
  aboutMeLong: '告诉我们一些关于您的事情，以便您未来的学生或老师能够更好的认识您。',
  //   END ---- components::EditDescriptionScreen

  // START ---- components::EditDescriptionScreen
  experienceTitle: '经验',
  experienceDesc: '您教学多少年了?',
  //   END ---- components::EditDescriptionScreen

  // START ---- components::ManageMyLessonScreen
  myStudents: '我的学生',
  myLessons: '我的课程时段',
  cLesson: '创建课程',
  new: '创建',
  lessonSettings: '课程设置',
  myFees: '我的费用',
  myTimetable: '我的时间表',
  resources: '学习资源',
  shop: '音乐商城',
  help: '帮助',
  helpCenter: '功能介绍',
  categories: '类型',
  myPayments: '我的付款方式',
  upcomingLessons: '即将开始的课程',
  favourite: '收藏',
  unfavourite: '取消收藏',
  addToCalendar: '添加到日历',
  editCalendar: '修改日历',
  cannotBeFound: '无法找到',
  //   END ---- components::ManageMyLessonScreen

  // START ---- components::AdminManageScreen
  newTeachers: '新老师',
  //   END ---- components::AdminManageScreen

  // START ---- components::ManageMyClassScreen
  myTeachers: '我的老师',
  myClasses: '我的课程',
  //   END ---- components::ManageMyClassScreen

  // START ---- components::FindTab
  connect: '连接',
  disconnect: '断开',
  find: '寻找',
  explore: '搜索',
  findMyTeacher: '寻找老师',
  connectMyTeacher: '扫二维码连接我的老师',
  connectMyStudent: '扫二维码连接我的学生',
  findTeacherSchool: '寻找新老师',
  any: '任何',
  genderPreference: '推荐性别',
  search: '搜索',
  keyword: '关键词',
  hourlyRate: '课时费/小时',
  searchCriteria: '搜索条件',
  noSubject: '老师还未选择任何科目',
  feeIndication: '课费范围',
  upTo: '{0}{1}以下',
  andAbove: '以上',
  lastOnlineTime: '最后在线 {0} 之前',
  months: '月',
  year: '年',
  searchForTeacher: '搜索老师',
  searchForTeacherAndSchool: '老师/学校',
  //   END ---- components::FindTab

  // START ---- components::BasicProfileTab
  wantToLearn: '我想学习',
  wantToTeach: '我想教课',
  //   END ---- components::BasicProfileTab

  // START ---- components::VideoCallScreen
  waiting: '等待',
  waitingToReconnect: '重新连接中...',
  //   END ---- components::VideoCallScreen

  // START ---- components::TopUpFundScreen
  deposit: '充值',
  studyCreditDeposit: '钱包充值',
  //   END ---- components::TopUpFundScreen

  // START ---- components::FundTransactionListScreen
  txnHistory: '交易历史',
  //   END ---- components::FundTransactionListScreen

  // START ---- components::FundTransactionListScreen/FundPriceListItem
  studyCredit: '钱包余额',
  //   END ---- components::FundTransactionListScreen/FundPriceListItem

  // START ---- components::TopUpPaymentMethodScreen
  paymentMethod: '支付方式',
  topUpAmt: '充值金额',
  retry: '重试',
  convertedAmount: '已兑换金额',
  processingFee: '手续费',
  alipayProcessingFee: '支付宝手续费',
  wechatProcessingFee: '微信支付手续费',
  thirdPartyProcessingFee: '银行手续费',
  pay: '去支付',
  paymentWillBeMadeToAccount: '您将支付至 {0}',
  //   END ---- components::TopUpPaymentMethodScreen

  // START ---- components::BrioWalletSummaryScreen
  moreTxnHistory: '更多交易历史...',
  youHaveNotMadeAnyTransactions: '您未做任何交易。',
  mostRecentTransactions: '最新交易记录',
  withdraw: '提现',
  serviceFee: '服务费',
  receivableAmount: '应收款',
  //   END ---- components::BrioWalletSummaryScreen

  // START ---- components::NotificationListScreen
  notifications: '通知',
  youDoNotHaveAnyNotificationsYet: '您还未有任何消息',
  markAllAsRead: '标记所有通知为已读',
  deleteNotification: '删除通知',
  //   END ---- components::NotificationListScreen

  // START ---- components::LessonNotificationListScreen
  lessonRequestNotifications: '课程请求通知',
  //   END ---- components::LessonNotificationListScreen

  // START ---- components::CreateLessonScreen
  createLesson: '创建课程时段',
  startLesson: '立即开课',
  scheduleLesson: '课程创建',
  createAndInvite: '创建并分享',
  shareLesson: '分享课程',
  close: '关闭',
  invite: '邀请',
  anyone: '任何人',
  selectStudent: '选择学生',
  timeNow: '现在时间',
  lessonDashboard: '课程控制面板',
  lessonType: '课程类型',
  lessonDuration: '课程时间',
  nMinutes: '{0} 分钟',
  lessonSlots: '课程时段',
  lessonName: '课程名称',
  lessonDescription: '课程介绍',
  lessonFee: '课程费用',
  iWantToReviewMyStudents: '我想要确认的学生',
  tDuration: '时长',
  sunday: '星期天',
  monday: '星期一',
  tuesday: '星期二',
  wednesday: '星期三',
  thursday: '星期四',
  friday: '星期五',
  saturday: '星期六',
  daily: '每天',
  once: '一次',
  setLessonSlot: '设置课程时段',
  lesson: '课程',
  set: '确认设置',
  reminder: '温馨提醒',
  minimumLessonDuration: '最短课程时段',
  minimumDuration: '最短时段',
  repeat: '重复',
  time: '时间',
  onceOnly: '一次',
  notify: '通知我认识的人',
  notifyStudents: '通知学生',
  noNotify: '我不想通知任何人',
  availableForEveryone: '开放给任何人',
  lessonFeeForTeacher: '老师的学费',
  makeThisLessonFree: '建立免费课程',
  //   END ---- components::CreateLessonScreen

  // START ---- components::MyCalendarScreen
  myTimeTable: '我的时间表',
  noLesson: '无课程',
  practiceLesson: '练习课',
  pleaseTapOnADate: '请点击以上日期。',
  groupLesson: '多人课程',
  //   END ---- components::MyCalendarScreen

  // START ---- components::BrowseLessonListScreen
  someonesLessons: '{0}\'的课程',
  //   END ---- components::BrowseLessonListScreen

  // START ---- components::BookLessonScreen
  bookLesson: '预定课程',
  selectLessonSlots: '选择课程时段',
  feeSummary: '费用总结',
  platformCharge: '平台收费',
  gstCharged: 'GST',
  currency: '货币',
  total: '总额',
  discountedTotal: '优惠价',
  lessonGoals: '课程目标',
  //   END ---- components::BookLessonScreen

  // START ---- components::LessonPaymentScreen
  studyWalletBalance: '我的钱包余额',
  toBePaid: '需付款',
  payAndBookLesson: '支付并预定课程',
  topUp: '充值',
  yesSure: '好的，没问题',
  noThanks: '不了，谢谢',
  //   END ---- components::LessonPaymentScreen

  // START ---- components::LessonPaymentScreen
  noLessonBookingFound: '无法找到任何预定课程',
  awaitingApproval: '等待老师同意',
  confirmed: '已确认',
  bookingRejected: '请求被拒绝',
  cancelledByTeacher: '已被老师取消',
  cancelledByStudent: '已被学生取消',
  cancelledByStudentNoRefund: '已被学生取消 (无退款)',
  expiredStudent: '已过期 (学生未出现)',
  expiredTeacher: '已过期 (老师未出现)',
  bookingCompleted: '已完成',
  inProgress: '课程进行中',
  bookedAt: '预定时间',
  //   END ---- components::LessonPaymentScreen

  // START ---- component::TeacherLessonBookingDetailScreen
  bookingId: '预定ID',
  status: '状态',
  bookedBy: '预定者',
  referralDetails: '推荐详情',
  bookedThrough: '预定管道',
  commission: '提成',
  timeToClass: '上课时间',
  timeToLesson: '上课时间',
  lessonStarted: '课程已经开始了',
  lessonIsCanceled: '课程已被取消了',
  bookingDetails: '课程详情',
  yearsDuration: '年',
  monthsDuration: '个月',
  weeksDuration: '个星期',
  daysDuration: '天',
  hoursDuration: '小时',
  minsDuration: '分钟',
  days: '天',
  hours: '时',
  hour: '时',
  minutes: '分钟',
  minShort: '分钟',
  mins: '分',
  seconds: '秒',
  approve: '同意',
  reject: '拒绝',
  rejectLessonBooking: '拒绝此预定课程',
  cancelLessonBooking: '取消此预定课程',
  completeLessonBooking: '标记此课程为已完成',
  referredBy: '推荐人',
  dualCamera: '双镜头',
  //   END ---- component::TeacherLessonBookingDetailScreen

  // START ---- component::StudentLessonBookingDetailScreen
  taughtBy: '老师名称',
  web: '网页版本',
  spectatorMode: '观众模式',
  viewLesson: '查看课程',
  enter: '进入',
  enterLesson: '进入教室',
  joinLesson: '参与课程',
  enterLessonRoom: '进入教室',
  launchSmartboardOnly: '只开启智能板',
  dualDeviceMode: '双设备模式',
  whatIsDualDeviceMode: '什么是双设备模式？',
  lateCancellation: '临时取消',
  prepareSmartboard: '预备智能板',
  viewSmartboardNotes: '查看智能版笔记',
  youHaveNoSmartboardNotes: '您智能版笔记不存在。',
  //   END ---- component::StudentLessonBookingDetailScreen

  // START ---- component::BookingSlotChangeRequestListScreen
  requestTimeChange: '请求更改课程时间',
  changeRequests: '课程更改请求',
  deleteChangeRequest: '撤销课程更改请求',
  approveChangeRequest: '批准课程更改请求',
  rejectChangeRequest: '拒绝课程更改请求',
  noChangeRequestFoundForThisBooking: '此课程无更改请求。',
  sendRequest: '发送请求',
  notes: '备注',
  pleaseStateYourChangeRequestReason: '请填写更改时间的原因、其他合宜时段等等...',
  you: '您',
  yourTeacher: '您的老师',
  yourStudent: '您的学生',
  requestApproved: '请求已被批准',
  requestDeclined: '请求已被拒绝',
  pendingRequest: '待定的请求',
  deleteRequest: '撤销请求',
  requestToChangeLessonTime: '{0}做出了更改课程时间的请求。',
  youHaveALessonAtThisTime: '在这时段里您将有另一个课程',
  youHaveNoLessonAtThisTime: '此时段无冲突课程。',
  checkingForOverlappingLesson: '正在搜索冲突课程...',
  unableToCheckForOverlappingLesson: '无法查询冲突课程。\n请点击以重试。',
  requestedByAt: '{0}在 {1} 做出了此请求。',
  yourTimeChangeRequestApproved: '您的课程请求已被批准了。',
  yourTimeChangeRequestRejected: '您的课程请求已被拒绝了。',
  //   END ---- component::BookingSlotChangeRequestListScreen

  // START ---- component::TeacherEditLessonScreen
  settings: '设置',
  editLesson: '修改课程时段',
  updateLesson: '更新课程时段',
  deleteLesson: '删除课程时段',
  unlistLesson: '撤销此课程',
  relistLesson: '重新发布课程',
  lessonCreatedFor: '课程为此学生而创建',
  //   END ---- component::TeacherEditLessonScreen

  // START ---- component::MyLessonTeacherListScreen/MyLessonTeacherListItem
  nLessonsBooked: '预定了 {0} 节课程',
  selected: '已选',
  selectAll: '全选',
  unselectAll: '取消全选',
  selectedAll: '已全选',
  //   END ---- component::MyLessonTeacherListScreen/MyLessonTeacherListItem

  // START ---- component::ProfilePreviewScreen
  onlyForStudents: '请注意',
  //   END ---- component::ProfilePreviewScreen

  // START ---- component::ExaminationListScreen/ExaminationListItem
  onlineExamination: '在线考试',
  offlineExamination: '线下考试',
  //   END ---- component::ExaminationListScreen/ExaminationListItem

  // START ---- component::ManageMyClassScreen/UpcomingLessonBookingListItem
  now: '现在',
  tapToBook: '课程通知',
  group: '多人班',
  pendingBooking: '待预约中...',
  //   END ---- component::ManageMyClassScreen/UpcomingLessonBookingListItem

  // START ---- component::TimeSlotModalPicker
  slotStart: '开始',
  slotEnd: '结束',
  date: '日期',
  slotRecurringDay: '重复',
  hint: '提示',
  completeNow: '立即完成',
  selectTimeSlot: '选择时段',
  select: '选择',
  thisIsRecurring: '这是重复的时段。',
  addTimeSlot: '添加课程',
  startDate: '开始日期',
  endDate: '结束日期',
  //   END ---- component::TimeSlotModalPicker

  // START ---- component::BookingTimeSlotModalPicker
  iWantToBook: '我要约课',
  fee2: '学费',
  // START ---- component::BookingTimeSlotModalPicker

  // START ---- component::FundWithdrawScreen
  myPaymentMethods: '更新我的提现方式',
  choosePaymentMethod: '选择提现方式',
  //   END ---- component::FundWithdrawScreen

  // START ---- component::FundWithdrawPaymentMethodScreenList
  addPaymentMethod: '添加提现方式',
  //   END ---- component::FundWithdrawPaymentMethodScreenList

  // START ---- component::FundWithdrawAddPaymentMethodScreen
  bank: '银行',
  bsb: 'BSB',
  accNo: '银行户口号码',
  paypalEmail: 'Paypal 电子邮件地址',
  alipayEmailOrMobile: '支付宝 电子邮件地址/手机号码',
  fullNameRegistered: '全名',
  alipay: '支付宝',
  wechatpay: '微信支付',
  paypal: 'Paypal',
  bankTransfer: '银行转帐',
  paymentChannel: '支付管道',
  selectBank: '请选择银行',
  bankCountry: '银行分行所属国家',
  bankSwiftCode: 'SWIFT/BIC代码',
  iban: '国际银行帐户号码（IBAN）',
  bankName: '银行名称',
  bankAddress: '银行地址',
  //   END ---- component::FundWithdrawAddPaymentMethodScreen

  // START ---- component::WithdrawalSummaryScreen
  withdrawalSummary: '提现摘要',
  amount: '金额',
  enterPassword: '请输入密码',
  enterLoginPassword: '请输入登陆密码',
  //   END ---- component::WithdrawalSummaryScreen

  // START ---- component::FundWithdrawalListScreen
  myWithdrawals: '提现',
  makeWithdrawal: '创建提现请求',
  //   END ---- component::FundWithdrawalListScreen

  // START ---- component::WriteReviewScreen
  writeAReview: '写评语',
  writeAFeedback: '给予反馈',
  editAReview: '修改评分',
  rateTeacher: '给予老师评价',
  rateStudent: '给予学员评价!',
  rateCallQuality: '上课质量反馈',
  leaveComment: '你对他的课程评价',
  leaveCommentQuestion: '你愿意现在评价这节课吗?或者你随时可以回到次课程评价。',
  leaveAsAnonymous: '匿名留言',
  anonymous: '匿名',
  //   END ---- component::WriteReviewScreen

  // START ---- component::ViewReviewScreen
  allReviews: '全部评论',
  viewMore: '查看更多',
  viewLess: '查看更少',
  //   END ---- component::ViewReviewScreen

  // START ---- component::SimpleIntroSlider
  sTitleZero: '如果您是一位学生/家长',
  tTitleZero: '如果您是一位老师',
  tutorial: '指南',
  thisTutorial: '这个指南',
  skip: '跳过',
  done: '完成',
  inLearning: '对学习有兴趣',
  inTeaching: '对教学有兴趣',
  titleZero: '1. 设立个人简介',
  sDescZero: '设立您的YOUBRIO帐号为学生帐号',
  tDescZero: '设立您的YOUBRIO帐号为老师帐号',
  sTitleOne: '2. 完成您的个人简介',
  sDescOne: '填写您的个人资料',
  sTitleTwo: '3. 选择老师',
  sDescTwo: '使用“寻找老师”功能以寻找及选择适合您的学习需求。',
  sTitleThree: '4. 选择课程',
  sDescThree: '当您选好老师后，就可以选择老师所提供的课程以及上课时间。',
  sTitleFour: '5. 准备上课',
  sDescFour: '进入教室并等候您的老师！',
  tTitleOne: '2. 完成您的简介',
  tDescOne: '填写您的个人资料',
  tTitleTwo: '3. 创建课程',
  tDescTwo: '您所创建的课程都会让您的学生参阅。请在创建课程时填写课程详情以及课程内容。',
  tTitleThree: '4. 确认课程预定',
  tDescThree: '当学生向您预定课程时，您可以选择自动确认并加入到您的课程表或自行手动确认。',
  tTitleFour: '5. 准备上课',
  tDescFour: '当上课时间到了，进入教室并开始上课！',
  //   END ---- component::SimpleIntroSlider

  // START ---- component::SchoolIntroScreen
  schoolOrg: '学校',
  registerMySchoolOrg: '登记我的学校',
  manageMySchoolOrg: '管理学校',
  searchForSchool: '搜寻学校',
  //   END ---- component::SchoolIntroScreen

  // START ---- component::CreateSchoolScreen
  uploadSchoolProfilePhoto: '上传学校简介图像',
  schoolOrgName: '学校名称',
  schoolOrgIntrouction: '学校介绍',
  yearFounded: '创立年份',
  commissionPercentage: '提成率',
  defaultSchoolCommissionRate: '默认学校提成',
  organisationApplication: '申请学校',
  organisation: '学校',
  //   END ---- component::CreateSchoolScreen

  // START ---- component::SchoolProfileScreen
  editSchool: '编辑学校',
  schoolName: '学校名称',
  profitPercentage: '盈利百分比',
  numOfTeacher: '老师',
  schoolOrgIntroduction: '学校介绍',
  schoolOrgAddress: '学校地址',
  discountPercentage: '学生折扣百分比',
  //   END ---- component::SchoolProfileScreen

  // START ---- component::ManagingSchoolListScreen/ManagingSchoolListItem
  teachers: '老师',
  students: '学生',
  requests: '请求',
  //   END ---- component::ManagingSchoolListScreen/ManagingSchoolListItem

  // START ---- component::SchoolTeacherListScreen/SchoolTeacherListItem
  removeFromSchool: '从学校删除',
  //   END ---- component::SchoolTeacherListScreen/SchoolTeacherListItem

  // START ---- component::SchoolPendingMembershipListScreen
  pendingJoinRequest: '待批准的加入请求',
  decline: '拒绝',
  blacklistName: '把{0}列入黑名单',
  viewNameProfile: '查看{0}的简介',
  teacher: '老师',
  student: '学生',
  //   END ---- component::SchoolPendingMembershipListScreen

  // START ---- component::SchoolBlacklistedMembershipListScreen
  blacklistedProfiles: '黑名单',
  removeNameFromBlacklist: '把{0}从黑名单上删除',
  //   END ---- component::SchoolBlacklistedMembershipListScreen

  // START ---- component::WizardResultScreen
  school: '学校',
  //   END ---- component::WizardResultScreen

  // START ---- component::SchoolProfilePreviewScreen
  join: '加入',
  requestSent: '已发加入请求',
  joined: '已加入',
  leaveSchool: '退出学校',
  selectTeacher: '选择老师',
  joinSchool: '加入学校',
  viewSchool: '查看学校',
  admin: '管理员',
  //   END ---- component::SchoolProfilePreviewScreen

  // START ---- component::EditSchoolProfileScreen
  updateSchoolProfile: '更新学校简介',
  //   END ---- component::EditSchoolProfileScreen

  // START ---- component:SchoolAdminProfileScreen
  deleteApplication: '删除申请',
  findStudent: '寻找学生',
  //   END ---- component:SchoolAdminProfileScreen

  // START ---- component::DocumentFolderScreen
  sheetMusicFolder: '曲目文件夹',
  //   END ---- component::DocumentFolderScreen

  // START ---- component::AnnotationDocumentScreen
  waitingForSheetMusic: '正在载入文件中',
  unableToLoadSheetMusic: '无法载入文件。',
  zoom: '放大',
  annotate: '注释',
  mode: '模式',
  uploadAnImage: '上传新图片',
  blankWhiteboard: '添加空白页',
  eraseAll: '删除所有笔画',
  closeTab: '关闭此页',
  tabRemoved: '此页将被移除',
  //   END ---- component::AnnotationDocumentScreen

  // START --- component::AnnotationUploadPhotoScreen
  annotateMusicSheet: '标记曲谱',
  chooseMusicSheetImage: '选择曲谱照片',
  myTeacherStudentWillUploadInstead: '恢复之前记录（若有）',
  //   END --- component::AnnotationUploadPhotoScreen

  // START --- component::CalendarSlotPickerScreenV2
  doesNotFulfillMinDuration: '不符合最短时段',
  totalDuration: '总时间 {0} 分钟 = {1} 节课',
  nMinutesLesson: '{0}分钟课程',
  //   END --- component::CalendarSlotPickerScreenV2

  // START --- component::QRCodeScreen
  qrCode: '二维码',
  myQRCode: '我的二维码',
  ypLabel: '优伯个人主页 - ',
  notNow: '拒绝',
  openSettings: '打开设置',
  scanToSee: '扫二维码连接',
  //   END --- component::QRCodeScreen

  // START --- component::QRCodeScannerScreen
  cameraPermission: '相机权限',
  //   END --- component::QRCodeScannerScreen

  // START --- component::SettingScreen
  package: '课程包',
  discount: '优惠',
  lessons: '课程管理',
  off: '优惠',
  optin: '开启课程包',
  agreePackage: '选择开启，即表示您同意以下课程包: ',
  enablePackageNote: '通过开启课程包，您可以鼓励学生定期和长期致力于与您一起学习。',
  optInReferral: '开通并允许推荐活动',
  optInReferralNote: '当您开通此选项，您同意将一部分的学费奖赏您的推荐人。',
  deleteAccount: '删除账号',
  //   END --- component::SettingScreen

  // START ---- component::ReferralQRCodeScreen
  referralProgram: '推荐活动',
  scanQRWithYoubrioScanner: '使用YOUBRIO APP扫一扫',
  wantToReceiveCommission: '分享并获取YOUBRIO赏金',
  //   END ---- component::ReferralQRCodeScreen

  // START ---- component::FAQListScreen
  faq: '常见问题',
  //   END ---- component::FAQListScreen

  // START --- component::ResourceScreen
  smartBoard: '智能白板',
  mLibrary: '音乐图书馆',
  mExam: '音乐考级',
  areaOfFocus: '教学重点',
  //   END --- component::ResourceScreen

  // START ---- components::LiveLessonRoomScreen/VideoCallTabScreen
  permission: '权限',
  microphonePermission: '麦克风权限',
  storagePermission: '储存权限',
  backToVideoCall: '返回视频通话',
  //   END ---- components::LiveLessonRoomScreen/VideoCallTabScreen

  // START ---- components::LiveLessonRoomScreen/SmartboardTabScreen
  noPicture: '无图',
  arrangeTabs: '编排页面',
  dragNToMovePages: '拖动 {0} 以移动页面',
  refresh: '刷新',
  //   END ---- components::LiveLessonRoomScreen/SmartboardTabScreen

  // START ---- helpers::ApiHelper
  networkError: '网络信号弱',
  //   END ---- helperps::ApiHelper

  // START ---- components::common/LessonRequestModal
  lessonRequest: '请求课程',
  requestLesson: '请求课程',
  requestADifferentTime: '请求另外一个时段',
  send: '发送',
  //   END ---- components::common/LessonRequestModal

  // START ---- components::common/ChangePasswordScreen
  changePassword: '更改密码',
  oldPassword: '旧密码',
  newPassword: '新密码',
  confirmNewPassword: '确认新密码',
  //   END ---- components::common/ChangePasswordScreen

  // START ---- components::modals/ProfilePreviewScreen
  myFocus: '主攻方向',
  myExperience: '个人经历',
  myEducation: '教育背景',
  myAbout: '个人简介',
  comment: '点评',
  review: '用户点评',
  myReview: '我的点评',
  endorse: '点评',
  peopleSay: '用户评语',
  rateEndorse: '打个分数',
  endReached: '已全部显示',
  tProfile: '老师简介',
  lProfile: '学生简介',
  schoolProfile: '学校简介',
  //   END ---- components::modals/ProfilePreviewScreen

  // START ---- components::component/LandingScreen/LandingTab/HomeTab
  schoolShort: '学科',
  clearAll: '清除选项',
  filter: '过滤',
  genderShort: '性别',
  sort: '排序',
  relevance: '相关优先',
  lastOnline: '在线优先',
  highestRating: '好评优先',
  highestLessonFee: '最高课程费用',
  lowestLessonFee: '最低课程费用',
  ratingSort: '好评优先',
  nameSortAZ: '名字 (A-Z)',
  nameSortZA: '名字 (Z-A)',
  back: '返回',
  translate: '显示翻译',
  original: '显示原文',
  all: '全部',
  newRequest: '新增',
  reset: '重置',
  lRequest: '请求',
  later: '稍后吧',
  addTeacher: '添加老师',
  addStudent: '添加学生',
  create: '创建',
  start: '立即开课',
  ongoingImmediateLesson: '您现在有一个即使课程进行中',
  referral: '老师推荐',
  book: '约课',
  bookLessonAgain: '再次约课',
  createLessonAgain: '创建相同课程',
  iWantToTeach: '我想教课',
  iWantToLearn: '我想学习',
  contactAdmin: '联系客服',
  iWantToTeachBannerTagline: '让我们知道您的专长，我们会向您介绍学生！',
  adminBannerStudentText1: '现在就预约！',
  adminBannerStudentText2: '想学什么告诉我们，我们帮您预约老师！',
  adminBannerTeacherText1: '优伯客服',
  adminBannerTeacherText2: '在优伯你可以教，转介绍，经营在线机构，现在就联系我们如何操作！',
  shareProfile: '分享我的简介',
  schedule: '课程预订',
  shareMyProfile: '分享我的简介',
  homeSearchTeacher: '老师搜寻',
  homeDeposit: '学费储蓄',
  homeJoinLesson: '参与课程',
  homeManageLesson: '课程管理',
  homeOnlineExam: '在线测评',
  homeMusicLibrary: '音乐图书馆',
  homeTeacherReferral: '老师推荐',
  homeNetworkTest: '网络检测',
  //   END ---- components::component/LandingScreen/LandingTab/HomeTab

  //   START ---- acknowledgements
  youbrio: '优伯教育',
  cbe: '康柏尔国际在线音乐考级',
  copyright: '© 2022 康柏尔国际在线音乐考级 版权所有',
  licenses: '许可证',
  bugReport: '发现问题?请帮助我们一起解决. ',
  agreeTC: '若您使用此应用软件及相关服务，则视为您已同意使用条款',
  libraries: '此应用软件使用以下开源库（在Apache的2.0下许可）:',
  feedback: '反馈',
  contactUsViaEmail: '联系我们 - 电子邮件',
  contactUsViaChat: '联系客服',
  //   END ---- acknowledgements

  //  START ---- components::component/ChatListScreen
  chat: '聊天',
  typeMsg: '输入一条信息...',
  newMsg: '新消息',
  requireAction: '未回复',
  incompleteProfile: '未完善个人简介',
  //  END ---- components::component/ChatListScreen

  //  START ---- components::component/ChatScreen
  createPrivateLesson: '创建课程',
  lessonSlotNotFound: '找不到此课程。',
  loadingPrivateLesson: '载入课程中...',
  createdAPrivateLesson: '{0} 已创建了一个课程',
  unableToGetPrivateLesson: '无法索取此课程详情。\n请点击以重试。',
  deleted: '已被删除',
  expired: '已过期',
  booked: '已被预订',
  privateLesson: '课程',
  deletePrivateLesson: '删除课程',
  yesterday: '昨天',
  startVideoCall: '开始视频通话',
  nDeclinedCall: '{0} 拒绝通话。',
  callEnded: '通话已结束。',
  //  END ---- components::component/ChatScreen

  // START ---- components::VideoCallChatScreen
  goToChat: '聊天',
  goToSmartBoard: '智能板',
  //   END ---- components::VideoCallChatScreen

  // START ---- component/modal/CallScreenModal
  incomingVideoCall: '视频通话',
  youbrioLesson: '优伯教学课程',
  //   END ---- component/modal/CallScreenModal

  // START ---- component/modal/ReferralCodeModal
  enterReferralCode: '输入推荐码',
  validCode: '推荐码有效。',
  invalidCode: '推荐码无效。',
  useCode: '使用',
  unableToVerifyCode: '无法核实推荐码。',
  verifying: '核实中',
  //   END ---- component/modal/ReferralCodeModal

  // START ---- component/ArticleScreen
  joinCBENow: '现在就报名康柏尔国际在线音乐考级',
  //   END ---- component/ArticleScreen

  // START ---- component/MySalesReferralScreen
  myReferrals: '我的推荐',
  //   END ---- component/MySalesReferralScreen

  // START ---- component/SchoolFeeCategoryListScreen
  feeCategory: '学费类别',
  setDefault: '设为默认',
  default: '默认',
  //   END ---- component/SchoolFeeCategoryListScreen

  // START ---- component/SchoolFeeCategoryEditScreen
  editFeeCategory: '更改学费类别',
  //   END ---- component/SchoolFeeCategoryEditScreen

  // START ---- component/SchoolFeeCategoryCreateScreen
  newFeeCategory: '新学费类别',
  teachersAllowedToEditPrice: '老师可以更改学费',
  teachersNotAllowedToEditPrice: '老师不可更改学费',
  labelOrRemarks: '标签/备注',
  setThisAsDefault: '设为默认学费类别',
  selectTeachers: '选择老师',
  addOrRemoveTeachers: '选择/移除老师',
  selectedTeachers: '已选的老师',
  createFeeCategory: '创建学费类别',
  otherSettings: '其他设置',
  removeAll: '全部清除',
  searchNameOrEmail: '搜索姓名/邮箱地址',
  andNMoreTeachers: '与另外{0}位老师',
  noTeachersAssigned: '未委任老师',
  allTeachersWithoutCategory: '所有未属于任何学费类别的老师',
  searchTeacher: '搜寻老师',
  searchStudent: '搜寻学生',
  copy: '复制',
  used: '已使用',
  friend: '用户',
  friends: '用户',
  friendUsedCode: '使用过你的推荐码',
  referAndEarn: '推荐 & 分享',
  scanQrCode: '扫二维码',
  shareByLink: '分享推荐码链接',
  shareFlyer: '分享推荐码海报',
  teachersLessonFee: '老师的学费',
  schoolCommission: '学校提成率',
  schoolsLessonFee: '学校的学费',
  //   END ---- component/SchoolFeeCategoryCreateScreen

  // START ---- components::SplashScreenV2
  theComprehensiveOnlineTeachingTool: '每位在线上课的好伙伴',
  //   END ---- components::SplashScreenV2

  // START ---- component/ReferralIntroScreen
  searchTheTeacherYouWishToRefer: '搜寻您想介绍的老师',
  referralBannerText1: '现在就分享！',
  referralBannerText2: '没看到要介绍的老师？\n介绍他们上优伯教学平台！',
  downloadYoubrioNow: '现在就下载优伯教学！',
  shareYoubrio: '分享优伯教学',
  //   END ---- component/ReferralIntroScreen

  // START ---- component/SearchStudentScreen
  searchForStudent: '搜寻学生...',
  //   END ---- component/SearchStudentScreen

  // START ---- modal/AgreementModal
  agree: '同意',
  //   END ---- modal/AgreementModal

  // START ---- component/SelectedPhotoPreviewScreen
  onlyNPhotoPerUpload: '一次最多上传 {0} 张照片',
  longTapAndDragThumbnailToArrangePicture: '长按并拖动缩略图以编排图片',
  //   END ---- component/SelectedPhotoPreviewScreen

  // START ---- component/SelectedPDFPreviewScreen
  onlyNPagesPerUpload: '一次最多上传 {0} 页',
  onlyNFilesPerUpload: '一次最多上传 {0} 个文件',
  nPages: '{0} 页',
  //   END ---- component/SelectedPDFPreviewScreen

  // START ---- component/NetworkTestingModal
  networkDiagnostics: '网络检测',
  networkStart: '开始',
  networkStartComment: '在课堂开始前做好网络检测\n可以提升优伯课程的稳定性',
  ipRetrieval: 'IP存取测试',
  serverResponse: 'Server Response',
  downloadSpeedtest: '下载速度测试',
  uploadSpeedtest: '上传速度测试',
  lessonSimulation: '课程模拟测试',
  networkLowSpeed: '检测结果: 慢速',
  networkModerateSpeed: '检测结果: 中速',
  networkFastSpeed: '检测结果: 快速',
  requestDemoCall: '与客服预约测试连线',
  congratulations: '恭喜!',
  notBad: '不错哦!',
  stopTest: '停止检测',
  retrievingConfig: '正在载入检测设定...',
  unableRetrieveNetworkTestConfig: '无法载入检测设定。',
  //   END ---- component/NetworkTestingModal

  // START ---- component/DatingScreen/SliderEntry
  searchAgain: '重新搜寻',
  //   END ---- component/DatingScreen/SliderEntry

  // START ---- component/Lottie
  newFeature: '新功能!',
  gift: '礼物',
  giftTotal: '总数',
  oops: '抱歉',
  myGift: '我的礼物',
  sendGift: '发送礼物',
  duringLesson: '上课时',
  comingSoon: '即将推出',
  totalReceived: '您一共收到',
  totalReceivedGifts: '您一共收到 {0} 份礼物',
  // END ---- component/Lottie

  // START ---- component/JitsiAudioSettingScreen/JitsiAudioSettingScreen
  advancedAudioSetting: '进阶音频设置',
  audioSetting: '音频设置',
  audioSettingShort: '音频设置',
  presets: '预设',
  //   END ---- component/JitsiAudioSettingScreen/JitsiAudioSettingScreen

  // START ---- components::common/TimePickerV2
  invalidTime: '时间无效',
  //   END ---- components::common/TimePickerV2

  // START ---- component/MyReferralCodeScreen/MyReferralCodeScreen
  myReferralCode: '我的推荐码',
  wechatShare: '微信分享',
  //   END ---- component/MyReferralCodeScreen/MyReferralCodeScreen

  // START ---- component/CreateMeetScheduleScreen
  selectStudents: '选择学生',
  maxStudent: '学生最多',
  openForAnyoneToBook: '任何人可预定。',
  onlyForTheFollowingStudents: '此课程只允许选定学生预定。',
  //   END ---- component/CreateMeetScheduleScreen

  // START ---- component/EditMeetScheduleScreen
  invited: '已邀请',
  cancelInvitation: '取消邀请',
  //   END ---- component/EditMeetScheduleScreen

  // START ---- component/ScheduledMeetDetailScreen
  lessonDetails: '课程详情',
  lessonParticipants: '课程参与者',
  cancelLesson: '取消课程',
  attention: '注意',
  creatorNameLesson: '{0}的課程',
  youHaveRatedAllParticipants: '您已经为所有学生留下评语。',
  youHaveRatedNOfTheParticipants: '您已经为 {0}/{1} 学生留下评语。',
  rate: '评价',
  reviewsAbtYou: '对您的评价',
  //   END ---- component/ScheduledMeetDetalScreen

  // START ---- component/ImmediateMeetScreen
  startLessonNow: '立即开课',
  selectInvitees: '选择学生',
  hey: '嗨',
  checkYour: '请检查您的',
  startAt: '开始时间',
  advanceSettings: '高级设置',
  private: '私人',
  public: '公开',
  maxParticipants: '最多人数',
  anyoneCanJoin: '任何人可加入',
  errorMax: '最多人数只要应该是 {0}, 因为您已经邀请了 {1}',
  invitee: '学生',
  invitees: '学生',
  maybeLater: '之后再说',
  wantToReturn: '您是否想要回到课程?',
  overview: '课程内容',
  viewMoreDetails: '更多详情',
  end: '结束',
  //   END ---- component/ImmediateMeetScreen

  // START ---- component/modals/GiftV2
  sendTo: '发送至...',
  everyone: '所有人',
  noUserFoundRefresh: '发现无人。\n点击刷新。',
  //   END ---- component/modals/GiftV2

  // START ---- MeetReviewListScreen
  reviewForN: '给予 {0} 的评语',
  feedbackForN: '给予 {0} 的反馈',
  youHaveNotWrittenAnyReview: '您还未留下任何评语。',
  youHaveNotWrittenAnyFeedback: '您还未留下任何反馈。',
  feedbackforTutors: '给老师的反馈',
  //   END ---- MeetReviewListScreen

  // START ---- YoubrioXSubscriptionScreen
  tutorRoom: '优伯随心学',
  tutorRoomSubscription: '优伯随心学会员专区',
  perMonth: '每月',
  oneMonth: '1 个月',
  threeMonth: '3 个月',
  twelveMonth: '12 个月',
  getFreeTrial: '点击领取20分钟的优伯随心学体验课！',
  tutorRoomPlan: '优伯随心学会员套餐：',
  open: '开课中，赶紧加入',
  opens: '下次开课',
  openUntil: '开课到',
  closes: '下课在',
  closed: '暂时休息中',
  participants: '学生',
  numberOfStudent: '{0}位学生',
  firstOneJoin: '火热报名中！',
  subscribed: '已订阅',
  subscribe: '现在就报名！',
  iWantToSubscribe: '我想订阅优伯随心学',
  // END ---- YoubrioXSubscriptionScreen

  // START ---- components/PracticeModeScreen/modals/LearnModeNotificationModal
  lessonMode: '学习模式',
  practiceMode: '练习模式',
  activated: '启动',
  deactivated: '未启动',
  practice: '练习',
  teach: '教学',
  //   END ---- components/PracticeModeScreen/modals/LearnModeNotificationModal

  // START --- components/PracticeModeScreen/modals/StudentProfileDetailTabV2
  objective: '目标',
  note: '笔记',
  progressNote: '笔记',
  records: '记录',
  profile: '资料',
  files: '文件',
  stillWorking: '我们还在努力中',
  noData: '暂无数据',
  nothingAdded: '您还未添加任何东西',
  accountSecurity: '账户和安全',
  personalDetails: '个人资料',
  generalInfo: '通用',
  name: '名字',
  goal: '目标',
  device: '设备',
  pleaseEnterHere: '请在此输入...',
  week: '周',
  month: '月',
  lastSevenDays: '前7天',
  lastThirtyDays: '前30天',
  todayYouPracticed: '今天您练习了',
  today: '今天',
  noParticipants: '今天暂时还没有任何学生',
  stats: '数据',
  weeklyReport: '本周报告',
  tutorRoomWeeklyRecord: '优伯随心学 ｜ 学员每周练琴档案',
  averageMin: '分钟平均',
  day: '天',
  practiced: '练习',
  missionAccomplished: '任务完成',
  noAccomplishedMission: '你还没完成任何任务',
  compareWithOther: '优伯随心学习榜',
  beats: '击败',
  totalTimeThisWeek: '学员总计练习时间',
  startPractice: '开始练习',
  //   END ---- components/PracticeModeScreen/modals/StudentProfileDetailTabV2

  // START ---- components/PracticeModeScreen/modals/MeetParticipantListModal
  tutoredBy: '老师: {0}',
  noNotes: '暂无笔记。',
  //   END ---- components/PracticeModeScreen/modals/MeetParticipantListModal

  // START ---- components::PracticeRoomListScreen
  practiceRoomComingSoon: '即将开课',
  //   END ---- components::PracticeRoomListScreen

  // START ---- components/PracticeModeScreen/modals/CoffeeBreakModal
  coffeeBreak: '休息时光',
  goOnBreak: '让我休息片刻',
  imBack: '我回来了！'
  //   END ---- components/PracticeModeScreen/modals/CoffeeBreakModal
};
