import ApiHelper from '../helpers/ApiHelper';
import AppHelper from '../helpers/AppHelper';

export const getEnrolExamDisclaimerText = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`disclaimer/exam?lang=${lang}`, true);
};

export const getEnrolPieceEvaluationDisclaimerText = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`disclaimer/pieceevaluation?lang=${lang}`, true);
};

export const getEnrolExamTnC = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`tnc/exam?lang=${lang}`, true);
};

export const getAppealExamOptions = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`appeal/options/exam?lang=${lang}`, true);
};
