import React, { useEffect, useState } from 'react';
// import { useDebounce, useSessionStorageState } from 'ahooks';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router-dom';
import {
  // Layout,
  // Button,
  Loading,
  Form,
  Alert,
  // Message,
  // Card,
  // Dialog,
  // Notification,
  MessageBox,
} from 'element-react';
import 'element-theme-default';
import {
  // Typography,
  Paper,
  Container,
  Grid,
} from '@material-ui/core';
import qs from 'qs';

import {
  BraintreeDropIn,
} from '../common';
import {
  getCertPurchaseDetails as getCertPurchaseDetailsApi,
  // purchaseCertificate as purchaseCertificateApi,
  studentCertificatePaySubmit as studentCertificatePaySubmitApi,
} from '../../api';
// import { labels } from '../../lang/labels';
// import { messages } from '../../lang/messages';
// import AppHelper from '../../helpers/AppHelper';
// import GLOBAL from '../../helpers/GLOBAL';

// const CONSTANT = require('../../helpers/ConstantHelper');

const GeneralPurchaseScreen = (props) => { 
  const { t } = useTranslation();
  // const {
  //   history,
  // } = props;
  let params = props.location.search;
  params = qs.parse(params.slice(1));
  const {
    exam_id: examId,
    new_grade_id: newGradeId,
    refer_url: refreUrl,
    type,
  } = params;
  // const user = GLOBAL.get('user');
  // const {
  //   cbeRole,
  // } = user;
  // const isTeacherRole = cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER);
  // const isUserInChina = false; // AppHelper.isUserInChina(); // not work
  // const isCNLang = GLOBAL.get('lang')?.includes('cn');

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [message, setMessage] = useState('');
  const btDiv = React.useRef(null);

  const fetchDetails = async() => {
    try {
      setLoading(true);
      let res = await getCertPurchaseDetailsApi({ examId });
      setLoading(false);
      if (res && res.data) {
        let details = res.data?.certPurchaseDetails || {};
        setDetails(details);
      } else {
        console.warn(res);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchPurchaseCertificate = async(params) => {
    try {
      setPurchaseLoading(true);
      let res = await studentCertificatePaySubmitApi(params);
      setPurchaseLoading(false);
      if (res && res.data) {
        let success = res.data?.success || {};
        if (success) {
          MessageBox.confirm(
            t('purchase.paySubmitSucceed'),
            '',
            {
              type: 'success',
              confirmButtonText: t('confirm'),
              showCancelButton: false,
            }
          ).then(() => {
            window.location.href = refreUrl;
          }).catch(()=> {
            console.log('canceled');
          });
        } else {
          console.warn(res);
          setMessage(res?.data);
        }
      } else {
        console.warn(res);
        setMessage(res?.data);
      }
    } catch (e) {
      console.error(e);
      setMessage(e?.message);
    } finally {
      setPurchaseLoading(false);
    }
  };


  useEffect(() => {
    fetchDetails();
  }, []);

  const renderBtDropIn = () => {
    const onBTError = (error) => {
      console.log(error);
    };

    const onBTInit = ({ instance }) => {
      if (btDiv) {
        btDiv.current.scrollIntoView({ behavior: 'smooth'});
      }
    };

    const onPaymentMethodNonceReceived = ({ nonce }) => {
      MessageBox.confirm(
        t('purchase.please-cfm-payment-of') + ' '
          + details?.paymentCurrency + ' '
          + (details?.paymentCurrency === 'CNY' ? '￥' : '$')
          + (details?.originalPrice),
        '',
        {
          type: 'warning',
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        }
      ).then(() => {
        let params = {
          examID: examId,
          amount: details?.originalPrice,
          payment_method_nonce: nonce,
          type,
        };
        if (type == 2) {
          params.newGradeId = newGradeId;
        }
        fetchPurchaseCertificate(params);

      }).catch(()=> {
          console.log('canceled');
      });
    };

    return (
      <div className="pay">
        <Loading loading={purchaseLoading}>
        {
          <Grid item ref={btDiv}>
            <Paper square className='px-3 py-3'>
              <BraintreeDropIn
                onError={onBTError}
                onInitialized={onBTInit}
                onPaymentMethodNonceReceived={onPaymentMethodNonceReceived}
              />
            </Paper>
          </Grid>
        }
        </Loading>
      </div>
    );
  };

  const renderForm = () => {
    const onSubmit = (e) => {
      e.preventDefault();
    };

    // const onClickSubmitBtn = async () => {
    //   let isOK = checkCommonParams();
    //   if (!isOK) {
    //     return;
    //   }
    // };

    return (
      <Loading loading={loading}>
        <Form model={details} labelWidth="120" onSubmit={onSubmit}>
          <Form.Item label={t('instrument')} required>
            <span>{details?.instrumentName}</span>
          </Form.Item>
          <Form.Item label={t('grade')} required>
            <span>{details?.gradeName}</span>
          </Form.Item>
          {/*<Form.Item label={t('address')}>
            <span>{details?.address}</span>
          </Form.Item>
          <Form.Item>
            <span>{details?.address2}</span>
          </Form.Item>
          <Form.Item label={t('suburb')}>
            <span>{details?.suburb}</span>
          </Form.Item>
          <Form.Item label={t('postcode')}>
            <span>{details?.postcode}</span>
          </Form.Item>
           <Form.Item label={t('country')}>
            <span>{details?.country}</span>
          </Form.Item>
          <Form.Item label={t('certificate-option')} required>
            {
              details?.countryIdD === 'AU'
              ?
              <span>{t('certificate-option-1')}</span>
              :
              <span>{t('certificate-option-2')}</span>
            }
          </Form.Item>
          */}
          <Form.Item label={t('purchase.candidate-general-payment-type')}>
            {
              type == 1 && <span>
                {t('purchase.candidate-general-payment-type-upgrade-certificate')+' '+t('purchase.int-courier-tracking-plus-digital-cert')}
              </span>
            }
            {
              type == 2 && <span>
                {t('purchase.candidate-general-payment-type-upgrade-level')+' '+details?.gradeName || newGradeId}
              </span>
            }
          </Form.Item>
          <Form.Item label={t('cost')} required>
            <span>
              {
                details?.paymentCurrency + ' '
                + (details?.paymentCurrency === 'CNY' ? '￥' : '$')
                + (details?.originalPrice)
              }
            </span>
          </Form.Item>
          {
            message &&
              <Form.Item>
                <Alert title={message} type="error" closable={false} />
              </Form.Item>
          }
          {/*<Form.Item style={{'marginBottom': '10px'}}>
            <Button
              type="primary"
              nativeType="submit"
              disabled={false}
              loading={loading?.submit}
              onClick={onClickSubmitBtn}
            >
              {t('proceed-for-payment')}
            </Button>
          </Form.Item>*/}
        </Form>
        {renderBtDropIn()}
      </Loading>
    );
  };

  return (
    <Container>
      <Grid>
        <div style={{ 'marginLeft': '10%', 'marginRight': '10%', 'marginTop': '2%', 'marginBottom': '2%', "paddingTop": '10px', "paddingBottom": '10px'}}>
          {renderForm()}
       </div>
      </Grid>
    </Container>
 );
};

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {
})(GeneralPurchaseScreen);
