import React from 'react';
// import _ from 'lodash';
import { connect } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  // Container,
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  // Paper,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Grid,
  Collapse,
  Divider,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Home as HomeIcon,
  // Add as AddIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  Schedule as ScheduleIcon,
  // Chat as ChatIcon,
  Power as PowerIcon,
  Settings as SettingsIcon,
  QueueMusic as QueueMusicIcon,
  LibraryMusic as LibraryMusicIcon,
  AttachMoney as AttachMoneyIcon,
  AccountBox as AccountBoxIcon,
  MenuBook as MenuBookIcon,
  Help as HelpIcon,
  // HelpOutline as HelpOutlineIcon,
  // Dashboard as DashboardIcon,
  ExitToApp as ExitToAppIcon,
  PeopleAlt as PeopleAltIcon,
  Language as WwwIcon,
} from '@material-ui/icons';
import {
  blue,
  // orange,
  // deepOrange,
  // purple,
  // pink,
  // green,
  lightBlue,
  // lightGreen,
  red,
} from '@material-ui/core/colors';

import {
  logout,
} from '../../actions';

import AppHelper from '../../helpers/AppHelper';
import GLOBAL from '../../helpers/GLOBAL';
import './css/NavigationBarWrapper.css';

import config from '../../config';
const env = process.env.REACT_APP_ENV || 'dev';
const region = process.env.REACT_APP_REGION || 'global';

const hostWww = config.cbeWwwUrl[env][region];

const drawerWidth = 240;
const studentSidbarBtns = [
  {
    color: blue[500],
    label: 'dash.home',
    path: '/dash',
    icon: <HomeIcon />,
    activeIcon: <HomeIcon style={{ color: blue[500] }} />
  },
  {
    ignoreTheLabel: true,
    collapse: [
      {
        color: lightBlue[500],
        label: 'dash.enrolNewExam',
        path: '/enrol-new-exam',
        icon: <AddCircleOutlineIcon />,
        activeIcon: <AddCircleOutlineIcon style={{ color: lightBlue[500] }} />,
      },
      {
        color: lightBlue[500],
        label: 'dash.performanceEvaluation',
        path: '/performance-evaluation',
        icon: <AddCircleOutlineIcon />,
        activeIcon: <AddCircleOutlineIcon style={{ color: lightBlue[500] }} />,
      }
    ]
  },
  {
    ignoreTheLabel: true,
    collapse: [
      {
        color: lightBlue[500],
        label: 'dash.myOpenExams',
        path: '/open-exam-list',
        icon: <LibraryMusicIcon />,
        activeIcon: <LibraryMusicIcon style={{ color: lightBlue[500] }} />,
      },
      {
        color: lightBlue[500],
        label: 'dash.myCompletedExams',
        path: '/my-completed-exams',
        icon: <ScheduleIcon />,
        activeIcon: <ScheduleIcon style={{ color: lightBlue[500] }} />,
      },
      {
        color: lightBlue[500],
        label: 'dash.myEvaluations',
        path: '/my-evaluations',
        icon: <ScheduleIcon />,
        activeIcon: <ScheduleIcon style={{ color: lightBlue[500] }} />,
      }
    ]
  },
  {
    color: lightBlue[500],
    label: 'dash.requestExamPiece',
    path: '/request-exam-piece',
    icon: <QueueMusicIcon />,
    activeIcon: <QueueMusicIcon style={{ color: lightBlue[500] }} />
  },
  {
    ignoreTheLabel: true,
    collapse: [
      {
        color: lightBlue[500],
        label: 'dash.paymentHistory',
        path: '/payment-history',
        icon: <AttachMoneyIcon />,
        activeIcon: <AttachMoneyIcon style={{ color: lightBlue[500] }} />,
      },
      {
        color: lightBlue[500],
        label: 'dash.personalDetails',
        path: '/personal-details',
        icon: <AccountBoxIcon />,
        activeIcon: <AccountBoxIcon style={{ color: lightBlue[500] }} />,
      },
    ]
  },
  {
    ignoreTheLabel: true,
    collapse: [
      {
        color: lightBlue[500],
        label: 'dash.syllabus',
        path: '/syllabus',
        icon: <LibraryMusicIcon />,
        activeIcon: <LibraryMusicIcon style={{ color: lightBlue[500] }} />,
      },
      {
        color: lightBlue[500],
        label: 'dash.hallOfFame',
        path: '/hall-of-fame',
        icon: <LibraryMusicIcon />,
        activeIcon: <LibraryMusicIcon style={{ color: lightBlue[500] }} />,
      },
    ]
  },
  {
    ignoreTheLabel: true,
    collapse: [
      {
        color: lightBlue[500],
        label: 'dash.examGuidelines',
        path: '/exam-guidelines',
        icon: <MenuBookIcon />,
        activeIcon: <MenuBookIcon style={{ color: lightBlue[500] }} />,
      },
      {
        color: lightBlue[500],
        label: 'dash.gradingGuidelines',
        path: '/grading-guidelines',
        icon: <MenuBookIcon />,
        activeIcon: <MenuBookIcon style={{ color: lightBlue[500] }} />,
      },
      {
        color: lightBlue[500],
        label: 'dash.feeStructure',
        path: '/exam-fee-structure',
        icon: <AttachMoneyIcon />,
        activeIcon: <ScheduleIcon style={{ color: lightBlue[500] }} />,
      },
   ]
  },
  {
    ignoreTheLabel: true,
    collapse: [
      {
        color: lightBlue[500],
        label: 'dash.evaluationGuidelines',
        path: '/evaluation-guidelines',
        icon: <MenuBookIcon />,
        activeIcon: <MenuBookIcon style={{ color: lightBlue[500] }} />,
      },
      {
        color: lightBlue[500],
        label: 'dash.evaluationFeeStructure',
        path: '/evaluation-fee-structure',
        icon: <AttachMoneyIcon />,
        activeIcon: <ScheduleIcon style={{ color: lightBlue[500] }} />,
      },
   ]
  },
  {
    ignoreTheLabel: true,
    collapse: [
      {
        color: lightBlue[500],
        label: 'dash.settings',
        path: '/settings',
        icon: <SettingsIcon />,
        activeIcon: <SettingsIcon style={{ color: lightBlue[500] }} />
      },
      {
        color: lightBlue[500],
        label: 'dash.requestHelp',
        path: '/request-help',
        icon: <HelpIcon />,
        activeIcon: <HelpIcon style={{ color: lightBlue[500] }} />,
      },
//      {
//        color: lightBlue[500],
//        label: 'dash.wwwBackToConBrioExaminations',
//        path: '/cbe-www',
//        icon: <WwwIcon />,
//        target: '_blank',
//        activeIcon: <WwwIcon style={{ color: lightBlue[500] }} />,
//      },
    ]
  },
  {
    color: red[500],
    label: 'dash.logout',
    path: '/logout',
    icon: <ExitToAppIcon color="secondary" />
  }
];

const teacherSidbarBtns = [
  studentSidbarBtns[0],
  {
    color: red[500],
    label: 'dash.studentList',
    path: '/student-list',
    icon: <PeopleAltIcon color="secondary" />
  },
  ...studentSidbarBtns.slice(1)
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    border: 'none'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    border: 'none'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    backgroundColor: '#fff',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: '#fff',
  },
  contentContainer: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: '#f6f8fa',
    marginTop: 64,
    justifyContent: 'center',
  },
  main: {
    width: '100%',
  },
  gridContainer: {
    width: '100%',
    height: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const createSidebarBtnsInitState = (sidebarBtns) => {
  let result = {};
  sidebarBtns.forEach(item => {
    result[item.label] = item.collapse && item.collapse.length > 0;
  });
  return result;
};

const _NavigationBarWrapper = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const user = GLOBAL.get('user');

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [sidebarBtns, setSidebarBtns] = React.useState(AppHelper.isCBETeacher() ? teacherSidbarBtns : studentSidbarBtns);
  const [sidebarBtnsCollapse, setSidebarBtnsCollapse] = React.useState(createSidebarBtnsInitState(sidebarBtns));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    props.logout();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        className={clsx('white-bg', classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className="title" noWrap>
            { t('signin.welcome') }, {AppHelper.getFullname(user.firstName, user.lastName)}
          </Typography>
          <Avatar src={user.avatarUrl} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
        <Link to={{ pathname: hostWww }} target="_blank" style={{textDecoration:"none", color:"black"}}>
          <React.Fragment>
              <div style={{display:"inline-block", verticalAlign: "middle"}}>
                <img alt="logo" height="28px" width="28px" src={require('../../img/logo.png')} />
              </div>
              <div style={{display:"inline-block", verticalAlign: "middle"}}>
                <Typography variant="h6">
                  &nbsp;{t('cbe')}
                </Typography>
              </div>
          </React.Fragment>
          </Link>
          
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>

        {
          // Removed for performance.
          // open ? 
          //   <Paper className="ghost-white-bg br-3 avatar-container mb-3" elevation={0}>
          //     <Avatar src={user.avatarUrl} />
          //     <Typography variant="body1">{AppHelper.getFullname(user.firstName, user.lastName)}</Typography>
          //   </Paper>
          //   :
          //   null
        }

        <List>
          {
            sidebarBtns.map((btn) => {
              const selected = btn.path === location.pathname;
              const isLogout = btn.label === 'dash.logout';
              const collapse = open ? btn.collapse : btn?.collapse?.slice(0,1);

              return (
                <>
                  {
                    !btn.ignoreTheLabel && (<ListItem
                      button
                      key={btn.label}
                      selected={selected}
                      style={selected ? { borderRight: `4px ${btn.color} solid` } : {}}
                      onClick={() => (isLogout ? handleLogout() : history.push(btn.path))}
                    >
                      <ListItemIcon>
                        {selected ? btn.activeIcon : btn.icon}
                      </ListItemIcon>
                      <ListItemText primary={t(btn.label)} style={(selected || isLogout) ? { color: btn.color, fontSize: '0.7rem' } : { fontSize: '0.7rem' }} />
                    </ListItem>)
                  }
                  <Collapse in={sidebarBtnsCollapse[btn.label]} key={btn.label + '-collapse'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {
                        collapse && collapse.map(item => (
                          <ListItem
                            button
                            key={item.label}
                            selected={item.path === location.pathname}
                            onClick={() => (isLogout ? handleLogout() : history.push(item.path))}
                            >
                            <ListItemIcon>
                              {selected ? item.activeIcon : item.icon}
                            </ListItemIcon>
                            <ListItemText primary={t(item.label)} style={(selected || isLogout) ? { color: item.color, fontSize: '0.7rem' } : { fontSize: '0.7rem' }} />
                          </ListItem>
                        ))
                      }
                    </List>
                  </Collapse>
                  <Divider />
                </>
              );
            })
          }
        </List>
      </Drawer>
      <main className={`${classes.main}`}>
        <Grid container className={`${classes.gridContainer}`}>
          {/* inner fluid container */}
          <div className={`${classes.contentContainer}`}>
            { props.children }
          </div>
        </Grid>
        
        {/* outer container wrapper */}
        {/* <div className={`ghost-white-bg br-3 ${clsx([{'opened-outer-container': open, 'outer-container': !open}])}`} /> */}
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

export const NavigationBarWrapper = connect(mapStateToProps, {
  logout
})(_NavigationBarWrapper);
